import React, {ReactElement, useCallback, useEffect, useMemo, useRef, useState} from "react";
import clsx from "clsx";

import {Link, useNavigate} from "react-router-dom";

import {CustomerLoanAppraisal, Project as ProjectType, ProjectTask, Query, SearchFormData, SetProjectNameRequest} from "@app/types";
import {dateToString} from "@utils/format";
import {isOverdue} from "@utils/dates";

import ContentTitle from "@features/Layout/components/ContentTitle";
import Paper from "@components/atoms/Paper";
import Button from "@components/atoms/Button";
import RemixIcon from "@components/atoms/RemixIcon";
import DashboardBlock from "@components/organisms/DashboardBlock";
import DashboardBlockExpandable from "@components/organisms/DashboardBlockExpandable";
import {Aside} from "@features/Layout";

import DashboardBlockEmpty from "@components/molecules/DashboardBlockEmpty";
import ProjectSummary from "@components/organisms/ProjectSummary";
import ProjectFullSummary from "@components/organisms/ProjectFullSummary";
import DashboardLines from "@components/organisms/DashboardLines";
import DashboardTagLines, { Tag, TagLine } from "@components/organisms/DashboardTagLines";
import type {TagState} from "@components/organisms/DashboardTagLines";
import CompareAction from "@components/molecules/CompareAction";

import {urlEncodeGuid} from "@utils/format";

import styles from "./Project.module.css";
import { decodeSearch } from "@app/api/rest/query";
import Dialog from "@components/atoms/Dialog";
import Question from "@components/organisms/Question";

interface FeedProps {
  projectId?: string;
  acquisitionId?: string;
}

interface TasksProps {
  projectId: string;
  appraisalId?: string;
  groupBy?: "lender";
  show?: "ongoing" | "complete";
  showBage?: boolean;
  showStatus?: boolean;
  countActive?: boolean;
  onLoad?: (count: number, value?: ProjectTask[]) => unknown;
}

interface ProjectProps {
  projectId: string;
  loading?: boolean;
  value?: ProjectType;
  archiving?: boolean;
  renaming?: boolean;
  renameSuccess?: boolean;
  onOpen: (projectId: string) => void;
  onClose: () => void;
  onArchive: (projectId: string) => unknown;
  onRename: (request: SetProjectNameRequest) => unknown;
  onRenameReset: () => unknown;
  onSetQuery: (value: SearchFormData) => unknown;  
  Feed: (props: FeedProps) => ReactElement | null;  
  Tasks: (props: TasksProps) => ReactElement | null;
}

const SHOW_MORE = {
  caption: "Show more",
};

const NEW_SEARCH = {
  caption: "New search",
  url: "/search/new/step-1/",
};

const questionText = `Are you sure that you want to move this project to archive?`;

function selectState(item: CustomerLoanAppraisal): TagState | undefined {  
  if (item.status === 'Submitted') return 'complete';
  if (item.status === 'Active') return 'active';
  if (item.status === 'Expired') return 'expired';
  if (item.status === 'CancelledByLender') return 'declined';
  if (item.status === 'CancelledByUser') return 'cancelled';
  return undefined;
}

function stateToText(value: string) {
  const re = /([A-Z])/gm;
  return value.replace(re, (match) => ' ' + match).trim();  
}

function getOfferIsReady(offer: CustomerLoanAppraisal, tasks?: ProjectTask[]) {  
  if (!tasks) return false;
  if (offer.status !== 'Active') return false;
  const offerTasks = ((tasks || []) as ProjectTask[])
    .filter(item => item && item.relatedLenderIds && item.relatedLenderIds.indexOf(offer.lender.id) >= 0)
    .filter(item => item.status !== 'Completed');  
  return offerTasks.length === 0;
}

export default function Project({projectId, loading, archiving, renaming, renameSuccess, value, onOpen, onClose, onArchive, onRename, onRenameReset, onSetQuery, Feed, Tasks}: ProjectProps) {
  const [showMoveToArchive, setShowMoveToArchive] = useState(false);
  //
  const openMoveToArchive = useCallback(() => {
    setShowMoveToArchive(true);
  }, [setShowMoveToArchive]);
  //
  const closeMoveToArchive = useCallback(() => {
    setShowMoveToArchive(false);
  }, [setShowMoveToArchive]);
  //
  useEffect(() => {
    onOpen(projectId);
    return () => {
      onClose();
    };
  }, [projectId, onOpen, onClose]);

  const handleProjectArchive = useCallback(() => {
    onArchive && onArchive(projectId);
  }, [onArchive]);

  const handleProjectRename = useCallback(
    (name: string) => {
      onRename && onRename({projectId, name});
    },
    [onRename]
  );

  const navigate = useNavigate();

  const handleNewSearch = useCallback((value: Query) => {
    onSetQuery(decodeSearch(value));
    navigate('/search/new/');
  }, [navigate, onSetQuery]);

  const refArchiving = useRef<boolean | undefined>(false);

  useEffect(() => {
    if (refArchiving.current !== archiving) {
      if (refArchiving.current === true) {
        closeMoveToArchive();
        navigate('/');
      }
      refArchiving.current = archiving;
    }    
  }, [archiving, closeMoveToArchive, navigate]);

  const NEW_SEARCH_ACTION = useMemo(() => {
    return {
      caption: 'New search',
      onClick: () => value ? handleNewSearch(value?.loanQuery) : null,
    };
  }, [handleNewSearch, value]);

  const [selection, setSelection] = useState({} as Record<number, boolean>);
  const selectionCount = useMemo(() => Object.keys(selection).length, [selection]);

  const compareUrl = useMemo(() => {
    return (
      `/projects/${projectId}/compare/` +
      Object.keys(selection)
        .map((i: string) => value?.offers[+i].offerId || "0")
        .map(urlEncodeGuid)
        .join(",")
    );
  }, [selection, value]);

  const handleToggleOffer = useCallback(
    i => {
      setSelection(current => {
        const result = {...current};
        if (result[i]) delete result[i];
        else result[i] = true;
        return result;
      });
    },
    [setSelection]
  );

  const [tasks, setTasks] = useState<ProjectTask[] | undefined>();

  const handleTasksLoaded = useCallback((count: number, tasks?: ProjectTask[]) => {
    setTasks(tasks);
  }, [setTasks]);

  const aside = (
    <>
      <Feed projectId={projectId} />
      <Paper className={styles["help-container"]} size="large" border>
        <RemixIcon className={styles["help-icon"]} value="chat-1" color="secondary" size="giant" margin={2} />
        <a className={styles["link"]} href={`${process.env.BLOG}/faqs/`}>
          Do you have any questions?
          <br />
          Read our FAQs
        </a>
      </Paper>
    </>
  );
  const offers = useMemo(() => {    
    return value && value.offers
      ? value.offers.map((item, i) => ({
          url: `/projects/${value.id}/offers/${item.appraisalId}/`,
          tags: [
            getOfferIsReady(item, tasks) ? {state: 'ready' as TagState, node: <>Ready to submit</>} : null,
            {state: selectState(item), node: <>{stateToText(item.status)}</>},
            {state: selectState(item), node: <>due {dateToString(item.deadline)}</>},
            {node: <>{item.name} Offer</>},
            {node: <>{isOverdue(item.deadline) && item.status === 'Active' ? <RemixIcon className={styles["overdue"]} value="alert" /> : null}</>}
          ].filter(item => !!item) as unknown as Tag[],
          actions: [
            {node: <CompareAction active={selectionCount > 1} url={compareUrl} index={i} selected={selection[i]} onToggle={handleToggleOffer} />}
          ]
        }))
      : undefined;
  }, [value, tasks, selection, selectionCount, handleToggleOffer]);
  return (
    <>
      <ContentTitle title="Dashboard" breadcrumb={{url: "/", caption: "Dashboard"}} />
      <ContentTitle
        title={value?.name || "(Project not found)"}
        loading={loading || value === undefined}
        breadcrumb={{url: `/projects/${value?.name}/`, caption: value?.name || ""}}
        editable
        onChange={handleProjectRename}
        onChangeReset={onRenameReset}
        renaming={renaming}
        renamed={renameSuccess}
      />
      {value?.status !== "Archive" ? (
        <Paper border margin="medium" size="small">
          <Button
            icon={<RemixIcon value="archive" size="small" margin={1} />}
            caption="Move to archive"
            layout="auto"
            margin={0}
            loading={archiving}
            onClick={openMoveToArchive}
          />
        </Paper>
      ) : null}
      <Aside aside={aside}>
        <DashboardBlockExpandable icon="file-list" caption="Summary" short={<ProjectSummary project={value} />} full={<ProjectFullSummary query={value?.loanQuery} offers={value?.offers} />} />
        <DashboardBlock icon="history" caption="Offers" bage={value?.offers?.length} action={NEW_SEARCH_ACTION}>
          {offers && offers.length ? <DashboardTagLines value={offers} /> : <DashboardBlockEmpty name="offers" />}
        </DashboardBlock>
        <Tasks projectId={projectId} showBage showStatus countActive onLoad={handleTasksLoaded} />
      </Aside>
      <Dialog open={showMoveToArchive} onClose={closeMoveToArchive}>
        <Question title="" text={questionText} action="Move" onAction={handleProjectArchive} onCancel={closeMoveToArchive} loading={archiving} />
      </Dialog>
    </>
  );
}
