import React, {useCallback, useEffect, useState} from 'react';
import Button from '@components/atoms/Button';
import InlineInput from '@components/atoms/InlineInput';
import ProjectRow from '../ProjectRow';

import {DeveloperProjectExperience} from '@app/types';

import styles from './PreviousProjects.module.css';
import { FormErrors } from '@features/Tasks/hooks/validate';

interface PreviousProjectsProps {
  error?: FormErrors<DeveloperProjectExperience>[];
  value?: DeveloperProjectExperience[];
  active?: boolean;
  onChange: (value: DeveloperProjectExperience[]) => unknown;
}

export default function PreviousProjects({error, value, active, onChange}: PreviousProjectsProps) {
  const [rows, setRows] = useState<DeveloperProjectExperience[]>(value || [{} as DeveloperProjectExperience]);
  const handleRowChange = useCallback((index: number, value?: DeveloperProjectExperience) => {
    if (!value) return;
    setRows((rows) => {
      const result = [...rows];
      result[index] = value;
      return result;
    });
  }, [setRows]);
  const handleAddRow = useCallback(() => {
    setRows(rows => ([
      ...rows,
      {} as DeveloperProjectExperience,
    ]));
  }, [setRows]);
  const handleRowRemove = useCallback((i: number) => {
    setRows((rows) => {
      const source = [...rows];
      source.splice(i, 1);
      const result = source;
      return result;
    });
  }, [setRows]);
  useEffect(() => {
    if (!value) return;
    setRows(value);
  }, [value, setRows]);
  useEffect(() => {
    onChange(rows);
  }, [rows, onChange]);

  return (
    <div className={styles['container']}>
      <table className={styles['grid']}>
        <thead>
          <tr>
            <th>Location</th>
            <th>Type</th>
            <th>No. of units</th>
            <th>GDV</th>
            <th>Development cost</th>
            <th>Year delivered</th>
          </tr>
        </thead>
        <tbody>
          {rows ? rows.map((row: DeveloperProjectExperience, i: number) => (
            <ProjectRow error={error ? !!error[i] : false} index={i} value={row} onChange={handleRowChange} onRemove={handleRowRemove} key={i} canRemove={rows?.length > 1 && active} active={active} />
          )) : null}
        </tbody>
      </table>
      {active ? <Button type="button" caption="Additional row" margin={0} color="inline" size="big" onClick={handleAddRow} /> : null}
    </div>
  );
};
