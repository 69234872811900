import React from 'react';
import ContentTitle from '@features/Layout/components/ContentTitle';
import {Navigate, Outlet} from 'react-router-dom';
import {connect} from 'react-redux';
import {selectors, RootState} from '@app/store/session';
import {Dashboard} from '@features/Dashboard';

export interface DashboardViewProps {  
  loggedIn: boolean;

}

export function DashboardView({loggedIn}: DashboardViewProps) {
  if (!loggedIn) return (console.log('navigating to search'),
    
    <Navigate to="search/" replace />
  );
  return (
    <>
      <ContentTitle title="Your dashboard" breadcrumb={{caption: 'Dashboard', url: '/'}} />
      <Dashboard />
    </>
  );
}

export default connect(
  (state: RootState) => ({
    loggedIn: selectors.isLoggedIn(state),
  })
)(DashboardView);
