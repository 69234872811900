import React, {useCallback} from 'react';
import clsx from 'clsx';

import {File} from '@app/types';

import Button from '@components/atoms/Button/Button';
import Paper from '@components/atoms/Paper';
import RemixIcon from '@components/atoms/RemixIcon';
import Spinner from '@components/atoms/Spinner';
import Title from '@components/atoms/Title';

import {useConfirm, useDelete, useDownload} from '@hooks/.';

import styles from './DownloadFile.module.css';

interface DownloadFileProps {  
  active?: boolean;
  sampleFile?: File;
  file?: File;
  onDeleted?: () => unknown;
}

export default function DownloadFile({active = true, onDeleted, sampleFile, file}: DownloadFileProps) {
  const {loading, error, download} = useDownload(); 
  const {loading: deleting, error: deleteError, perform} = useDelete({onDeleted});
  const usingFile = file || sampleFile;
  const handleDownload = useCallback(async () => {
    if (!usingFile) return;
    download(usingFile);
  }, [usingFile, download]);
  const handleDelete = useCallback(() => {
    if (file) {
      perform(file.fileId)
    }
  }, [file]);
  const {open, dialog} = useConfirm({question: 'Are you sure that you want to delete this file?', onAction: handleDelete, processing: deleting});
  if (!usingFile) return null;
  const isUploaded = usingFile === file;
  const errors = error || deleteError ? [error, deleteError].filter(item => !!item).join('. ') : undefined;
  return (
    <Paper size="large" margin="large">
      <Title className={styles['caption']} level={2}>Download</Title> 
      {!isUploaded ? <div className={styles['description']}>Download a sample document, fill it out</div> : null}
      <div className={clsx(styles['control'], loading ? styles['loading'] : null)} onClick={handleDownload}>
        {usingFile.fileName}
        <span className={styles['icon']}>
          <RemixIcon value="download-2" />
          <Spinner active={loading} />
        </span>
      </div>
      {errors ? <div className={styles['error']}>{errors}</div> : null}
      {isUploaded && active ? <div className={styles['actions']}>
        <Button type="button" className={styles['action']} caption="Delete file" layout="auto" margin={0} loading={deleting} onClick={open} />
      </div> : null}
      {dialog}
    </Paper>
  );
};

