import {createContext} from "react";

import type {SearchFormData} from '@app/types/search';
export type {SearchFormData as FormData};
export type FormDataGetter = () => SearchFormData;

export interface ContextData {
  get: () => SearchFormData | undefined;
  setGetter: (v: FormDataGetter) => void;
}

const context = createContext<ContextData>({} as ContextData);
const {Consumer, Provider} = context;

export default context;
export {
  Consumer, 
  Provider,
};
