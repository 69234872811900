import React, { ReactElement } from 'react';
import clsx from 'clsx';

import Button from '@components/atoms/Button';
import Title from '@components/atoms/Title';

import styles from './PageAction.module.css';

type ButtonProps = Parameters<typeof Button>[0];
type ButtonComponent = (props: ButtonProps) => ReactElement;

interface PageActionProps {
  className?: string;
  title?: string;
  subTitle?: string;
  action?: string;
  urlAction?: string;
  onAction?: () => void;
  Action?: ButtonComponent;
}

export default function PageAction({className, title, subTitle, action, urlAction, onAction, Action}: PageActionProps) {
  const ActionControl = Action || Button;
  return (
    <div className={clsx(styles['container'], className)}>
      <div className={styles['content']}>
        <Title className={styles['title']} level={3}>
          {title}
        </Title>
        {subTitle ? <div className={styles['sub-title']}>{subTitle}</div> : null}
      </div>
      <ActionControl className={styles['action']} caption={action} url={urlAction} onClick={onAction} currentWindow />
    </div>
  );
}
