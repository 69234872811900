import React from 'react';
import Money from '@components/atoms/Money';

import SummaryValueControl from '@components/atoms/SummaryValue';
import { AreaMetric } from '@app/types';
import AreaUnits from '@components/atoms/AreaUnits';
import {dateToString, dateTimeToString} from '@utils/format';

type SummaryValueControlProps = Parameters<typeof SummaryValueControl>[0];

interface SummaryValueProps extends Omit<SummaryValueControlProps, "children"> {
  format?: 'text' | '%' | '%.0' | '%.2' | 'currency' | 'integer' | 'date' | 'datetime' | 'months' | 'weeks' | 'unknown_field' | 'shariah';
  metric?: AreaMetric;
  value?: string | number | null;
  clickable?: boolean;
}

function convertText(value: string) {
  if (value === 'GroundUp') return 'Ground-up';
  if (value === 'CancelledByUser') return 'Cancelled by user';
  if (value === 'FirstTimeDevelopment') return 'First time development';
  if (value === 'ExperiencedDeveloper') return 'Experienced developer';
  if (value === 'MainContractor') return 'Main contractor';
  if (value === 'ConstructionManagement') return 'Construction management';
  if (value === 'PD') return 'Permitted development';
  if (value === 'DD') return 'Due dilligence'
  return value;
}

export default function SummaryValue({format = 'text', metric, value, ...props}: SummaryValueProps) {
  return (
    <SummaryValueControl {...props}>
      {format === 'unknown_field' ? '???' : null}       
      {format === 'text' && value ? convertText(value.toString()) : null}
      {format === '%' ? <><Money value={value as number || 0} oneDigit />%</> : null}
      {format === '%.0' ? <><Money value={value as number || 0} integer />%</> : null}
      {format === '%.2' ? <><Money value={value as number || 0} />%</> : null}
      {format === 'currency' ? <Money value={value as number || 0} currency="£" integer /> : null}
      {format === 'integer' ? <Money value={value as number || 0} integer /> : null}
      {format === 'date' ? dateToString(value as string) : null}
      {format === 'datetime' ? dateTimeToString(value as string) : null}
      {format === 'months' ? <><Money value={value as number || 0} integer /> months</> : null}
      {format === 'weeks' ? <><Money value={value as number || 0} integer /> weeks</> : null}
      {format === 'shariah' ? (value ? 'Shariah' : 'Conventional' ) : null}
      {metric ? <> <AreaUnits value={metric} /></> : null}
    </SummaryValueControl>
  );
};
