import React, { useState } from 'react';
import DashboardBlock from '@components/organisms/DashboardBlock';
import {Lender} from '@app/types';

import Dialog from '@components/atoms/Dialog';
import LenderPopup from '@features/Search/components/LenderPopup';

import styles from './RelatedLenders.module.css';

interface RelatedLendersProps {
  loading?: boolean;
  value?: Lender[];
}

export default function RelatedLenders({loading, value}: RelatedLendersProps) {  
  const [openLender, setOpenLender] = useState<Lender | undefined>();
  return (    
    <DashboardBlock caption="Related lenders" size='small' icon="file-list" border>
      <ul className={styles['lenders']}>
        {value ? value.map((item: Lender, i: number) => (
          <li className={styles['lender']} key={i} onClick={() => setOpenLender(item)}>
            {item.name}
          </li>
        )) : null}
      </ul>
      {/*<SummaryBlock columns={COLUMNS} value={value as Value} loading={loading} padding={1} />*/}      
      <LenderPopup value={openLender} setter={setOpenLender} />
    </DashboardBlock>
  );
};
