import React, {useCallback} from 'react';
import {BorrowerDetailsForm} from '@app/types';

import {useBorrowerDetails} from '../../hooks/useBorrowerForm';
import TextInput from '@components/molecules/TextInput';
import RadioInput from '@components/molecules/RadioInput';
import { FormErrors } from '@features/Tasks/hooks/validate';

interface BorrowingSpvProps {
  error?: FormErrors<BorrowerDetailsForm>
  value: BorrowerDetailsForm;
  active?: boolean;
  onChange: (value: BorrowerDetailsForm) => unknown;
}

export default function BorrowingSpv({error, value, active, onChange}: BorrowingSpvProps) {
  const handleChange = useCallback((value?: BorrowerDetailsForm) => {
    if (value) onChange(value);
  }, [onChange]);
  const {fields} = useBorrowerDetails({error, values: value, onChange: handleChange});
  const {companyName, typeOfCompany, address, countryOfIncorporation, registrationNumber} = fields;
  return (
    <>
      <TextInput field={companyName} margin={0} smallError readOnly={!active} />
      <RadioInput field={typeOfCompany} margin={0} smallError readOnly={!active} />
      <TextInput field={countryOfIncorporation} margin={0} smallError readOnly={!active} />      
      <TextInput field={registrationNumber} margin={0} smallError readOnly={!active} />
      <TextInput field={address} smallError readOnly={!active} />
    </>
  );
};
