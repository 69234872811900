import {useCallback} from 'react';
import useForm, {FieldsDefs, useFieldForm} from "@hooks/useForm";

import {Asset, AssetAndLiability, Liability} from "@app/types";
import { FormErrors } from './validate';
import {FormErrors as NativeErrors} from '@hooks/useForm';

export const fieldsAssetsAndLiabilities = {
  gurantor: {
    name: 'gurantor',
    caption: 'Guarantor full name',
    description: 'Enter the name',
    required: true,
    hint: 'Enter your name and surname.',    
  }
} as FieldsDefs<AssetAndLiability>;

export const fieldsAssets = {
  mainResidence: {
    name: 'mainResidence',
    caption: 'Home residence',
    description: 'Enter amount',
    hint: 'Current value of your home.',
    placeholder: '£',
    prefix: '£',
    type: 'int',
  },
  asset1: {
    name: 'asset1',
    caption: 'Property investments',
    description: 'Enter amount',
    hint: 'Current value of any other real estate investments.',
    placeholder: '£',
    prefix: '£',
    type: 'int',
  },
  asset2: {
    name: 'asset2',
    caption: 'Stock and shares',
    description: 'Enter amount',
    hint: 'Current value of any stocks and shares owned.',
    placeholder: '£',
    prefix: '£',
    type: 'int',
  },
  cashResources: {
    name: 'cashResources',
    caption: 'Cash resources',
    description: 'Enter amount',
    hint: 'Current amount of cash resources.',
    placeholder: '£',
    prefix: '£',
    type: 'int',
  },
  otherInvestments: {
    name: 'otherInvestments',
    caption: 'Other investments',
    description: 'Enter amount',
    hint: 'Any other investments not listed above.',
    placeholder: '£',
    prefix: '£',
    type: 'int',
  },
} as FieldsDefs<Asset>;

export const fieldsLiabilities = {
  mortgage: {
    name: 'mortgage',
    caption: 'Mortgage',
    description: 'Enter amount',
    hint: 'Current amount of any mortgage secured against your home.',
    placeholder: '£',
    prefix: '£',
    type: 'int',
  },
  otherDebt: {
    name: 'otherDebt',
    caption: 'Other debt',
    description: 'Enter amount',
    hint: 'Current amount of any other mortgages.',
    placeholder: '£',
    prefix: '£',
    type: 'int',
  },
  bankOverdraft: {
    caption: 'Bank overdraft',
    name: 'bankOverdraft',
    description: 'Enter amount',
    hint: 'Current amount of bank overdrafts.',
    placeholder: '£',
    prefix: '£',
    type: 'int',
  },
  creditCards: {
    name: 'creditCards',
    caption: 'Credit cards',
    description: 'Enter amount',
    hint: 'Current amount on your credit cards.',
    placeholder: '£',
    prefix: '£',
    type: 'int',
  },
  otherLiabilities: {
    name: 'otherLiabilities',
    caption: 'Other liabilities',
    description: 'Enter amount',
    hint: 'Current amount of any other debts not listed above.',
    placeholder: '£',
    prefix: '£',
    type: 'int',
  },
} as FieldsDefs<Liability>;

interface UseAssetAndLiabilityProps {
  values?: AssetAndLiability;
  error?: FormErrors<AssetAndLiability>
  onChange?: (value?: AssetAndLiability) => unknown;
}

export default function useAssetAndLiabilityForm({error, values, onChange}: UseAssetAndLiabilityProps) {
  const {fields, handleSubmit, errors} = useForm({
    title: 'Asset and Liability',
    fields: fieldsAssetsAndLiabilities,
    values,
    onChange,
    trackValues: true,
    errors: error ? error as NativeErrors<AssetAndLiability> : undefined,
  });
  const handleChangeAssets = useCallback((assets?: Asset) => {
    if (assets) {
      if ((!values || (values.assets !== assets)) && onChange) {
        onChange({
          ...(values || {} as AssetAndLiability),
          assets,
        });
      }
    }
  }, [values, onChange]);
  const handleChangeLiabilites = useCallback((liabilities?: Liability) => {
    if (liabilities) {
      if ((!values || (values.liabilities !== liabilities)) && onChange) {
        onChange({
          ...(values || {} as AssetAndLiability),
          liabilities,
        });
      }
    }
  }, [values, onChange]);
  // const assets = useFieldForm({field: fields.assets, fields: fieldsAssets, trackValues: true});
  // const liabilities = useFieldForm({field: fields.liabilities, fields: fieldsLiabilities, trackValues: true});
  const assets = useForm({fields: fieldsAssets, values: values?.assets, onChange: handleChangeAssets, trackValues: true});
  const liabilities = useForm({fields: fieldsLiabilities, values: values?.liabilities, onChange: handleChangeLiabilites, trackValues: true});
  return {    
    fields,
    errors,
    assets,    
    liabilities,
    handleSubmit,
  };
};
