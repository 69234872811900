import {connect} from 'react-redux';
import {actions, selectors, RootState} from '@app/store/contacts';
import ContactUs from '../components/ContactUs';

export default connect(
  (state: RootState) => (console.log(state), {
    loading: selectors.postGetInTouch.loading(state),
    error: selectors.postGetInTouch.error(state),
    success: selectors.postGetInTouch.success(state),
    done: selectors.postGetInTouch.done(state),
  }), {
    onSubmit: actions.postGetInTouch.post,
    onReset: actions.postGetInTouch.reset,
  }  
)(ContactUs);
