import {TaskStatus} from './tasks';

export type AcquisitionStatus = 'Owned' | 'Ongoing';
export type AreaMetric = 'Ft' | 'M';
export type ExitStrategy = 'Sell' | 'Rent';
export type ExperienceLevel = 'FirstTimeDevelopment' | 'ExperiencedDeveloper';
export type PlanningStatus = 'Granted' | 'Pending';
export type ProcurementRoute = 'MainContractor' | 'ConstructionManagement';
export type ProjectStatus = 'DD' | 'Archive';
export type ProposedDevelopment = 'PD' | 'GroundUp' | 'Refurbishment';
export type UnitType = 'Flat' | 'House' | 'Commercial';
export interface UnitDetails {
  type: UnitType;
  count?: number;
  valuePerUnit?: number;
  area?: number;
  valuePerMetricEntity?: number;
  totalValue?: number;
}
export type YesNoUnsure = 'No' | 'Yes' | 'Unsure';
export type YesNo = 'Yes' | 'No';

export const ProposedDevelopmentCaptions = {
  'GroundUp': 'Ground-up',
  'PD': 'Permitted development',
  'Refurbishment': 'Refurbishment',
};

export interface Search {
  id?: string;
  userId?: string;
  createdOn?: string;
  modifiedOn?: string;
  siteFullAddress?: string;
  sitePostcode?: string;
  siteCity?: string;
  siteCountry?: string;
  siteRegion?: string;
  acquisitionStatus?: AcquisitionStatus;
  planningStatus?: PlanningStatus;
  proposedDevelopment?: ProposedDevelopment;
  projectSummary?: string;
  partBuiltScheme?: boolean;
  experienceLevel?: ExperienceLevel;
  completedSimilarSchemes?: number;
  canProvidePersonalGuarantee?: boolean | string;
  adverseCreditHistory?: boolean | string;
  countryOfResidence?: string;
  borrowingSPVDomicile?: string;
  areaMetric?: AreaMetric;
  unitDetails?: UnitDetails[];
  salesAgentFee?: number;
  salesLegalFee?: number;
  procurementRoute?: ProcurementRoute;
  gia?: number;
  buildCosts?: number;
  contingency?: number;
  professionalCosts?: number;
  otherCosts?: number;
  statutoryCosts?: number;
  purchasePrice?: number;
  stampDutyTaxLevy?: number;
  acquisitionAgentFee?: number;
  acquisitionLegalFee?: number;
  shariahCompliant?: boolean;
  netLoan?: number;
  brokerFee?: number;
  loanStartPeriodWeeks?: number;
  buildPeriodMonths?: number;
  loanTenureMonths?: number;
  exitStategy?: ExitStrategy;
  // * calc fields
  unitNumber?: number;
  nia?: number;
  averageUnitPrice?: number;
  averageUnitPricePerMeter?: number;
  developmentCost?: number;
  roc?: number;
  roGDV?: number;
  projectIRR?: number;
  grossDevelopmentValue?: number;
  salesCosts?: number;
  acquisitionCost?: number;
  ltgdv?: number;
  ltc?: number;
  netDevelopmentValue?: number;
  estimatedProfit?: number;
  buildCostWithContingency?: number;
  buildCostPerMetric?: number
  facility?: string;
  funding?: string;
  calculatedLTC?: number;
  displayName?: string;
  // int fields
  getBestOffers?: boolean;
  totalAcquisitionCost?: number;
  totalBuildCost?: number;
  commercialRationToTotalGDV?: number;
  acquisitionCostPerMetric?: number;
}


export type UnitDetailsFormData = {
  [key in UnitType]?: UnitDetails;
};

export interface SearchFormData extends Omit<Search, 'unitDetails'> {
  unitDetails?: UnitDetailsFormData;  
}

export type SearchPersistentFieldNames = 'id' | 'userId' | 'createdOn' | 'modifiedOn';
export type SearchAddressFieldNames = 'sitePostcode' | 'siteCity' | 'siteCountry' | 'siteRegion';
export type SearchStep1FieldNames =
  | 'projectSummary'
  | 'proposedDevelopment'
  | 'partBuiltScheme'
  | 'siteFullAddress'
  | 'acquisitionStatus'
  | 'planningStatus' 
  | SearchAddressFieldNames;
export type SearchStep2FieldNames =
  | 'experienceLevel'
  | 'completedSimilarSchemes'
  | 'canProvidePersonalGuarantee'
  | 'adverseCreditHistory'
  | 'countryOfResidence'
  | 'borrowingSPVDomicile';
export type SearchStep3FieldNames = 'areaMetric' | 'unitDetails' | 'salesAgentFee' | 'salesLegalFee' | 'nia';
export type SearchStep4FieldNames =
  | 'procurementRoute'
  | 'gia'
  | 'buildCosts'
  | 'contingency'
  | 'professionalCosts'
  | 'statutoryCosts'
  | 'otherCosts'
  | 'nia';
export type SearchStep5FieldNames =
  | 'purchasePrice'
  | 'stampDutyTaxLevy'
  | 'acquisitionAgentFee'
  | 'acquisitionLegalFee';
export type SearchStep6FieldNames =
  | 'shariahCompliant'
  | 'ltc'
  | 'getBestOffers'
  | 'brokerFee'
  | 'buildPeriodMonths'
  | 'loanTenureMonths'
  | 'exitStategy'
  | 'loanStartPeriodWeeks'
  | 'totalAcquisitionCost'
  | 'totalBuildCost';

export type SearchStep1Data = Pick<SearchFormData, SearchStep1FieldNames>;
export type SearchStep2Data = Pick<SearchFormData, SearchStep2FieldNames>;
export type SearchStep3Data = Pick<SearchFormData, SearchStep3FieldNames>;
export type SearchStep4Data = Pick<SearchFormData, SearchStep4FieldNames>;
export type SearchStep5Data = Pick<SearchFormData, SearchStep5FieldNames>;
export type SearchStep6Data = Pick<SearchFormData, SearchStep6FieldNames>;
