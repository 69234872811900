import React, {useCallback, useEffect, useMemo} from "react";
import clsx from "clsx";
import {useNavigate} from "react-router-dom";

import {urlEncodeGuid} from "@utils/format";
import {ApplyOffersRequest, IdSet, LoanOfferSearchSorting} from "@app/types";

import Button from "@components/atoms/Button";
import Paper from "@components/atoms/Paper";
import RemixIcon from "@components/atoms/RemixIcon";

import styles from "./OffersActions.module.css";

interface OffersActionsProps {
  className?: string;
  loading?: boolean;
  reloading?: boolean;
  selection: IdSet;
  loggedIn: boolean;
  queryId: string;
  applying: boolean;
  applied: boolean;
  projectId?: string;
  sorting: LoanOfferSearchSorting;
  onSortingChange: (value: LoanOfferSearchSorting) => void;
  onSelectTop5: () => void;
  onReset: () => void;
  onApply: (request: ApplyOffersRequest) => void;
}

type ButtonColor = "primary" | undefined;

const PRIMARY: ButtonColor = "primary";
const PRICING = "pricing";
const EQUITY = "equity";
const ROE = "roe";
const IRR = "irr";
const CHEAPEST: LoanOfferSearchSorting = "Cheapest";
const MIN_EQUITY: LoanOfferSearchSorting = "MinEquity";
const MAX_RoE: LoanOfferSearchSorting = "MaxRoE";
const MAX_IRR: LoanOfferSearchSorting = "MaxIRR";

type ButtonColorMatrix = {
  [key in LoanOfferSearchSorting]: ButtonColor;
};

type SortMatrix = {
  pricing: ButtonColorMatrix;
  equity: ButtonColorMatrix;
  roe: ButtonColorMatrix;
  irr: ButtonColorMatrix;
};

const SORT_MATRIX = {
  [PRICING]: {
    [CHEAPEST]: PRIMARY,
  },
  [EQUITY]: {
    [MIN_EQUITY]: PRIMARY,
  },
  [ROE]: {
    [MAX_RoE]: PRIMARY,
  },
  [IRR]: {
    [MAX_IRR]: PRIMARY,
  },
} as SortMatrix;

export default function OffersActions({
  className,
  loading,
  reloading,
  selection,
  queryId,
  applying,
  applied,
  projectId,
  loggedIn,
  sorting,
  onSortingChange,
  onReset,
  onSelectTop5,
  onApply,
}: OffersActionsProps) {
  const compareUrl = useMemo(() => {
    return `/searches/${queryId}/compare/` + Object.keys(selection).map(urlEncodeGuid).join(",");
  }, [selection]);
  const selected = Object.keys(selection).length;
  const navigate = useNavigate();
  const handleApply = useCallback(() => {
    const offers = Object.keys(selection);
    onApply({queryId, offers});
  }, [selection, onApply, queryId]);
  useEffect(() => {
    if (applied && projectId) {
      navigate(`/projects/${projectId}/`);
    }
  }, [applied, projectId, navigate]);
  useEffect(() => {
    return () => {
      onReset();
    };
  }, [onReset]);
  const handleSetSortingPricing = useCallback(() => {
    onSortingChange(CHEAPEST);
  }, [onSortingChange]);
  const handleSetSortingEquity = useCallback(() => {
    onSortingChange(MIN_EQUITY);
  }, [onSortingChange]);
  const handleSetSortingRoE = useCallback(() => {
    onSortingChange(MAX_RoE);
  }, [onSortingChange]);
  const handleSetSortingIRR = useCallback(() => {
    onSortingChange(MAX_IRR);
  }, [onSortingChange]);
  return (
    <Paper className={clsx(styles["container"], className)} margin="large" loading={loading}>
      <div className={styles["actions"]}>
        {loggedIn ? (
          <>
            <Button
              className={styles["order"]}
              caption="Best pricing"
              color={SORT_MATRIX[PRICING][sorting] || "secondary"}
              loading={SORT_MATRIX[PRICING][sorting] && reloading}
              light
              layout="auto"
              margin={0}
              onClick={handleSetSortingPricing}
            />
            <Button
              className={styles["order"]}
              caption="Lowest equity"
              color={SORT_MATRIX[EQUITY][sorting] || "secondary"}
              loading={SORT_MATRIX[EQUITY][sorting] && reloading}
              light
              layout="auto"
              margin={0}
              onClick={handleSetSortingEquity}
            />
            <Button className={styles["order"]} caption="Best ROE" color={SORT_MATRIX[ROE][sorting] || "secondary"} 
              loading={SORT_MATRIX[ROE][sorting] && reloading}
            light layout="auto" margin={0} onClick={handleSetSortingRoE} />
            <Button className={styles["order"]} caption="Best IRR" color={SORT_MATRIX[IRR][sorting] || "secondary"}
            loading={SORT_MATRIX[IRR][sorting] && reloading} light layout="auto" margin={0} onClick={handleSetSortingIRR} />
          </>
        ) : null}
      </div>
      <div className={styles["actions"]}>
        {loggedIn ? <Button className={styles["action"]} caption="Select top 5" color="secondary" layout="auto" margin={0} onClick={onSelectTop5} /> : null}
        {loggedIn ? <Button
          className={styles["action"]}
          icon={<RemixIcon className={styles["action-icon"]} value="checkbox-multiple" size="small" />}
          disabled={selected < 2 || !loggedIn}
          caption="Compare"
          color="secondary"
          layout="auto"
          margin={0}
          url={compareUrl}
        /> : null}
        {loggedIn ? (
          <Button
            className={styles["action"]}
            icon={<RemixIcon className={styles["action-icon"]} value="thumb-up" size="small" />}
            disabled={selected === 0}
            loading={applying}
            caption="Apply"
            color="primary"
            layout="auto"
            margin={0}
            onClick={handleApply}
          />
        ) : null}
      </div>
    </Paper>
  );
}
