import {connect} from 'react-redux';

import {actions, selectors, RootState} from '@app/store/history';

import Feed from '../components/Feed';

export default connect(
  (state: RootState) => ({
    loading: selectors.loading(state),
    items: selectors.value(state),
  }),
  {
    onOpen: actions.get,
    onClose: actions.reset,
  }
)(Feed);
