import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import clsx from "clsx";

import SummaryGroup from "../SummaryGroup/SummaryGroup";

import styles from "./SummaryBlock.module.css";


type ArrayType<T> = T extends ArrayLike<infer R> ? R : never;
type SummaryGroupPropsSource = Parameters<typeof SummaryGroup>[0];
export type SummaryGroupProps = Omit<SummaryGroupPropsSource, "value">;
export type Value = SummaryGroupPropsSource["value"];
export type SummaryGroupRow = SummaryGroupProps[];
export type SummaryItem = ArrayType<SummaryGroupPropsSource["items"]>;

interface SummaryBlockProps {
  className?: string;
  loading?: boolean;
  value?: Value;
  columns?: SummaryGroupProps[];
  padding?: number;
  rows?: SummaryGroupRow[];
  onUpdateHeight?: (value: number) => void;
  opacity?: number;
  noTitle?: boolean;
  onClickItem?: (item: SummaryItem) => unknown;
}

function createRow(columns: SummaryGroupProps[], value?: Value, loading?: boolean, onClickItem?: (item: SummaryItem) => unknown) {
  return (
    <>
      {columns.map((item, i) => (
        <SummaryGroup className={styles["group"]} {...item} value={value} loading={loading} key={i} onClickItem={onClickItem} />
      ))}
    </>
  );
}

function createRows(rows: SummaryGroupRow[], value?: Value, loading?: boolean, onClickItem?: (item: SummaryItem) => unknown) {
  return rows.map((item, i) => (
    <div className={styles["row"]} key={i}>
      {createRow(item, value, loading, onClickItem)}
    </div>
  ));
}

export default function SummaryBlock({className, loading, value, columns, rows, padding, opacity, noTitle, onClickItem, onUpdateHeight}: SummaryBlockProps) {
  const refContainer = useRef<HTMLDivElement | null>(null);
  const [, setHeight] = useState(0);
  const recalcHeight = useCallback(() => {
    if (!value || !refContainer.current || !onUpdateHeight) return;
    const result = refContainer.current.offsetHeight;
    setHeight(value => {
      if (value !== result) onUpdateHeight(result);
      return result;
    });
  }, [setHeight, onUpdateHeight, value, refContainer]);
  //
  useEffect(() => {
    recalcHeight();
  }, [recalcHeight]);
  //
  const stylesControl = useMemo(
    () => ({
      opacity,
    }),
    [opacity]
  );
  const animated = opacity !== undefined;
  if (!value && !loading) return null;
  return (
    <div
      className={clsx(
        styles["container"], 
        rows ? styles["rows"] : null, 
        padding ? styles["padding_" + padding.toString()] : null, 
        padding && noTitle ? styles["no-title_padding_" + padding.toString()] : null, 
        animated ? styles["animated"] : null,        
        className
      )}
      ref={refContainer}
      style={stylesControl}
    >
      {/*{value ? <SummaryGroup {...value} /> : null}*/}
      {columns ? createRow(columns, value, loading, onClickItem) : null}
      {rows ? createRows(rows, value, loading, onClickItem) : null}
    </div>
  );
}
