import Api, {ApiError} from './api';
// import JwtSession from '../utils/jwt-session';
import Auth from './auth';
import Contacts from './contacts';
import History from './history';
import Session from './session';
import Query from './query';
import Projects from './projects';
import Offers from './offers';
import {RestApiError} from '../utils/rest-api';
import {storage} from '../../../utils/storage';
import {AuthToken, JwtToken} from '@app/types';
import Tasks from './tasks';
import Files from './files';
import jwtDecode from 'jwt-decode';

export const LOGIN_URL = `${process.env.MAIN_HOST}/MicrosoftIdentity/Account/SignIn`;

const api = new Api(process.env.API_URL!!);

export const auth = new Auth(api);
export const contacts = new Contacts(api);
export const history = new History(api);
export const projects = new Projects(api);
export const query = new Query(api);
export const session = new Session(api);
export const tasks = new Tasks(api);
export const files = new Files(api);
export const offers = new Offers(api);

let timer: ReturnType<typeof setTimeout> | null = null;
let loginToken: string | undefined;

function setLoginTimer(accessToken?: string) {
  if (accessToken) {
    const token = jwtDecode(accessToken) as JwtToken;
    const now = Math.trunc((new Date().getTime()) / 1000);
    const duration = (token.exp - now - 30)*1000;
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      // window.location.href = LOGIN_URL;
    }, duration);
    console.log('SET LOGIN TIMER', duration);
  } else {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
  }
}

function checkToken(value?: string) {
  if (loginToken !== value) {    
    loginToken = value;
    setLoginTimer(value);
  }
}

api.onGetAccessToken = async function () {
  const token = storage.token;
  checkToken(token?.accessToken);
  return token?.accessToken;
};

api.onAuthError = async function() {  
  /*const token = storage.token;
  if (token) {
    // @todo remove from here
    // if (window.location.pathname !== '/') window.location.pathname = '/';    
  } else {*/
    window.location.href = `${process.env.MAIN_HOST}/MicrosoftIdentity/Account/SignIn`;
  //}
};

auth.onSignIn = setToken;
session.onSignOut = setToken;

async function setToken(value?: AuthToken) { 
  storage.token = value;
}

/*export const jwtSession = new JwtSession(auth.refreshToken.bind(auth));
api.onGetAccessToken = jwtSession.getToken;
jwtSession.start();

auth.onSignIn = jwtSession.setToken;
session.onSignOut = jwtSession.setToken;*/

export default {
  auth,
  history,
  offers,
  projects,
  query,
  session,
  tasks,
  files,
};

export {ApiError, RestApiError};
