import React, {useMemo} from 'react';
import clsx from 'clsx';

import ProjectTile from '../ProjectTile';

import styles from './ProjectTiles.module.css';

interface ProjectTilesProps {
  className?: string;
  loading?: boolean;
  items?: unknown[];
  baseUrl: string;
  colCount?: number;
}

export default function ProjectTiles({className, loading, items, baseUrl, colCount=3}: ProjectTilesProps) {
  const tiles = useMemo(() => {
    if (loading) {
      if (colCount === 3) return [undefined, undefined, undefined];
      else return [undefined, undefined, undefined, undefined];
    }
    else return items;
  }, [items, loading, colCount]);
  return (
    <ul className={clsx(styles['container'], colCount ? styles[`col-count_${colCount}`] : null, className)}>
      {tiles ? tiles.map((item, i) => (
        <ProjectTile {...item} className={styles['tile']} loading={loading} baseUrl={baseUrl} key={i} />
      )) : null}
    </ul>
  )
}