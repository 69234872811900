import React, {useMemo} from 'react';

import {formatAmount} from 'src/utils/format';

interface MoneyProps {
  value?: number;
  currency?: string;
  integer?: boolean;
  oneDigit?: boolean;
}

export default function Money({value, currency, integer, oneDigit}: MoneyProps) {
  const result = useMemo(() => {
    return formatAmount(value, currency, integer, oneDigit);
  }, [value, currency]);
  return <>{result}</>;
}
