enum HttpMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Patch = 'PATCH',
  Delete = 'DELETE',
}

export type Headers = Record<string, string>;
export type GetAccessTokenProc = () => Promise<string | undefined>;

type FetchRequest = {
  method: HttpMethod;
  headers: Headers;
  body?: string | FormData | null;
}

export class RestApiError extends Error {
  constructor(message: string, readonly status: number, readonly data: Record<string, unknown>) {
    super(message);
  }
  static isRestApiError(e: unknown) {
    return e instanceof RestApiError;
  }
}

export type RestApiResponse = object | Blob | string | void; 

export default class RestApi {
  private _onGetAccessToken: GetAccessTokenProc | undefined;
  constructor(private readonly baseUrl: string) {}
  get onGetAccessToken() {
    return this._onGetAccessToken;
  }
  set onGetAccessToken(value: GetAccessTokenProc | undefined) {
    this._onGetAccessToken = value;
  }
  get<Response extends RestApiResponse>(url: string, headers?: Headers): Promise<Response> {
    return this._request<void, Response>(HttpMethod.Get, url, undefined, headers);
  }
  post<Request, Response extends RestApiResponse>(url: string, data?: Request, headers?: Headers): Promise<Response> {
    return this._request<Request, Response>(HttpMethod.Post, url, data, headers);
  }
  put<Request, Response extends RestApiResponse>(url: string, data?: Request, headers?: Headers): Promise<Response> {
    return this._request<Request, Response>(HttpMethod.Put, url, data, headers);
  }
  patch<Request, Response extends RestApiResponse>(url: string, data?: Request, headers?: Headers): Promise<Response> {
    return this._request<Request, Response>(HttpMethod.Patch, url, data, headers);
  }
  delete<Request, Response extends RestApiResponse>(url: string, data?: Request, headers?: Headers): Promise<Response> {
    return this._request<Request, Response>(HttpMethod.Delete, url, data, headers);
  }
  private async _request<Request, Response extends RestApiResponse>(
    method: HttpMethod,
    url: string,
    data?: Request,
    requestHeaders?: Headers,
  ): Promise<Response> {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...(requestHeaders || {}),
    } as Headers;    
    if (!headers.Authorization && headers.Authorization !== '') {
      const accessToken = await this.getAccessToken();
      if (accessToken) headers.Authorization = `Bearer ${accessToken}`;
    }
    const options = {
      method: method || 'GET',
      body: data ? JSON.stringify(data) : null,
      headers,
    } as FetchRequest;
    if (data instanceof FormData) {
      delete options.headers['Content-Type'];
      options.body = data;
    }
    //@todo: make other manipulations    
    let baseUrl = this.baseUrl;
    if (url && url[0] === '^') {
      const parts = baseUrl.split('/');
      baseUrl = parts.slice(0, parts.length - 1).join('/');
      url = url.slice(1);
    }
    const response = await fetch(baseUrl + url, options);
    if (response.ok) {
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.indexOf('application/json') !== -1) return response.json();
      if (contentType && contentType.indexOf('text') !== -1) return response.text() as Response;
      return response.blob() as Response;
    } else {
      const contentType = response.headers.get('content-type');
      const data = contentType && contentType.indexOf('application/json') !== -1 ? await response.json() : undefined;
      const error = new RestApiError(response.statusText, response.status, data);
      throw error;
    }
  }
  private getAccessToken(): Promise<string | undefined> {
    if (this._onGetAccessToken) return this._onGetAccessToken();
    else return Promise.resolve(undefined);
  }
}
