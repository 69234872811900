import React, {useCallback, useContext, useMemo, ReactElement, useRef, KeyboardEvent, useState, useEffect} from "react";
import {SearchFormData, UnitDetails, UnitDetailsFormData} from "@app/types";
import useForm, {Fields, FieldsDefs, FormErrors, BeforeUpdateHandlerProps} from "@hooks/useForm";
import Button from "@components/atoms/Button";
import Errors from '../Errors';
import Context from '../../contexts/form';

import styles from "./Form.module.css";
import { useNavigate } from "react-router-dom";
import deepEqual from "deep-equal";

export interface FormStepProps<StepData> {
  fields: Required<Fields<StepData, {}>>;
  loading?: boolean;
  value?: SearchFormData;
}

export type FormStep<StepData> = (props: FormStepProps<StepData>) => ReactElement;
type SubmitHandler<T> = (value: T) => any;

interface FormProps<StepData> {
  className?: string;
  fields: FieldsDefs<StepData, {}>;
  index: number;
  queryId?: string;
  value?: SearchFormData;
  loading?: boolean;
  posting?: boolean;
  first?: boolean;
  last?: boolean;
  Step: FormStep<StepData>;
  onSubmit: SubmitHandler<SearchFormData>;
  onSetStep?: (index: number) => void;
}

function cleanupUnitDetails(v?: UnitDetails): UnitDetails | undefined {
  if (!v) return v;
  const result = {...v};
  if (!result.area && !result.count && !result.valuePerUnit) return {
    area: undefined,
    count: undefined,
    totalValue: undefined,
    type: undefined,
    valuePerMetricEntity: undefined,
    valuePerUnit: undefined,
  } as unknown as UnitDetails;
  return result;
}

function cleanupUnitDetailsFormData(v?: UnitDetailsFormData): UnitDetailsFormData | undefined {
  if (!v) return v;
  return {
    Commercial: cleanupUnitDetails(v.Commercial),
    Flat: cleanupUnitDetails(v.Flat),
    House: cleanupUnitDetails(v.House),
  } as UnitDetailsFormData;
}

export default function Form<T extends {}>({className, fields: fieldsDefs, queryId, index, loading, posting, first, last, value, onSubmit, onSetStep, Step}: FormProps<T>) {
  const refValue = useRef<SearchFormData | undefined>(value);
  const handleOnBeforeUpdate = useCallback(({values, name, value}: BeforeUpdateHandlerProps<T, {}>) => {    
    const v = values as SearchFormData;
    const n = name as string;
    if (n === 'unitDetails') {
      const v1 = cleanupUnitDetailsFormData(refValue.current?.unitDetails);
      const v2 = cleanupUnitDetailsFormData(value);
      const equal = deepEqual(v1, v2);
      if (!equal) {
        v.gia = undefined;
        v.nia = undefined;
        v.buildCosts = undefined;        
      }
    }    
    refValue.current = values;
  }, [refValue]);
  const {fields, errors, handleSubmit} = useForm({title: "Test", fields: fieldsDefs, values: value as T, onBeforeUpdate: handleOnBeforeUpdate});
  const navigate = useNavigate();
  useEffect(() => {
    if (!posting && value && value.id && value.id !== queryId) {
      const pathname = window.location.pathname;
      const re = /step-(\d*)/gmi;
      const res = re.exec(pathname);      
      const url = ('/search/{id}/step-{index}/').replace('{id}', value?.id || 'new').replace('{index}', (index).toString());
      navigate(url);
    } 
  }, [navigate, queryId, value, posting, index]);
  const handleBack = useCallback(() => {        
    const pathname = window.location.pathname;
    const re = /step-(\d*)/gmi;
    const res = re.exec(pathname);
    if (res) {
      const index = +res[1];
      if (index > 1) {
        const url = ('/search/{id}/step-{index}/').replace('{id}', value?.id || 'new').replace('{index}', (index - 1).toString());
        navigate(url);
      }
    }
  }, [navigate]);
  const handleKeyPress = useCallback((e: KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') e.preventDefault();
  }, []);
  const opts = useMemo(
    () => ({
      fields,
      loading,
      value,
    }),
    [fields, loading, value]
  ) as FormStepProps<T>;
  const hasErrors = errors && Object.keys(errors).length > 0;
  console.log('form errors', errors);
  useEffect(() => {
    if (hasErrors) window.scrollTo({left: 0, top: 0, behavior: 'smooth'});
  }, [hasErrors]);
  const fieldNames = Object.keys(fields) as (keyof T)[];
  const getter = useCallback(() => {
    return refValue.current!;
  }, [refValue]);
  const context = useContext(Context);
  context.setGetter(getter);
  return (
    <form className={className} onSubmit={handleSubmit(onSubmit as SubmitHandler<T>)} onKeyPress={handleKeyPress}>      
      {hasErrors ? <Errors items={errors} fields={fieldNames} /> : null}
      <Step {...opts} />
      <div className={styles["actions"]}>
        {first ? <span /> : <Button className={styles["action"]} type="button" caption="Previous step" onClick={handleBack} />}
        {last ? <span /> : <Button className={styles["action"]} type="submit" caption="Next step" color="primary" loading={posting} />}
      </div>
    </form>
  );
}
