import { InitialState } from './slice'
export * from './slice'

export interface RootState {
    session: InitialState
}

export const selectors = {
    isLoaded: (state: RootState) => state.session.loaded,
    isLoading: (state: RootState) => state.session.loading,
    isLoggedIn: (state: RootState) => !!state.session.value,
    user: (state: RootState) => state.session.value,
}