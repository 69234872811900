import React, {ReactNode, useEffect} from 'react';

import ErrorImage from '../ErrorImage';
import ErrorText from '../ErrorText';
import type {Action} from '../ErrorText';

import styles from './PageError.module.css';

interface PageErrorProps {
  title: ReactNode;
  message: ReactNode;
  actions?: Action[];
  image?: 'common' | 'search';
}

export default function PageError({title, message, actions, image}: PageErrorProps) {
  return (
    <div className={styles['container']}>
      <div className={styles['message']}>
        <ErrorText title={title} message={message} actions={actions} />
      </div>
      <ErrorImage className={styles['image']} image={image} />
    </div>
  );
};

export {
  Action
}