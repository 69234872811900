import React from 'react';

import styles from './ServerErrors.module.css';

interface ServerErrorsProps {
  errors: string[];
}

export default function ServerErrors({errors}: ServerErrorsProps) {
  return (
    <div className={styles['container']}>
      <div className={styles['title']}>Error</div>
      {errors.map((item, i) => (
        <div className={styles['item']} key={i}>{item}</div>
      ))}      
    </div>
  );
};
