import useForm, {FieldsDefs, useFieldForm, Option} from "@hooks/useForm";

import {BorrowerDetailsForm, ProposedSPV, Spv, BorrowerType, Shareholder} from "@app/types";
import {FormErrors as NativeFormErrors} from "@hooks/useForm";
import {FormErrors} from '@features/Tasks/hooks/validate';

interface UseFormProps<T> {
  error?: FormErrors<T>
  values: T;
  onChange: (value?: T) => unknown;
}

type UseBorrowerDetailsFormProps = UseFormProps<BorrowerDetailsForm>;
type UseShareholderProps = UseFormProps<Shareholder>;
type UseBorrowerDirectorProps = UseFormProps<Spv>;

const OPTIONS_BORROWER_TYPE = [
  {caption: 'Individual', value: 'Individual'},
  {caption: 'Corporate', value: 'Corporate'},
] as Option<BorrowerType>[];

const OPTIONS_COMPANY_TYPE = [
  {caption: 'LTD', value: 'LimitedCompany'},
  {caption: 'LLP', value: 'LLP'},
  {caption: 'Offshore', value: 'Offshore'},
] as Option<ProposedSPV>[];

const fieldsBorrowerDetails = {
  companyName: {
    name: 'companyName',
    caption: 'Company name',
    required: true,
    description: 'Enter the name',
    hint: 'Name of the borrowing SPV. If unknown at this stage, just enter TBD.',
  },
  typeOfCompany: {
    name: 'typeOfCompany',
    caption: 'Type of company',
    options: [
      {caption: 'LTD', value: 'LimitedCompany'},
      {caption: 'LLP', value: 'LLP'},
      {caption: 'Offshore', value: 'Offshore'},
    ] as Option<ProposedSPV>[],
    hint: 'Choose the appropriate option. If unknown at this stage, keep blank.',
  },
  countryOfIncorporation: {
    name: 'countryOfIncorporation',
    caption: 'Country of incorporation',
    required: true,
    description: 'Enter the country',
    hint: 'If unknown at this stage, tell us where you expect the SPV to be registered.',
  },
  registrationNumber: {
    name: 'registrationNumber',
    caption: 'Registration number',
    required: true,
    description: 'Enter the number',    
    hint: 'If unknown at this stage, enter 0.',
  },
  address: {
    name: 'address',
    caption: 'Registered address',
    description: 'Enter the full address',
    required: true,
    hint: 'If unknown at this stage, just enter TBD.',
  },
} as FieldsDefs<BorrowerDetailsForm>;

const fieldsShareholder = {
  type: {
    name: 'type',
    caption: 'Select appropirate',
    required: true,
    options: OPTIONS_BORROWER_TYPE,
    hint: 'Let us know know if the shareholder is an individual or a corporate entity.',
  },
  title: {
    name: 'title',
    caption: 'Title',
    description: 'Mr, Mrs or Miss',
    required: true,
    hint: 'Provide the appropriate title: Mr, Mrs, or Miss.',
  },
  name: {
    name: 'name',
    caption: (v) => v.type === 'Corporate' ? 'Company name' : 'Full name',
    description: (v) => v.type === 'Corporate' ? 'Enter name' : 'Name & Surname',
    required: true,
    hint: (v) => v.type === 'Corporate' ? 'Provide the name of the entity.' : 'Enter the name and surname',
  },
  dateOfBirth: {
    name: 'dateOfBirth',
    caption: 'Date of birth',
    description: 'Enter the DoB',
    required: true,
    hint: 'Enter the date of birth; for example 14/10/1989',
  },
  typeOfCompany: {
    name: 'typeOfCompany',
    caption: 'Type of company',    
    options: OPTIONS_COMPANY_TYPE,
    hint: 'Select the appropriate option. If unknown, keep blank.',
  },
  countryOfResidence: {
    name: 'countryOfResidence',
    caption: (v) => v.type === 'Corporate' ? 'Country of incorporation' : 'Country of residence',
    description: 'Enter the country',
    required: true,
    hint: (v) => v.type === 'Corporate' ? 'Provide the country where the company is registered.' : 'UK resident is defined as a person physically present in the UK for 183 days or more in a fiscal year.',
  },
  registrationNumber: {
    name: 'registrationNumber',
    caption: 'Registration number',
    description: 'Enter the number',    
    hint: 'If unknown, keep blank.',
  },
  address: {
    name: 'address',
    caption: (v) => v.type === 'Corporate' ? 'Registered address' : 'Home address',
    description: 'Enter the address',
    required: (v) => v.type !== 'Corporate',
    hint: (v) => v.type === 'Corporate' ? 'If unknown, keep blank.' : 'Current registered home address.',
  },
  shareholding: {
    name: 'shareholding',
    caption: '% shareholding',
    description: 'Enter the % shareholding',
    required: true,
    hint: 'How much do you own in the SPV; if not know at this stage, put 0%.',
    type: 'float',
  },
} as FieldsDefs<Shareholder>;

const fieldsBorrowerDirector = {
  type: {
    name: 'type',
    caption: 'Select appropriate',
    required: true,    
    options: OPTIONS_BORROWER_TYPE,    
    hint: 'Select the appropriate option',
  },
  title: {
    name: 'title',
    caption: 'Title',
    description: 'Mr, Mrs or Miss',
    required: true,
    hint: 'Provide the appropriate title: Mr, Mrs, or Miss.',
  },
  name: {
    name: 'name',
    caption: (v) => v.type === 'Corporate' ? 'Company name' : 'Full name',
    description: (v) => v.type === 'Corporate' ? 'Enter name' : 'Name & Surname',
    required: true,
    hint: (v) => v.type === 'Corporate' ? 'Provide the name of the entity.' : 'Enter the name and surname',
  },
  dateOfBirth: {
    name: 'dateOfBirth',
    caption: 'Date of birth',
    description: 'Enter the DoB',
    required: true,
    hint: 'Enter the date of birth; for example 14/10/1989',
  },
  typeOfCompany: {
    name: 'typeOfCompany',
    caption: 'Type of company',    
    options: OPTIONS_COMPANY_TYPE,
    hint: 'Select the appropriate option. If unknown, keep blank.',
  },
  countryOfResidence: {
    name: 'countryOfResidence',
    caption: (v) => v.type === 'Corporate' ? 'Country of incorporation' : 'Country of residence',
    description: 'Enter the country',
    required: true,
    hint: (v) => v.type === 'Corporate' ? 'Provide the country where the company is registered.' : 'UK resident is defined as a person physically present in the UK for 183 days or more in a fiscal year.',
  },
  registrationNumber: {
    name: 'registrationNumber',
    caption: 'Registration number',
    description: 'Enter the number',    
    hint: 'If unknown, keep blank.',
  },
  address: {
    name: 'address',
    caption: (v) => v.type === 'Corporate' ? 'Registered address' : 'Home address',
    description: 'Enter the address',
    required: (v) => v.type !== 'Corporate',
    hint: (v) => v.type === 'Corporate' ? 'If unknown, keep blank.' : 'Current registered home address.',
  },
} as FieldsDefs<Spv>;

function useBorrowerForm<T>(fieldsDefs: FieldsDefs<T>, {error, values, onChange}: UseFormProps<T>) {
  const {fields, handleSubmit} = useForm({    
    fields: fieldsDefs,
    values,  
    errors: error as NativeFormErrors<T>,
    onChange,
    trackValues: true,
  });
  return {
    fields, 
    handleSubmit,
  }
}

export function useBorrowerDetails(props: UseBorrowerDetailsFormProps) {
  return useBorrowerForm(fieldsBorrowerDetails, props);
};

export function useShareholder(props: UseShareholderProps) {
  return useBorrowerForm(fieldsShareholder, props);
};

export function useBorrowerDirector(props: UseBorrowerDirectorProps) {
  return useBorrowerForm(fieldsBorrowerDirector, props);
};
