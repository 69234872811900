import React from 'react';
import {useParams} from 'react-router-dom';
import {Project} from '@features/Dashboard';

export default function ProjectView() {
  const {projectId} = useParams();  
  if (!projectId) return null;
  return (
    <Project projectId={projectId} />
  )
}