import React, {useEffect, useMemo} from 'react';
import {ProjectTask} from '@app/types';
import Group from './Group';

interface TasksProps {
  loading: boolean;
  items?: ProjectTask[];
  onOpen: () => unknown;
  onClose: () => unknown;
}

interface ProjectIndexRecord {
  name: string;
  items: ProjectTask[];
}

type ProjectIndex = {
  [projectId: string]: ProjectIndexRecord;
}

function compareTasks(t1: ProjectTask, t2: ProjectTask) {
  if (t1.deadline < t2.deadline) return -1;
  if (t1.deadline > t2.deadline) return 1;
  return 0;
}

function compareGroups(g1: ProjectIndexRecord, g2: ProjectIndexRecord) {
  if (g1.name < g2.name) return -1;
  if (g1.name > g2.name) return 1;
  return 0;
}

const EMPTY = [] as ProjectTask[];

export default function Tasks({loading, items, onOpen, onClose}: TasksProps) {
  useEffect(() => {
    onOpen();
    return () => {
      onClose();
    }
  }, [onOpen, onClose]);
  const groups = useMemo(() => {
    if (!items) return undefined;
    const index = items.reduce((result, item: ProjectTask) => {
      if (!result[item.projectId]) result[item.projectId] = {
        name: item.projectName || 'Unnamed project',
        items: [],
      } as ProjectIndexRecord;
      result[item.projectId].items.push(item);
      return result;
    }, {} as ProjectIndex);
    const keys = Object.keys(index) as string[];
    return keys
      .map((key) => {
        const result = index[key];
        result.items.sort(compareTasks);
        return result;
      })
      .sort(compareGroups);
  }, [items]);  
  return (
    <>
      {loading ? [1,2].map((i) => (
        <Group loading name="loading" items={EMPTY} key={i} />
      )) : groups ? groups.map((item, i) => (
        <Group name={item.name} items={item.items} key={i} />
      )) : null}      
    </>
  );
};
