import React, {ReactNode} from 'react';
import clsx from 'clsx';

import CommonInput from '../CommonInput';

import styles from './PercentageText.module.css';

interface PercentageTextProps {
  className?: string;
  caption?: string | ReactNode;
  description?: string | ReactNode;
  value0?: string | number | ReactNode;
  value1?: string | number | ReactNode;
  margin?: number;
  tooltipCaption?: string;
  tooltip?: string;
}

export default function PercentageText({
  className,
  caption,
  description,
  value0,
  value1,
  margin,
  tooltipCaption,
  tooltip,
}: PercentageTextProps) {
  return (
    <CommonInput
      className={className}
      caption={caption}
      description={description}
      margin={margin}
      tooltipCaption={tooltipCaption}
      tooltip={tooltip}
      flex
    >      
      <span className={styles['text']}>{value0}</span>
      <span className={styles['delimiter']}>or</span>
      <span className={styles['text']}>{value1}</span>
    </CommonInput>
  );
}
