import { connect } from "react-redux"

import {selectors as session, RootState as AuthState} from '@app/store/session';
import {actions, selectors, RootState}  from '@app/store/search';

import OffersActions from '../components/OffersActions';

export default connect(
  (state: RootState) => ({
    loggedIn: session.isLoggedIn(state as unknown as AuthState),
    applying: selectors.offers.applying(state),
    applied: selectors.offers.applied(state),
    projectId: selectors.offers.projectId(state),
  }), {
    onApply: actions.offers.apply,
    onReset: actions.offers.reset,
  }
)(OffersActions);
