import React, {useMemo} from 'react';
import {useParams} from 'react-router-dom';

import ContentTitle from '@features/Layout/components/ContentTitle';
import PageError from '@components/organisms/PageError';
import type {Action} from '@components/organisms/PageError';
import {LOGIN_URL} from '@features/Auth/components/LoginButton/LoginButton';

interface NotFoundProps {
  loggedIn: boolean;
}

const title = <>We are sorry.<br/> We couldn't find a match.</>
const message = <>
Search help:
<ul>
  <li>Try to select “show me the best offers” in step 6</li>
   <li>You may want to reduce your loan amount as it may be exceeding our cap</li>
</ul>
</>;
//'Unfortunately, we could not find any results on your entered data. Try filling out the form again.';
const actions = [
  {caption: 'Sign Up / Sign In', primary: true, url: LOGIN_URL},
  {caption: 'Edit your search'},
] as Action[];

export default function NotFound({loggedIn}: NotFoundProps) {  
  const {queryId} = useParams();
  const editUrl = useMemo(() => {
    return `/search/${queryId}/step-7/`;
  }, [queryId]);
  const errorActions = useMemo(() => {
    let result = actions.map((item, i) => {
      const result = {...item};
      if (i === 1) result.url = editUrl;
      return result;
    });
    if (loggedIn) result = result.slice(1);
    return result;
  }, [editUrl]);
  const error = <PageError title={title} message={message} image="search" actions={errorActions} />
  return <ContentTitle title="" error={error} />  
};

