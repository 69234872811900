import React, {ChangeEvent, InputHTMLAttributes, ReactNode, useCallback, useMemo} from 'react';
import clsx from 'clsx';

import CheckBox from '@components/atoms/CheckBox';

import CommonInput from '../CommonInput';
import { Field } from '@hooks/useForm';

// import styles from './RadioInput.module.css';

export interface OptionObject<T> {
  caption: string | ReactNode;
  value: T;
}
export type Option<T> = T | OptionObject<T>;

interface RadioInputProps<T, N> {
  className?: string;
  field: Field<T, N, {}>;
  anchor?: string;
  name?: string;
  caption?: ReactNode;
  error?: ReactNode;
  required?: boolean;
  options?: Option<T>[];
  tooltip?: string | ReactNode;
  tooltipCaption?: string;  
  margin?: number;
  smallError?: boolean;
  readOnly?: boolean;
  register?: () => Partial<InputHTMLAttributes<HTMLInputElement>>;
}

export default function RadioInput<T, N>({
  className,
  field,
  anchor,
  name,
  smallError,
  caption,
  error,
  required,
  options: sourceOptions,
  register,
  tooltip,
  readOnly,
  tooltipCaption,
  margin,
}: RadioInputProps<T, N>) {
  const options = sourceOptions || field?.options;
  const fieldName = name || field?.name;  
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.currentTarget.value;
      const name = e.currentTarget.name;      
      field && field.setTextValue && field.setTextValue(value);
    },
    [field]
  );
  const changeProps = useMemo(
    () =>
      field
        ? {
            fieldValue: field.textValue,
            onChange: handleChange,
          }
        : {},
    [field, handleChange]
  );  
  const finalError = error || field?.error;
  return (
    <CommonInput
      className={className}
      caption={caption || field?.caption}
      error={smallError ? null : finalError}
      required={required || !!field?.required}
      description="Choose one of the options"
      tooltip={tooltip || field?.hint}      
      flex
      margin={margin}
    >
      {options 
        ? options.map((item, i) => (
            <CheckBox anchor={anchor} error={smallError && !!finalError} name={fieldName as string | undefined} value={item} readOnly={readOnly} {...changeProps} key={i} />
          ))
        : null}
    </CommonInput>
  );
}
