import React, {useMemo} from 'react';

import DashboardLine from '@components/molecules/DashboardLine';

import styles from './DashboardLines.module.css';
import Title from '@components/atoms/Title';
import Skeleton from '@components/atoms/Skeleton';

type DashboardLineProps = Parameters<typeof DashboardLine>[0];

interface DashboardLinesProps {
  caption?: string;
  loading?: boolean;
  bage?: string | number; 
  lines?: DashboardLineProps[];
  skeletonCount?: number;
}

export default function DashboardLines({caption, loading, bage, lines, skeletonCount = 4}: DashboardLinesProps) {
  const skeletons = useMemo(() => Array.from({length: skeletonCount}, (v, i) => (
    <DashboardLine loading key={i} />
  )), [skeletonCount]);
  return (
    <div className={styles['container']}>
      {caption ? <Title className={styles['caption']} level={3}>
        {loading ? <Skeleton active /> : <>
          {caption}
          {bage ? <span className={styles['bage']}> ({bage})</span> : null}
        </>}
      </Title> : null}
      {loading ? <ul className={styles['list']}>
        {skeletons}
      </ul> : lines ? <ul className={styles['list']}>
        {lines.map((props, i) => (
          <DashboardLine {...props} key={i} />
        ))}
      </ul> : null}
    </div>
  )
}