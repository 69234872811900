import React, { ReactNode } from 'react';
import Title from '@components/atoms/Title';
import Button from '@components/atoms/Button';

import styles from './ErrorText.module.css';

export interface Action {
  caption: string;
  url?: string;
  onClick?: () => void; 
  primary?: boolean;
}

interface ErrorTextProps {
  title: ReactNode;
  message: ReactNode;
  actions?: Action[];
}

export default function ErrorText({title, message, actions}: ErrorTextProps) {
  return (
    <>
      <Title className={styles.title}>{title}</Title>
      <p className={styles.message}>{message}</p>
      <div className={styles.actions}>
        {actions ? actions.map((item, i) => (
          <Button className={styles.action} color={item.primary ? 'primary' : undefined} caption={item.caption} url={item.url} onClick={item.onClick} key={i}/>
        )) : null}
      </div>
    </>
  );
};
