import React, {useEffect, useMemo} from 'react';

import DashboardBlock from '@components/organisms/DashboardBlock';

import {GetQueriesRequest, SearchFormData} from '@app/types';
import DashboardLines from '@components/organisms/DashboardLines';
import DashboardTagLines from '@components/organisms/DashboardTagLines';
import DashboardBlockEmpty from '@components/molecules/DashboardBlockEmpty';
import {selectMaxStepFilled} from '@app/store/search';

interface SearchesGroupProps {
  loading?: boolean;
  items?: SearchFormData[];
  onOpen: (request: GetQueriesRequest) => unknown;
  onClose?: () => never;
}

const ACTION = {
  caption: 'New search',
  url: '/search/',
}

export default function SearchesGroup({loading, items, onOpen, onClose}: SearchesGroupProps) {
  useEffect(() => {
    onOpen({top: 5});
    return () => {
      onClose && onClose();
    }
  }, [onOpen, onClose]);
  const empty = items?.length === 0;
  const lines = useMemo(() => {
    return items ? items.map((item) => ({
      url: `/search/${item.id}/step-${(selectMaxStepFilled(item) || 0) + 1}/`,
      tags: [
        {node: <>{item.displayName}</>}
      ]      
    })) : undefined;
  }, [items]);
  return (
    <DashboardBlock caption="Recent searches" bage={items?.length} action={ACTION}>
      {loading ? <DashboardLines loading={loading} /> : 
      !empty ? <DashboardTagLines value={lines} /> : <DashboardBlockEmpty name="searches" />}
    </DashboardBlock>    
  );
}