import {connect} from "react-redux";

import {actions, selectors, RootState} from '@app/store/tasks';
import {actions as actionsProjects, selectors as selectorsProjects, RootState as RootStateProjects} from '@app/store/projects';
import {actions as actionsOffers, selectors as selectorsOffers, RootState as RootStateOffers} from '@app/store/offers';

import TaskDetails from "./TaskDetails";
import Task from '../components/Task';

export default connect(
  (state: RootState) => ({
    loading: selectors.item.loading(state),
    saving: selectors.item.saving(state),
    sharing: selectors.item.sharing(state),
    completing: selectors.item.completing(state),
    completed: selectors.item.completed(state),
    cancelling: selectors.item.cancelling(state),
    task: selectors.item.value(state),
    loadingProject: selectorsProjects.item.isLoading(state as unknown as RootStateProjects),
    offer: selectorsOffers.item.value(state as unknown as RootStateOffers),
    project: selectorsProjects.item.value(state as unknown as RootStateProjects),
    TaskDetails,
  }),{
    onOpen: actions.item.open,
    onClose: actions.item.reset,
    onOpenProject: actionsProjects.item.open,
    onOpenOffer: actionsOffers.item.get,
    onSave: actions.item.save,
    onShare: actions.item.share,
    onComplete: actions.item.complete,
    onCompleteReset: actions.item.resetComplete,
    onCancel: actions.item.cancel,
  }
)
(Task);
