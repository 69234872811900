import {combineReducers} from '@reduxjs/toolkit';
import {item} from './slice';
import {getItem, submit, reject, setName} from './thunks';

export const reducer = combineReducers({
  item: item.reducer,
});

export type LocalState = ReturnType<typeof reducer>;

export interface RootState {
  offers: LocalState;
};

export const actions = {
  item: {
    get: getItem,
  },
  operations: {
    setName,
    setNameReset: item.actions.resetRename,
    reject,
    rejectReset: item.actions.resetReject,
    submit,
    submitReset: item.actions.resetSubmit,
  },
};

export const selectors = {
  item: {
    loading: (state: RootState) => state.offers.item.loading,
    value: (state: RootState) => state.offers.item.value,
    error: (state: RootState) => state.offers.item.error,
  },
  operations: {
    setName: {
      loading: (state: RootState) => state.offers.item.loading,
      success: (state: RootState) => state.offers.item.renamed,
    },
    reject: {
      loading: (state: RootState) => state.offers.item.rejecting,
      success: (state: RootState) => state.offers.item.rejected,
    },
    submit: {
      loading: (state: RootState) => state.offers.item.submitting,
      success: (state: RootState) => state.offers.item.submitted,
    },
  },
};
