import React, { MouseEventHandler } from 'react';
import clsx from 'clsx';

import RemixIcon from '../RemixIcon';

import styles from './CloseButton.module.css';

interface CloseButtonProps {
  className?: string;
  onClick?: MouseEventHandler;
}

export default function CloseButton({className, onClick}: CloseButtonProps) {
  return (
    <span className={clsx(styles['container'], className)} onClick={onClick}>
      <RemixIcon value="close" />
    </span>
  );
};
