import React, {useEffect} from 'react';
import {Project} from '@app/types';
import ProjectsGroup from '../ProjectsGroup';
import {ControllerOf} from '@app/types';
import {Aside} from "@features/Layout";
import Feed from '../Feed';
import Tasks from '../TasksGroup';
import SearchesGroup from '../SearchesGroup';

import styles from '../Project/Project.module.css';
import Paper from '@components/atoms/Paper';
import RemixIcon from '@components/atoms/RemixIcon';
import { Link } from 'react-router-dom';

type Query<T> = {
  loading: boolean;
  open: boolean;
  value?: T;
  error?: unknown;
}

interface DashboardProps {
  projects: Query<Project[]>;
  onOpenProjects: () => unknown;
  ProjectsGroup: ControllerOf<typeof ProjectsGroup, never>;
  Feed: ControllerOf<typeof Feed, never>;
  Tasks: ControllerOf<typeof Tasks, "groupBy" | "expandMode" | "show">;
  SearchesGroup: ControllerOf<typeof SearchesGroup, never>;
}

export default function Dashboard({projects, onOpenProjects, ProjectsGroup, Feed, Tasks, SearchesGroup}: DashboardProps) {
  useEffect(() => {
    onOpenProjects && onOpenProjects();
  }, [onOpenProjects]);
  const aside = (
    <>
      <Feed />
      <Paper className={styles['help-container']} size="large" border>
        <RemixIcon className={styles['help-icon']} value="chat-1" color="secondary" size="giant" margin={2} />        
        <a className={styles['link']} href={`${process.env.BLOG}/faqs/`}>Do you have any questions?<br/>Read our FAQs</a>
      </Paper>
    </>
  );
  return (
    <Aside aside={aside}>
      <ProjectsGroup />
      <Tasks show="ongoing" groupBy="lender" expandMode="redirect"  />
      <SearchesGroup />
    </Aside>
  );
};
