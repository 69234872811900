import {connect} from 'react-redux';

import {actions, selectors, RootState} from '@app/store/search';

import Steps from '../components/Steps';

import SearchActions from './SearchActions';
import Stepper from './Stepper';
import Step1 from './Step-1';
import Step2 from './Step-2';
import Step3 from './Step-3';
import Step4 from './Step-4';
import Step5 from './Step-5';
import Step6 from './Step-6';
import Step7 from './Step-7';

export default connect(
  (state: RootState) => ({
    queryId: selectors.current.queryId(state),
    loaded: selectors.current.loaded(state),
    posted: selectors.current.posted(state),
    saving: selectors.current.saving(state),
    savingForShare: selectors.current.savingForShare(state),
    step: selectors.current.step(state),
    SearchActions,
    Stepper,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    Step7,
  }),
  {
    onSetStep: actions.current.setStep,
    onSave: actions.current.save,
    onSaveForShare: actions.current.saveForShare,
    onReset: actions.current.reset,
  }
)(Steps);
