import Api from './api';
import {AuthToken, ApiResponse, User} from '../../types';

export type OnSignIn = (token: AuthToken) => void;

export default class Auth {
  onSignIn?: OnSignIn;

  constructor(private readonly api: Api) {}

  async signin(token: string) {
    // const accessToken = window.prompt('Paste the access token');
    // const result = await this.api.post<SigninRequest, SigninResponse>(auth.signin(), request);
    // @todo: get token from result
    //const token = {} as AuthToken;
    const authToken = {
      accessToken: token,
      refreshToken: 'refresh',
    };
    this.onSignIn && this.onSignIn(authToken);
    const result = await this.api.get('/History?Count=1');    
    return {
      login: 'test_user',
      name: 'Test user',
    };
  }

  async signup() {}

  async forgot() {}

  async refreshToken(token: string) {
    /*
    const result = await this.api.post<RefreshTokenRequest, ApiResponse<AuthToken>>(auth.refreshToken(), {
      refreshToken: token,
    });
    if (!result.data?.refreshToken) result.data!!.refreshToken = token;
    return result.data!!;
    */
  }

  async authenticate() {
    /*
    const result = await this.api.post<AuthenticateRequest, ApiResponse<AuthToken>>(auth.authenticate(), request);
    this.onSignIn && this.onSignIn(result.data!!);
    return result.data;
    */
  }
}
