import React from 'react';
import clsx from 'clsx';

import Facebook from './facebook.svg';
import Twitter from './twitter.svg';

import styles from './SocialIcon.module.css';

interface SocialIconProps {
  className?: string;
  url: string;
}

export default function SocialIcon({className, url}: SocialIconProps) {
  const icon = url.indexOf('facebook.') >= 0 ? Facebook : url.indexOf('twitter.') >= 0 ? Twitter : '';
  return (
    <a className={clsx(styles['container'], className)} href={url}>
      <img src={icon} />
    </a>
  )
}