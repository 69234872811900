import Api from './api';
import {
  ContactUsRequest,
} from '@app/types';

export default class Contacts {
  constructor(private readonly api: Api) {}
  async postGetInTouch(value: ContactUsRequest): Promise<void> {
    await this.api.post(`/Contacts/contact-us`, value);
  }  
};
