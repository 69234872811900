import React, {PropsWithChildren} from 'react';
import clsx from 'clsx';

import styles from './DashboardTagLine.module.css';

export type TagState = 'complete' | 'expired' | 'active' | 'cancelled' | 'declined' | 'ready';

interface TagProps {
  className?: string;
  state?: TagState;
}

export default function Tag({className, state, children}: PropsWithChildren<TagProps>) {
  const classes = clsx(styles['tag'], state ? styles['tag_' + state] : null, className);
  return (
    <span className={classes}>
      {children}
    </span>
  )
}