import Api from './api';

interface UploadProps {
  taskId: string;
  file: File;
}

export default class Files {
  constructor(private readonly api: Api) {}
  get(fileId: string) {
    return this.api.get<Blob>(`/Files/${fileId}`);
  }
  upload({taskId, file}: UploadProps) {
    const formData = new FormData();  
    formData.append('file', file);
    return this.api.post(`/Files/tasks/${taskId}`, formData);
  }
  delete(fileId: string) {
    return this.api.delete(`/Files/${fileId}`);
  }
};
