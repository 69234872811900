import {connect} from 'react-redux';
import {selectors, RootState} from '@app/store/session';

import Header from '../components/Header';

export default connect(
  (state: RootState) => ({
    loggedIn: selectors.isLoggedIn(state),
  })
  
)(Header);
