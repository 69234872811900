import React, {useCallback, useState} from 'react';
import Button from '@components/atoms/Button';
import {storage} from '@utils/storage';

type ButtonProps = Parameters<typeof Button>[0];
type LoginButtonProps = Omit<ButtonProps, "url" | "onClick" | "currentWindow" | "loading">;

export const LOGIN_URL = `${process.env.MAIN_HOST}/MicrosoftIdentity/Account/SignIn`;

function handleLoginClick() {
  storage.lastUrl = window.location.pathname + (window.location.search || '');
}

export default function LoginButton(props: LoginButtonProps) {
  const [loading, setLoading] = useState(false);
  const handleClick = useCallback(() => {
    setLoading(true);
    handleLoginClick();
  }, [setLoading]);  
  return <Button {...props} loading={loading} url={LOGIN_URL} onClick={handleClick} currentWindow />
}
