import React, {useCallback} from 'react';
import {Spv} from '@app/types';
import {useBorrowerDirector} from '@features/Tasks/hooks/useBorrowerForm';
import RadioInput from '@components/molecules/RadioInput';
import TextInput from '@components/molecules/TextInput';
import {FormErrors} from '@features/Tasks/hooks/validate';

interface BorrowerDirectorProps {
  error?: FormErrors<Spv>;
  value: Spv;
  active?: boolean;
  onChange: (value: Spv) => unknown;
}

export default function BorrowerDirector({error, value, active, onChange}: BorrowerDirectorProps) {
  const handleChange = useCallback((value?: Spv) => {
    if (value) onChange(value);
  }, [onChange]);
  const {fields} = useBorrowerDirector({error, values: value, onChange: handleChange})
  const {type, title, name, typeOfCompany, dateOfBirth, countryOfResidence, registrationNumber, address} = fields;
  return (
    <>
      <RadioInput field={type} margin={0} smallError readOnly={!active} />
      {type.value === 'Corporate' ? null : <TextInput field={title} margin={0} smallError readOnly={!active} />}
      <TextInput field={name} margin={0} smallError readOnly={!active} />
      {type.value === 'Corporate' ? <TextInput field={typeOfCompany} margin={0} smallError readOnly={!active} /> : null}
      {type.value === 'Corporate' ? null : <TextInput field={dateOfBirth} margin={0} smallError readOnly={!active} />}
      <TextInput field={countryOfResidence} margin={0} smallError readOnly={!active} />
      {type.value === 'Corporate' ? <TextInput field={registrationNumber} margin={0} smallError readOnly={!active} /> : null}
      <TextInput field={address} smallError readOnly={!active} />
    </>
  );
};
