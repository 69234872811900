import React, {ElementType, PropsWithChildren} from 'react';
import clsx from 'clsx';

import styles from './Paper.module.css';

interface PaperProps {
  className?: string;
  border?: boolean;
  color?: 'primary';
  size?: 'small' | 'medium' | 'large';
  margin?: 'small' | 'medium' | 'large';
  flex?: boolean;
  loading?: boolean;
  compact?: boolean;
  Component?: string | ElementType;
}

export default function Paper({
  className,
  border,
  color,
  size,
  margin,
  loading,
  compact,
  flex,
  Component = 'div',
  children,
}: PropsWithChildren<PaperProps>) {
  const classes = clsx(
    styles['container'],
    border ? styles['has_border'] : null,
    color ? styles['color_' + color] : null,
    size ? styles['size_' + size] : null,
    margin ? styles['margin_' + margin] : null,
    loading ? styles['is_loading'] : null,
    compact ? styles['compact'] : null,
    flex ? styles['is_flex'] : null,
    className
  );
  return <Component className={classes}>{children}</Component>;
}
