import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

interface UseAnchorProps {
  hash?: string;
}

const TIMER = 50;
const TIMEOUT = 1000;
const FOCUS_TIMEOUT = 50;


export default function useAnchor({hash}: UseAnchorProps) {
  const navigate = useNavigate();
  //
  useEffect(() => {
    let cancelled = false;
    if (!hash) return;
    const hashValue = hash.slice(1);
    const start = new Date().getTime();
    let timer: ReturnType<typeof setInterval> | null = setInterval(() => {
      if (cancelled) return;
      const duration = (new Date().getTime()) - start;
      if (duration > TIMEOUT) {
        console.log('anchor not found, stopped');
        if (timer) {
          clearInterval(timer);
          timer = null;
        }
      }
      const element = document.querySelector(`[data-anchor=${hashValue}]`);
      if (element) {
        setTimeout(() => {
          if (cancelled) return;
          (element as HTMLElement).focus();
          navigate(window.location.pathname, {replace: true});
        }, FOCUS_TIMEOUT);        
        if (timer) {
          clearInterval(timer);
          timer = null;
        }
      }
    }, TIMER);
    return () => {
      if (timer) {
        clearInterval(timer);
        timer = null;
      }
      cancelled = true;
    };
  }, [navigate, hash]);
}