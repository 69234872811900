import React, {useCallback, useMemo} from 'react';
import clsx from 'clsx';
import {NavLink} from 'react-router-dom';

import styles from './NavMenu.module.css';

interface MenuItem {
  caption: string;
  url: string;
  onClick?: (v: unknown) => unknown;
}

interface NavMenuProps {
  className?: string;
  items?: MenuItem[];
}

export default function NavMenu({className, items}: NavMenuProps) {  
  const getItemClassName = useCallback(({isActive}) => {    
    return clsx(styles['link'], isActive ? styles['active'] : null);
  }, []);
  const links = useMemo(() => {
    if (!items) return undefined;
    return items.map((item, i) => {
      if (item.url?.indexOf('http') === 0) return (
        <a className={styles['link']} href={item.url} onClick={item.onClick} key={i}>{item.caption}</a>
      ); else return (
        <NavLink className={getItemClassName} to={item.url} end={item.url === '/'} key={i}>{item.caption}</NavLink>
      );      
    });
  },  [items]);
  return (
    <nav className={clsx(styles['container'], className)}>
      {links}
    </nav>
  );
};
