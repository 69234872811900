import React, {useEffect, useState} from 'react';
import base64 from 'uuid-base64';
import {useParams, useNavigate} from 'react-router-dom';
import {tasks} from '@app/api/rest';

function getParams(v: string) {  
  const id = base64.decode(v);
  return {id};
}

export default function OpenTask() {
  const navigate = useNavigate();
  const params = useParams();
  useEffect(() => {
    let cancelled = false;
    const {id} = params;    
    if (id) {
      const {id: taskId} = getParams(id);
      (async () => {
        const task = await tasks.get(taskId);
        if (cancelled) return;
        if (!task) return;
        const url = `/projects/${task.projectId}/tasks/${taskId}/`;
        navigate(url, {replace: true});        
      })();
    }
    return () => {
      cancelled = true;
    }
  }, [params, navigate]);
  return null;
}
