import {query} from '@app/api/rest';
import {createApiThunk} from '@app/lib/thunks';
import {SearchFormData} from '@app/types';

export const get = createApiThunk('@@search/current/get', query.get.bind(query));
export const getList = createApiThunk('@@search/list/get', query.getList.bind(query));
export const post = createApiThunk('@@search/current/post', query.post.bind(query));
export const save = createApiThunk('@@search/current/save', query.post.bind(query));
export const saveForShare = createApiThunk('@@search/current/save-for-share', query.post.bind(query));
export const getOffers = createApiThunk('@@search/offers/get', query.getOffers.bind(query));
export const applyOffers = createApiThunk('@@search/offers/apply', query.applyOffers.bind(query));

export const open = createApiThunk('@@search/current/open', async (queryId?: string): Promise<SearchFormData> => {
  if (queryId && queryId !== 'new') {
    const result = await query.get({queryId});
    return result as SearchFormData;
  } else {
    const result = {
      contingency: 5,      
      areaMetric: 'Ft',
      // ltc: 85,      
    } as SearchFormData;
    return result;
  }
});

export default {
  current: {
    open,
    get,
    post,
    save,
    saveForShare,
  },
  list: {
    get: getList,
  },
  offers: {
    get: getOffers,
    apply: applyOffers,
  },  
};
