import React from 'react';
import AreaUnits from '@components/atoms/AreaUnits';

import {
  AcquisitionStatus,
  AreaMetric,
  ExitStrategy,
  ExperienceLevel,
  PlanningStatus,
  ProcurementRoute,
  ProposedDevelopment,  
  SearchFormData,  
  SearchStep1Data,
  SearchStep2Data,
  SearchStep3Data,
  SearchStep4Data,
  SearchStep5Data,
  SearchStep6Data,
  UnitDetails,
  UnitDetailsFormData,
  YesNo,
} from '@app/types';

import {FieldsDefs, FieldDef, Option} from '@hooks/useForm';
import KeyValue from '@components/atoms/KeyValue';

const OPTIONS_YESNO = [
  {caption: 'Yes', value: 'Yes'},
  {caption: 'No', value: 'No'},
] as Option<YesNo>[];

const OPTIONS_YESNO_BOOL = [
  {caption: 'Yes', value: true},
  {caption: 'No', value: false},
] as Option<boolean>[];

function metricToText(metric?: AreaMetric): string {
  if (!metric) return '';
  if (metric === 'M') return 'sq.m.';
  if (metric === 'Ft') return 'sq.ft.';
  throw new TypeError('Unknown metric: ' + metric);
}

function isUnitDetailsRowEmpty(v?: UnitDetails) {
  if (!v) return true;
  if (typeof v.type === 'undefined') return false;
  const allZeroes = (!v.area && !v.count && !v.totalValue  && !v.valuePerMetricEntity && !v.valuePerUnit);
  if (allZeroes) return false;
  const result = (!v.area || !v.count || !v.totalValue  || !v.valuePerMetricEntity || !v.valuePerUnit); 
  return result;  
}

function isUnitDetailsEmpty(value?: UnitDetailsFormData) {
  console.log('checking unit details', value);
  if (!value) return true;
  let emptyCount = 0;
  if (value.Commercial && typeof value.Commercial.type === 'undefined') emptyCount++;
  if (value.Flat && typeof value.Flat.type === 'undefined') emptyCount++;
  if (value.House && typeof value.House.type === 'undefined') emptyCount++;  
  if (emptyCount === 3) return true;
  return isUnitDetailsRowEmpty(value.Commercial) || isUnitDetailsRowEmpty(value.Flat) || isUnitDetailsRowEmpty(value.House);
}

export const fieldsStep1 = {
  projectSummary: {
    name: 'projectSummary',
    caption: 'Project summary',
    placeholder: 'An eight storey development of fourteen apartments, with a commercial retail unit on the ground floor, comprising 6 two bedroom units and 8 one bedroom apartments.',
    hint: 'Provide a description of the proposed development: type of project, number of units, houses or flats.',
    required: true,
  },
  proposedDevelopment: {
    name: 'proposedDevelopment',
    caption: 'Proposed development',
    options: [
      {caption: 'Ground-up', value: 'GroundUp'},
      {caption: 'Permitted development', value: 'PD'},
      {caption: 'Refurbishment', value: 'Refurbishment'},
    ] as Option<ProposedDevelopment>[],
    hint: 'Select the appropriate option based on the intended development.',
    required: true,
  },
  partBuiltScheme: {
    name: 'partBuiltScheme',
    caption: 'Part-built scheme?',
    options: OPTIONS_YESNO_BOOL,
    required: true,
    hint: 'If you started works on site, let us know.',
  },
  siteFullAddress: {
    name: 'siteFullAddress',
    caption: 'Site address',
    description: 'Simply enter the address',    
    hint: 'Provide full address of the site including postcode.',
    required: true,
  },
  siteCountry: {
    name: 'siteCountry',    
  },
  siteCity: {
    name: 'siteCity',
  },
  sitePostcode: {
    name: 'sitePostcode',
  },
  siteRegion: {
    name: 'siteRegion',
  },
  acquisitionStatus: {
    name: 'acquisitionStatus',
    caption: 'Acquisition status',
    options: [
      {caption: 'Owned', value: 'Owned'},
      {caption: 'Ongoing', value: 'Ongoing'},
    ] as Option<AcquisitionStatus>[], 
    hint: 'Let us know whether you already own the site or you\'re in the process of purchasing the site.',
    required: true,
  },
  planningStatus: {
    name: 'planningStatus',
    caption: 'Planning status',
    options: [
      {caption: 'Granted', value: 'Granted'},
      {caption: 'Pending', value: 'Pending'},
    ] as Option<PlanningStatus>[],
    hint: 'Let us know whether the scheme has planning consent or planning is pending.',
    required: true,
  },
} as FieldsDefs<SearchStep1Data>;

export const fieldsStep2 = {
  experienceLevel: {
    name: 'experienceLevel',
    caption: 'Developer experience',
    options: [
      {caption: 'First time developer', value: 'FirstTimeDevelopment'},
      {caption: 'Experienced developer', value: 'ExperiencedDeveloper'},
    ] as Option<ExperienceLevel>[],    
    hint: 'To be considered an experienced developer, you need to have completed at least 2 similar projects.',
    required: true,
  },
  completedSimilarSchemes: {
    name: 'completedSimilarSchemes',
    caption: 'Number of similar schemes completed',
    description: 'Enter the amount',
    type: 'int',
    required: (value: SearchFormData) =>  {      
      return value.experienceLevel !== 'FirstTimeDevelopment';
    },
    hint: 'No need to complete if a first time developer.',    
  },
  canProvidePersonalGuarantee: {
    name: 'canProvidePersonalGuarantee',
    caption: 'Can the borrower provide a personal guarantee?',
    options: OPTIONS_YESNO_BOOL,
    type: 'boolean',
    required: 'Fill in the "Personal guarantee" field',
    hint: 'Let us know if the borrower has the financial means to provide a personal guarantee (PG). Typically, a PG amounts to 20% of the loan.',
  },
  adverseCreditHistory: {
    name: 'adverseCreditHistory',
    caption: 'Any adverse credit history in the last 5 years?',
    options: OPTIONS_YESNO_BOOL,
    type: 'boolean',
    required: 'Fill in the "Adverse credit history" field',
    hint: 'For example, bankruptcy, mortgage or credit cards missed payments, CCJ, IVA and CVA.',
  },
  countryOfResidence: {
    name: 'countryOfResidence',
    caption: 'Majority shareholders residency status',
    options: ['UK-resident', 'Non UK-resident'],
    type: 'string',
    required: 'Fill in the "Majority shareholders residency status" field',
    hint: 'Majority shareholders is defined as shareholders with minimum 25% stake in the borrowing SPV.',
  },
  borrowingSPVDomicile: {
    name: 'borrowingSPVDomicile',
    caption: 'Borrowing SPV domicile',
    options: ['UK', 'Offshore'],
    type: 'string',
    required: 'Fill in the "Borrowing SPV domicile" field',
    hint: 'Let us know the location of the borrowing SPV. Offshore is defined as Jersey, Mauritius, etc.',
  },
} as FieldsDefs<SearchStep2Data>;

//

export const fieldsStep3 = {
  areaMetric: {
    name: 'areaMetric',
    caption: 'Choose the appropriate metric',
    options: [
      {
        caption: (
          <>
            <AreaUnits value="M" style="lowercase" /> (Square meter)
          </>
        ),
        value: 'M',
      },
      {
        caption: (
          <>
            <AreaUnits value="Ft" style="lowercase" /> (Square foot)
          </>
        ),
        value: 'Ft',
      },
    ] as Option<AreaMetric>[],
    required: 'Fill in the "Appropriate metric" field',
  },
  unitDetails: {
    name: 'unitDetails',
    caption: 'Schedule of accommodation',
    description: 'Fill in the data',
    required: true,
    validate: (value) => {
      const {unitDetails} = value;
      const isEmpty = isUnitDetailsEmpty(unitDetails);
      console.log('unit details empty', isEmpty);
      if (isEmpty) return 'Fill in the "Schedule of accomodation" field';
      return;
    },
    type: 'object',
    hint: 'First, select the appropriate type of units (flats, houses and or commercial) and then enter the relevant data in the table.',
  },
  salesAgentFee: {
    name: 'salesAgentFee',
    caption: 'Agent fee',
    description: 'Enter one of the options',
    type: 'float',
    required: true,
    hint: 'Enter the fee in £ or as a % of the GDV. If the fee is unknown at this stage, you can put 1% as default setting.',    
    placeholder: '£',
    orPlaceholder: '% of the GDV',    
  },
  salesLegalFee: {
    name: 'salesLegalFee',
    caption: 'Legal fee',
    description: 'Enter one of the options',
    hint: 'Enter the fee in £ or as a % of the GDV. If the fee is unknown at this stage, you can input 1% as an assumption.',
    required: true,
    placeholder: '£',
    orPlaceholder: '% of the GDV',    
  },
  //
  nia: {
    name: 'nia',
    type: 'float'
  }
} as FieldsDefs<SearchStep3Data, {orPlaceholder?: string}>;

// step 4
export const fieldsStep4 = {
  procurementRoute: {
    name: 'procurementRoute',
    caption: 'Procurement route',
    options: [
      {caption: 'Fixed price contract', value: 'MainContractor'},
      {caption: 'Construction management', value: 'ConstructionManagement'},
    ] as Option<ProcurementRoute>[],
    required: true,
    hint: 'If you intend to use a JCT Design & Build contract, select "Fixed price contract" otherwise, select "Construction management".',
  },
  gia: {
    name: 'gia',
    caption: 'Gross area (GIA)',
    placeholder: (data: SearchFormData) => metricToText(data.areaMetric),
    hint: 'To calculate the GIA, you can either enter the GIA directly in Ft2 or as a % of the NIA. Min GIA cannot be lower than the NIA.',
    percentsCaption: '% NIA',
    percentsPlaceholder: '%',
    type: 'float',    
    required: true,
    validate: (data: SearchFormData) => {    
      const nia = data.nia;
      const gia = data.gia;
      if (nia === undefined || gia === undefined) {
        return 'Fill in the "Gross area (GIA)" field';
      }
      const percents = (gia * 100) / nia;      
      if (percents < 100) {
        return 'GIA cannot be lower than NIA';
      }
      return undefined;
    },
  },
  buildCosts: {
    name: 'buildCosts',
    caption: 'Build cost',
    description: 'Enter one of the options',
    type: 'float',    
    placeholder: '£',   
    orPlaceholder: (data: SearchFormData) => '£ ' + metricToText(data.areaMetric), 
    hint: 'For example, demolition cost, preliminary cost, construction cost (excl. professional cost). You can either enter the amount directly in £ or as £ Sq.Ft of the GIA.',
    required: true,
  },
  contingency: {
    name: 'contingency',
    caption: 'Contingency',    
    description: 'Enter the amount',
    type: 'float',
    placeholder: '%',    
    required: true,
    hint: 'Contingency is set at minimum 5%.',
    validate: (data: SearchFormData) => { 
      if (data.contingency === undefined) return '"Contingency" is required';
      if (data.contingency < 5) return '"Contingency" is fixed at minimum 5%';
      return undefined;
    }
  },
  professionalCosts: {
    name: 'professionalCosts',
    caption: 'Professional cost',
    description: 'Enter one of the options',
    type: 'float',
    required: true,
    hint: 'Architect, engineers, project manager, QS and other consultants You can either input the amount directly in £ or as a % of the build cost. Professional cost varies from 8 to 15% of the build on average.',
    placeholder: '£',
    orPlaceholder: '% build cost',
  },
  statutoryCosts: {
    name: 'statutoryCosts',
    caption: 'Statutory cost',
    description: 'Enter the amount',
    placeholder: '£',
    type: 'float',
    required: true,
    hint: 'Section 106 and Community Infrastructure Levy (CIL) if applicable. Otherwise, enter 0.',
  },
  otherCosts: {
    name: 'otherCosts',
    caption: 'Other cost',
    placeholder: '£',    
    description: 'Enter the amount',
    type: 'float',
    hint: 'For example surveys, warranties, insurances, etc. If these costs are already included in the professional cost section, enter 0 or ignore.',
  },
  //
  nia: {
    name: 'nia',
    caption: 'NIA',
    type: 'float',    
  }
} as FieldsDefs<SearchStep4Data>;

// step 5
export const fieldsStep5 = {
  purchasePrice: {
    name: 'purchasePrice',
    caption: 'Purchase price',
    description: ' Enter the amount',
    type: 'float',
    required: true,
    hint: 'Let us know the price paid or you intend to pay for the site.',
    placeholder: '£',
    orPlaceholder: '% purchase price'    
  },
  stampDutyTaxLevy: {
    name: 'stampDutyTaxLevy',
    caption: 'Stamp duty tax levy (SDTL)',
    description: 'Enter one of the options',
    type: 'float',
    required: true,
    hint: 'Enter the amount in £ or as a % of the purchase price. If unknown at this stage, you can input 3% of the purchase price as an assumption.',
    placeholder: '£',
    orPlaceholder: '% purchase price'    
  },
  acquisitionAgentFee: {
    name: 'acquisitionAgentFee',
    caption: 'Agent fee',
    description: 'Enter one of the options',
    type: 'float',
    required: true,
    hint: 'Enter the fee in £ or as a % of the purchase price. If unknown at this stage, input 1 or 2% of the purchase price as an assumption.',
    placeholder: '£',
    orPlaceholder: '% purchase price'    
  },
  acquisitionLegalFee: {
    name: 'acquisitionLegalFee',
    caption: 'Legal fee',
    description: 'Enter one of the options',
    type: 'float',
    required: true,
    hint: 'Enter the fee in £ or as a % of the purchase price. If unknown at this stage, input 1% of the purchase price as an assumption.',
    placeholder: '£',
    orPlaceholder: '% purchase price'    
  },
} as FieldsDefs<SearchStep5Data>;

// step 6
export const fieldsStep6 = {
  shariahCompliant: {
    name: 'shariahCompliant',
    caption: 'Does the loan have to be Shariah compliant?',
    options: OPTIONS_YESNO_BOOL,
    type: 'boolean',
    required: true,    
  },
  ltc: {
    name: 'ltc',
    caption: 'Net loan',
    description: 'Enter one of the options',
    type: 'float',
    hint: 'Let us know how much debt you need in £ or in terms of Loan-to-Cost (LTC). Alternatively, you can select "show me the best offers".',
    placeholder: '£',
    orPlaceholder: '% loan-to-cost (LTC)',
    max: 90,
    validate: (value) => {
      if (typeof value.ltc !== 'undefined' && typeof value.totalBuildCost !== 'undefined') {        
        const ltcBase = (value.totalAcquisitionCost || 0) + (value.totalBuildCost || 0);
        const ltc = ltcBase * value.ltc / 100;        
        console.log('tbc', value.totalBuildCost, 'ltc', ltc);
        if (ltc < value.totalBuildCost) return 'Minimum net loan should be equal to the total build cost';
      }
      return;
    }
  },
  brokerFee: {
    name: 'brokerFee',
    caption: 'Broker fee',
    description: 'Enter the amount',
    type: 'float',
    required: true,
    placeholder: '%',
    hint: 'Let us know if you would like to add a broker or introducer fee.',
    min: 0,
    max: 2,
  },
  buildPeriodMonths: {
    name: 'buildPeriodMonths',
    caption: 'Build period',
    description: 'Enter number of months',
    type: 'int',
    hint: 'Let us know how long you expect the build period to be.',
    required: true,    
    placeholder: 'months',    
    max: 36,
    min: 1,
  },
  loanTenureMonths: {
    name: 'loanTenureMonths',
    caption: 'Loan tenure',
    description: 'Enter number of months',
    required: true,
    type: 'int',
    placeholder: 'months',
    hint: 'Let us know how long you need the loan for. You need to assume a minimum of 3 months sales period to be added to the build period.',
    max: 48,
    validate: (data: SearchFormData) => {
      if (!data.loanTenureMonths) return 'Fill in the \'Loan tenure\' field';
      const min = data.buildPeriodMonths ? data.buildPeriodMonths + 3 : undefined;
      if (min !== undefined && data.loanTenureMonths !== undefined && data.loanTenureMonths < min) {
        return `Loan tenure should be minimum ${min} months`
      }
      return undefined;
    },    
  },
  exitStategy: {
    name: 'exitStategy',
    caption: 'Exit strategy',
    options: [
      {caption: 'Sell', value: 'Sell'},
      {caption: 'Rent', value: 'Rent'},
    ] as Option<ExitStrategy>[],
    required: true,
    hint: 'Let us know how you intend to repay the loan: open market sales or rental model.',
  },
  loanStartPeriodWeeks: {
    name: 'loanStartPeriodWeeks',
    caption: 'When do you need to close the loan?',
    description: 'Enter number of weeks',
    type: 'float',
    required: true,
    hint: 'Let us know when you need the money. Minimum 4 weeks is required to close a loan and 6 weeks is the average.',
    placeholder: 'weeks',    
    min: 6,
    max: 12,
    validate: (data: SearchFormData) => {
      if (!data.loanStartPeriodWeeks) {
        return 'Fill in the "When do you need to close the loan?" field';
      }
      if (data.loanStartPeriodWeeks !== undefined && data.loanStartPeriodWeeks < 6) {
        return `"When do you need to close the loan?" should be minimum 6 weeks`;
      }
      return undefined;
    }
  },
  getBestOffers: {
    name: 'getBestOffers',
    options: OPTIONS_YESNO_BOOL,
    type: 'boolean',    
  },
  totalAcquisitionCost: {
    name: 'totalAcquisitionCost',
    type: 'float'
  },
  totalBuildCost: {
    name: 'totalBuildCost',
    type: 'float',
  }
} as FieldsDefs<SearchStep6Data>;

export const formFieldsDefs = {
  ...fieldsStep1,
  ...fieldsStep2,
  ...fieldsStep3,
  ...fieldsStep4,
  ...fieldsStep5,
  ...fieldsStep6,
} as FieldsDefs<SearchFormData>;
