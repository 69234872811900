import {createSlice} from "@reduxjs/toolkit";
import {getList} from "./thunks";
import {History} from '@app/types';

export interface InitialState {
  loading: boolean;
  value?: History[];
  error?: unknown;
}

const initialState = {
  loading: false,   
} as InitialState;

export const slice = createSlice({
  name: '@@history/list',
  initialState,
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.value = undefined;
      state.error = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getList.fulfilled, (state, {payload}) => {
        state.loading = false;
        state.value = payload;
      })
      .addCase(getList.rejected, (state, {payload}) => {
        state.loading = false;
        state.value = undefined;
        state.error = payload;
      });
  }
});

export default {
  slice,

}