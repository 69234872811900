import useForm, {FieldsDefs, FieldDef, Option} from '@hooks/useForm';
import {ContactUsRequest} from '@app/types';

export const fields = {
  firstname: {
    name: 'firstname',
    caption: 'First name',
    required: true,
  },
  lastname: {
    name: 'lastname',
    caption: 'Last name',
    required: true,
  },
  company: {
    name: 'company',
    caption: 'Company',
  },
  phone: {
    name: 'phone',
    caption: 'Phone',
  },
  email: {
    name: 'email',
    caption: 'Email',    
    required: true,
  },  
  message: {
    name: 'message',
    caption: 'Message',
    required: true,        
  },
} as FieldsDefs<ContactUsRequest>;

export default function useContactUsForm() {
  return useForm({fields, title: 'Get in touch', values: {}});
};
