import React, {ChangeEvent, ReactNode, useEffect, useMemo, useRef, useState} from "react";
import clsx from "clsx";

import styles from "./BuildCosts.module.css";

import PercentageInput from "@components/molecules/PercentageInput";
import TextInput from "@components/molecules/TextInput";
import {Field} from "@hooks/useForm";
import AreaUnits from "@components/atoms/AreaUnits";
import Money from "@components/atoms/Money";
import {AreaMetric} from "@app/types";
import {formatAmount} from '@utils/format';

interface ExtraProps {
  percentsCaption?: string | ReactNode;
  percentsPlaceholder?: string;
}

interface BuildCostsProps<Name> {
  gia: Field<number | undefined, Name, ExtraProps>;
  nia: number;
  value: Field<number | undefined, string, ExtraProps>;
  metric?: AreaMetric;
  contigency: Field<number | undefined, string, ExtraProps>;
  onTotalChange?: (value: number) => void;
}

export default function BuildCosts<Name>({gia, nia, value, contigency, metric, onTotalChange}: BuildCostsProps<Name>) {
  const [procNia, setProcNia] = useState("");
  const refProcNia = useRef<number | null | undefined>();
  const refGia = useRef<number | null | undefined>();
  useEffect(() => {
    if (gia.value !== refGia.current) {
      refGia.current = gia.value;
      if (gia.textValue === '') {
        if (procNia !== '') setProcNia('');        
      } else {
        const proc = ((gia.value || 0) * 100) / nia;
        if (!isNaN(proc)) {
          refProcNia.current = proc;
          setProcNia(proc.toString());
        }
      }
    } else if (refProcNia.current !== +procNia && !isNaN(+procNia)) {
      if (procNia === '') {        
        if (gia.textValue !== '') gia.setTextValue('');
      } else {
        refProcNia.current = +procNia;
        const newGia = (+procNia * nia) / 100;
        const value = +newGia.toFixed(0);
        refGia.current = value;
        gia.setValue(value);
      }
    }
  }, [gia, nia, procNia, refGia, refProcNia]);
  const cont = useMemo(() => {
    if ((contigency.value || 0) > 0) {
      const result = (value.value || 0) * (contigency.value || 0) / 100;
      if (!isNaN(result)) return result;
    }
    return undefined;
  }, [value, contigency]);
  const bcwithCont = useMemo(() => {
    return (value.value || 0) + (cont || 0);
  }, [value, cont]);
  const bcwithContUnit = useMemo(() => {
    const gv = gia.value || 0;
    if (!!gv) return bcwithCont / gv;
    return undefined;    
  }, [bcwithCont, gia]);
  useEffect(() => {
    onTotalChange && onTotalChange(bcwithCont);
  }, [bcwithCont, onTotalChange]);
  console.log('gia', gia);
  console.log('value', value);
  //const metric = typeof gia.placeholder === 'string' ? gia.placeholder.replace('sq', '').trim() : '';
  return (
    <>
      <div className={styles["row"]}>
        <TextInput
          anchor="gia"
          margin={0}
          caption={gia.caption}
          placeholder={gia.placeholder}
          inputType="integer"
          suffix={' ' + gia.placeholder}
          value={gia.textValue}
          onChange={(e: ChangeEvent<HTMLInputElement>) => gia.setTextValue(e.currentTarget.value)}
          error={gia.error}
          smallError
        />
        <span className={styles["or"]}>or</span>
        <TextInput
          margin={0}
          caption={gia.percentsCaption}
          placeholder={gia.percentsPlaceholder}
          value={procNia || ''}
          inputType="float"
          suffix="%"
          error={gia.error}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setProcNia(e.currentTarget.value)}
          smallError
        />
        <TextInput margin={0} caption="Net area (NIA)" required value={formatAmount(nia, undefined, true)!} onChange={() => {}} label postfix={<AreaUnits value={metric} />} tooltip={gia.hint} smallError />
      </div>
      <PercentageInput anchor="buildCost" margin={0} field={value} base={(gia.value || 0) * 100} orPrefix="£" orSuffix={' ' + gia.placeholder} smallError />
      <PercentageInput margin={0} field={contigency} percentsShowOnly labelValue={<Money currency="£" value={cont} integer />} smallError />
      <div className={styles['totals']}>
        <div className={styles['inner']}>
          <span>Build Cost (incl. contingency)</span>
          <span className={styles['values']}><Money currency="£" value={bcwithCont} integer /></span>
          <span className={styles['values']}><Money currency="£" value={bcwithContUnit} integer /> <AreaUnits value={metric} /> </span>
        </div>
      </div>      
    </>
  );
}
