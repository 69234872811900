import React, {forwardRef, useMemo, LegacyRef, ReactNode, Ref, ButtonHTMLAttributes} from 'react';
import clsx from 'classnames';
import {NavLink, NavLinkProps} from 'react-router-dom';
import Spinner from '../Spinner';

import styles from './Button.module.css';
import Skeleton from '../Skeleton';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  active?: boolean;
  className?: string;
  loading?: boolean;
  color?: 'primary' | 'secondary' | 'inline';
  light?: boolean;
  size?: 'normal' | 'big';
  icon?: ReactNode;
  caption?: ReactNode;
  url?: string;
  replace?: boolean;
  disabled?: boolean;
  link?: boolean;
  type?: 'button' | 'reset' | 'submit';
  layout?: 'row' | 'auto' | 'inline';
  noWrap?: boolean;
  margin?: number;  
  currentWindow?: boolean;
  skeleton?: boolean;
}

interface ExternalNavLinkProps extends Omit<NavLinkProps, 'className' | 'to' | 'active' | 'replace'> {}
interface ExternalAProps extends Omit<ButtonHTMLAttributes<HTMLAnchorElement>, 'className'> {}

export function Button(
  {
    className,
    color,
    light,
    active,
    icon,
    caption,
    url,
    link,
    replace,
    size,
    disabled,    
    layout = 'row',
    margin,
    loading = false,
    noWrap,
    currentWindow,
    skeleton,
    ...props
  }: ButtonProps,
  ref: Ref<HTMLAnchorElement> | LegacyRef<HTMLButtonElement>
) {  
  const classes = clsx(
    styles['button'],
    color ? styles['color_' + color] : null,
    light ? styles['is_light'] : null,
    active ? styles['active'] : null,
    loading ? styles['loading'] : null,
    styles[size || ''],
    link ? styles['link'] : null,
    noWrap ? styles['no-wrap'] : null,
    skeleton ? styles['is_skeleton'] : null,
    margin != null ? styles['margin-' + margin] : null,
    styles[layout || ''],
    {
      [styles['disabled']]: disabled,
    },
    className
  );
  const isOuterUrl = useMemo(() => {
    return url && (url.indexOf('http') === 0 || url.indexOf('mailto') === 0 || url.indexOf('tel') === 0);
  }, [url]);
  if (isOuterUrl) {
    return (
      <a
        className={classes}
        href={url}
        ref={ref as Ref<HTMLAnchorElement>}
        target={currentWindow ? undefined : "_blank"}
        rel="noopener noreferrer"
        {...(props as ExternalAProps)}
      >
        {icon}
        {caption}
        <Spinner className={styles['spinner']} active={loading} />
      </a>
    );
  } else if (url) {
    return (
      <NavLink
        className={classes}
        to={url}
        replace={replace}
        ref={ref as Ref<HTMLAnchorElement>}        
        {...(props as ExternalNavLinkProps)}
      >
        {icon}
        {caption}
        <Spinner className={styles['spinner']} active={loading} />
      </NavLink>
    );
  } else {
    return (
      <button className={classes} ref={ref as LegacyRef<HTMLButtonElement>} disabled={disabled} {...props}>
        {skeleton ? <Skeleton className={styles['skeleton']} active={skeleton} /> : <>
          {icon}
          {caption}        
          <Spinner className={styles['spinner']} active={loading} />
        </>}        
      </button>
    );
  }
}

export default forwardRef(Button);
