import React, {useCallback, useEffect, useMemo, ElementType} from "react";
import clsx from "clsx";
import {useParams, Outlet} from "react-router-dom";
import base64 from "uuid-base64";

import useSteps from "../../hooks/useSteps";
import SearchActions from "../SearchActions";

import styles from "./Steps.module.css";
import {SearchFormData} from "@app/types";

interface StepsProps {
  loaded: boolean;
  posted: boolean;
  step: number;
  queryId?: string;
  saving?: boolean;
  savingForShare: boolean;
  onSetStep: (v: number) => void;
  onSave?: (value: SearchFormData) => void;
  onSaveForShare?: (value: SearchFormData) => void;
  onReset?: () => void;
  SearchActions: ElementType;
  Stepper: ElementType;
  Step1: ElementType;
  Step2: ElementType;
  Step3: ElementType;
  Step4: ElementType;
  Step5: ElementType;
  Step6: ElementType;
  Step7: ElementType;
}

export default function Steps({
  loaded,
  posted,
  step,
  queryId,
  saving,
  savingForShare,
  onSetStep,
  onSave,
  onSaveForShare,
  onReset,
  Stepper,
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Step6,
  Step7,
  ...props
}: StepsProps) {
  const {urlStep, setUrlStep} = useSteps({step, queryId, param: "step"});
  const shareLink = useMemo(() => {
    if (!queryId || queryId === "new") return window.location.origin + "/d/new/";
    return window.location.origin + "/d/" + (+step + 1).toString() + base64.encode(queryId) + "/";
  }, [queryId, step]);
  // sync url to store
  useEffect(() => {
    if (urlStep !== step) {
      onSetStep(urlStep);
    }
  }, [step, urlStep, onSetStep]);
  // sync new step to url
  useEffect(() => {
    if (posted && urlStep === step) {
      setUrlStep(step + 1);
    }
  }, [step, urlStep, setUrlStep, posted]);
  //
  const handleBack = useCallback((index: number) => {
    if (index > 0) setUrlStep(index - 1);
  }, [onSetStep]);

  return (
    <>
      <SearchActions shareLink={shareLink} saving={saving} queryId={queryId} savingForShare={savingForShare} onSave={onSave} onSaveForShare={onSaveForShare} onReset={onReset} />
      <div className={styles["content"]}>
        <Stepper className={styles["stepper"]} />
        <main className={styles["form"]} key={queryId}>
          {step === 0 ? <Step1 onBack={handleBack} /> : null}
          {step === 1 ? <Step2 onBack={handleBack} /> : null}
          {step === 2 ? <Step3 onBack={handleBack} /> : null}
          {step === 3 ? <Step4 onBack={handleBack} /> : null}
          {step === 4 ? <Step5 onBack={handleBack} /> : null}
          {step === 5 ? <Step6 onBack={handleBack} /> : null}
          {step === 6 ? <Step7 onBack={handleBack} /> : null}
          <Outlet />
        </main>
      </div>
    </>
  );
}
