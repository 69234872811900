import React, {useCallback, forwardRef, useRef, useEffect, InputHTMLAttributes, LegacyRef, KeyboardEvent, ChangeEvent} from 'react';
import clsx from 'clsx';
import NumberFormat, {NumberFormatValues, SourceInfo} from 'react-number-format';

import useNumberInput from '@hooks/useNumberInput';

import styles from './Input.module.css';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  inputType?: 'integer' | 'float';
  anchor?: string;
  prefix?: string;
  suffix?: string;
  inputMask?: string;
  inputPattern?: string;
  error?: boolean;
}

export function Input(
  {className, anchor, error, inputMask, inputPattern, prefix, suffix, inputType, value, onChange, placeholder, ...props}: InputProps,
  ref: LegacyRef<HTMLInputElement>
) {
  const refInput = useRef<HTMLInputElement | null>(null);
  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {        
    onChange && onChange(e);
  }, [onChange]);

  useEffect(() => {
    if (anchor && refInput.current) refInput.current.setAttribute('data-anchor', anchor);
  }, [anchor, refInput]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (inputPattern && !e.altKey && !e.ctrlKey && e.key && e.key.length === 1) {        
        const re = new RegExp(inputPattern, 'gmi');
        if (!re.test(e.key)) return e.preventDefault();
      }
    },
    [inputPattern]
  );

  const handleValueChange = useCallback((values: NumberFormatValues, sourceInfo: SourceInfo) => {    
    if (refInput.current !== document.activeElement) return;        
    const e = {
      currentTarget: {
        ...(sourceInfo.event?.target || {}),
        value: (values.floatValue?.toString() || '')
      }      
    } as unknown as ChangeEvent<HTMLInputElement>;    
    onChange && onChange(e);
  }, [onChange]);
  if (!inputType) {  
    return (
      <input
        className={clsx(styles['input'], {[styles['error']]: error}, className)}
        placeholder={placeholder}
        value={value || ''}
        data-anchor={anchor}
        {...props}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        ref={ref}
      />
    );
  } else {
    return (
      <NumberFormat
        className={clsx(styles['input'], {[styles['error']]: error}, className)}
        value={(value as string) || ''}
        decimalScale={inputType === 'float' ? 2 : 0}
        name={props.name}
        thousandSeparator=","
        decimalSeparator='.'
        prefix={prefix}
        suffix={suffix}
        placeholder={placeholder}        
        getInputRef={refInput}
        readOnly={props.readOnly}
        onValueChange={handleValueChange}
      />
    )
  }
}

export default forwardRef(Input);