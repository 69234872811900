import React, { PropsWithChildren } from 'react';
import RemixIcon from '@components/atoms/RemixIcon';

import styles from './LenderPopup.module.css';

interface LenderPopupProps {
  icon?: string;
  name?: string;
  category?: string;
  description?: string;
  email?: string;
  phone?: string;
  url?: string;
  onClose?: () => void;
}

export default function LenderPopup({icon, name, category, url, description, email, phone, onClose, children}: PropsWithChildren<LenderPopupProps>) {
  return (
    <div className={styles['container']}>
      <div className={styles['header']}>
        <RemixIcon className={styles['header__icon']} value="dashboard" size="large" color="primary" />
        <span className={styles['header__caption']}>
          <span className={styles['header__name']}>{name}</span>
          {category ? <span className={styles['header__type']}>{category}</span> : null}
        </span>
        <div className={styles['header__close']} onClick={onClose}>
          <RemixIcon value="close" size="large" />
        </div>
      </div>
      <div className={styles['content']}>
        <div className={styles['description__title']}>Description</div>
        <div className={styles['description__content']}>{description}</div>
        {url || email || phone ? <div className={styles['contacts']}>
          {url ? <span className={styles['website']}>
            <RemixIcon className={styles['website__icon']} value="global" />
            <a className={styles['website__link']} href={url} target="_blank" rel="noopen noreferrer">{url}</a>
          </span> : null}
          {email ? <span className={styles['website']}>
            <RemixIcon className={styles['website__icon']} value="mail" />
            <a className={styles['website__link']} href={`mailto:${email}`}>{email}</a>
          </span> : null}
          {phone ? <span className={styles['website']}>
            <RemixIcon className={styles['website__icon']} value="phone" />
            <a className={styles['website__link']} href={`tel:${phone}`}>{phone}</a>
          </span> : null}          
        </div> : null}        
        {children}
      </div>
    </div>
  )

}