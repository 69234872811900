import React, {ReactElement, ReactNode} from 'react';

import styles from './EqualColumns.module.css';

interface EqualColumnsProps {
  left?: ReactNode;
  right?: ReactNode;
}

export default function EqualColumns({left, right}: EqualColumnsProps) {
  return (
    <div className={styles['container']}>
      <div className={styles['column']}>{left}</div>
      <div className={styles['column']}>{right}</div>
    </div>
  );
};
