import Api from './api';
import {
  AreaMetric,
  GetQueriesRequest,
  GetQueryRequest,
  Search,
  SearchFormData,
  UnitDetails,
  UnitDetailsFormData,
  GetOffersRequest,
  LoanOfferSearchRequest,
  LoanOfferSearchResponse,
  Project,
  ApplyOffersRequest,
} from '@app/types';

function fixBool(v?: string | boolean): boolean | undefined {
  if (typeof v === 'string') {
    if (v.toUpperCase() === 'TRUE') return true;
    if (v.toUpperCase() === 'FALSE') return false;
    return undefined;
  } else {
    return v;
  }
}

const EMPTY_OFFERS_RESPONSE = {count: 0, minRate: 0, offers: []} as LoanOfferSearchResponse;

function encodeUnitDetails(v?: UnitDetailsFormData): UnitDetails[] {
  if (!v) return [] as UnitDetails[];
  //
  return (Object.keys(v) as (keyof UnitDetailsFormData)[]).reduce((result, key) => {
    if (v[key]) result.push({...v[key]!, type: key});
    return result;
  }, [] as UnitDetails[]);
}

function decodeUnitDetails(v?: UnitDetails[]): UnitDetailsFormData {
  return (v || ([] as UnitDetails[])).reduce((result, item) => {
    if (item && item.type) {
      result[item.type] = item;
    }
    return result;
  }, {} as UnitDetailsFormData);
}

function encodeSearch(v: SearchFormData) {
  const data = {...v} as Search;
  data.unitDetails = encodeUnitDetails(v.unitDetails);
  if (typeof data.adverseCreditHistory === 'string') data.adverseCreditHistory = fixBool(data.adverseCreditHistory);
  if (typeof data.canProvidePersonalGuarantee === 'string')
    data.canProvidePersonalGuarantee = fixBool(data.canProvidePersonalGuarantee);
  if (typeof v.shariahCompliant === 'string') data.shariahCompliant = fixBool(v.shariahCompliant);
  if (typeof v.partBuiltScheme === 'string') data.partBuiltScheme = fixBool(v.partBuiltScheme);
  if (typeof v.otherCosts === 'string') data.otherCosts = v.otherCosts ? +v.otherCosts : v.otherCosts;
  return data;
}

export function decodeSearch(v: Search): SearchFormData {
  const data = {...v} as SearchFormData;
  data.unitDetails = decodeUnitDetails(v.unitDetails);
  if (typeof data.adverseCreditHistory === 'boolean') data.adverseCreditHistory = data.adverseCreditHistory.toString();
  if (typeof data.canProvidePersonalGuarantee === 'boolean')
    data.canProvidePersonalGuarantee = data.canProvidePersonalGuarantee.toString();
  //@ts-ignore
  if (typeof v.shariahCompliant === 'boolean') data.shariahCompliant = v.shariahCompliant.toString();
  //@ts-ignore
  if (typeof v.partBuiltScheme === 'boolean') data.partBuiltScheme = v.partBuiltScheme.toString();
  return data;
}

export default class Query {
  constructor(private readonly api: Api) {}
  async get(query: GetQueryRequest): Promise<SearchFormData> {
    const result: Search = await this.api.get(`/Query/${query.queryId}`);
    return decodeSearch(result);
  }
  async getList(query: GetQueriesRequest): Promise<SearchFormData[]> {    
    const result = (await this.api.get('/Query')) as Search[];
    return result ? result.map(decodeSearch) : ([] as SearchFormData[]);
  }
  async post(query: SearchFormData): Promise<SearchFormData> {
    const result: Search = await this.api.post('/Query', encodeSearch(query));    
    return decodeSearch(result);
  }
  async getOffers({queryId, sorting}: GetOffersRequest): Promise<LoanOfferSearchResponse> {  
    if (!queryId) return EMPTY_OFFERS_RESPONSE;
    const query: Search = await this.api.get(`/Query/${queryId}`);
    const offers = await this.api.post<LoanOfferSearchRequest, LoanOfferSearchResponse> (`/Query/search`, {
      query,
      sorting,
    });
    return offers;
  }
  applyOffers(request: ApplyOffersRequest): Promise<Project> {
    return this.api.post(`/Query/apply`, request);
  }
}
