import React, {ChangeEvent, useCallback} from 'react';
import clsx from 'clsx';

import {UnitDetails, UnitType} from '@app/types';

import RowHeader from '@components/molecules/RowHeader';

import {Field} from '@hooks/useForm';
import UnitDetailsRowInput from './UnitDetailsRowInput';

interface UnitDetailsRowProps<N> {
  field: Field<UnitDetails | undefined, N, {}>;
  unit: UnitType;  
  hidden?: boolean;  
  onHiddenChange: (unit: UnitType) => void;
}

const PLACEHOLDER = 'Value';

export default function UnitDetailsRow<N>({field, unit, hidden, onHiddenChange}: UnitDetailsRowProps<N>) {
  const handleHiddenChange = useCallback(() => onHiddenChange(unit), [onHiddenChange]);  
  return (    
    <tr>
      <td>
        <RowHeader caption={field.caption} hidden={hidden} onHiddenChange={handleHiddenChange} />
      </td>
      <UnitDetailsRowInput field={field} unit={unit} hidden={hidden} />      
    </tr>
  );
}
