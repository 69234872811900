import React, {Fragment} from "react";
import clsx from "clsx";

import SummaryKey from "@components/atoms/SummaryKey";
import SummaryTitle from "@components/atoms/SummaryTitle";
import SummaryValue from "../SummaryValue";
import {AreaMetric} from "@app/types";
import AreaUnits from "@components/atoms/AreaUnits";

type SummaryValuePropsSource = Parameters<typeof SummaryValue>[0];
type SummaryValueProps = Omit<SummaryValuePropsSource, "className" | "value">;
type ValueType = SummaryValuePropsSource["value"];
type Value = Record<string, ValueType>;

interface SummaryItem extends SummaryValueProps {
  caption: string;
  captionColor?: "primary";
  fieldName: string;
  clickable?: boolean;
  showMetricCaption?: boolean;
}

interface SummaryGroupProps {
  className?: string;
  title?: string;
  loading?: boolean;
  items: SummaryItem[];
  value?: Value;
  onClickItem?: (item: SummaryItem) => unknown;
}

export {ValueType, Value};

export default function SummaryGroup({className, loading, title, items, value, onClickItem}: SummaryGroupProps) {
  return (
    <div className={className}>
      {title ? <SummaryTitle>{title}</SummaryTitle> : null}
      {items.map((item, i) => (
        <Fragment key={i}>
          <SummaryKey color={item.captionColor} loading={loading}>
            {item.caption}
            {value && item.metric && item.showMetricCaption ? <span> per <AreaUnits value={value[item.metric] as string | undefined} /></span> : null}
          </SummaryKey>
          <SummaryValue
            color={item.color}
            loading={loading}
            format={item.fieldName[0] === "?" ? "unknown_field" : item.format}
            metric={(value && item.metric ? value[item.metric] : undefined) as AreaMetric}
            value={value ? value[item.fieldName] : undefined}
            clickable={item.clickable}
            onClick={() => item.clickable && onClickItem ? onClickItem(item) : null}
          />
        </Fragment>
      ))}
    </div>
  );
}
