import React, {createContext, ReactNode, useContext, useEffect} from 'react';

export interface Breadcrumb {
  caption: string;
  url: string;
}

export interface ContentTitleProps {
  title: string;
  loading?: boolean;
  error?: ReactNode;
  breadcrumbs?: Breadcrumb[];
  breadcrumb?: Breadcrumb;
  editable?: boolean;
  renaming?: boolean;
  renamed?: boolean;
  onChange?: (value: string) => unknown;
  onChangeReset?: () => unknown;
  onRenameChange?: (value: boolean) => unknown;
}

interface ContextProps {
  get: () => ContentTitleProps;
  set: (value: ContentTitleProps) => void;
  pop: () => void;
}

export const Context = createContext<ContextProps>({} as ContextProps);

export default function ContentTitle({title, loading, breadcrumb, error, editable, renaming, renamed, onChange, onChangeReset, onRenameChange}: ContentTitleProps) {
  const {set, pop} = useContext(Context);
  useEffect(() => {
    // console.log('set', title, loading, breadcrumb);
    set({title, loading, error, breadcrumb, editable, renaming, renamed, onChange, onChangeReset, onRenameChange});
    return () => {
      pop();
    };
  }, [set, title, loading, error, breadcrumb, editable, renaming, renamed, onChange, onChangeReset, onRenameChange]);
  return null;
};
