import React, {useEffect, useMemo} from 'react';

import TextInput from '@components/molecules/TextInput';
import {EqualColumns} from '@features/Layout';

import useForm from '../../hooks/useAssetAndLiabilityForm';
import DashboardBlock from '@components/organisms/DashboardBlock';
import FormAction from '../FormAction/FormAction';
import Total from '@components/molecules/Total';
import Money from '@components/atoms/Money';
import {AssetAndLiability as AssetAndLiabilityType, ProjectTaskUpdateRequest} from '@app/types';
import Paper from '@components/atoms/Paper';
import Errors from '@features/Search/components/Errors';
import { FormErrors } from '@features/Tasks/hooks/validate';

interface AssetAndLiabilityProps {
  active?: boolean;
  error?: FormErrors<AssetAndLiabilityType>;
  value?: AssetAndLiabilityType;
  submitting?: boolean;
  saving?: boolean;
  onSubmit: (value: AssetAndLiabilityType) => unknown;
  onChange: (value?: AssetAndLiabilityType) => unknown;
  onSave?: () => unknown;
}

function convertRequest(value: AssetAndLiabilityType) {
  const result = {

  };
}

export default function AssetAndLiability({active, error, value, submitting, saving, onSubmit, onChange, onSave}: AssetAndLiabilityProps) {  
  const {fields, handleSubmit, assets, liabilities, errors} = useForm({values: value, onChange, error});
  const {gurantor} = fields;
  const assetsFields = [
    assets.fields.mainResidence,
    assets.fields.asset1,
    assets.fields.asset2,
    assets.fields.cashResources,
    assets.fields.otherInvestments,
  ];
  const liablitiesFields = [
    liabilities.fields.mortgage,
    liabilities.fields.otherDebt,
    liabilities.fields.bankOverdraft,
    liabilities.fields.creditCards,
    liabilities.fields.otherLiabilities,
  ];
  const totalAssets = useMemo(() => {
    return assetsFields.reduce((result, item) => result + (item.value || 0), 0);
  }, assetsFields);
  const totalLiabilities = useMemo(() => {
    return liablitiesFields.reduce((result, item) => result + (item.value || 0), 0);
  }, liablitiesFields);
  const totalNetWorth = useMemo(() => {
    return (<Money value={totalAssets - totalLiabilities} integer currency='£' />);
  }, [totalAssets, totalLiabilities]);
  const hasError = useMemo(() => {
    return !!errors && Object.keys(errors).length > 0;
  }, [errors]);

  useEffect(() => {
    if (hasError) window.scrollTo({left: 0, top: 0, behavior: 'smooth'});
  }, [hasError]);
  
  

  const left = (
    <>
      <DashboardBlock caption="Assets">
        <TextInput field={assets.fields.mainResidence} margin={0} readOnly={!active} inputType="float" prefix="£" />
        <TextInput field={assets.fields.asset1} margin={0} readOnly={!active} inputType="float" prefix="£" />
        <TextInput field={assets.fields.asset2} margin={0} readOnly={!active} inputType="float" prefix="£" />
        <TextInput field={assets.fields.cashResources} margin={0} readOnly={!active} inputType="float" prefix="£" />
        <TextInput field={assets.fields.otherInvestments} readOnly={!active} inputType="float" prefix="£" />
        <Total caption="Total Assets" values={[<Money value={totalAssets} integer currency='£' />]} valuesAlign="right" />
      </DashboardBlock>
    </>
  );

  const right = (
    <>
      <DashboardBlock caption="Liabilities">
        <TextInput field={liabilities.fields.mortgage} margin={0} readOnly={!active} inputType="float" prefix="£" />
        <TextInput field={liabilities.fields.otherDebt} margin={0} readOnly={!active} inputType="float" prefix="£" />
        <TextInput field={liabilities.fields.bankOverdraft} margin={0} readOnly={!active} inputType="float" prefix="£" />
        <TextInput field={liabilities.fields.creditCards} margin={0} readOnly={!active} inputType="float" prefix="£" />
        <TextInput field={liabilities.fields.otherLiabilities} readOnly={!active} inputType="float" prefix="£" />
        <Total caption="Total Liabilities" values={[<Money value={totalLiabilities} integer currency='£' />]} valuesAlign="right" />
      </DashboardBlock>
    </>
  );  
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {hasError ? <Errors<AssetAndLiabilityType> fields={['gurantor']} items={errors} /> : null}
      <TextInput field={gurantor} margin={0} smallError readOnly={!active} />
      <TextInput caption="Total Net Worth" label valueColor="primary" readOnly={!active}>{totalNetWorth}</TextInput>
      <EqualColumns left={left} right={right} />
      {active ? <FormAction type="submit" action="Complete" loading={submitting} prevAction="Save" onPrevAction={onSave} prevLoading={saving} /> : null}
    </form>
  )
}
