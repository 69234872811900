import React, {ReactNode} from 'react';
import clsx from 'clsx';

import RemixIcon from '@components/atoms/RemixIcon';

import styles from './RowHeader.module.css';

interface RowHeaderProps {
  className?: string;
  caption: string | ReactNode;
  hidden?: boolean;
  onHiddenChange?: () => void;
}

export default function RowHeader({className, caption, hidden, onHiddenChange}: RowHeaderProps) {
  const icon = hidden ? 'checkbox-blank' : 'checkbox';
  return (
    <span className={clsx(styles['container'], className)}>
      {caption}
      <RemixIcon
        className={clsx(styles['icon'], {[styles['visible']]: !hidden})}
        value={icon}
        onClick={onHiddenChange}
      />
    </span>
  );
}
