import {connect} from 'react-redux';

import {actions, selectors, RootState} from '@app/store/projects';
import {actions as search} from '@app/store/search';

import Project from '../components/Project';
import Feed from './Feed';
import Tasks from './TasksGroup';

export default connect(
  (state: RootState) => ({
    loading: selectors.item.isLoading(state),
    archiving: selectors.operations.archive.loading(state),
    renaming: selectors.operations.setName.loading(state),
    archiveError: selectors.operations.archive.error(state),
    renameError: selectors.operations.setName.error(state),
    renameSuccess: selectors.operations.setName.success(state),
    open: selectors.item.isOpen(state),
    value: selectors.item.value(state),
    Feed,
    Tasks,
  }),
  {
    onOpen: actions.item.open,
    onClose: actions.item.close,
    onArchive: actions.operations.archive.set,
    onRename: actions.operations.setName.set,
    onResetRename: actions.operations.setName.reset,
    onSetQuery: search.current.setQuery,
  }
)(Project);
