import React from 'react';
import ContentTitle from '@features/Layout/components/ContentTitle';
import {Tasks as TasksControl} from '@features/Dashboard';

export default function Tasks() {
  return (
    <>
      <ContentTitle title='Dashboard' breadcrumb={{caption: 'Dashboard', url: '/'}} /> 
      <ContentTitle title='Tasks to Do' breadcrumb={{caption: 'Tasks to Do', url: '/tasks/'}} /> 
      <TasksControl />
    </>   
  )
};
