import {useCallback, useMemo} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

interface UseStepsProps {
  step: number;
  param: string;
  queryId?: string;
}

export default function useSteps({step, queryId = 'new', param}: UseStepsProps) {
  const params = useParams();
  const navigate = useNavigate();
  const result = {
    urlStep: useMemo(() => {
      const v = params[param] || '';
      const result = +v.toString().replace(/\D/gim, '');
      return result - 1;
    }, [param, params]),
    setUrlStep: useCallback(
      (v: number) => {
        navigate(`/search/${queryId}/step-${v + 1}/`);
      },
      [navigate, queryId]
    ),
  };
  return result;
}
