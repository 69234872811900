import React from 'react';
import {Link} from 'react-router-dom';

import RemixIcon from '@components/atoms/RemixIcon';

import styles from './FaqLink.module.css';

export default function FaqLink() {
  return (
    <div className={styles['container']}>
      <a className={styles['link']} href={`${process.env.BLOG}/faqs/`}>
        <RemixIcon className={styles['icon']} value="chat-1" color="secondary" size="giant" />
        <span>
          Do you have any questions?
          <br />
          Read our FAQs
        </span>
      </a>
    </div>
  );
};
