import React, {useCallback, useContext, useEffect, useState} from 'react';
import clsx from 'clsx';
import {useNavigate} from 'react-router-dom';

import {SearchFormData} from '@app/types';

import Button from '@components/atoms/Button';
import Dialog from '@components/atoms/Dialog';
import Paper from '@components/atoms/Paper';
import RemixIcon from '@components/atoms/RemixIcon';
import Question from '@components/organisms/Question';
import Share from '@components/organisms/Share';

import Context from '../../contexts/form';
import styles from './SearchActions.module.css';

interface SearchActionsProps {
  className?: string;
  shareLink?: string;  
  saving?: boolean;  
  savingForShare?: boolean;
  queryId?: string;
  onSave?: (value: SearchFormData) => void;
  onSaveForShare?: (value: SearchFormData) => void;
  onReset?: () => void;
}

export default function SearchActions({className, shareLink, queryId, saving, savingForShare, onSave, onSaveForShare, onReset}: SearchActionsProps) {  
  const [openStartOver, setOpenStartOver] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [sharing, setSharing] = useState(false);
  const showStartOver = useCallback(() => setOpenStartOver(true), [setOpenStartOver]);
  const hideStartOver = useCallback(() => setOpenStartOver(false), [setOpenStartOver]);
  const showShare = useCallback(() => setOpenShare(true), [setOpenShare]);
  const hideShare = useCallback(() => setOpenShare(false), [setOpenShare]);  
  const navigate = useNavigate();
  const handleStartOver = useCallback(() => {
    setOpenStartOver(false);
    onReset && onReset();
    // navigate('/search/new/', {replace: true});
  }, [setOpenStartOver, navigate]);  
  const context = useContext(Context);    
  const handleSave = useCallback(() => {
    const values = context.get();    
    values && onSave && onSave(values);
  }, [context]);
  const handleShare = useCallback(() => {
    if (!queryId || queryId === 'new') {
      const values = context.get();      
      if (values && onSaveForShare) {
        setSharing(true);
        onSaveForShare(values);
      }
    } else {
      setSharing(false);
      showShare();
    }
  }, [queryId, showShare, context, setSharing])
  useEffect(() => {
    if (!savingForShare && sharing) {
      setSharing(false);
      showShare();
    }
  }, [savingForShare, sharing, setSharing, showShare]);
  return (
    <Paper className={clsx(styles['container'], className)} size="small" margin="large" border>
      <Button
        className={styles['button']}
        caption="Start over"
        margin={0}
        noWrap
        layout="auto"
        icon={<RemixIcon className={styles['icon']} value="refresh" />}
        onClick={showStartOver}
      />
      <Button
        className={styles['button']}
        caption="Share link"
        margin={0}
        noWrap
        layout="auto"
        icon={<RemixIcon className={styles['icon']} value="share" />}
        onClick={handleShare}
      />
      <Button
        className={styles['button']}
        loading={saving}
        caption="Save"
        margin={0}
        noWrap
        layout="auto"
        icon={<RemixIcon className={styles['icon']} value="save-3" />}
        onClick={handleSave}
      />
      <span className={styles['spacer']} />
      <Button className={styles['link']} caption="Tutorial" margin={0} noWrap layout="auto" link />
      <Button className={styles['link']} caption="FAQs" margin={0} noWrap layout="auto" link url={`${process.env.BLOG}/faqs/`} />
      <Dialog open={openStartOver} onClose={hideStartOver}>
        <Question
          icon="refresh"
          title="Start over"
          text="ALL of your changes will be lost"
          action="Start over"
          onAction={handleStartOver}
          onCancel={hideStartOver}
        />
      </Dialog>
      <Dialog open={openShare} onClose={hideShare}>
        <Share name="query" open={openShare} link={shareLink} onCancel={hideShare} />
      </Dialog>      
    </Paper>
  );
}
