import React, {useCallback} from 'react';
import {Navigate, Routes, Route, useNavigate, Outlet} from 'react-router-dom';

import {Search, Steps, Open as OpenSearch, Compare as CompareOffers} from '@features/Search';
import {OpenTask} from '@features/Dashboard';
import {Compare, ContactUs, Dashboard, Faq, Layout, Offer, Offers, Project, Projects, Tasks, Task} from '@views/.';

export default function Private() {
  const navigate = useNavigate();
  const handleBack = useCallback(
    (index: number) => {
      navigate(`/steps/step-${index}/`);
    },
    [navigate]
  );
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="contact-us" element={<ContactUs />} />
        <Route index element={<Dashboard />} />
        
        <Route path="search/" element={<Outlet />}>
          <Route index element={<Navigate to="new/" replace />} />
          <Route path=":queryId" element={<Search />}>
            <Route index element={<Navigate to="step-1/" replace />} />
            <Route path=":step" element={<Steps />}>
              <Route index element={<div />} />
            </Route>
          </Route>
        </Route>
        <Route path="searches/" element={<Outlet />}>
          <Route index element={<div>Index</div>} />
          <Route path=":queryId" element={<Outlet />}>
            <Route index element={<Offers />} />
            <Route path="compare/" element={<Compare />}>
              <Route index element={<div>Index</div>} />
              <Route path=":ids" element={<CompareOffers />} />
            </Route>
          </Route>
        </Route>
        <Route path="compare/" element={<Compare />}>
          <Route index element={<div>Index</div>} />
          <Route path=":ids" element={<CompareOffers />} />
        </Route>
        <Route path="projects" element={<Projects />} />
        <Route path="projects/:projectId" element={<Project />} />
        <Route path="projects/:projectId/offers/:offerId" element={<Offer />} />
        <Route path="projects/:projectId/tasks/:taskId" element={<Task />} />
        <Route path="projects/:projectId/compare/" element={<Compare />}>
          <Route index element={<div>Index</div>} />
          <Route path=":ids" element={<CompareOffers />} />
        </Route>
        <Route path="tasks" element={<Tasks />} />        
      </Route>
      <Route path="/faq" element={<Faq />} />
      <Route path="/d/:id" element={<OpenSearch />} />
      <Route path="/t/:id" element={<OpenTask />} />      
    </Routes>
  );
}
