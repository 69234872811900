import React from 'react';
import clsx from 'clsx';

import OfferTile from '../OfferTile';

import styles from './ImageSearch.module.css';

interface ImageSearchProps {
  className?: string;
}

const items = [{
  preview: {
    name: 'Offer 1',  
    netLoan: 1717366,
    ltgdv: 68,
    ltc: 85,
    equity: 350000,
    ineterestRate: 6,
    arrangementFee: 1,
    exitFee: 0.75,
    roC: 109.7,
    totalRoE: 430.9,
    roE: 92,
    leveredIRR: 430,
  },
}, {
  preview: {
    name: 'Offer 2',  
    netLoan: 1831860,
    ltgdv: 66.2,
    ltc: 84.6,
    equity: 460783,
    ineterestRate: 7.25,
    arrangementFee: 1.25,
    exitFee: 1.25,
    roC: 108,
    totalRoE: 537,
    roE: 92,
    leveredIRR: 282.3,
  }
}, {
  preview: {
    name: 'Offer 3',  
    netLoan: 1946351,
    ltgdv: 67.1,
    ltc: 85,
    equity: 333934,
    ineterestRate: 6.25,
    arrangementFee: 1,
    exitFee: 1.5,
    roC: 108,
    totalRoE: 738.7,
    roE: 92,
    leveredIRR: 363.6,
  }
}, {
  preview: {
    name: 'Offer 4',  
    netLoan: 1515622,
    ltgdv: 68.4,
    ltc: 83.2,
    equity: 48600,
    ineterestRate: 6,
    arrangementFee: 1,
    exitFee: 0.75,
    roC: 108.2,
    totalRoE: 460.4,
    roE: 93,
    leveredIRR: 322.4,
  },
}, {
  preview: {
    name: 'Offer 5',  
    netLoan: 1672499,
    ltgdv: 66.6,
    ltc: 85.5,
    equity: 460783,
    ineterestRate: 7.25,
    arrangementFee: 1.25,
    exitFee: 1.25,
    roC: 108,
    totalRoE: 537,
    roE: 92,
    leveredIRR: 282.3,
  }
}, {
  preview: {
    name: 'Offer 6',  
    netLoan: 2035267,
    ltgdv: 64.2,
    ltc: 83.4,
    equity: 333934,
    ineterestRate: 6.35,
    arrangementFee: 1,
    exitFee: 1.5,
    roC: 106.4,
    totalRoE: 738.7,
    roE: 92,
    leveredIRR: 363.6,
  }
}, {
  preview: {
    name: 'Offer 7',  
    netLoan: 1831860,
    ltgdv: 63.2,
    ltc: 84.3,
    equity: 460783,
    ineterestRate: 7.25,
    arrangementFee: 1.25,
    exitFee: 1.25,
    roC: 108,
    totalRoE: 537,
    roE: 92,
    leveredIRR: 282.3,
  }
}, {
  preview: {
    name: 'Offer 8',  
    netLoan: 1946351,
    ltgdv: 65,
    ltc: 85,
    equity: 333934,
    ineterestRate: 6.25,
    arrangementFee: 1,
    exitFee: 1.5,
    roC: 108,
    totalRoE: 738.7,
    roE: 92,
    leveredIRR: 363.6,
  }
}];

export default function ImageSearch({className}: ImageSearchProps) {
  return (
    <ul className={clsx(styles.container, className)}>
      {items.map((item, i) => 
        <OfferTile 
          className={styles.item}
          preview={item.preview}
          key={i} 
        />
      )};
    </ul>
  )
}