import React from 'react';
import clsx from 'clsx';

import Paper from '../../atoms/Paper';

import styles from './Notification.module.css';
import RemixIcon from '@components/atoms/RemixIcon';

interface NotificationProps {
  className?: string;
  type?: 'alert';
  title?: string;
  text?: string;
}

export default function Notification({className, type, title, text}: NotificationProps) {
  return (
    <Paper className={clsx(styles['container'], type ? styles['type_' + type] : null, className)} size="small" flex>
      {type === 'alert' ? <RemixIcon value="alert" color="filled-error" /> : null }
      <div className={styles['content']}>
        <div>{title}</div>
        <div>{text}</div>
      </div>
    </Paper>
  );
};
