import React, {useRef, useState, forwardRef, useEffect, InputHTMLAttributes, LegacyRef, useCallback, KeyboardEvent, ChangeEvent} from 'react';
import clsx from 'clsx';
import {Field} from '@hooks/useForm';
import NumberFormat, {NumberFormatValues, SourceInfo} from 'react-number-format';

import styles from './InlineInput.module.css';

interface InlineInputProps<T, N> extends InputHTMLAttributes<HTMLInputElement> {
  field: Field<T, N, {}>;
  anchor?: string;
  integer?: boolean;
  text?: boolean;
  year?: boolean;
  prefix?: string;
  errorIfEmpty?: boolean;  
}

export function InlineInput<T, N >(
  {className, anchor, field, name, integer, text, year, prefix, errorIfEmpty, readOnly, ...props}: InlineInputProps<T, N>,  
) {  
  const refInput = useRef<NumberFormat<string | null> | null>(null);
  const [showValue, setShowValue] = useState(field?.textValue);
  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {    
    field.setTextValue(e.currentTarget.value);
  }, [field]);
  
  const handleValueChange = useCallback((values: NumberFormatValues, sourceInfo: SourceInfo) => {    
    setShowValue(values.formattedValue);
    if (document.activeElement === refInput.current) {
      field.setTextValue(values.value || '');
    }
  }, [field, setShowValue, refInput]);

  useEffect(() => {
    if (field.textValue !== showValue) {
      setShowValue(field.textValue);
    }
  }, [field, showValue, setShowValue]);
  const v = field.textValue as string;

  useEffect(() => {
    if (anchor && refInput.current) (refInput.current as unknown as HTMLInputElement).setAttribute('data-anchor', anchor);
  }, [refInput, anchor]);

  if (text) {
    return (
      <input 
        className={clsx(styles['control'], className, errorIfEmpty && !showValue ? styles['error'] : null)} 
        data-anchor={anchor}
        name={field.name as unknown as string}        
        value={showValue}
        placeholder={typeof field.placeholder === 'string' ? field.placeholder : undefined}
        readOnly={readOnly}
        onChange={handleChange}      
      />
    )
  }
  
  return (
    <NumberFormat
      className={clsx(styles['control'], className, errorIfEmpty && !showValue ? styles['error'] : null)}
      name={field.name as unknown as string}        
      value={showValue}
      prefix={prefix}
      readOnly={readOnly}
      thousandSeparator={year ? undefined : ","}
      decimalSeparator={year ? undefined : '.'}
      decimalScale={integer || year ? 0 : 2}
      placeholder={typeof field.placeholder === 'string' ? field.placeholder : undefined}      
      onValueChange={handleValueChange}      
      getInputRef={refInput}
    />
  );
}

export default forwardRef(InlineInput);
