import React, {useEffect, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {urlDecodeGuid} from '@utils/format';

import Paper from '@components/atoms/Paper';
import CompareGrid, {FieldDef} from '@components/organisms/CompareGrid';
import {LoanOfferSearchResponse, Project} from '@app/types';
import ContentTitle from '@features/Layout/components/ContentTitle';

const title = 'Compare you loan offers';

const fieldsTerms = [
  // {caption: 'Tenure (months)', name: 'loanTenureMonths', compare: "max", type: 'integer'},
  {caption: 'LTGDV', name: 'ltgdv', compare: "max", type: '%', decimals: 0,},
  {caption: 'LTC', name: 'ltc', compare: "max", type: '%', decimals: 0},
  {caption: 'Arrangement fee', name: 'arrangementFee', compare: 'min', type: '%', decimals: 2},
  {caption: 'Interest rate (pa)', name: 'ineterestRate', compare: 'max', type: '%', decimals: 2},
  {caption: 'Exit fee', name: 'exitFee', compare: 'min', type: '%', decimals: 2}, 
] as FieldDef[];

const fieldsSummary = [
  {caption: 'Gross facility', name: 'grossFacility', compare: "max", type: 'money'},
  {caption: 'Interest facility', name: 'interestCost', compare: "max", type: 'money'},
  {caption: 'Build facility', name: 'totalBuildCost', compare: "max", type: 'money'},
  {caption: 'Acquisition facility', name: 'acquisitionFacility', compare: 'min', type: 'money'},
  {caption: 'Day 1 gross LTV', name: 'grossLTVDay1', compare: 'max', type: '%'},
  {caption: 'Arrangement fee', name: 'arrangementFeeCost', compare: 'min', type: 'money'},
  {caption: 'Net acquisition facility', name: 'netAcquisitionFacility', compare: 'min', type: 'money'}, 
  {caption: 'Equity requirement', name: 'equity', compare: 'min', type: 'money', total: true},   
] as FieldDef[];

const fieldsAppraisal = [
  {caption: 'Estimated profit (levered)', name: 'netProfit', compare: "max", type: 'money'},
  {caption: 'RoC', name: 'roC', compare: "max", type: '%', decimals: 1},
  {caption: 'Total RoE', name: 'totalRoE', compare: "max", type: '%', decimals: 1},
  {caption: 'Equity IRR', name: 'leveredIRR', compare: 'max', type: '%', decimals: 1},  
] as FieldDef[];

interface CompareProps {
  source?: LoanOfferSearchResponse;
  project?: Project;
  onOpenProject: (projectId: string) => unknown;
}

//

export default function Compare({source, project, onOpenProject}: CompareProps) {
  const {ids, queryId, projectId} = useParams();
  const items = useMemo(() => {
    const dids = ids ? ids.split(',').map(urlDecodeGuid) : [];
    const from = source ? source : project && project.id === projectId ? project : null;
    if (from) return from.offers.filter(item => dids.indexOf(item.offerId) >= 0);
    return [];
    /*if (project && project.id === projectId) 
    return source && source.offers ? source.offers.filter(item => dids.indexOf(item.offerId) >= 0) : [];*/
  }, [ids, source, project]);
  useEffect(() => {
    if (project?.id === projectId) return;    
    if (projectId && onOpenProject) onOpenProject(projectId);
  }, [onOpenProject, projectId, project]);  
  const breadcrumbs = useMemo(() => {
    if (projectId) {
      return [
        {caption: 'Dashboard', url: `/`},
        {caption: project?.name || 'Project', url: `/projects/${projectId}/`},
        {caption: 'Compare', url: `/projects/${projectId}/compare`},
      ].map((item, i) => (
        <ContentTitle title={title} breadcrumb={item} key={i} />
      ));
    } else {
      return [
        {caption: 'Search', url: `/search/${queryId}/`},
        {caption: 'Offers list', url: `/searches/${queryId}/`},
        {caption: 'Compare', url: '/'}
      ].map((item, i) => (
        <ContentTitle title={title} breadcrumb={item} key={i} />
      ));
    }
  }, [ids, queryId, projectId, project]);
  return (
    <>
      {breadcrumbs}
      <Paper size="medium" margin="medium">
        <CompareGrid caption="Loan terms" fields={fieldsTerms} objects={items} />        
      </Paper>
      <Paper size="medium" margin="medium">
        <CompareGrid caption="Loan summary" fields={fieldsSummary} objects={items} />        
      </Paper>
      <Paper size="medium" margin="medium">        
        <CompareGrid caption="Summary appraisal" fields={fieldsAppraisal} objects={items} />        
      </Paper>
    </>    
  )
};
