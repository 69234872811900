import React, {ElementType, PropsWithChildren} from 'react';
import clsx from 'clsx';

import styles from './Title.module.css';
import RemixIcon from '../RemixIcon';

interface TitleProps {
  className?: string;
  level?: number;  
}

const LEVELS: string[] = ['h1', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'h7'];

export default function Title({className, level = 1, children}: PropsWithChildren<TitleProps>) {
  const Component = LEVELS[level] as ElementType;
  return (
    <Component className={clsx(styles['title'], styles['title-' + level], className)}>
      {children}      
    </Component>
  );
}
