import React from 'react';

import {Projects} from '@features/Dashboard';

import ContentTitle from '@features/Layout/components/ContentTitle';

export default function ProjectsView() {
  return (
    <>
      <ContentTitle title='Projects' />
      <Projects />
    </>    
  )
};
