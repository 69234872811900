import React, {PropsWithChildren, ReactNode} from 'react';
import clsx from 'clsx';

import KeyValue from '../KeyValue';

import styles from './KeyValues.module.css';

interface KeyValueItem {
  term: string;
  definition?: string | ReactNode;
}

interface KeyValuesProps {
  className?: string;
  color?: 'primary' | 'secondary';
  items?: KeyValueItem[];
}

export default function KeyValues({className, color, items, children}: PropsWithChildren<KeyValuesProps>) {
  return (
    <dl className={clsx(styles['container'], className)}>
      {items
        ? items.map((item, i) => {
            <KeyValue term={item.term} definition={item.definition} color={color} key={i} />;
          })
        : null}
      {children}
    </dl>
  );
}
