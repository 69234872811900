import {connect} from 'react-redux';

import {actions, selectors} from '@app/store/search';
import {selectors as session} from '@app/store/session';

import {RootState} from '@app/store';

import Offers from '../components/Offers';
import OffersActions from './OffersActions';

export default connect(
  (state: RootState) => ({
    loading: selectors.offers.loading(state),
    loaded: selectors.offers.loaded(state),
    value: selectors.offers.value(state),
    error: selectors.offers.error(state),
    loggedIn: session.isLoggedIn(state),
    OffersActions,
  }),
  {
    onOpen: actions.offers.get
  }
)(Offers);
