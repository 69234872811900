import React, {useMemo, useState} from "react";
import {SearchStep3Data} from "@app/types";
import {FormStepProps} from "../Form";

import TextInput from "@components/molecules/TextInput";
import RadioInput from "@components/molecules/RadioInput";
import UnitDetails from "../UnitDetails";
import PercentageText from "@components/molecules/PercentageText";
import PercentageInput from "@components/molecules/PercentageInput";
import Money from "@components/atoms/Money";
import Total from "@components/molecules/Total";

export default function Step3({fields}: FormStepProps<SearchStep3Data>) {
  const {areaMetric, unitDetails, salesAgentFee, salesLegalFee} = fields;
  const [gdv, setGdv] = useState<number>();

  const ndv = useMemo(() => {    
    const result = gdv && salesAgentFee && salesLegalFee ? gdv - (salesAgentFee.value || 0) - (Number(salesLegalFee.value) || 0) : undefined;
    if (result === undefined || isNaN(result)) return 0;
    return result || 0;
  }, [gdv, salesAgentFee, salesLegalFee]);
  const costsOfSales = useMemo(() => {
    const value = (Number(salesAgentFee.value) || 0) + (Number(salesLegalFee.value) || 0);
    const percents = gdv && value ? (value * 100) / gdv : 0;
    return {
      value,
      percents,
    };
  }, [gdv, salesAgentFee, salesLegalFee]);
  return (
    <>
      <RadioInput field={areaMetric} />
      <UnitDetails field={unitDetails} units={areaMetric.value} onChangeTotal={setGdv} />
      <PercentageInput margin={0} field={salesAgentFee} base={gdv} smallError />
      <PercentageInput margin={0} field={salesLegalFee} base={gdv} smallError />
      <PercentageText
        caption="Cost of sales"
        description=""
        tooltip="Automatically generated as the sum of agent and legal fees."
        value0={<Money currency="£" value={costsOfSales.value} integer />}
        value1={
          <>
            <Money value={costsOfSales.percents} />
            <span>% of GDV</span>
          </>
        }
      />
      <Total caption="Net Development Value" values={[<Money currency="£" value={ndv} integer />]} />
    </>
  );
}
