import React, {useEffect, useState, PropsWithChildren, ReactElement, ReactNode} from 'react';
import QuickTip from '@components/atoms/QuickTip';

interface TooltipProps {
  caption?: string | ReactNode;
  text?: string | ReactNode;
}

function getPoint(e: HTMLElement) {
  const w = e.offsetWidth;
  const h = e.offsetHeight;
  const t = e.offsetTop;
  const l = e.offsetLeft;
  return {
    left: Math.trunc(w / 2),
    top: t + h + 9,
  };
}

export default function Tooltip({caption, text, children}: PropsWithChildren<TooltipProps>) {
  const [active, setActive] = useState<HTMLElement | null>();
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState({right: '10px', top: '64px'});
  const inner = React.Children.map(children, (child, i) => {
    const item = child as ReactElement<any>;
    return React.cloneElement(item, {
      onMouseEnter: (e: MouseEvent) => {
        setActive(e.target as HTMLElement);
      },
      onMouseLeave: () => {
        setActive(null);
      },
    });
  });
  useEffect(() => {
    if (active) {
      const point = getPoint(active);
      setPosition({right: point.left + 6 + 'px', top: point.top + 7.5 + 'px'});
    }
    setVisible(!!active);
  }, [active, setVisible, setPosition]);
  return (
    <>
      {inner}
      <QuickTip visible={visible} caption={caption} text={text} position={position} />
    </>
  );
}
