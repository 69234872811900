import React, {PropsWithChildren, ReactElement} from 'react';
import clsx from 'clsx';

import Button from '@components/atoms/Button';

import styles from './DashboardLine.module.css';
import RemixIcon from '@components/atoms/RemixIcon';
import Skeleton from '@components/atoms/Skeleton';

interface DashboardLineProps {
  className?: string;
  closed?: boolean;
  cancelled?: boolean;
  expired?: boolean;
  url?: string;  
  actions?: ReactElement;
  loading?: boolean;
}

export default function DashboardLine({className, closed, cancelled, expired, url, actions, loading, children}: PropsWithChildren<DashboardLineProps>) {
  return (
    <li className={clsx(styles['container'], loading ? styles['loading'] : null, className)}>
      {loading ? <Skeleton className={styles['skeleton']} active /> : <>
        <span className={clsx(styles['content'], closed ? styles['content_closed'] : null, cancelled ? styles['content_cancelled'] : null)}>{children}</span>
        <span className={styles['actions']}>
          {actions}
          {url ? <Button className={styles['link']} url={url} color="primary" margin={0} layout="auto" icon={<RemixIcon value="arrow-right-s" />} /> : null}
        </span>
        </>}
    </li>
  );
};
