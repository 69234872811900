import React, {forwardRef, ChangeEvent, useEffect, useRef, useState, LegacyRef, RefObject, TextareaHTMLAttributes, useCallback} from 'react';
import clsx from 'clsx';

import styles from './TextArea.module.css';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  anchor?: string;
  autoHeight?: boolean;  
  disabled?: boolean;
  error?: boolean;
  smallError?: boolean;
}

export function TextArea({className, anchor, error, autoHeight = true, onChange, value, ...props}: TextAreaProps, ref: LegacyRef<HTMLTextAreaElement>) {
  const [internalValue, setInternalValue] = useState(value || '');
  const classes = clsx(
    styles['container'],
    error ? styles['error'] : null,
    autoHeight ? styles['auto-height'] : null,
    className,
  );
  const handleChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setInternalValue(e.currentTarget.value);
    onChange && onChange(e);
  }, [onChange, setInternalValue]);
  return (
    <div className={classes}>
      <textarea className={styles['control']} {...props} value={value} onChange={handleChange} data-anchor={anchor}  ref={ref} />
      {autoHeight ? <div className={styles['former']}>{internalValue}|</div> : null}
    </div>
  )
}

export default forwardRef<HTMLTextAreaElement, TextAreaProps>(TextArea);
