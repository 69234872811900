import React, {useCallback, useMemo, useState} from "react";
import {SearchStep5Data} from "@app/types";
import {FormStepProps} from "../Form";

import TextInput from "@components/molecules/TextInput";
import RadioInput from "@components/molecules/RadioInput";
import BuildCosts from "@components/organisms/BuildCosts";
import UnitDetails from "../UnitDetails";
import PercentageText from "@components/molecules/PercentageText";
import PercentageInput from "@components/molecules/PercentageInput";
import Money from "@components/atoms/Money";
import Total from "@components/molecules/Total";
import AreaUnits from "@components/atoms/AreaUnits";

export default function Step5({fields}: FormStepProps<SearchStep5Data>) {
  const {purchasePrice, stampDutyTaxLevy, acquisitionAgentFee, acquisitionLegalFee} = fields;
  const total = useMemo(() => {
    return (purchasePrice.value || 0) + (stampDutyTaxLevy.value || 0) + (acquisitionAgentFee.value || 0) + (acquisitionLegalFee.value || 0)
  }, [purchasePrice, stampDutyTaxLevy, acquisitionAgentFee, acquisitionLegalFee]);  
  return (
    <>      
      <TextInput field={purchasePrice} inputType="float" prefix="£" />
      <PercentageInput field={stampDutyTaxLevy} base={purchasePrice.value} />
      <PercentageInput field={acquisitionAgentFee} base={purchasePrice.value} />
      <PercentageInput field={acquisitionLegalFee} base={purchasePrice.value} />
      <Total caption="Total Aquisition Cost" values={[<Money currency="£" value={total} integer />]} />
    </>
  );
}
