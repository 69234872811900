import {createSlice} from '@reduxjs/toolkit';
import {Search, SearchFormData} from '@app/types/search';
import thunks from './thunks';
import { StateChangeTypes } from 'downshift';
import { LoanOfferSearchResponse } from '@app/types';

export interface InitialStateItem {
  loading: boolean;
  posting: boolean;
  loaded: boolean;
  posted: boolean;
  saving: boolean;
  savingForShare: boolean;
  value?: SearchFormData;
  error?: unknown;
  step: number;
}

export interface InitialStateList {
  loading: boolean;
  value?: SearchFormData[];
  error?: unknown;
}

export interface InitialStateOffers {
  loading: boolean;
  loaded: boolean;
  queryId?: string;
  value?: LoanOfferSearchResponse;
  error?: unknown;
  applying: boolean;
  applied: boolean;
  appliedProjectId?: string;
  applyError?: unknown;
}

export const initialStateItem = {
  loading: false,
  posting: false,
  loaded: false,
  posted: false,
  saving: false,
  savingForShare: false, 
  value: undefined,
  step: 0,
} as InitialStateItem;

export const initialStateList = {
  loading: false,
  value: undefined,
} as InitialStateList;

export const initialStateOffers = {
  loading: false,
  loaded: false,
  queryId: undefined,
  value: undefined,
  error: undefined,
  applying: false,
  applied: false,
  appliedProjectId: undefined,
} as InitialStateOffers;

const current = createSlice({
  name: '@@search/current',
  initialState: initialStateItem,
  reducers: {
    setStep: (state, {payload}) => {
      state.step = payload;
      state.posted = false;
    },
    reset: (state) => {
      state.loaded = true;
      state.loading = false;
      state.error = undefined;
      state.posted = false;
      state.posting = false;
      state.saving = false;
      state.savingForShare = false;
      state.value = {
        contingency: 5,
        areaMetric: 'Ft',
      } as SearchFormData;
      state.step = 0;
    },
    setQuery: (state, {payload}) => {
      state.loaded = true;
      state.loaded = true;
      state.loading = false;
      state.error = undefined;
      state.posted = false;
      state.posting = false;
      state.saving = false;
      state.savingForShare = false;
      state.value = {
        ...payload,
        id: undefined,
      } as SearchFormData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.current.open.pending, (state) => {
        state.loading = true;
        state.posted = false;
      })
      .addCase(thunks.current.open.fulfilled, (state, {payload}) => {
        state.loading = false;
        state.loaded = true;
        state.value = payload;
        state.step = 0;
      })
      .addCase(thunks.current.post.pending, (state) => {
        state.posting = true;
        state.posted = false;
      })
      .addCase(thunks.current.post.rejected, (state, {payload}) => {
        state.loading = false;
        state.posting = false;
        state.posted = false;
        state.error = payload;
      })
      .addCase(thunks.current.post.fulfilled, (state, {payload}) => {
        state.loading = false;
        state.posting = false;
        state.posted = true;
        state.value = payload;
        state.error = undefined;
      })
      .addCase(thunks.current.save.pending, (state) => {
        state.saving = true;        
      })
      .addCase(thunks.current.save.fulfilled, (state, {payload}) => {
        state.saving = false;
        state.value = payload;
        state.error = undefined;
      })
      .addCase(thunks.current.save.rejected, (state, {payload}) => {
        state.saving = false;
        state.error = payload;
      })
      .addCase(thunks.current.saveForShare.pending, (state) => {
        state.savingForShare = true;        
      })
      .addCase(thunks.current.saveForShare.fulfilled, (state, {payload}) => {
        state.savingForShare = false;
        state.value = payload;
        state.error = undefined;
      })
      .addCase(thunks.current.saveForShare.rejected, (state, {payload}) => {
        state.savingForShare = false;
        state.error = payload;
      })
      .addCase(thunks.current.get.pending, (state) => {
        state.loading = true;
      })
      .addCase(thunks.current.get.fulfilled, (state, {payload}) => {
        state.loading = false;
        state.value = payload;
        state.error = undefined;
      })
      .addCase(thunks.current.get.rejected, (state, {payload}) => {
        state.loading = false;
        state.value = undefined;
        state.error = payload;
      });
  },
});

const list = createSlice({
  name: '@@search/list',
  initialState: initialStateList,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunks.list.get.pending, (state) => {
        state.loading = true;
      })
      .addCase(thunks.list.get.fulfilled, (state, {payload}) => {
        state.loading = false;
        state.value = payload;
        state.error = undefined;
      })
      .addCase(thunks.list.get.rejected, (state, {payload}) => {
        state.loading = false;
        state.value = undefined;
        state.error = payload;
      });
  },
});

const offers = createSlice({
  name: '@@search/offers',
  initialState: initialStateOffers,
  reducers: {  
    reset: (state) => {
      state.loaded = true;
      state.loading = false;
      state.error = undefined;
      state.applying = false;
      state.applied = false;
      state.appliedProjectId = undefined;
      state.applyError = undefined;      
    },
  },  
  extraReducers: (builder) => {
    builder
      .addCase(thunks.offers.get.pending, (state) => {
        state.loading = true;
      })
      .addCase(thunks.offers.get.fulfilled, (state, {payload}) => {
        state.loading = false;
        state.loaded = true;
        state.error = undefined;
        state.value = payload; 
      })
      .addCase(thunks.offers.get.rejected, (state, {payload}) => {
        state.loading = false;
        state.loaded = true;
        state.value = undefined;
        state.error = payload;
        console.log(payload)
      })
      .addCase(thunks.offers.apply.pending, (state) => {
        state.applying = true;
      })
      .addCase(thunks.offers.apply.fulfilled, (state, {payload}) => {
        state.applying = false;
        state.applied = true;
        state.appliedProjectId = payload.id;
      })
      .addCase(thunks.offers.apply.rejected, (state, {payload}) => {
        state.applying = false;
        state.applied = false;
        state.appliedProjectId = undefined;
        state.applyError = payload;
      });
  }
});

export default {
  current,
  list,
  offers,
};
