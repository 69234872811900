import React, {ChangeEvent, ReactNode, useCallback, useState, useRef} from 'react';

import {GoogleMap, Autocomplete, AutocompleteProps, StandaloneSearchBox, StandaloneSearchBoxProps, useJsApiLoader} from '@react-google-maps/api';

import {Field} from '@hooks/useForm';

// import AutoComplete from '@components/atoms/AutoComplete';

import CommonInput from '../CommonInput';
import Input from '@components/atoms/Input';

interface GoogleAutocompleteState {
  getPlace: () => unknown;
}

interface AutocompleteState {
  autocomplete: GoogleAutocompleteState;
}

type AddressFieldValue = string | undefined;

interface AddressInputProps<T, N> {
  className?: string;
  anchor?: string;
  caption?: ReactNode;
  description?: ReactNode;  
  error?: ReactNode;
  layout?: "row";
  tooltip?: string;
  tooltipCaption?: string;
  margin?: number;  
  disabled?: boolean;
  required?: boolean;
  field?: Field<T, N, {}>;
  fieldCountry?: Field<AddressFieldValue, unknown, {}>;
  fieldCity?: Field<AddressFieldValue, unknown, {}>;
  fieldPostCode?: Field<AddressFieldValue, unknown, {}>;
  fieldRegion?: Field<AddressFieldValue, unknown, {}>;
}

interface SiteAddress {
  siteFullAddress: string;
  sitePostcode?: string;
  siteCountry?: string;
  siteCity?: string;
  siteRegion?: string;
  countryCode?: string;
}

const libraries = ['places'] as ('places')[];

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

interface Place {
  address_components: AddressComponent[];
  formatted_address: string;
}

function getAddressComponent(components: AddressComponent[], type: string): AddressComponent {
  return components.find(item => item.types.indexOf(type) >= 0) || {} as AddressComponent;
}

function placeToAddress(place: Place): SiteAddress {
  const country = getAddressComponent(place.address_components, 'country');
  return {
    siteFullAddress: place.formatted_address,
    siteCity: getAddressComponent(place.address_components, 'postal_town').long_name,
    siteCountry: country.long_name,
    sitePostcode: getAddressComponent(place.address_components, 'postal_code').long_name,
    countryCode: country.short_name,
    siteRegion: getAddressComponent(place.address_components, 'administrative_area_level_2').long_name,
  } as SiteAddress;
}

export default function AddressInput<T, N>({className, anchor, fieldCountry, fieldCity, fieldPostCode, fieldRegion, field, caption, description, disabled, required, error, layout, tooltip, tooltipCaption, margin, ...props}: AddressInputProps<T, N>) {
  const [text, setText] = useState(field?.textValue);  
  const refInput = useRef<HTMLInputElement | null>(null);
  const refAutocomplete =  useRef<Autocomplete | null>(null);
  const [localError, setLocalError] = useState<string | undefined>();
  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {  
    setText(e.currentTarget.value);
  }, [setText]);
  const {isLoaded} = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyBmmsIhZGv3o7bX1vtYOoxaMvVWpAHWi30',
    language: 'en',
    libraries,
  });
  const handlePlaceChange = useCallback(() => {
    //console.log('handlePlaceChanged', refInput.current.value);    
    const place = refAutocomplete.current?.state.autocomplete?.getPlace();
    const address = place ? placeToAddress(place as Place) : null;
    if (address && refInput.current) {
      const value = refInput.current.value;
      setText(value);
      field?.setTextValue(value);
      fieldCountry && fieldCountry.setTextValue(address.siteCountry);
      fieldPostCode && fieldPostCode.setTextValue(address.sitePostcode);
      fieldCity && fieldCity.setTextValue(address.siteCity);
      fieldRegion && fieldRegion.setTextValue(address.siteRegion);
      if (address.countryCode !== 'GB') setLocalError('Please enter a valid UK address');
      else setLocalError('');
    } else {
      setText('');
      field?.setTextValue('');
      fieldCountry && fieldCountry.setTextValue('');
      fieldPostCode && fieldPostCode.setTextValue('');
      fieldCity && fieldCity.setTextValue('');
      fieldRegion && fieldRegion.setTextValue('');
      setLocalError('');
    }
  }, [refInput, setText, field, refAutocomplete, setLocalError]);
  return (
    <CommonInput 
      className={className} 
      caption={caption || field?.caption}
      required={required || !!field?.required}
      disabled={disabled}
      description={description || field?.description}
      error={error || field?.error || localError}
      ownError={localError}
      layout={layout}
      tooltip={tooltip || field?.hint}      
      margin={margin}
    >
      {isLoaded ? <Autocomplete onPlaceChanged={handlePlaceChange} ref={refAutocomplete}>
        <Input anchor={anchor} value={text} onChange={handleInputChange} ref={refInput} />
      </Autocomplete>  : null}
    </CommonInput>
  )
}
