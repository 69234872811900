import {Selector} from '@reduxjs/toolkit'

export type LocalSelector<RootState extends {}, 
    InitialState extends {}, 
    Params extends readonly any[] = any[]> = Selector<RootState, InitialState, Params>;

export function scopeSelector<
    LocalState, RootState, Result, LocalParams extends readonly any[] = any[], 
    RootParams extends readonly any[] = any[],
>(
    selector: Selector<LocalState, Result, LocalParams>, 
    getLocalState: Selector<RootState, LocalState, RootParams>, ...rootParams: RootParams) 
{
    return (state: RootState, ...params: LocalParams) => selector(getLocalState(state, ...rootParams), ...params);
}