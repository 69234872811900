import React, { ReactElement } from "react";
import clsx from "clsx";
import {storage} from '@utils/storage';

import Button from "@components/atoms/Button";
import Logo from "@components/atoms/Logo";
import NavMenu from "@components/molecules/NavMenu";
import NavActions from "@components/molecules/NavActions";

import styles from "./Header.module.css";
import Skeleton from "@components/atoms/Skeleton";

import api from '@app/api/rest';

type ButtonProps = Parameters<typeof Button>[0];

interface HeaderProps {
  className?: string;
  loading?: boolean;
  loggedIn?: boolean;  
  onLogin?: () => void;
  LoginButton?: (props: ButtonProps) => ReactElement;
}

function handleClickToHost() {
  storage.lastUrl = window.location.pathname;  
}

function handleLogoutClick() {
  storage.lastUrl = undefined;  
  storage.token = undefined;
}

const publicActions = [
  {icon: "home-4", url: "/"},
  {icon: "newspaper", url: "https://fundkeet.wordpress.com/"},
  {icon: "search", url: "/search/"},
  {icon: "user", url: `${process.env.MAIN_HOST!}/MicrosoftIdentity/Account/EditProfile`, currentWindow: true},
  {icon: "logout-box-r", url: `${process.env.MAIN_HOST!}/MicrosoftIdentity/Account/SignOut`, accent: true, currentWindow: true},
];

const menuItems = [
  {caption: 'Home', url: "/"},
  {caption: 'Blog', url: "https://fundkeet.wordpress.com/", onClick: handleClickToHost},
  {caption: 'Search', url: "/search"},
  {caption: 'Profile', url: `${process.env.MAIN_HOST!}/MicrosoftIdentity/Account/EditProfile`, currentWindow: true, onClick: handleClickToHost},
  {caption: 'Sign out', url: `${process.env.MAIN_HOST!}/MicrosoftIdentity/Account/SignOut`, accent: true, currentWindow: true, onClick: handleLogoutClick},
];

export default function Header({className, loading, loggedIn, LoginButton}: HeaderProps) {
  const LButton = LoginButton || Button;
  return (
    <header className={clsx(styles["container"], className)}>
      <Logo className={styles["logo"]} />      
      <div className={styles["side-menus"]}>
        {loading ? null : loggedIn ? (
          <>
            <NavMenu items={menuItems} />
            {/*<button onClick={() => api.session.$refresh()}>Refresh</button>*/}
          </>
        ) : (
          <LButton className={styles["login"]} caption="Sign Up / Sign In" color="primary" layout="auto" margin={0} />
        )}
      </div>
    </header>
  );
}

// {/*<NavActions items={publicActions} />*/}