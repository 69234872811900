import React from 'react';
import {Step1} from '../components/StepForms';
import Form from './Form';
import {fieldsStep1} from '../hooks/useNewForm';
import { SearchFormData, SearchStep1Data } from '@app/types';
import { FormStep } from '../components/Form/Form';
import { FieldsDefs } from '@hooks/useForm';

export default function StepForm() {
  return (    
    <Form first index={0} fields={fieldsStep1} Step={Step1 as FormStep<any>} />
  )
}

/*import React from 'react';
import {connect} from 'react-redux';

import {actions, selectors, RootState} from '@app/store/search';

// import Step1 from '../components/Step-1';
import {Step1} from '../components/StepForms';
import Form from '../components/Form';

export default connect(
  (state: RootState) => ({
    queryId: selectors.current.queryId(state),
    loading: selectors.current.loading(state),
    posting: selectors.current.posting(state),
    value: selectors.current.value(state),
    error: selectors.current.error(state),    
  }),
  {
    onSubmit: actions.current.post,
  }
)(Form);*/

/*
export default connect(
  (state: RootState) => ({
    queryId: selectors.current.queryId(state),
    loading: selectors.current.loading(state),
    posting: selectors.current.posting(state),
    value: selectors.current.value(state),
    error: selectors.current.error(state),
  }),
  {
    onSubmit: actions.current.post,
  }
)(Step1);
*/