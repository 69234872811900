import {connect} from 'react-redux';

import {actions, selectors, RootState} from '@app/store/projects';
import {actions as offersActions, selectors as offersSelectors, RootState as OffersState} from '@app/store/offers';

import Offer from '../components/Offer';
import Tasks from './TasksGroup';

export default connect(
  (state: RootState) => ({
    loading: selectors.item.isLoading(state) || offersSelectors.item.loading(state as unknown as OffersState),
    project: selectors.item.value(state),
    offer: offersSelectors.item.value(state as unknown as OffersState),
    rejecting: offersSelectors.operations.reject.loading(state as unknown as OffersState),
    rejected: offersSelectors.operations.reject.success(state as unknown as OffersState),
    renaming: offersSelectors.operations.setName.loading(state as unknown as OffersState),
    renamed: offersSelectors.operations.setName.success(state as unknown as OffersState),
    submitting: offersSelectors.operations.submit.loading(state as unknown as OffersState),
    submitted: offersSelectors.operations.submit.success(state as unknown as OffersState),
    Tasks,
  }),
  {
    onOpenProject: actions.item.open,
    onOpenOffer: offersActions.item.get,
    onCloseProject: actions.item.close,
    onReject: offersActions.operations.reject,
    onRejectReset: offersActions.operations.rejectReset,
    onRename: offersActions.operations.setName,
    onRenameReste: offersActions.operations.setNameReset,
    onSubmit: offersActions.operations.submit,
    onSubmitReset: offersActions.operations.submitReset,
  }
)(Offer);
