import React, {useMemo, InputHTMLAttributes, ReactNode, ChangeEventHandler} from 'react';
import clsx from 'clsx';

import styles from './CheckBox.module.css';

export interface OptionObject<T> {
  caption: string | ReactNode;
  value: T;
}
export type Option<T> = T | OptionObject<T>;

interface CheckBoxProps<T> {
  className?: string;
  anchor?: string;
  name?: string;
  type?: string;
  error?: boolean;
  value?: Option<T>;
  fieldValue?: string;
  readOnly?: boolean;
  required?: boolean;
  checked?: boolean;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  register?: () => InputHTMLAttributes<HTMLInputElement>;
}

const EMPTY_OBJECT = {};

interface Stringable {
  toString: () => string;
}

function isObject<T>(value?: Option<T>): value is OptionObject<T> {
  return !!value && typeof value === 'object';
}

function isStringable(value: Stringable | any): value is Stringable {
  return typeof value !== 'undefined' && !!(value as Stringable).toString;
}

function valueToFieldValue<T>(v: T): string {
  if (typeof v === 'string') return v;
  else if (isStringable(v)) return (v as Stringable).toString();  
  else return '';
}

export default function CheckBox<T>({className, anchor, error, type, name, value, disabled, readOnly, required, checked, fieldValue, onChange, register}: CheckBoxProps<T>) {
  const {inputValue, inputCaption} = useMemo(
    () =>
      isObject(value)
        ? {inputValue: valueToFieldValue(value.value), inputCaption: value.caption}
        : {inputValue: valueToFieldValue(value), inputCaption: value},
    [value]
  );  
  const boxClasses = clsx(
    styles['box'],
    inputValue === fieldValue || checked ? styles['box_checked'] : null,
    disabled ? styles['box_disabled'] : null,
  );
  return (
    <label className={clsx(styles['container'], disabled ? styles['disabled'] : null, readOnly ? styles['readonly'] : null, error ? styles['error'] : null, className)}>
      <input
        className={styles['field']}
        data-anchor={anchor}
        type={type || 'radio'}
        name={name}
        required={required}
        value={inputValue || ''}
        checked={inputValue === fieldValue || checked}
        disabled={disabled}
        onChange={onChange}
        readOnly={readOnly}
        {...(register ? register() : EMPTY_OBJECT)}
      />
      <span className={styles['box-container']}>
        <span className={boxClasses}>
          <span className={styles['mark']} />
        </span>
        {/*<span className={styles['box_disabled']}>
          <span className={styles['box_disabled-control']} />        
        </span>
        <span className={styles['box']}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19 5V19H5V5H19ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z"
              fill="#6B7280"
            />
          </svg>
        </span>
        <span className={styles['box_checked']}>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16 0H2C0.89 0 0 0.9 0 2V16C0 17.1 0.89 18 2 18H16C17.11 18 18 17.1 18 16V2C18 0.9 17.11 0 16 0ZM7 14L2 9L3.41 7.59L7 11.17L14.59 3.58L16 5L7 14Z"
              fill="currentColor"
            />
          </svg>
  </span>*/}
      </span>
      <span className={styles['value']}>{inputCaption}</span>
    </label>
  );
}
