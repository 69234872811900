import React, {PropsWithChildren} from 'react';
import clsx from 'clsx';

import Title from '@components/atoms/Title';
import RemixIcon from '@components/atoms/RemixIcon';

import styles from './FormSummary.module.css';
import Button from '@components/atoms/Button';

interface FormSummaryProps {
  className?: string;
  icon?: string;
  caption?: string;
  error?: boolean;
  onEdit?: () => void;
  urlEdit?: string;
}

export default function FormSummary({
  className,
  icon,
  caption,
  error,
  onEdit,
  urlEdit,
  children,
}: PropsWithChildren<FormSummaryProps>) {
  const classes = clsx(
    styles['container'],
    error ? styles['error'] : null,
    className,
  );
  return (
    <section className={classes}>
      <header className={styles['header']}>
        {icon ? <RemixIcon className={styles['icon']} value={icon} color="secondary" size="large" /> : null}
        <Title className={styles['caption']} level={3}>
          {caption}
        </Title>
        {onEdit || urlEdit ? (
          <Button
            className={styles['edit']}
            icon={<RemixIcon value="edit" />}
            caption="Edit"
            url={urlEdit}
            onClick={onEdit}
            layout="auto"
          />
        ) : null}
      </header>
      {children}
      <footer className={styles['footer]']}></footer>
    </section>
  );
}
