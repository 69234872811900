import React, {ReactNode} from 'react';
import clsx from 'clsx';

import {Link} from 'react-router-dom';

import styles from './KeyValue.module.css';
import RemixIcon from '../RemixIcon';

interface KeyValueProps {
  className?: string;
  color?: 'primary' | 'secondary';
  term?: string | ReactNode;
  definition?: string | ReactNode;
  error?: boolean;
  editUrl?: string;
}

export default function KeyValue({className, color, term, definition, error, editUrl}: KeyValueProps) {
  return (
    <>
      <dt className={clsx(styles['term'], error ? styles['error'] : color ? styles['color_' + color] : null, className)}>
        <span>{term}</span>
        {editUrl ? <span><Link className={styles['link']} to={editUrl}><RemixIcon value="edit" /></Link></span> : null}
      </dt>
      <dd className={clsx(styles['definition'], error ? styles['error'] : null, className)}>{definition}</dd>
    </>
  );
}
