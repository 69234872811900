import React from 'react';
import clsx from 'clsx';

import styles from './Spinner.module.css';

interface SpinnerProps {
  className?: string;
  active?: boolean;
  backdrop?: boolean;
}

export default function Spinner({className, active = true, backdrop = true}: SpinnerProps) {
  const stateStyles = {
    [styles['active']]: active,
    [styles['backdrop']]: backdrop,
  };
  return (
    <div className={clsx(styles['container'], stateStyles, className)}>
      <span className={styles['loader']} />
    </div>
  );
}
