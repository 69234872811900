import React from 'react';
import HeaderControl from '@components/organisms/Header';
import {LoginButton} from '@features/Auth';

interface HeaderProps {
  className?: string;
  loggedIn: boolean;
}

export default function Header({className, loggedIn}: HeaderProps) {
  return (
    <HeaderControl className={className} loggedIn={loggedIn} LoginButton={LoginButton} />
  );
};
