import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';

import {LoanQuery, Project} from '@app/types';
import SummaryBlock, {SummaryGroupProps, Value} from '@components/molecules/SummaryBlock';

import styles from './ProjectSummary.module.css';

interface ProjectSummaryProps {
  className?: string;
  project?: Project;
  onUpdateHeight?: (value: number) => void;
  opacity?: number;
}

const COLUMNS = [
  {
    items: [
      {caption: 'Created', fieldName: 'createdOn', format: 'datetime'},
      {caption: 'Location', fieldName: 'siteFullAddress'},
      {caption: 'Status', fieldName: 'status', format: 'text'},
    ]
  },
  {
    items: [
      {caption: 'Development', fieldName: 'proposedDevelopment', format: 'text'},
      {caption: 'Net Loan', fieldName: 'netLoan', format: 'currency'},
      {caption: 'Loan tenure', fieldName: 'loanTenureMonths', format: 'months'},
    ]
  }
] as SummaryGroupProps[];

export default function ProjectSummary({className, project, opacity, onUpdateHeight}: ProjectSummaryProps) {  
  if (!project) return null;  

  const value = {
    createdOn: project.createdOn,
    siteFullAddress: project.loanQuery.siteFullAddress,
    status: project.status,
    proposedDevelopment: project.loanQuery.proposedDevelopment,
    netLoan: project.loanQuery.netLoan,
    loanTenureMonths: project.loanQuery.loanTenureMonths,
  };
  const refContainer = useRef<HTMLDivElement | null>(null);
  const [, setHeight] = useState(0);
  const recalcHeight = useCallback(() => {
    if (!project || !refContainer.current || !onUpdateHeight) return;    
    const result = refContainer.current.offsetHeight;
    setHeight((value) => {
      if (value !== result) onUpdateHeight(result);
      return result;
    });
  }, [setHeight, onUpdateHeight, project, refContainer]);
  //
  useEffect(() => {
    recalcHeight();
  }, [recalcHeight]);
  //
  //
  const stylesControl = useMemo(() => ({
    opacity,
  }), [opacity]);
  if (!project) return null;
  //
  return (
    <div className={clsx(styles['container'], className)} ref={refContainer} style={stylesControl}>
      <SummaryBlock columns={COLUMNS} value={value as Value} />
    </div>
  );
};
