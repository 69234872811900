import {createSlice} from "@reduxjs/toolkit";
import {postGetInTouch as postGetInTouchThunk} from "./thunk";

export interface PostGetInTouchState {
  loading: boolean;
  success: boolean;
  error?: string;
  done: boolean;
};

const initialState = {
  loading: false,
  success: false,
  error: undefined,
  done: false,
} as PostGetInTouchState;

export const postGetInTouch = createSlice({
  name: '@@contacts/post-get-in-touch',
  initialState,
  reducers: {
    reset: (data) => {
      data.loading = false;
      data.success = false;
      data.error = undefined;
      data.done = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postGetInTouchThunk.pending, (state) => {
        state.done = false;
        state.loading = true;
        state.success = false;
      })
      .addCase(postGetInTouchThunk.fulfilled, (state) => {
        state.done = true;
        state.loading = false;
        state.success = true;
        state.error = undefined;
      })
      .addCase(postGetInTouchThunk.rejected, (state, {payload}) => {
        state.done = true;
        state.error = 'Error';
        state.success = false;
        state.loading = false;
      });       
  }
})