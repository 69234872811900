import React, {useCallback, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';

import Button from '@components/atoms/Button';
import Title from '@components/atoms/Title';
import Input from '@components/atoms/Input';
import RemixIcon from '@components/atoms/RemixIcon';

import styles from './Share.module.css';
import CloseButton from '@components/atoms/CloseButton';

interface ShareProps {
  className?: string;
  name: string;
  link?: string;
  open?: boolean;
  onCancel?: () => void;
}

export default function Share({className, name, link, open, onCancel}: ShareProps) {
  const refInput = useRef<HTMLInputElement>(null);
  const [copyIcon, setCopyIcon] = useState('checkbox-blank');
  useEffect(() => {
    if (open) setCopyIcon('checkbox-blank');
  }, [setCopyIcon, open]);
  const handleCopy = useCallback(() => {
    if (refInput.current && navigator.clipboard) {
      (async () => {
        if (refInput.current) {
          await navigator.clipboard.writeText(refInput.current.value);
          setCopyIcon('checkbox');
        }
      })();
    }
  }, [refInput, setCopyIcon]);
  return (
    <div className={clsx(styles['container'], className)}>
      <Title className={styles['title']} level={2}>
        Share
        <CloseButton onClick={onCancel} />
      </Title>
      <Input value={link} readOnly aria-readonly ref={refInput} />
      <div className={styles['actions']}>
        <Button
          className={styles['action']}
          color="primary"
          caption="Copy link"
          layout="auto"
          margin={0}
          onClick={handleCopy}
          icon={<RemixIcon className={styles['action-icon']} value={copyIcon} />}
        />
        <Button
          className={styles['action']}
          icon={<RemixIcon className={styles['action-icon']} value="mail-open" />}          
          caption="Email"
          url={`mailto:?subject=Fundkeet&body=${encodeURIComponent(`Your Fundkeet ${name}: ${link}`)}`}
          margin={0}
          layout="auto"
        />
        {/*<Button className={styles['share']} margin={0} caption="F" layout="auto" />
        <Button className={styles['share']} margin={0} caption="in" layout="auto" />
        <Button className={styles['share']} margin={0} caption="Tw" layout="auto" />*/}
      </div>
    </div>
  );
}
