import {connect} from 'react-redux';

import {actions, selectors, RootState,} from '@app/store/projects';

import ProjectsGroup from '../components/ProjectsGroup';

export default connect(
  (state: RootState) => ({
    loading: selectors.list.isLoading(state),
    items: selectors.list.value(state),
  }),
  {
    onOpen: actions.list.open,
    onClose: actions.list.close,
  }
)(ProjectsGroup);
