import {SigninRequest, SigninResponse, User} from '../../types';
import {createSlice, PayloadAction, Draft, AsyncThunk, ActionReducerMapBuilder} from '@reduxjs/toolkit';

import {
  signin as signinThunk,
  signup as signupThunk,
  forgot as forgotThunk,
} from /*, signup, forgot, reset */ './thunks';

// @todo: extract for common use

export interface InitialState<Value> {
  loading: boolean;
  value?: Value;
  error?: unknown;
}

export type InitialStateSignin = InitialState<User>;
export type InitialStateSignup = InitialState<User>;
export type InitialStateForgot = InitialState<User>;

const initialStateSignin = {loading: false} as InitialState<User>;
const initialStateSignup = {loading: false} as InitialState<User>;
const initialStateForgot = {loading: false} as InitialState<User>;

function createReducers<V>() {
  return {
    reset: (state: Draft<InitialState<V>>) => {
      state.loading = false;
      state.value = undefined;
      state.error = undefined;
    },
  };
}

function createSelectors<Value>() {
  return {
    isLoading: (state: InitialState<Value>) => state.loading,
    value: (state: InitialState<Value>) => state.value,
    error: (state: InitialState<Value>) => state.error,
  };
}

type GetAsyncThunkResponse<A> = A extends AsyncThunk<infer R, any, any> ? R : any;

function createExtraReducers<Thunk extends AsyncThunk<any, any, any>>(thunk: Thunk) {
  type Response = GetAsyncThunkResponse<Thunk>;
  return (builder: ActionReducerMapBuilder<InitialState<Response>>) => {
    builder
      .addCase(thunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(thunk.fulfilled, (state, {payload}) => {
        state.loading = false;
        state.value = payload;
        state.error = undefined;
      })
      .addCase(thunk.rejected, (state, {payload}) => {
        state.loading = false;
        state.value = undefined;
        state.error = payload;
      });
  };
}

export const signin = createSlice({
  name: '@@auth/signin',
  initialState: initialStateSignin,
  reducers: createReducers<User>(),
  extraReducers: createExtraReducers(signinThunk),
});

export const signup = createSlice({
  name: '@@auth/signup',
  initialState: initialStateSignup,
  reducers: createReducers<User>(),
  extraReducers: {},
  //createExtraReducers(signupThunk),
});

export const forgot = createSlice({
  name: '@@auth/forgot',
  initialState: initialStateForgot,
  reducers: createReducers<User>(),
  // extraReducers: createExtraReducers(forgotThunk),
});

export const signinSelectors = createSelectors<User>();
export {signinThunk, signupThunk, forgotThunk};
