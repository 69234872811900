import React, {useCallback, useMemo, useState, FC, PropsWithChildren, ReactNode, Fragment, ChangeEvent, KeyboardEvent, useEffect, ReactElement} from 'react';
import clsx from 'clsx';

import Title from '@components/atoms/Title';
import {Context, ContentTitleProps} from '../ContentTitle';
import HeaderControl from '../Header';
import FooterControl from '../Footer';

import styles from './Layout.module.css';
import Skeleton from '@components/atoms/Skeleton';
import { useLocation, Link } from 'react-router-dom';
import { current } from '@reduxjs/toolkit';
import RemixIcon from '@components/atoms/RemixIcon';
import Spinner from '@components/atoms/Spinner';
import ServerErrors from '../ServerErrors';

interface LayoutProps {
  hasErrors?: boolean;
  errors?: string[];
  onResetErrors?: () => unknown;
  Header: (props: Partial<Parameters<typeof HeaderControl>[0]>) => ReactElement;
  Footer: (props: Partial<Parameters<typeof FooterControl>[0]>) => ReactElement;  
}

export default function Layout({hasErrors, errors, onResetErrors, children, Header, Footer}: PropsWithChildren<LayoutProps>) {
  const location = useLocation();
  useEffect(() => {    
    onResetErrors && onResetErrors();
  }, [location, onResetErrors]);  
  const [titleStack, setTitleStack] = useState([] as {} as ContentTitleProps[]);
  const [contentTitle, setContentTitle] = useState({} as ContentTitleProps);  
  const [editActive, setEditActive] = useState(false);
  const [titleValue, setTitleValue] = useState('');  
  const handleDisableEdit = useCallback(() => {
    setEditActive(false);
  }, [setEditActive]);
  const [editError, setEditError] = useState(false);
  //
  const addTitleLevel = useCallback((value: ContentTitleProps) => {       
    setTitleStack((items) => {
      return [...items, value];
    });    
  }, [setTitleStack]);

  const removeTitleLevel = useCallback(() =>  {  
    setTitleStack((items) => {
      const item = items[items?.length - 1];
      if (item.editable) {
        setEditActive(false);
      }
      return (items || []).slice(0, (items?.length - 1) || 0);
    });
  }, [setTitleStack, setEditActive]);
  //
  const currentTitle = useMemo(() => {
    return titleStack.reduce((result, item) => ({
      ...result,
      ...item,
      breadcrumbs: item.breadcrumb ? [...(result.breadcrumbs || []), item.breadcrumb] : result.breadcrumbs,
    }), {} as ContentTitleProps);
  }, [titleStack]);
  const contextValue = useMemo(() => {
    return {
      get: () => currentTitle,
      //set: setContentTitle,
      //pop: () => {},
      set: addTitleLevel,
      pop: removeTitleLevel,
    };
  }, [currentTitle, addTitleLevel, removeTitleLevel]);
  const lastBreadсrumbIndex = (currentTitle.breadcrumbs?.length || 0) - 1;  
  const handleEnableEdit = useCallback(() => {
    setTitleValue(currentTitle.title);
    setEditActive(true);
  }, [setEditActive, currentTitle]);
  const handleChangeTitle = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTitleValue(e.currentTarget.value);
  }, [setTitleValue]);
  const handleRenameTitle = useCallback(() => {
    if (!titleValue) {
      setEditError(true);
      return;
    }
    setEditError(false);
    if (currentTitle?.onChange) {
      currentTitle.onChange(titleValue);
    }
  }, [currentTitle?.onChange, titleValue, setEditError]);
  const handleTitleKeydown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {    
    if (e.key === 'Enter') {
      handleRenameTitle();
    } else if (e.key === 'Escape') {
      handleDisableEdit();
    }    
  }, [handleRenameTitle, handleDisableEdit]);
  useEffect(() => {
    if (currentTitle.renamed) {
      currentTitle.onChangeReset && currentTitle.onChangeReset();
      handleDisableEdit();
    }
  }, [currentTitle.renamed, currentTitle.onChangeReset, handleDisableEdit]);  
  //
  useEffect(() => {
    if (hasErrors) window.scrollTo({left: 0, top: 0, behavior: 'smooth'});
  }, [hasErrors, errors]);

  useEffect(() => {
    currentTitle?.onRenameChange && currentTitle.onRenameChange(editActive);
  }, [editActive, currentTitle?.onRenameChange]);
  //
  return (
    <>
      <Context.Provider value={contextValue}>        
        <Header className={styles['header']} />
        <main className={styles['content']}>
          <Title className={styles['title']}>{currentTitle.loading ? <Skeleton active /> : <>
            {editActive ? <input className={clsx(styles['title-edit'], editError ? styles['title-edit_error'] : null)} readOnly={currentTitle.renaming} value={titleValue} onChange={handleChangeTitle} autoFocus onKeyDown={handleTitleKeydown} /> : currentTitle.title}
            {currentTitle.editable ? <div className={styles['actions']}>
              {editActive ? <span className={clsx(styles['action'], styles['action_primary'], currentTitle.renaming ? styles['action_loading'] : null)} onClick={handleRenameTitle}>
                <RemixIcon value="check" size="large" />
                {currentTitle.renaming ? <Spinner active backdrop={false} /> : null}
              </span> : null}
              {editActive ? <span className={styles['action']} onClick={handleDisableEdit}>
                <RemixIcon value="close" size="large" />
              </span> : null}
              {!editActive ? <span className={clsx(styles['action'], styles['action_primary'])} onClick={handleEnableEdit}>
                <RemixIcon value="pencil" size="large" />
              </span> : null}
            </div> : null}
          </>}</Title>
          {currentTitle.breadcrumbs ?  <div className={styles['breadcrumbs']}>          
            {currentTitle.loading ? <Skeleton active /> : currentTitle.breadcrumbs.map((item, i) => (
              <Fragment key={i}>
                {i !== lastBreadсrumbIndex && !!item.url ? <Link className={styles['breadcrumb']} to={item.url}>{item.caption}</Link> : <span>{item.caption}</span>}
                <span className={styles['breadcrumb__delimiter']}> / </span>
              </Fragment>
            ))}
          </div> : null}
          {hasErrors && errors ? <ServerErrors errors={errors} /> : null}
          {children}
        </main>
        {currentTitle.error ? currentTitle.error: null}
        <Footer className={styles['footer']} />
      </Context.Provider>
    </>
  );
}
