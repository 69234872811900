import React, {useCallback, useEffect, useMemo, useState, ReactNode, InputHTMLAttributes, FormEventHandler} from 'react';

import {UnitDetailsFormData, UnitType, UnitDetails as UnitDetailsType, SearchFormData} from '@app/types';
import AreaUnits from '@components/atoms/AreaUnits';
import Money from '@components/atoms/Money';
import CommonInput from '@components/molecules/CommonInput';
import {BeforeUpdateHandler, Field, FieldDef, FieldsDefs, useFieldForm} from '@hooks/useForm';

import UnitDetailsRow from './UnitDetailsRow';

import {formatAmount} from '@utils/format';
import styles from './UnitDetails.module.css';

interface UnitDetailsProps<T, N, E> {
  className?: string;
  caption?: string | ReactNode;
  description?: string | ReactNode;
  name?: string;
  field: Field<T | undefined, N, E>
  required?: boolean;
  register?: () => Partial<InputHTMLAttributes<HTMLInputElement>>;
  units?: string;
  value?: UnitDetailsFormData;
  onChange?: (value: UnitDetailsFormData) => void;
  onChangeTotal?: (value?: number) => void;
}

type HiddenState = {
  [type in UnitType]: boolean;
};

type TotalsValues = Omit<UnitDetailsType, 'type'>;
type TotalsKey = keyof TotalsValues;
type Totals = {
  [key in TotalsKey]?: number;
};

type GetNameTypeFromField<T> = T extends Field<any, infer R, any> ? R : never;

const unitDetailsFieldsDefs = {
  Flat: {
    name: 'Flat',
    caption: 'Flats',
    type: 'object',
  },
  Commercial: {
    name: "Commercial",
    caption: "Commercial",
    type: 'object',
  },
  House: {
    name: 'House',
    caption: 'Houses',
    type: 'object',        
  }
} as FieldsDefs<Required<UnitDetailsFormData>, {}>;

function numberIsZero(v?: number): boolean {
  if (typeof v === 'undefined') return false;
  return v === 0;
}

function rowIsZero(v?: UnitDetailsType): boolean {
  if (!v) return true;
  return numberIsZero(v.area) && numberIsZero(v.count) && numberIsZero(v.totalValue) && numberIsZero(v.valuePerMetricEntity) && numberIsZero(v.valuePerUnit);
}

export default function UnitDetails<N, E>({  
  className,  
  field,
  caption,
  description,
  required,
  units,
  value,
  register,
  onChange,
  onChangeTotal,
}: UnitDetailsProps<UnitDetailsFormData, N, E>) {
  //
  const [hidden, setHidden] = useState<HiddenState>({
    Commercial: false,
    Flat: false,
    House: false,
  });
  //
  useEffect(() => {
    const value = field.value;
    if (!value) return;
    setHidden((state) => {
      if (rowIsZero(value.Commercial)) state.Commercial = true;
      if (rowIsZero(value.Flat)) state.Flat = true;
      if (rowIsZero(value.House)) state.House = true;
      return state;
    });    
  }, [setHidden, field]);
  
  //
  const handleHiddenChange = useCallback(
    (type: UnitType) => {
      const newHidden = !hidden[type];
      setHidden({
        ...hidden,
        [type]: newHidden,
      });
      if (newHidden) {
        let newValue: UnitDetailsFormData | undefined = {
          ...(field.value || {}),
          [type]: undefined
        };        
        if (!newValue.Commercial && !newValue.Flat && !newValue.House) newValue = undefined;
        field.setValue(newValue);
      }        
    },
    [hidden, setHidden, field]
  );
  //
  const fieldsDefs = useMemo(() => {
    const keys = Object.keys(unitDetailsFieldsDefs) as UnitType[];
    return keys.reduce((result, key) => {
      result[key] = {
        ...unitDetailsFieldsDefs[key],
        required: !hidden[key],
      } as FieldDef<UnitDetailsFormData, {}>;
      return result;
    }, {} as FieldsDefs<UnitDetailsFormData, {}>);    
  }, [hidden]);    
  //
  const {fields} = useFieldForm({field, fields: fieldsDefs as FieldsDefs<UnitDetailsFormData, E>});
  const totals = useMemo(() => {
    const v = field.value || ({} as UnitDetailsFormData);
    const keys = Object.keys(v) as (keyof UnitDetailsFormData)[];
    const props = ['count', 'area', 'valuePerUnit', 'valuePerMetricEntity', 'totalValue'] as TotalsKey[];
    const counts = {'count': 0, 'area': 0, 'valuePerUnit': 0, 'valuePerMetricEntity': 0, 'totalValue': 0};
    return keys.reduce((result, key) => {
      const row = v[key];
      props.forEach((prop) => {
        const source = result[prop];
        const value = row ? row[prop] : undefined;
        if (value) counts[prop]++;
        if (!!source || !!value) {
          result[prop] = +(source || 0) + +(value || 0);
        }
      });      
      result['valuePerUnit'] = result['totalValue'] && result['count'] ? result['totalValue'] / result['count'] : undefined;
      result['valuePerMetricEntity'] = result['totalValue'] && result['area'] ? result['totalValue'] / result['area'] : undefined;
      return result;
    }, {} as TotalsValues);
  }, [units, field]);  
  //
  useEffect(() => {
    onChangeTotal && onChangeTotal(totals.totalValue);
  }, [totals, onChangeTotal]);
  //
  return (
    <CommonInput error={field.error} className={className} caption={field.caption} description={field.description} required={!!field.required} tooltip={field.hint} delimiter>      
      <table className={styles['table']}>
        <thead>
          <tr>
            <th>Description</th>
            <th className={styles['column']}>Number of units</th>
            <th className={styles['column']}>
              Area (<AreaUnits value={units} style="lowercase" />)
            </th>
            <th className={styles['column']}>Value (£)</th>
            <th className={styles['column']}>
              £ per <AreaUnits value={units} style="lowercase" />
            </th>
            <th className={styles['column']}>£ per unit</th>
          </tr>
        </thead>
        <tbody>
          <UnitDetailsRow
            field={fields?.Flat!}
            unit="Flat"                        
            hidden={hidden['Flat']}
            onHiddenChange={handleHiddenChange}
          />
          <UnitDetailsRow
            field={fields?.House!}
            unit="House"            
            hidden={hidden['House']}
            onHiddenChange={handleHiddenChange}
          />
          <UnitDetailsRow
            field={fields?.Commercial!}
            unit="Commercial"                  
            hidden={hidden['Commercial']}
            onHiddenChange={handleHiddenChange}
          />
          <tr className={styles['spacer']}>
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
          </tr>
        </tbody>
        <tfoot>
          <tr className={styles['total-row']}>
            <td>Total</td>
            <td>
              <Money value={totals.count} integer />
            </td>
            <td>
              {totals.area ? (
                <>
                  <Money value={totals.area} integer /> <AreaUnits value={units} />
                </>
              ) : null}
            </td>
            <td>
              <Money value={totals.totalValue} currency="£" integer />
            </td>
            <td>
              {totals.valuePerMetricEntity ? (
                <>
                  <Money value={totals.valuePerMetricEntity} currency="£" integer /> <AreaUnits value={units} />
                </>
              ) : null}
            </td>
            <td>
              <Money value={totals.valuePerUnit} currency="£" integer />
            </td>
          </tr>
        </tfoot>
      </table>
    </CommonInput>
  );  
}
