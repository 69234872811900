import Api from './api';
import {User, JwtToken} from '../../types';
import jwtDecode from 'jwt-decode';

const LOGIN_URL = `${process.env.MAIN_HOST}/MicrosoftIdentity/Account/SignIn`;

export type OnSignOut = (token: undefined) => void;
export type Query = {
  filter?: Record<any, any>;
  order?: Record<any, any>;
  page?: number;
  perPage?: number;
};

export default class Session {
  onSignOut?: OnSignOut;
  constructor(private readonly api: Api) {
    window.addEventListener('message', (e) => {
      const msg = e.data as {};
      console.log('INBOUND MESSAGE', msg);
    });
  }
  async getSessionExists(): Promise<boolean> {
    if (this.api.onGetAccessToken) {
      const token = await this.api.onGetAccessToken();      
      return !!token;
    }
    return false;
  }
  async getSession(): Promise<User | void> {
    console.log('getting session');   
    if (await this.getSessionExists()) {
      return this.getSessionValue();
    }
    return;
  }
  async getSessionValue(): Promise<User | void> {
    const token = this.api.onGetAccessToken ? await this.api.onGetAccessToken() : null;
    if (token) {
      const result = await this.api.get('/History?Count=1');
      const at = jwtDecode(token) as JwtToken;
      const user = {} as User;
      user.login = (at.emails || [])[0];
      user.name = at.name;
      if (window.top) {
        window.top.postMessage({msg: 'AUTH_SUCCESS', token}, '*');
      }
      return user;
    }
  }
  async signOut(): Promise<void> {
    this.onSignOut && this.onSignOut(undefined);
  }
  async $refresh(): Promise<void> {
    console.log('refreshing token');
    const frame = document.createElement('iframe');    
    frame.addEventListener('load', () => {
      console.log('FRAME LOADED!');
      frame.contentWindow?.postMessage({msg: 'CHECK MESSAGE'}, '*');
    })
    document.body.appendChild(frame);
    frame.setAttribute('src', LOGIN_URL);


  }
}
