import React, {useCallback, useEffect, useRef} from 'react';
import clsx from 'clsx';

import {UnitDetails, UnitType} from '@app/types';
import {useFieldForm, Field, FieldsDefs, BeforeUpdateHandler} from '@hooks/useForm';
import Money from '@components/atoms/Money';

import {InlineInput} from '@components/atoms/InlineInput/InlineInput';

import styles from './UnitDetails.module.css';

interface UnitDetailsRowInputProps<Name> {
  field: Field<UnitDetails | undefined, Name, {}>
  unit: UnitType;
  hidden?: boolean;
}

const PLACEHOLDER = 'Value';

const fieldsDefs = {
  type: {
    name: 'type',
    required: true,    
    placeholder: PLACEHOLDER,
  },
  count: {
    name: 'count',
    type: 'int',
    required: true,
    placeholder: PLACEHOLDER,
  },
  valuePerUnit: {
    name: 'valuePerUnit',
    type: 'float',
    required: true,
    placeholder: PLACEHOLDER,
  },
  area: {
    name: 'area',
    type: 'float',
    required: true,
    placeholder: PLACEHOLDER,
  },
  valuePerMetricEntity: {
    name: 'valuePerMetricEntity',
    type: 'float',
    required: true,
    placeholder: PLACEHOLDER,
  },
  totalValue: {
    name: 'totalValue',
    type: 'float',
    required: true,
    placeholder: PLACEHOLDER,
  },
} as FieldsDefs<UnitDetails>;

export default function UnitDetailsRowInput<Name>({field, unit, hidden}: UnitDetailsRowInputProps<Name>) {  
  const refLastField = useRef<string>();
  const refTotalValueTouched = useRef<boolean>(false);
  const handleFormChange = useCallback(({values, name}) => {
    if (!values) return;
    if (hidden) return;    
    if (name === 'area') {
      if (!refTotalValueTouched.current && values.valuePerMetricEntity !== undefined) {
        const totalValue = values.area ? values.valuePerMetricEntity * values.area : undefined;
        values.totalValue = totalValue;        
      }
      else if (values.totalValue) {
        const valuePerMetricEntity = values.area ? values.totalValue / values.area : undefined;
        if (valuePerMetricEntity !== undefined && !isNaN(valuePerMetricEntity)) values.valuePerMetricEntity = +valuePerMetricEntity.toFixed(2);
        else values.valuePerMetricEntity = undefined;
      }      
    }
    if (name === 'totalValue') {
      refTotalValueTouched.current = true;
      if (values.area) {
        const valuePerMetricEntity = values.area && values.totalValue ? values.totalValue / values.area : undefined;
        if (valuePerMetricEntity !== undefined && !isNaN(valuePerMetricEntity)) values.valuePerMetricEntity = +valuePerMetricEntity.toFixed(2);
        else values.valuePerMetricEntity = undefined;
      }
    }
    if (name === 'valuePerMetricEntity') {
      if (values.area) {
        const totalValue = values.area && values.valuePerMetricEntity ? values.area * values.valuePerMetricEntity : undefined;
        if (totalValue !== undefined && !isNaN(totalValue)) values.totalValue = +totalValue.toFixed(2);
        else values.totalValue = undefined;
      }
    }
    const perUnit = values.count && values.totalValue ? values.totalValue / values.count : undefined;    
    if (!!perUnit && !isNaN(perUnit)) values.valuePerUnit = +perUnit.toFixed(2);    
    else values.valuePerUnit = undefined;
  }, [hidden, refTotalValueTouched]) as BeforeUpdateHandler<UnitDetails>;
  //
  const {fields} = useFieldForm({field, fields: fieldsDefs, onBeforeUpdate: handleFormChange});  
  const {type, count, valuePerUnit, area, valuePerMetricEntity, totalValue} = fields;
  useEffect(() => {
    if (!hidden && type.value !== unit) {
      type.setValue(unit);      
    }
    if (hidden) {
      if (type.getTextValue() !== '') type.setTextValue('');
      if (area.getTextValue() !== '') area.setTextValue('');
      if (count.getTextValue() !== '') count.setTextValue('');
      if (valuePerUnit.getTextValue() !== '') valuePerUnit.setTextValue('');
      if (valuePerMetricEntity.getTextValue() !== '') valuePerMetricEntity.setTextValue('');
      if (totalValue.getTextValue() !== '') totalValue.setTextValue('');
    }
  }, [type, unit, hidden, count, area]);  
  //
  const classesInput = clsx(styles['row-input'], {[styles['hidden']]: hidden});
  const classesPerUnit = clsx(styles['per-unit'], {[styles['hidden']]: hidden});
  return (
    <>
      <td>
        <InlineInput anchor="numberOfUnits" className={classesInput} field={count} integer />
      </td>
      <td>
        <InlineInput anchor="nia" className={classesInput} field={area} />
      </td>
      <td>
        <InlineInput anchor="gdv" className={classesInput} field={totalValue} />
      </td>
      <td>
        <InlineInput className={classesInput} field={valuePerMetricEntity} />
      </td>
      <td className={classesPerUnit}>
        {<Money value={valuePerUnit.value} integer /> || valuePerUnit.placeholder}
      </td>
    </>
  );
};
