import React, {useMemo} from 'react';
import clsx from 'clsx';

import styles from './ProjectTile.module.css';
import Skeleton from '@components/atoms/Skeleton';
import Money from '@components/atoms/Money';
import Button from '@components/atoms/Button';
import { dateTimeToString } from '@utils/format';

interface ProjectTileProps {  
  className?: string;  
  loading?: boolean;
  id?: string;
  name?: string;
  createdOn?: string;
  type?: string;
  amount?: number;
  status?: string;
  url?: string;
  baseUrl?: string;
}

function abbrToText(v?: string) {  
  if (!v) return '';
  if (v === 'DD') return 'Due-diligence';
  if (v === 'GroundUp') return 'Ground-up';
  return v;
}

export default function ProjectTile({className, loading, id, name, createdOn, type, amount, status, url, baseUrl}: ProjectTileProps) {
  const targetUrl = useMemo(() => {
    return baseUrl ? baseUrl + id + '/' : url;
  }, [url, baseUrl, id]);
  return (
    <li className={clsx(styles['container'], loading ? styles['loading'] : null, className)}>
      <div className={styles['caption']}>{loading ? <Skeleton active /> : name}</div>
      <div className={styles['type']}>{loading ? <Skeleton active /> : <><span className={styles['key']}>Created:</span><span className={styles['value']}>{dateTimeToString(createdOn)}</span></>}</div>      
      <div className={styles['type']}>{loading ? <Skeleton active /> : <><span className={styles['key']}>Development:</span> <span className={styles['value']}>{abbrToText(type)}</span></> }</div>      
      <div className={styles['amount']}>{loading ? <Skeleton active /> : <><span className={styles['key']}>Net loan:</span> <span className={styles['value']}><Money value={amount} currency="£" integer /></span></>}</div>
      <div className={styles['status']}>{loading ? <Skeleton active /> : <><span className={styles['key']}>Status:</span> <span className={styles['value']}>{abbrToText(status)}</span></>}</div>      
      <Button url={targetUrl} caption="Open" color="primary" margin={0} skeleton={loading}/>
    </li>
  )
}