import React from 'react';
import clsx from 'clsx';

import Logo from '@components/atoms/Logo';
import Rights from '@components/atoms/Rights';
import SocialIcon from '@components/atoms/SocialIcon';
import NavMenu from '@components/molecules/NavMenu';

import styles from './Footer.module.css';

interface FooterProps {
  className?: string;
}

const menuItems = [
  {caption: 'About Us', url: `${process.env.BLOG}/about-us/`},
  {caption: 'Contact Us', url: `/contact-us/`},
  {caption: 'FAQs', url: `${process.env.BLOG}/faqs/`},
  {caption: 'Privacy Policy', url: `${process.env.BLOG}/privacy-policy/`},
  {caption: 'Terms & Conditions', url: `${process.env.BLOG}/terms-and-conditions/`},
];

export default function Footer({className}: FooterProps) {
  return (
    <footer className={clsx(styles['container'], className)}>
      <NavMenu className={styles['menu']} items={menuItems} />
      <Logo className={styles['logo']} />
      <div className={styles['social-icons']}>
        <SocialIcon url="https://facebook.com/" />
        <SocialIcon url="https://twitter.com/" />
      </div>
      <Rights />
    </footer>
  );
};
