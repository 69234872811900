import {connect} from 'react-redux';

import {actions, selectors, RootState} from '@app/store/tasks';

import TasksGroup from '../components/TasksGroup';

export default connect(
  (state: RootState) => ({
    loading: selectors.list.loading(state),
    items: selectors.list.value(state),    
    totalCount: selectors.list.totalCount(state),
  }),
  {
    onOpen: actions.list.get,
    onClose: actions.list.reset,
  },
)(TasksGroup);
