import {connect} from "react-redux";

import {actions, selectors, RootState} from '@app/store/projects';

import Dashboard from "../components/Dashboard";
import Feed from "./Feed";
import ProjectsGroup from "./ProjectsGroup";
import Tasks from './TasksGroup';
import SearchesGroup from "./SearchesGroup";

export default connect(
  (state: RootState) => ({
    projects: {
      loading: selectors.list.isLoading(state),
      open: selectors.list.isOpen(state),
      value: selectors.list.value(state),
      error: selectors.list.error(state),
    },
    ProjectsGroup,
    Feed,    
    Tasks, 
    SearchesGroup,
  }),
  {
    onOpenProjects: actions.list.open,
    onCloseProjects: actions.list.close,
  }
)(Dashboard);
