import {AnyAction, AsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;

interface ActionError {
  message: string;
}

type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']> & {error: ActionError};
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

interface ApiRequest {
  state: 'pending' | 'error' | 'retry';
  action: PendingAction;
  error?: string;
}

export type InitialState = Record<string, ApiRequest>;

function getTaskId(action: AnyAction): string {
  const type = action.type as string;
  const parts = type.split('/');
  return parts.slice(0, parts.length - 1).join('/');
}

const initialState = {} as InitialState;

function isPendingAction(action: AnyAction): action is PendingAction {
  return action.type.endsWith('/pending');
}

function isRejectedAction(action: AnyAction): action is RejectedAction {
  return action.type.endsWith('/rejected');
}

function isFulfilledAction(action: AnyAction): action is FulfilledAction {
  return action.type.endsWith('/fulfilled');
}

export const slice = createSlice({
  name: '@@api',
  initialState,
  reducers: {
    reset: (state) => {
      console.log('do reset api');
      const keys = Object.keys(state).filter(key => state[key].state !== 'pending');
      keys.forEach(key => {
        delete state[key];
      });      
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isPendingAction, (state, action) => {
        const id = getTaskId(action);
        console.log('API pending', action, id);
        state[id] = {
          state: 'pending',
          action,
        } as ApiRequest;        
      })
      .addMatcher(isRejectedAction, (state, action) => {
        const id = getTaskId(action);        
        console.log('API rejected', action, id);
        const request = state[id];
        if (request) {
          request.state = 'error';
          request.error = (action as RejectedAction).error.message;
        } else {
          console.warn('rejected action was not found');
        }
      })
      .addMatcher(isFulfilledAction, (state, action) => {
        const id = getTaskId(action);        
        console.log('API fulfilled', action, id)
        // action.
        delete state[id];
      });
  }
});
