import React, {Dispatch, FormEvent, SetStateAction, useCallback, useEffect, useMemo, useRef, useState} from "react";
import DashboardBlock from "@components/organisms/DashboardBlock";
import FormAction from "../FormAction/FormAction";
import {BorrowerDetails as BorrowerDetailsType, BorrowerDetailsForm, Spv, Shareholder, MainShareholder, OptionalShareholder} from "@app/types";
import BorrowingSpv from "../BorrowingSpv";
import BorrowerShareholder from "../BorrowerShareholder";
import BorrowerDirector from "../BorrowerDirector";
import equal from "deep-equal";

import {FormErrors} from "../../hooks/validate";
import Errors from "@features/Search/components/Errors";

interface BorrowerDetailsProps {
  active: boolean;
  error?: FormErrors<BorrowerDetailsType>;
  value?: BorrowerDetailsType;
  submitting?: boolean;
  saving?: boolean;
  onChange: (value: BorrowerDetailsType) => unknown;  
  onSubmit: (value: BorrowerDetailsType) => unknown;
  onSave: () => unknown;
}

const useAddItem = function <T>(setter: Dispatch<SetStateAction<T[]>>, setterVersion?: Dispatch<SetStateAction<number>>) {
  return useCallback(() => {
    setter(value => [...value, {type: "Individual"} as unknown as T]);
  }, [setter]);
};

const useRemoveItem = function <T>(setter: Dispatch<SetStateAction<T[]>>, setterVersion: Dispatch<SetStateAction<number>>) {
  return useCallback(
    (i: number) => {
      setterVersion(v => v + 1);
      setter(value => {
        const result = [...value];
        result.splice(i, 1);
        return result;
      });
    },
    [setter]
  );
};

function isSame<T extends object>(a?: T, b?: T) {
  return equal(a, b);
}

function getFirstObjectError(v: Record<string, string>) {
  if (!v) return undefined;
  const keys = Object.keys(v);
  return v[keys[0]];
}

const FIELDS = {
  companyName: "Company name",
  address: "Address",
  countryOfIncorporation: "Country of incorporation",
  registrationNumber: "Registration number",
  typeOfCompany: "Type of company",
  proposedDirectors: "Directors",
  shareholders: "Shareholders",
} as Record<keyof BorrowerDetailsType, string>;

export default function BorrowerDetails({active, submitting, saving, error, value, onChange, onSubmit, onSave}: BorrowerDetailsProps) {
  const [versionShareholders, setVersionShareholders] = useState(0);
  const [versionDirectors, setVersionDirectors] = useState(0);
  const refValue = useRef<BorrowerDetailsType | undefined>(value);
  const refShareholders = useRef<BorrowerDetailsType | undefined>(value);
  //
  const [borrowingSpv, setBorrowingSpv] = useState({} as BorrowerDetailsForm);
  const [shareholders, setShareholders] = useState<Shareholder[]>([{type: "Individual"} as Shareholder]);
  const [directors, setDirectors] = useState<Spv[]>([{type: "Individual"} as Spv]);
  //
  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      refValue.current && onSubmit(refValue.current);
    },
    [onSubmit, refValue]
  );    
  //
  useEffect(() => {
    if (!value) return;
    if (refValue.current === value) return;
    const eq = JSON.stringify(refValue.current) === JSON.stringify(value);
    refValue.current = value;
    if (eq) return;
    setBorrowingSpv(current => {
      if (
        current.address !== value.address ||
        current.companyName !== value.companyName ||
        current.countryOfIncorporation !== value.countryOfIncorporation ||
        current.registrationNumber !== value.registrationNumber ||
        current.typeOfCompany !== value.typeOfCompany
      ) {
        return value;
      }
      return current;
    });
    setShareholders(current => {
      console.log("shareholders", current, value.shareholders);
      const eq = JSON.stringify(current) === JSON.stringify(value.shareholders);
      if (!eq) return value.shareholders;
      return current;
    });
    setDirectors(current => {
      console.log("directors", current, value.proposedDirectors);
      const eq = JSON.stringify(current) === JSON.stringify(value.proposedDirectors);
      if (!eq) return value.proposedDirectors;
      return current;
    });
  }, [value, setBorrowingSpv, setShareholders, setDirectors, refValue]);
  //
  const handleAddShareholder = useAddItem(setShareholders);
  const handleRemoveShareholder = useRemoveItem(setShareholders, setVersionShareholders);
  const handleAddDirector = useAddItem(setDirectors);
  const handleRemoveDirector = useRemoveItem(setDirectors, setVersionDirectors);
  //
  useEffect(() => {
    const result = {
      ...borrowingSpv,
      shareholders,
      proposedDirectors: directors,
    } as BorrowerDetailsType;
    onChange(result);
  }, [borrowingSpv, shareholders, directors, onChange]);
  //
  const handleChangeOptionalShareholder = useCallback(
    (value: Shareholder, index: number) => {
      setShareholders((state: Shareholder[]) => {
        const result = [...state];
        result[index] = value;
        return result;
      });
    },
    [setShareholders]
  );
  const handleChangeOptionalDirector = useCallback(
    (value: Spv, index: number) => {
      setDirectors((state: Spv[]) => {
        const result = [...state];
        result[index] = value;
        return result;
      });
    },
    [setDirectors]
  );

  const flatErrors = useMemo(() => {
    return error
      ? Object.keys(error).reduce((result, k) => {
          const key = k as keyof typeof error;
          const value = error[key];
          const isArray = Array.isArray(value);
          if (isArray) {            
            result[key] = getFirstObjectError(value[0] as Record<string, string>);
          }
          else result[key] = typeof error[key] === "string" ? error[key] as string : `Fill in the ${FIELDS[key]}`;
          return result;
        }, {} as Record<string, string | undefined>)
      : undefined;
  }, [error]);
  //  
  useEffect(() => {
    if (flatErrors || error) window.scrollTo({left: 0, top: 0, behavior: 'smooth'});
  }, [error, flatErrors]);

  //
  return (
    <form onSubmit={handleSubmit}>
      {flatErrors ? <Errors fields={Object.keys(flatErrors) as (keyof BorrowerDetailsForm)[]} items={flatErrors} /> : null}
      <DashboardBlock size="big" caption="Borrowing SPV">
        <BorrowingSpv error={error} value={borrowingSpv} onChange={setBorrowingSpv} active={active} />
      </DashboardBlock>
      {shareholders.map((item, i) => (
        <DashboardBlock
          size="big"
          caption="Shareholders (more than 25%)"
          key={versionShareholders.toString() + "-" + i.toString()}
          action={{caption: "Remove", disabled: shareholders.length < 2, onClick: () => handleRemoveShareholder(i)}}
        >
          <BorrowerShareholder
            error={error?.shareholders ? (error.shareholders[i] as FormErrors<Shareholder>) : undefined}
            value={item}
            onChange={(value: Shareholder) => handleChangeOptionalShareholder(value, i)}
            active={active}
          />
        </DashboardBlock>
      ))}
      {active ? <FormAction caption="Do you want to add another shareholder?" action="Add" onAction={handleAddShareholder} /> : null}
      {directors.map((item, i) => (
        <DashboardBlock
          size="big"
          caption={`Director ${i + 1}`}
          key={versionDirectors.toString() + "-" + i.toString()}
          action={{caption: "Remove", disabled: directors.length < 2, onClick: () => handleRemoveDirector(i)}}
        >
          <BorrowerDirector
            error={error?.proposedDirectors ? (error.proposedDirectors[i] as FormErrors<Spv>) : undefined}
            value={item}
            onChange={(value: Spv) => handleChangeOptionalDirector(value, i)}
            active={active}
          />
        </DashboardBlock>
      ))}
      {active ? <FormAction caption="Do you want to add another director?" action="Add" onAction={handleAddDirector} /> : null}      
      {active ? <FormAction action="Complete" type="submit" loading={submitting} prevAction="Save" onPrevAction={onSave} prevLoading={saving} /> : null}
    </form>
  );
}
