import {createSlice} from "@reduxjs/toolkit";
import {CustomerLoanAppraisal} from "@app/types";

import {getItem, submit, reject, setName} from './thunks';

interface InitialState {
  loading: boolean;
  value?: CustomerLoanAppraisal;
  error?: unknown;
  rejecting: boolean;
  rejected: boolean;
  renaming: boolean;
  renamed: boolean;
  submitting: boolean;
  submitted: boolean;
}

const initialState = {
  loading: false,
  value: undefined,
  error: undefined,
  rejecting: false,
  rejected: false, 
  renaming: false,
  renamed: false,
  submitting: false,
  submitted: false,
} as InitialState;

export const item = createSlice({
  name: '@@offers/item',
  initialState,
  reducers: {
    resetReject: (state) => {
      state.rejecting = false;
      state.rejected = false;
    },
    resetRename: (state) => {
      state.renaming = false;
      state.renamed = false;
    },
    resetSubmit: (state) => {
      state.submitting = false;
      state.submitted = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(getItem.fulfilled, (state, {payload}) => {
        state.loading = false;
        state.value = payload;
        state.error = undefined;
      })
      .addCase(getItem.rejected, (state, {payload}) => {
        state.loading = false;
        state.value = undefined;
        state.error = payload;
      })
      .addCase(reject.pending, (state) => {
        state.rejecting = true;
      })
      .addCase(reject.fulfilled, (state) => {
        state.rejecting = false;
        state.rejected = true;
      })
      .addCase(reject.rejected, (state) => {
        state.rejecting = false;
        state.rejected = false;
      })
      .addCase(setName.pending, (state) => {
        state.renaming = true;        
      })
      .addCase(setName.fulfilled, (state) => {
        state.renaming = false;
        state.renamed = true;
      })
      .addCase(setName.rejected, (state) => {
        state.renaming = false;
        state.renamed = false;
      })
      .addCase(submit.pending, (state) => {
        state.submitting = true;
        state.submitted = false;
      })
      .addCase(submit.fulfilled, (state) => {
        state.submitting = false;
        state.submitted = true;
      })
      .addCase(submit.rejected, (state) => {
        state.submitted = false;
        state.submitting = false;
      });
  },
});
