import React from 'react';
import clsx from 'clsx';

import Paper from '@components/atoms/Paper';
import RemixIcon from '@components/atoms/RemixIcon';
import Title from '@components/atoms/Title';

import {FormError, FormErrors} from '@hooks/useForm';

import styles from './Errors.module.css';

interface ErrorsProps<FormData> {
  className?: string;
  items: FormErrors<FormData>;
  fields: (keyof FormData)[];
}

export default function Errors<FormData = {}>({className, items, fields}: ErrorsProps<FormData>) {
  return (
    <Paper className={clsx(styles['container'], className)} size="large">
      <Title level={3}>Error's list</Title>
      <ul className={styles['list']}>
        {fields
          ? fields.map((field, i) => {
              const key = field as keyof typeof items;
              const item = items[key] as FormError;
              if (!item) return null;
              return (
                <li className={styles['item']} key={i}>
                  <RemixIcon value="alert" color="error" size="small" />
                  <span className={styles['error']}>{item}</span>
                </li>
              );
            })
          : null}
      </ul>
    </Paper>
  );
}
