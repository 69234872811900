import React, {useCallback} from 'react';
import useForm, {FieldsDefs} from '@hooks/useForm';
import {DeveloperProjectExperience} from '@app/types';
import {InlineInput} from '@components/atoms/InlineInput/InlineInput';

import styles from './ProjectRow.module.css';
import RemixIcon from '@components/atoms/RemixIcon';

interface ProjectRowProps {
  index: number;
  error?: boolean;
  value?: DeveloperProjectExperience;
  active?: boolean;
  canRemove?: boolean;
  onChange: (index: number, value?: DeveloperProjectExperience) => unknown;
  onRemove?: (index: number) => unknown;
}

const fieldsDefs = {
  location: {
    name: 'location',
    caption: 'Location',
    placeholder: 'Value',
  },  
  type: {
    name: 'type',
    caption: 'Type',
    placeholder: 'Value',
  },
  numberOfUnits: {
    name: 'numberOfUnits',
    caption: 'No. of units',
    type: 'int',
    placeholder: 'Value',
  },
  gdv: {
    name: 'gdv',
    caption: 'GDV',
    type: 'int',
    placeholder: 'Value',
  },  
  developmentCost: {
    name: 'developmentCost',
    caption: 'Development cost',
    placeholder: 'Value',
  },  
  deliveredOnYear: {
    name: 'deliveredOnYear',
    caption: 'Delivered on',
    placeholder: 'Value',
    type: 'int',
  },
} as FieldsDefs<DeveloperProjectExperience>;

export default function ProjectRow({error, index, value, canRemove, active, onChange, onRemove}: ProjectRowProps) {
  const handleChange = useCallback((v?: DeveloperProjectExperience) => {
    if (!v) return;    
    onChange(index, v);    
  }, [onChange, index]);
  const handleRemove = useCallback(() => {
    onRemove && onRemove(index);
  }, [onRemove, index]);
  const {fields} = useForm({fields: fieldsDefs, values: value, trackValues: true, onChange: handleChange})
  const {location, type, numberOfUnits, gdv, developmentCost, deliveredOnYear} = fields;
  return (
    <tr>
      <td>
        <InlineInput field={location} text errorIfEmpty={error} readOnly={!active} />
      </td>
      <td>
        <InlineInput field={type} text errorIfEmpty={error} readOnly={!active} />
      </td>
      <td>
        <InlineInput field={numberOfUnits} integer errorIfEmpty={error} readOnly={!active} />
      </td>
      <td>
        <InlineInput field={gdv} integer prefix="£" errorIfEmpty={error} readOnly={!active} />
      </td>
      <td>
        <InlineInput field={developmentCost} integer prefix="£" errorIfEmpty={error} readOnly={!active} />
      </td>
      <td className={styles['project-row-actions']}>
        <InlineInput field={deliveredOnYear} year errorIfEmpty={error} readOnly={!active} />
        {canRemove && active ? <span className={styles['project-row-actions__action']} onClick={handleRemove}>
          <RemixIcon value="close" />
        </span> : null}
      </td>
    </tr>
  )
}