import {actions as auth} from './auth';
import {actions as contacts} from './contacts';
import {actions as history} from './history';
import {actions as session} from './session';
import {actions as search} from './search';
import {actions as tasks} from './tasks';
import {actions as offers} from './offers';

export default {
  auth,
  contacts,
  history,
  offers,
  search,
  session,
  tasks,
};
