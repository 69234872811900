import React from 'react';
import clsx from 'clsx';

import Button from '@components/atoms/Button';
import Title from '@components/atoms/Title';
import RemixIcon from '@components/atoms/RemixIcon';

import styles from './Question.module.css';

interface QuestionProps {
  className?: string;
  icon?: string;
  title?: string;
  text?: string;
  action: string;
  loading?: boolean;
  onAction?: () => void;
  onCancel?: () => void;
}

export default function Question({className, icon, title, text, loading, action, onAction, onCancel}: QuestionProps) {
  return (
    <div className={clsx(styles['container'], className)}>
      {icon ? <RemixIcon className={styles['icon']} value={icon} size="large" color="secondary" /> : null}
      <Title className={styles['title']} level={2}>
        {title}
      </Title>
      <div className={styles['text']}>{text}</div>
      <div className={styles['actions']}>
        <Button type="button" className={styles['action']} color="primary" caption="Cancel" margin={0} onClick={onCancel} />
        <Button type="button" className={styles['action']} caption={action} margin={0} onClick={onAction} loading={loading} />
      </div>
    </div>
  );
}
