import {connect} from 'react-redux';

import {actions, selectors, RootState} from '@app/store/search';

import SearchesGroup from '../components/SearchesGroup';

export default connect(
  (state: RootState) => ({
    loading: selectors.list.loading(state),
    items: selectors.list.value(state),
  }),
  {
    onOpen: actions.list.get,
    // onClose: actions.list.cl
  }
)(SearchesGroup);
