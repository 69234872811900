import React, {ReactNode} from 'react';
import clsx from 'clsx';

import styles from './QuickTip.module.css';

interface QuickTipProps {
  className?: string;
  caption?: string | ReactNode;
  text?: string | ReactNode;
  visible?: boolean;
  position?: {
    right: string;
    top: string;
  };
}

export default function QuickTip({className, caption, text, visible = false, position}: QuickTipProps) {
  const classes = clsx(styles['container'], visible ? styles['visible'] : null, className);
  return (
    <div className={classes} role="tooltip" style={position}>
      {caption ? <h4 className={styles['caption']}>{caption}</h4> : null}
      <div className={styles['content']}>{text}</div>
    </div>
  );
}
