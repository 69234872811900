import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";

import {DeveloperCv as DeveloperCvType, DeveloperCvForm, DeveloperDetails, ProjectTask, DeveloperProjectExperience} from "@app/types";
import DetailsForm from "../DeveloperDetailsForm";
import FormAction from "../FormAction/FormAction";
import DashboardBlock from "@components/organisms/DashboardBlock";
import PreviousProjects from "../PreviousProjects";
import DownloadFile from "../DownloadFile";
import TextInput from "@components/molecules/TextInput";
import {useDeveloperForm} from "@features/Tasks/hooks/useDeveloperCvForm";
import {FormErrors} from "@features/Tasks/hooks/validate";
import Errors from "@features/Search/components/Errors";

interface DeveloperCvProps {
  active: boolean;
  task: ProjectTask;
  value?: DeveloperCvType;
  error?: FormErrors<DeveloperCvType>;
  submitting?: boolean;
  saving?: boolean;
  onChange: (value: DeveloperCvType) => unknown;
  onSubmit: (value: DeveloperCvType) => unknown;
  onSave: () => unknown;
}

const FIELDS = {
  companyName: "Company name",
  website: "Website",
  developerDetails: "Developers",
  previousProjects: "Previous projects",
} as Record<keyof DeveloperCvType, string>;

export default function DeveloperCv({active, error, submitting, saving, task, value, onChange, onSubmit, onSave}: DeveloperCvProps) {  
  const [version, setVersion] = useState(0);
  const refValue = useRef(value);
  const [localValue, setLocalValue] = useState<DeveloperCvType | undefined>(value || {developerDetails: [{} as DeveloperDetails]});
  const [header, setHeader] = useState<DeveloperCvForm>({});
  const [developers, setDevelopers] = useState<DeveloperDetails[]>([{} as DeveloperDetails]);
  const [previousProjects, setPreviousProjects] = useState<DeveloperProjectExperience[]>([{} as DeveloperProjectExperience]);

  // VALUE => local
  useEffect(() => {
    if (refValue.current !== value) {
      refValue.current = value;
      setLocalValue(value);
    }
  }, [refValue, value, setLocalValue]);

  // local => VALUE
  useEffect(() => {
    if (refValue.current !== localValue) {
      refValue.current = localValue;
      console.log('local => value', localValue);
      if (localValue && onChange) onChange(localValue);
    }
  }, [localValue, onChange, refValue])

  /*
  //
  // VALUE => local
  //
  useEffect(() => {
    console.log("DEV VALUE CHANGED", value);
    setDevelopers(current => {
      if (value?.developerDetails && value.developerDetails !== current) return value.developerDetails;
      return current;
    });
    // setDevelopers(value?.developerDetails || [{} as DeveloperDetails]);
  }, [value, setDevelopers]);
  useEffect(() => {
    console.log("PRJ VALUE CHANGED", value);
    setPreviousProjects(current => {
      if (value?.previousProjects && value.previousProjects !== current) return value.previousProjects;
      else return current;
    });
    // setPreviousProjects(value?.previousProjects || [{} as DeveloperProjectExperience]);
  }, [value, setPreviousProjects]);
  useEffect(() => {
    console.log("HEADER VALUE, CHANGED", value);
    setHeader(current => {
      if (current.companyName !== value?.companyName || current.website !== value?.website) return value as DeveloperCvForm;
      else return current;
    });
  }, [value, setHeader]);
  const refValueForHeader = useRef<typeof value>();
  const refValueForDevelopers = useRef<typeof value>();
  const refValueForProjects = useRef<typeof value>();
  //
  // local => VALUE
  //
  useEffect(() => {
    if (value !== refValueForHeader.current) {
      refValueForHeader.current = value;
    } else {
      onChange({...value, ...header});
    }
  }, [value, header, refValueForHeader, onChange]);
  useEffect(() => {
    if (value !== refValueForDevelopers.current) {
      refValueForDevelopers.current = value;
    } else {
      console.log('updating developers!!!');
      onChange({...value, developerDetails: developers});
    }
   
  }, [value, developers, refValueForDevelopers, onChange]);

  useEffect(() => {
    if (value !== refValueForProjects.current) {
      refValueForProjects.current = value;
    } else {
      console.log("projects user changed", value?.previousProjects, previousProjects);
      onChange({...value, previousProjects});
    }
  }, [value, previousProjects, refValueForProjects, onChange]);
  //
  const handleChangeDetails = useCallback(
    (index, value) => {
      setDevelopers(rows => {
        const result = [...rows];
        result[index] = value;        
        return result;
      });
    },
    [setDevelopers]
  );
  const handleAddDeveloper = useCallback(() => {
    setDevelopers(rows => [...rows, {} as DeveloperDetails]);
  }, [setDevelopers]);
  const handleRemoveDeveloper = useCallback(
    index => {
      setDevelopers(rows => {
        const result = [...rows];
        result.splice(index, 1);
        return result;
      });
      setVersion(i => i++);
    },
    [setDevelopers, setVersion]
  );*/

  const handleChangeHeader = useCallback(
    (v?: DeveloperCvForm) => {
      setLocalValue(current => ({
        ...(current || {}),
        companyName: v?.companyName || undefined,
        website: v?.website || undefined,
      }));      
    },
    [setLocalValue]
  );

  const handleAddDeveloper = useCallback(() => {
    setLocalValue(current => ({
      ...(current || {}),
      developerDetails: [
        ...(current?.developerDetails || []),
        {} as DeveloperDetails,
      ]
    }));    
  }, [setLocalValue]);

  const handleRemoveDeveloper = useCallback(index => {
    setLocalValue(current => {
      const result = {...current || {}};
      const developers = [...(result.developerDetails || [])];
      developers.splice(index, 1);
      result.developerDetails = developers;
      return result;
    });      
    setVersion(i => i++);
  },[setLocalValue, setVersion]);

  const handleChangeDetails = useCallback(
    (index, value) => {    
      setLocalValue(current => {
        const result = {...(current || {})};
        const developers = [...(result.developerDetails || [])];
        developers[index] = value;
        result.developerDetails = developers;
        return result;
      })      
    },
    [setLocalValue]
  );

  const handleChangePreviousProjects = useCallback((previousProjects: DeveloperProjectExperience[]) => {
    setLocalValue(current => ({
      ...current,
      previousProjects,
    }));
  }, [setLocalValue]);

  const {fields, handleSubmit, errors} = useDeveloperForm({error, values: localValue, onChange: handleChangeHeader});
  const {companyName, website} = fields;

  const flatErrors = useMemo(() => {
    let result = error
      ? Object.keys(error).reduce((result, k) => {
          const key = k as keyof typeof error;
          result[key] = typeof error[key] === "string" ? (error[key] as string) : `Fill in the ${FIELDS[key]}`;
          return result;
        }, {} as Record<string, string | undefined>)
      : undefined;        
    // @ts-ignore
    if (errors) Object.keys(errors).forEach(key => {
      // @ts-ignore
      if (errors[key]) {
        if (!result) result = {};
        // @ts-ignore
        result[key] = typeof errors[key] === 'string' ? errors[key] : `Fill in the ${FIELDS[key]}`;
      }      
    });
    return result;
  }, [error, errors]);

  useEffect(() => {
    if (flatErrors || error) window.scrollTo({left: 0, top: 0, behavior: 'smooth'});
  }, [flatErrors, error]);

  const canRemoveDeveloper = localValue && localValue.developerDetails && localValue.developerDetails?.length > 1;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {flatErrors ? <Errors fields={Object.keys(flatErrors) as (keyof DeveloperCvType)[]} items={flatErrors} /> : null}
      <TextInput field={companyName} margin={0} smallError readOnly={!active} />
      <TextInput field={website} smallError readOnly={!active} />
      {localValue && localValue.developerDetails ? localValue.developerDetails.map((row, i) => (
        <DetailsForm
          error={error?.developerDetails ? (error.developerDetails[i] as FormErrors<DeveloperDetails>) : undefined}
          active={active}
          index={i}
          value={row}
          canRemove={canRemoveDeveloper}
          onChange={handleChangeDetails}
          onRemove={handleRemoveDeveloper}
          key={i.toString() + "-" + version.toString()}
        />
      )) : null}
      {active ? <FormAction caption="Would you like to add another developer?" action="Add" actionSize="medium" onAction={handleAddDeveloper} /> : null}
      <DashboardBlock size="big" caption="Previous projects" required description="Fill in the data" hint="Provide as much details as possible on the previous completed schemes.">
        <PreviousProjects error={error?.previousProjects as FormErrors<DeveloperProjectExperience>[]} value={localValue?.previousProjects} onChange={handleChangePreviousProjects} active={active} />
      </DashboardBlock>
      <DownloadFile sampleFile={task?.sampleFile} />
      {active ? <FormAction action="Complete" type="submit" actionSize="medium" loading={submitting} prevAction="Save" onPrevAction={onSave} prevLoading={saving} /> : null}
    </form>
  );
}
