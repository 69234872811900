import {configureStore} from '@reduxjs/toolkit';
// import {connect as _connect, MapStateToProps, MapDispatchToProps} from 'react-redux';

import actions from './actions';
import selectors from './selectors';
import rootReducer from './reducer';

export const createStore = () =>
  configureStore({
    reducer: rootReducer,
  });

const store = createStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
export {store, actions, selectors};
export const dispatch = store.dispatch as AppDispatch;
//export function connect<
//  S extends MapStateToProps<{}, {}, RootState>,
//  D extends MapDispatchToProps<{}, {}>
//>(mapStateToProps: S, mapDispatchToProps: D) {
  /*return _connect(mapStateToProps, (_, ownProps) => {
    if (mapDi)
    mapDispatchToProps(dispatch, ownProps)
  });*/
//};