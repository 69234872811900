import React, { MouseEventHandler, PropsWithChildren } from 'react';
import clsx from 'clsx';

import Skeleton from '../Skeleton';

import styles from './SummaryValue.module.css';

interface SummaryValueProps {
  className?: string;
  color?: 'primary';
  loading?: boolean;
  clickable?: boolean;
  onClick?: MouseEventHandler;
}

export default function SummaryValue({className, loading, color, clickable, onClick, children}: PropsWithChildren<SummaryValueProps>) {
  const classes = clsx(
    styles['value'],
    color ? styles['color_' + color] : null,
    clickable ? styles['clickable'] : null,
    className,
  );
  return (
    <dd className={classes} onClick={onClick}>{loading ? <Skeleton active /> : children}</dd>
  );
};
