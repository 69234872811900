import {combineReducers, Selector} from '@reduxjs/toolkit';
import {scopeSelector} from '../../lib/thunks';
import {
  signin,
  signup,
  forgot,
  signinThunk,
  signupThunk,
  forgotThunk,
  signinSelectors,
  InitialState,
  InitialStateSignin,
  InitialStateSignup,
  InitialStateForgot,
} from './slice';

interface AuthState {
  signin: InitialStateSignin;
  signup: InitialStateSignup;
  forgot: InitialStateForgot;
}

export interface RootState {
  auth: AuthState;
}

export const reducer = combineReducers({
  signin: signin.reducer,
  signup: signup.reducer,
  forgot: forgot.reducer,
  // reset: reset.reducer,
});

export const actions = {
  signin: {
    ...signin.actions,
    submit: signinThunk,
  },
  signup: {
    ...signup.actions,
    submit: signupThunk,
  },
  forgot: {
    ...forgot.actions,
    submit: forgotThunk,
  },
};

// @todo: extract for common use

type LocalSelector<LocalState> = Selector<RootState, LocalState, any>;

function createSelectors<LocalState extends InitialState<any>>(selector: LocalSelector<LocalState>) {
  return {
    isLoading: scopeSelector((state: {loading: any}) => state.loading, selector),
    value: scopeSelector((state) => state.value, selector),
    error: scopeSelector((state) => state.error, selector),
  };
}

export const selectors = {
  signin: createSelectors((state: RootState) => state.auth.signin),
  signup: createSelectors((state: RootState) => state.auth.signup),
  forgot: createSelectors((state: RootState) => state.auth.forgot),
};
