import React, {PropsWithChildren, ReactNode} from 'react';
import clsx from 'clsx';

import Paper from '@components/atoms/Paper';
import RemixIcon from '@components/atoms/RemixIcon';
import Tooltip from '../Tooltip';

import styles from './CommonInput.module.css';

interface CommonInputProps {
  className?: string;
  caption?: ReactNode;
  description?: ReactNode;
  tooltipCaption?: string;
  tooltip?: string | ReactNode;
  required?: boolean;
  disabled?: boolean;
  error?: ReactNode;
  ownError?: ReactNode;
  flex?: boolean;
  layout?: 'row';
  delimiter?: boolean;
  margin?: number;  
}

export default function CommonInput({
  className,
  caption,
  required,
  description,
  tooltipCaption,
  tooltip,
  disabled,
  flex,
  error,
  ownError,
  layout = 'row',
  delimiter,
  margin = 1,  
  children,
}: PropsWithChildren<CommonInputProps>) {
  const classes = clsx(
    styles['container'],
    layout === 'row' ? styles['row'] : null,
    margin ? styles['margin_' + margin] : null,
    error ? [styles['has_error']] : null,    
    className
  );  
  return (
    <Paper className={classes} size="large" compact={margin === 0}>
      <span className={styles['caption']}>
        {caption}
        {required ? <span className={styles['required-mark']}>*</span> : null}
        <span className={styles['horz-spacer']} />
        {!!tooltip || !!tooltipCaption ? (
          <Tooltip caption={tooltipCaption} text={tooltip}>
            <RemixIcon className={styles['tooltip']} value="error-warning" />
          </Tooltip>
        ) : null}
      </span>
      {description ? <span className={styles['description']}>{description}</span> : null}
      {delimiter ? <hr className={styles.delimiter} /> : null}
      <div className={clsx(styles['control'], {[styles['flex']]: flex})}>{children}</div>
      {ownError ? <span className={styles['error']}>{ownError}</span> : null}
    </Paper>
  );
}
