import React, {useCallback, useMemo, useState} from 'react';

import {ProjectTask, TasksRequest} from "@app/types";

import {TagLine, TagState} from '@components/organisms/DashboardTagLines';

import {dateToString} from "@utils/format";

interface UseTagsLinesProps {
  projectId?: string;
  items?: ProjectTask[];
  groupBy?: "lender";
  show?: "ongoing" | "complete";
  showBage?: boolean;
  showStatus?: boolean;
  countActive?: boolean;
  expandMode?: "expand" | "redirect";
  totalCount?: number;
}

const SHOW_MORE = {
  caption: "Show more",
  url: "/tasks/",
};

interface TaskGroup {
  title: string;
  items: ProjectTask[];
}

const SHOW_LINES = 4;

function selectState(item: ProjectTask): TagState | undefined {
  return item.status.toLowerCase() as TagState;  
}

function selectDue(task: ProjectTask): string {  
  if (task.deadline) return 'due ' + dateToString(task.deadline);
  else return '';  
}

function selectProjectUrl(item: ProjectTask): string {
  return `/projects/${item.projectId}/tasks/${item.id}/`;
}

function selectItem(item: ProjectTask): TagLine {
  const result = {} as TagLine;
  result.url = selectProjectUrl(item);
  const state = selectState(item);
  result.tags = [
    {state, node: <>{item.status}</>},
    {state, node: <>{selectDue(item)}</>},
    {state: undefined, node: <>{item.name}</>}
  ];
  return result;
}

function selectGroups(items?: ProjectTask[], groupBy?: "lender") {
  if (!groupBy) return undefined;
  if (!items) return undefined;
  return items.reduce((result, item) => {
    const key = groupBy === 'lender' ? item.projectId : undefined;
    if (key) {
      if (!result[key]) result[key] = {
        title: item.projectName || '<Unnamed project>',
        items: [],
      };
      result[key].items.push(item);
    }
    return result;
  }, {} as Record<string, TaskGroup>);
}

export default function useTagsLines({projectId, items, groupBy, show, countActive, expandMode, totalCount}: UseTagsLinesProps) {
  const [expanded, setExpanded] = useState(false);
  const caption = show === "ongoing" ? "Ongoing tasks" : show === "complete" ? "Completed tasks" : "To do List";
  const toggleExpand = useCallback(() => {
    setExpanded(value => !value);
  }, [setExpanded]);
  const sourceItems = useMemo(() => {
    let result = items;
    if (!result) return result;
    if (projectId) result = result.filter(item => item.projectId === projectId);
    if (items && show === "ongoing") result = result.filter(item => item.status === "Active");
    if (items && show === "complete") result = result.filter(item => item.status === "Completed");
    return result;
  }, [projectId, items, show]);
  const groupItems = useMemo(() => {
    const groups = selectGroups(sourceItems, groupBy);
    if (!groups) return undefined;
    const keys = Object.keys(groups);
    return keys.map((key, i) => ({
      title: groups[key].title,
      items: groups[key].items.slice(0, SHOW_LINES).map(selectItem),
      count: groups[key].items.length,
    }));
}, [sourceItems, groupBy]);
  const plainItems = useMemo(() => {
    if (groupBy) return undefined;
    return sourceItems?.map(selectItem);
  }, [sourceItems, groupBy]);
  const activeItems = useMemo(() => {
    if (!sourceItems) return [];
    if (!countActive) return sourceItems;
    return sourceItems.filter(item => item.status === 'Active');
  }, [sourceItems, countActive]);  
  const bage = typeof totalCount !== 'undefined' ? totalCount : activeItems?.length || 0;
  const showAction = sourceItems && sourceItems.length > SHOW_LINES;
  const empty = sourceItems?.length === 0;
  const action = showAction ? {
    caption: expanded ? "Show less" : "Show more",
    url: expandMode === "redirect" ? (projectId ? `/projects/${projectId}/tasks/` : "/tasks/") : undefined,
    onClick: expandMode === "expand" ? toggleExpand : undefined,
  } : undefined;
  return {
    caption,
    groupItems, 
    plainItems, 
    sourceItems,
    bage,
    action,
    empty,
  };
}