import React, {FormEvent, ReactNode, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import equal from 'fast-deep-equal';

export type Text = string | ReactNode;
export type GetTextFunc<FormData> = (value: Partial<FormData>) => Text;
export type TextOrFunc<FormData> = Text | GetTextFunc<FormData>;
export type GetStringFunc<FormData> = (value: FormData) => string | undefined;
export type StringOrFunc<FormData> = string | GetStringFunc<FormData> | undefined;

export type FieldType = 'int' | 'float' | 'boolean' | 'string' | 'date' | 'object';

export interface OptionObject<T> {
  caption: Text;
  value: T;
}
export type Option<T> = T | OptionObject<T>;
export type GetOptionsFunc<T> = () => Option<T>[];
export type OptionsOrFunc<T> = (Option<T>[] | GetOptionsFunc<T>)

export interface FieldDefStd<FormData> {
  name: keyof FormData;
  caption?: TextOrFunc<FormData>;
  description?: TextOrFunc<FormData>;
  hint?: TextOrFunc<FormData>;
  required?: boolean | TextOrFunc<FormData>;
  placeholder?: StringOrFunc<FormData>;
  type?: FieldType;
  min?: number;
  max?: number;
  inputFilter?: string;
  options?: OptionsOrFunc<FormData[keyof FormData]>;  
  getOptions?: (data: FormData) => FieldDefStd<FormData>;
  validate?: (data: FormData) => FormError;
}

export type FieldDef<FormData, ExtraProps = {}> = FieldDefStd<FormData> & ExtraProps;

export type FieldsDefs<FormData, ExtraProps = {}> = {
  [key in keyof FormData]: FieldDef<FormData, ExtraProps>;
};

export type FormError = string | undefined;
export type FormErrors<FormData> = {
  [key in keyof FormData]: FormError | FormErrors<FormData[keyof FormData]>;
};

export interface FieldStd<T, Name = string> {
  name: Name;
  caption?: Text;
  description?: Text;
  hint?: Text;
  required?: boolean | Text;
  placeholder?: string;
  error?: Text;
  options?: Option<T>[];
  value: T;
  textValue: string;
  getValue: () => T | undefined;
  setValue: (value?: T) => void;
  getTextValue: () => string | undefined;
  setTextValue: (value?: string) => void;
}

export type Field<T, Name, ExtraProps> = FieldStd<T, Name> & ExtraProps;

export type Fields<FormData, ExtraProps> = Required<{
  [key in keyof FormData]: Field<FormData[key], key, ExtraProps>;
}>;

export interface BeforeUpdateHandlerProps<T, V=T[keyof T]> {  
  values?: T,
  name: keyof T;
  value?: V;
}
export type BeforeUpdateHandler<T> = (props: BeforeUpdateHandlerProps<T>) => T | void;

export interface UseChildFormProps<FormData, ExtraProps, Name> {
  title?: Text;
  name: Name;
  fields: FieldsDefs<FormData, ExtraProps>;
  trackValues?: boolean;
  values?: Partial<FormData>;
  errors?: FormErrors<FormData>;
  skipValidation?: boolean;
  onChange?: (values?: FormData) => void;
  onBeforeUpdate?: BeforeUpdateHandler<FormData>;
}

export type UseFormProps<FormData, ExtraProps> = Omit<UseChildFormProps<FormData, ExtraProps, never>, 'name'>;

export type SubmitHandler<FormData> = (value: FormData) => void;
export type SubmitEvent<FormData> = (handler: SubmitHandler<FormData>) => (e: FormEvent<HTMLFormElement>) => void;
export type SetValueProc<FormData> = (name: keyof FormData, value: FormData[typeof name]) => void;

export interface UseFormResult<FormData, ExtraProps> {
  title?: string;
  name: string;
  handleSubmit: SubmitEvent<FormData>;
  fields: Fields<FormData, ExtraProps>;
  values?: FormData;
  setValue: SetValueProc<FormData>;
  errors: FormErrors<FormData>;
  reset: () => unknown;
}

export function getFromGetter<FormData, T>(getter: T, value: Partial<FormData>): T {
  if (typeof getter === 'function') return getter(value);
  return getter;  
}

interface Stringable {
  toString: () => string;
}

export function isStringable(value: Stringable | any): value is Stringable {
  return typeof value !== 'undefined' && typeof (value as Stringable).toString === 'function';
}

export function encodeValue<T>(value: T, type?: FieldType): string {  
  if (typeof value === 'undefined') return '';  
  if (typeof value === 'string') {
    if (type === 'date') {
      if (!value) return '';
      const parts = value.split('.');
      console.log(parts);
      return [...parts.reverse()].join('-');
    } else {
      return value;  
    }
  }
  if (isStringable(value)) {    
    const v = (value as Stringable).toString();    
    if (v === '[object Object]') return JSON.stringify(value);
    else return v;
  }
  return '';
}

function getDataType(value: any): FieldType | undefined {
  if (typeof value === 'undefined') return undefined;
  if (typeof value === 'string') return 'string';
  if (typeof value === 'boolean') return 'boolean';
  if (typeof value === 'number') return 'float';
  if (value instanceof Date) return 'date';
  if (typeof value === 'object') return 'object';
  return undefined;
}

function getFieldType(name: string, type?: FieldType, options?: Option<any>[]): FieldType | undefined {
  if (type) return type;
  if (options && options.length) {
    const option = options[0];
    if (typeof option === 'object' && typeof option.caption !== 'undefined') return getDataType(option.value);
    else return getDataType(option);
  }
  return 'string';
}

function stringToDate(value?: string) {
  if (!value) return undefined
  const parts = value.split('-');
  return [...parts.reverse()].join('.');
}

export function decodeValue(value: string, type: FieldType | undefined) {
  if (typeof type === 'undefined') return undefined;
  if (value === '') return undefined;
  if (type === 'boolean') return (value === 'true');
  if (type === 'int') return value === '0' ? 0 : (parseInt(value) || undefined);
  if (type === 'float') return value === '0' ? 0 : (parseFloat(value) || undefined);
  if (type === 'date') return stringToDate(value);
  if (type === 'object') return JSON.parse(value);
  if (type === 'string') return value;
  throw new TypeError('invalid type ' + type);
}

export function useChildForm<FormData, ExtraProps = {}, Name = string>({name, title, fields: fieldsDefs, values, errors: externalErrors, trackValues = false, skipValidation, onBeforeUpdate, onChange}: UseChildFormProps<FormData, ExtraProps, Name>): UseFormResult<FormData, ExtraProps> {
  const result = {
    title,
  } as UseFormResult<FormData, ExtraProps>;
  const refValue = useRef<Partial<FormData> | undefined>();  
  const [value, setValue] = useState<Partial<FormData> | undefined>(values);
  const [validationErrors, setValidationErrors] = useState<FormErrors<FormData>>({} as FormErrors<FormData>);
  useEffect(() => {
    if (trackValues && refValue.current !== values && !equal(refValue.current, values)) {
      refValue.current = values;    
      setValue(values);
    }
  }, [values, setValue, refValue, trackValues]);
  useEffect(() => {
    if (refValue.current === values) return;      
    onChange && console.log('populating to parent', value);
    if (value !== values && onChange) onChange(value as FormData);
  }, [value, onChange, refValue]);
  const errors = useMemo(() => {
    const result = {
      ...(validationErrors || {}),
      ...(externalErrors || {}),
    } as FormErrors<FormData>;
    const keys = Object.keys(result) as (keyof FormErrors<FormData>)[] ;
    for (let key of keys) {
      if (typeof result[key] === 'undefined') delete result[key];
    }
    return result;
  }, [validationErrors, externalErrors])  
  refValue.current = value;
  const processFormChange = useCallback(({values, name, value}) => {
    const newValues = {...values, [name]: value};
    if (onBeforeUpdate) {
      onBeforeUpdate({values: newValues, name, value});
    }
    setValue(newValues);
  }, [setValue, onBeforeUpdate]);
  const handleSubmitEvent = useCallback((e: Event) => {
    e.preventDefault();
  }, [result]);
  result.handleSubmit = useCallback((handler) => {      
    return (e: FormEvent<HTMLFormElement>) => {
      //
      e.preventDefault();
      console.log('skipValidation', skipValidation);
      // validation
      if (!skipValidation) {
        const errors = validate(fieldsDefs, refValue.current);
        console.log('errors', errors, fieldsDefs, refValue.current);
        if (errors) {
          setValidationErrors(errors);
          return;
        } else {
          setValidationErrors({} as FormErrors<FormData>);
        }
      }
      // submitting
      handler(refValue.current as FormData);
      //
    }    
  }, [refValue, setValidationErrors]) as SubmitEvent<FormData>;

  const handleGetTextValue = useCallback((name: keyof FormData, type?: FieldType) => {
    return encodeValue(refValue.current ? refValue.current[name] : undefined, type);
  }, [refValue]);

  const handleResetForm = useCallback(() => {
    setValue({} as FormData)
  }, [setValue]);

  const handleSetTextValue = useCallback((name: keyof FormData, value: string, type?: FieldType) => {
 //    console.log('change text value', name, value, type);
    const result = decodeValue(value, type);
    const newValue = {...(refValue.current || {}), [name]: result };
    // console.log('newValue', newValue);
    if (validationErrors && validationErrors[name]) {
      const newValidationErrors = {...validationErrors};
      delete newValidationErrors[name];
      console.log('useForm.handleSetTextValue.setValidationErrors');
      setValidationErrors(newValidationErrors);
    }
    processFormChange({values: newValue, name, value: result});
  }, [refValue, value, setValue, processFormChange, validationErrors, setValidationErrors]);

  const fields = useMemo(() => {
    const keys = Object.keys(fieldsDefs) as (keyof typeof fieldsDefs)[];
    return keys.reduce((result, key) => {
      const source = fieldsDefs[key];
      const {name, caption, description, hint, required, placeholder, options, type, ...restProps} = source;
      const finalOptions = getFromGetter(options, refValue.current || {}) as Option<any>[];
      const finalType = getFieldType(name as string, type, finalOptions);
      const currentValue = value ? value[source.name] : undefined;
      const resultField = {
        name,
        caption: getFromGetter(caption, refValue.current || {}),
        description: getFromGetter(description, refValue.current || {}),
        placeholder: getFromGetter(placeholder, refValue.current || {}),
        hint: getFromGetter(hint, refValue.current || {}),
        required: getFromGetter(required, refValue.current || {}),
        options: finalOptions,
        ...restProps,
        value: currentValue,
        textValue: handleGetTextValue(source.name, finalType),
        getValue: () => refValue.current ? refValue.current[source.name as keyof FormData] : undefined,
        setValue: (value: FormData[typeof key] | undefined) => (value !== currentValue) && processFormChange({values: refValue.current || {}, name: source.name, value}),
        getTextValue: () => handleGetTextValue(key),
        setTextValue: (value: string) => handleSetTextValue(name, value, finalType),
        error: (errors || {})[key],
      } as Field<FormData[keyof FormData], typeof key, ExtraProps>;      
      result[key] = resultField as Required<Field<FormData[keyof FormData], typeof key, ExtraProps>>;
      //@ts-ignore
      if (typeof resultField.orPlaceholder === 'function') {
        //@ts-ignore
        resultField.orPlaceholder = getFromGetter(resultField.orPlaceholder, refValue.current || {});
      }
      return result;
    }, {} as Required<Fields<FormData, ExtraProps>>);
  }, [fieldsDefs, refValue, errors, value]);
  result.fields = fields;
  result.errors = errors;
  result.reset = handleResetForm;
  return result;
};

export interface UseFieldFormProps<Name, FieldType, ExtraProps = {}> {
  field: Field<FieldType, Name, ExtraProps>;
  fields: FieldsDefs<FieldType, {}>;
  trackValues?: boolean;
  onBeforeUpdate?: BeforeUpdateHandler<FieldType>;
};

export function useFieldForm<Name, FieldType, ExtraProps = {}>({field, fields, trackValues, onBeforeUpdate}: UseFieldFormProps<Name, FieldType, ExtraProps>) {    
  const handleChange = useCallback((v?: FieldType) => {    
    field.setValue(v);
  }, [field]);
  const result = useChildForm<FieldType, {}, Name>({name: field.name, title: field.caption, fields, values: field.value, trackValues, onChange: handleChange, onBeforeUpdate});  
  return result;
};

export default function useForm<FormData, ExtraProps = {}>(props: UseFormProps<FormData, ExtraProps>): UseFormResult<FormData, ExtraProps> {
  return useChildForm({name: 'root', ...props});
};


export function validate<FormData>(defs: FieldsDefs<FormData, {}>, data?: Partial<FormData>): FormErrors<FormData> | undefined {
  if (!data) return undefined;
  const fields = Object.keys(defs) as (keyof FormData)[];  
  let success = true;  
  const result = fields.reduce((result, field) => {
    result[field] = validateField(data[field], defs[field], data);
    if (result[field]) success = false;
    return result;
  }, {} as FormErrors<FormData>);
  return success ? undefined : result;
}

function validateField<Def, T, FormData>(value: T, def: FieldDef<Def>, data: FormData): FormError {
  const opts = def.getOptions ? ({
    ...def,
    ...def.getOptions(data as unknown as Def),    
  }) : def;  
  if (typeof def.validate === 'function') {
    return def.validate(data as unknown as Def);
  }
  const required = typeof opts.required === 'function' ? opts.required(data) : opts.required;  
  if (required && typeof value === 'undefined') return `Fill in the "${def.caption}" field`;
  if (typeof opts.min !== 'undefined') {
    if (typeof value === 'undefined') return;
    if (typeof value !== 'number') return `"${def.caption}" has invalid value`;
    if (value < opts.min) return `"${def.caption}" should be at least ${opts.min}`;
  }  
  if (typeof opts.max !== 'undefined') {
    if (typeof value === 'undefined') return;
    if (typeof value !== 'number') return `"${def.caption}" has invalid value`;
    if (value > opts.max) return `"${def.caption}" should be at most ${opts.max}`;
  }
  /*if (typeof def.validate === 'function') {
    return def.validate(data as unknown as Def);
  }*/
  return undefined;
}