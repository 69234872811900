import {scopeSelector} from '@app/lib/thunks';
import {combineReducers} from '@reduxjs/toolkit';
import {list, item, archive, setName} from './slice';
import {archive as archiveThunk, setName as setNameThunk} from './thunks';

export const actions = {
  list: list.actions,
  item: item.actions,
  operations: {  
    archive: {
      set: archiveThunk,
      reset: archive.actions.reset,
    },
    setName: {
      set: setNameThunk,
      reset: setName.actions.reset,
    },
  },
};

export const reducer = combineReducers({
  list: list.reducer,
  item: item.reducer,
  operations: combineReducers({
    archive: archive.reducer,
    setName: setName.reducer,
  })
});

export interface RootState {
  projects: ReturnType<typeof reducer>;
}

export const selectors = {
  list: {
    isLoading: (state: RootState) => list.selectors.isLoading(state.projects.list),
    isOpen: (state: RootState) => list.selectors.isOpen(state.projects.list),
    value: (state: RootState) => list.selectors.value(state.projects.list),
    error: (state: RootState) => list.selectors.error(state.projects.list),    
  },
  item: {
    isLoading: (state: RootState) => item.selectors.isLoading(state.projects.item),
    isOpen: (state: RootState) => item.selectors.isOpen(state.projects.item),
    value: (state: RootState) => item.selectors.value(state.projects.item),
    error: (state: RootState) => item.selectors.error(state.projects.item),  
  },
  operations: {
    archive: {
      loading: (state: RootState) => state.projects.operations.archive.loading,
      success: (state: RootState) => state.projects.operations.archive.success,
      error: (state: RootState) => state.projects.operations.archive.error,      
    },
    setName: {
      loading: (state: RootState) => state.projects.operations.setName.loading,
      success: (state: RootState) => state.projects.operations.setName.success,
      error: (state: RootState) => state.projects.operations.setName.error,      
    }
  }
};
