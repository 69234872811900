import React, { PropsWithChildren } from 'react';

import Skeleton from '@components/atoms/Skeleton';

interface LoadingProps {
  loading?: boolean;
}

export default function Loading({loading, children}: PropsWithChildren<LoadingProps>) {
  if (loading) return (
    <Skeleton active />
  );
  return (
    <>
      {children}
    </>
  );
};
