import { SetProjectNameRequest } from '@app/types';
import {createSlice, AsyncThunk, Draft} from '@reduxjs/toolkit';
import {list, item, archive as archiveThunk, setName as setNameThunk} from './thunks';

export {
  list, 
  item,
}

export interface InitialStateOperation<T> {
  loading: boolean;
  success: boolean;
  value?: T;
  error?: string;  
}

const initialState = {
  loading: false,
  success: false,
  value: undefined,
  error: undefined,  
} as InitialStateOperation<unknown>;

function createOperation<T, P, ActionCreator extends AsyncThunk<T, P, any>>(name: string, thunk: ActionCreator) {
  return createSlice({
    name,
    initialState: initialState as InitialStateOperation<T>,
    reducers: {
      reset: (state) => {
        state.loading = false;
        state.success = false;
        state.value = undefined;
        state.error = undefined;
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(thunk.pending, (state) => {
          state.loading = true;
          state.success = false;
          state.error = undefined;
        })
        .addCase(thunk.fulfilled, (state, {payload}) => {
          state.loading = false;
          state.success = true;
          state.error = undefined;
          state.value = payload as Draft<T>;
        })
        .addCase(thunk.rejected, (state) => {
          state.loading = false;
          state.success = false;
          state.error = 'Error';
        });
    }
  });
}

export const archive = createOperation<any, string, typeof archiveThunk>('@@projects/archive', archiveThunk);
export const setName = createOperation<any, SetProjectNameRequest, typeof setNameThunk>('@@projects/setName',  setNameThunk);
