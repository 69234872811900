import React from 'react';
import {SearchStep2Data} from '@app/types';
import {FormStepProps} from '../Form';

import TextInput from '@components/molecules/TextInput';
import RadioInput from '@components/molecules/RadioInput';

export default function Step2({fields}: FormStepProps<SearchStep2Data>) {
  const {
    experienceLevel,
    completedSimilarSchemes,
    canProvidePersonalGuarantee,
    adverseCreditHistory,
    countryOfResidence,
    borrowingSPVDomicile,
  } = fields;  
  return (
    <>
      <RadioInput anchor="experienceLevel" field={experienceLevel} />
      <TextInput anchor="completedSimilarSchemes" field={completedSimilarSchemes} inputPattern="\d" />
      <RadioInput anchor="canProvidePersonalGuarantee" field={canProvidePersonalGuarantee} />            
      <RadioInput anchor="adverseCreditHistory" field={adverseCreditHistory} />        
      <RadioInput anchor="countryOfResidence" field={countryOfResidence} />        
      <RadioInput anchor="borrowingSPVDomicile" field={borrowingSPVDomicile} />
    </>        
  );  
}