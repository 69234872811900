import React from 'react';
import {Step6} from '../components/StepForms';
import Form from './Form';
import {fieldsStep6} from '../hooks/useNewForm';
import { SearchFormData, SearchStep1Data } from '@app/types';
import { FormStep } from '../components/Form/Form';
import { FieldsDefs } from '@hooks/useForm';

export default function StepForm() {
  return (    
    <Form index={6} fields={fieldsStep6} Step={Step6 as FormStep<any>} />
  );
};
