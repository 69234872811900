import {createSelector} from '@reduxjs/toolkit';
import {slice, InitialState} from './slice';

export interface RootState {
  api: InitialState;
};

export const actions = slice.actions;
export const reducer = slice.reducer;

const selectState = (state: RootState) => state.api;
const selectErrors = createSelector(selectState, state => {
  const keys = Object.keys(state);
  return keys.filter(key => state[key].state !== 'pending').map(key => state[key].error!);
});
const selectHasErrors = createSelector(selectErrors, errors => errors.length > 0);

export const selectors = {
  hasErrors: selectHasErrors,
  errors: selectErrors,
};
