import React, {useCallback} from 'react';
import {DeveloperCvForm, DeveloperDetails} from '@app/types';

import {useDeveloperDetails} from '../../hooks/useDeveloperCvForm';
import TextInput from '@components/molecules/TextInput';
import DashboardBlock from '@components/organisms/DashboardBlock';
import { FormErrors } from '@features/Tasks/hooks/validate';

interface DeveloperDetailsProps {
  error?: FormErrors<DeveloperDetails>;
  index: number;
  value?: DeveloperDetails;
  active?: boolean;
  canRemove?: boolean;
  onChange: (index: number, value?: DeveloperDetails) => unknown;
  onRemove: (index: number) => unknown;
}

export default function DeveloperDetailsForm({error, index, active, canRemove, value, onChange, onRemove}: DeveloperDetailsProps) {  
  const handleChange = useCallback((value) => {
    onChange(index, value);
  }, [onChange, index]);
  const handleRemove = useCallback(() => {
    onRemove(index);
  }, [onRemove, index]);
  //
  const {fields} = useDeveloperDetails({error, values: value, onChange: handleChange});
  const {name, yearsOfExperience, bio} = fields;  
  return (
    <>      
      <DashboardBlock caption={`Developer ${index + 1}`} size="big" action={{caption: 'Remove', disabled: !canRemove || !active, onClick: handleRemove}}>
        <TextInput field={name} margin={0} smallError readOnly={!active} />
        <TextInput field={yearsOfExperience} margin={0} smallError readOnly={!active} />
        <TextInput field={bio} multiline smallError readOnly={!active} />
      </DashboardBlock>       
    </>
  )
}