import React, {useMemo, forwardRef, InputHTMLAttributes, Ref, ReactNode, TextareaHTMLAttributes, ChangeEvent, useCallback} from "react";
import clsx from "clsx";

import CommonInput from "../CommonInput";
import Input from "../../atoms/Input";
import TextArea from "../../atoms/TextArea";
import {Field} from "@hooks/useForm";
import {formatAmount} from "@utils/format";

import styles from './TextInput.module.css';

interface TextInputProps<T, N> extends InputHTMLAttributes<HTMLInputElement> {
  caption?: ReactNode;
  anchor?: string;
  field?: Field<T, N, {}>;
  description?: ReactNode;
  error?: ReactNode;
  layout?: "row";
  tooltip?: string | ReactNode;
  tooltipCaption?: string;
  margin?: number;
  multiline?: boolean;
  label?: boolean;
  format?: boolean;
  postfix?: string | ReactNode;
  inputPattern?: string;
  inputType?: 'integer' | 'float';
  prefix?: string;
  suffix?: string;
  valueColor?: 'primary';
  smallError?: boolean;
}

export function TextInput(
  {className, anchor, margin, caption, disabled, error, required, description, tooltip, tooltipCaption, multiline, layout = "row", inputPattern, label, format, field, postfix, valueColor, smallError, ...props}: TextInputProps<any, any>,
  ref: Ref<HTMLInputElement | HTMLTextAreaElement>
) {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {            
      field && field.setTextValue(e.currentTarget.value);
    },
    [field]
  );
  const changeProps = useMemo(
    () =>
      field
        ? {
            value: field.textValue,
            onChange: handleChange,
          }
        : {},
    [field, handleChange]
  );
  const textValue = props.value || field?.textValue;
  const value = format ? formatAmount(+(textValue || 0)) : textValue;  
  const controlError = smallError && (error || field?.error || null);
  return (
    <CommonInput
      className={className}
      caption={caption || field?.caption || ""}
      required={required || !!field?.required || false}
      // @todo: disabled
      disabled={disabled}
      description={description || field?.description || ""}
      error={smallError ? null : (error || field?.error || null)}
      layout={layout}
      tooltip={tooltip || field?.hint}
      margin={margin}
    >
      {label ? <div className={clsx(styles['label'], valueColor ? styles['color_' + valueColor] : undefined)}>
        {props.children ? props.children : (<>{value} {postfix}</>)}
      </div> : multiline ? (
        <TextArea anchor={anchor} error={!!controlError} smallError={smallError} ref={ref as Ref<HTMLTextAreaElement>} {...(props as TextareaHTMLAttributes<HTMLTextAreaElement>)} placeholder={typeof field?.placeholder === 'string' ? field.placeholder : undefined} {...changeProps} />
      ) : (
        <Input anchor={anchor} disabled={disabled} error={!!controlError} inputPattern={inputPattern} placeholder={typeof field?.placeholder === 'string' ? field.placeholder : undefined} {...props} {...changeProps} />
      )}
    </CommonInput>
  );
}

export default forwardRef(TextInput);
