import React, {useCallback, useMemo, useState} from "react";
import {SearchStep4Data} from "@app/types";
import {FormStepProps} from "../Form";

import TextInput from "@components/molecules/TextInput";
import RadioInput from "@components/molecules/RadioInput";
import BuildCosts from "@components/organisms/BuildCosts";
import UnitDetails from "../UnitDetails";
import PercentageText from "@components/molecules/PercentageText";
import PercentageInput from "@components/molecules/PercentageInput";
import Money from "@components/atoms/Money";
import Total from "@components/molecules/Total";
import AreaUnits from "@components/atoms/AreaUnits";

export default function Step4({fields, value}: FormStepProps<SearchStep4Data>) {
  const {procurementRoute, gia, nia, buildCosts, contingency, professionalCosts, statutoryCosts, otherCosts} = fields;
  const [bcWithCont, setBcWithCont] = useState(0);  
  const tbc = useMemo(() => {
    return bcWithCont + (professionalCosts.value || 0) + (statutoryCosts.value || 0) + (otherCosts.value || 0);
  }, [bcWithCont, professionalCosts, statutoryCosts, otherCosts]);
  const tbcPerUnit = useMemo(() => {
    const gv = gia.value || 0;
    if (!!gv)  return tbc/gv;
    return 0;
  }, [tbc, gia]);    
  const handleBcChange = useCallback((value: number) => {
    setBcWithCont(value);
  }, [setBcWithCont]);
  return (
    <>
      <RadioInput anchor="procurementRoute" field={procurementRoute} />
      <BuildCosts gia={gia} nia={nia.value || 0} value={buildCosts} contigency={contingency} onTotalChange={handleBcChange} metric={value?.areaMetric} />
      <PercentageInput anchor="professionalCosts" field={professionalCosts} base={bcWithCont} />
      <TextInput anchor="statutoryCosts" field={statutoryCosts} inputType="float" prefix="£" />
      <TextInput anchor="otherCosts" field={otherCosts} inputType="float" prefix="£" />      
      <Total caption="Total Build Cost" values={[<Money currency="£" value={tbc} integer />, <><Money currency="£" value={tbcPerUnit} integer /> <AreaUnits value={value?.areaMetric} /> </>]} />
    </>
  );
}
