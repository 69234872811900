import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';

import styles from './SummaryTitle.module.css';

interface SummaryTitleProps {
  className?: string;
}

export default function SummaryTitle({className, children}: PropsWithChildren<SummaryTitleProps>) {
  return (
    <h3 className={clsx(styles['title'], className)}>{children}</h3>
  );
};
