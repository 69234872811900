import React from 'react';
import {connect} from 'react-redux';
import {selectors, RootState} from '@app/store/search';

import Stepper, {Step} from '@components/organisms/Stepper';
import {useParams} from 'react-router-dom';

const items: Step[] = [
  {primary: 'The Project', secondary: 'Step 1', icon: 'home-gear'},
  {primary: 'The Borrower', secondary: 'Step 2', icon: 'parent'},
  {primary: 'Revenue', secondary: 'Step 3', icon: 'money-pound-circle'},
  {primary: 'Build cost', secondary: 'Step 4', icon: 'barricade'},
  {primary: 'Acquisition cost', secondary: 'Step 5', icon: 'community'},
  {primary: 'Funding requirement', secondary: 'Step 6', icon: 'wallet-3'},
  {primary: 'Get your offers', secondary: 'Step 7', icon: 'vip-diamond'},
];

export default connect((state: RootState) => ({
  index: selectors.current.step(state),
  baseUrl: '/search/{id}/step-{index}/',
  searchId: selectors.current.value(state)?.id,
  nextIndex: selectors.current.maxStepFilled(state),
  items,
}))(Stepper);
