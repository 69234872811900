import {combineReducers} from "@reduxjs/toolkit";

import {postGetInTouch, PostGetInTouchState} from "./slice";
import {postGetInTouch as postGetInTouchThunk} from './thunk';

interface ContactsState {
  postGetInTouch: PostGetInTouchState;
}

export interface RootState {
  contacts: ContactsState;
}

export const reducer = combineReducers({
  postGetInTouch: postGetInTouch.reducer,
});

export const actions = {
  postGetInTouch: {
    post: postGetInTouchThunk,
    reset: postGetInTouch.actions.reset,
  },
};

export const selectors = {
  postGetInTouch: {
    loading: (state: RootState) => state.contacts.postGetInTouch.loading,
    success: (state: RootState) => state.contacts.postGetInTouch.success,
    done: (state: RootState) => state.contacts.postGetInTouch.done,
    error: (state: RootState) => state.contacts.postGetInTouch.error,
  },
};
