import Api from './api';
import {Project, ProjectTask, ProjectTasksResponse, ProjectTaskUpdateRequest, TasksRequest} from '@app/types';
import {dateToString, stringToDate} from '@utils/format';

export default class Tasks {
  constructor(private readonly api: Api) {}
  getList(request?: TasksRequest): Promise<ProjectTasksResponse> {
    //return [];
    const params = new URLSearchParams();    
    if (request?.projectId) params.set('projectId', request.projectId);
    if (request?.appraisalId) params.set('appraisalId', request.appraisalId);
    if (request?.top) params.set('count', request.top.toString());
    if (request?.skip) params.set('from', request.skip.toString());
    if (request?.returnTotal) params.set('returnTotal', 'true');
    if (request?.status) params.set('status', request.status.toString());
    let url = '^/Tasks';
    if  (true || request?.projectId || request?.appraisalId) url += '?' + params.toString();
    return this.api.get(url);
  }
  async get(taskId: string): Promise<ProjectTask> {
    const source: ProjectTask = await this.api.get(`^/Tasks/${taskId}`);    
    const result = {...source};    
    if (result.developerCv) {
      if (result.developerCv.previousProjects) {
        result.developerCv.previousProjects = result.developerCv.previousProjects.map(item => ({
          ...item,          
        }));
      }
    }
    if (result.borrowerDetails) {
      if (result.borrowerDetails.shareholders) {
        result.borrowerDetails.shareholders = result.borrowerDetails.shareholders.map(item => ({
          ...item,
          dateOfBirth: dateToString(item.dateOfBirth),
        }));
      }
      if (result.borrowerDetails.proposedDirectors) {
        result.borrowerDetails.proposedDirectors = result.borrowerDetails.proposedDirectors.map(item => ({
          ...item,
          dateOfBirth: dateToString(item.dateOfBirth)!,
        }));
      }
    }
    return result;
  }
  save(value: ProjectTaskUpdateRequest): Promise<ProjectTask> {
    const result = {...value};
    debugger;
    if (result.developerCv) {
      /*if (result.developerCv.previousProjects) {
        result.developerCv.previousProjects = result.developerCv.previousProjects.map(item => ({
          ...item,
        }));
      }*/
    }
    else if (result.borrowerDetails) {
      if (result.borrowerDetails.shareholders) {
        result.borrowerDetails = {
          ...result.borrowerDetails,
          shareholders: result.borrowerDetails.shareholders.map(item => ({
            ...item,
            dateOfBirth: stringToDate(item.dateOfBirth)!,
          }))
        }        
      }
      if (result.borrowerDetails.proposedDirectors) {
        result.borrowerDetails = {
          ...result.borrowerDetails,
          proposedDirectors: result.borrowerDetails.proposedDirectors.map(item => ({
            ...item,
            dateOfBirth: stringToDate(item.dateOfBirth)!,
          }))
        }        
      }
    }
    /*else {
      return this.api.get(`^/Tasks/${result.taskId}`);
    }*/
    return this.api.put<ProjectTaskUpdateRequest, ProjectTask>('^/Tasks', result);    
  }
  async complete(value: ProjectTaskUpdateRequest): Promise<ProjectTask> {
    if (Object.keys(value).length === 1 && value.taskId) {
      return this.api.put(`^/Tasks/${value.taskId}/complete`);    
    }
    const result = await this.save(value);
    return this.api.put(`^/Tasks/${result.id}/complete`);    
  }
  cancel(value: ProjectTask) {
    return this.api.put(`^/Tasks/${value.id}/cancel`, value);
  }
};
