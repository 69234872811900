import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {ApiError, files} from '@app/api/rest';
import {saveAs} from 'file-saver';

interface DownloadProps {
  fileId: string;
  fileName?: string;
}

interface UseUploadProps {
  onUploaded?: () => unknown;  
}

interface UploadProps {
  taskId: string;
  file: File;
}

type Loadings = Record<string, boolean>;
type Errors = Record<string, string>;

interface UseDeleteProps {
  onDeleted?: () => unknown;
}

export function useDownload() {
  const refCancelled = useRef<boolean | null>(null);
  const [loadings, setLoadings] = useState<Loadings>({});
  const [errors, setErrors] = useState<Errors>({});
  const loading = useMemo(() => Object.keys(loadings).length > 0, [loadings]);
  const error = useMemo(() => {
    const key = Object.keys(errors)[0];
    return key ? errors[key] : undefined;
  }, [errors]);
  //
  useEffect(() => () => {
    refCancelled.current = true;
  }, [refCancelled]);
  //
  const download = useCallback(async ({fileId, fileName}: DownloadProps) => {
    setLoadings(state => ({...state, [fileId]: true}));
    setErrors((state) => {      
      let result = state;
      if (result[fileId]) {
        result = {...state};
        delete result[fileId];        
      }
      return result;
    })
    try {
      const data = await files.get(fileId);
      if (refCancelled.current) return;
      saveAs(data, fileName);      
    } catch (e) {      
      const message = (e instanceof Error) ? (e as Error).message : 'Unknown error';
      setErrors((state) => ({...state, [fileId]: message}));
    } finally {
      if (refCancelled.current) return;
      setLoadings((state) => {
        const result = {...state};
        delete result[fileId];
        return result;
      });
    }
  }, [setLoadings, refCancelled]);  
  return {
    loading,
    loadings,
    error,
    errors,
    download,
  };
}

export function useUpload({onUploaded}: UseUploadProps) {
  const refCancelled = useRef<boolean | null>(null);
  const [loadings, setLoadings] = useState<Loadings>({});
  const [errors, setErrors] = useState<Errors>({});
  const loading = useMemo(() => Object.keys(loadings).length > 0, [loadings]);
  const error = useMemo(() => {
    const key = Object.keys(errors)[0];
    return key ? errors[key] : undefined;
  }, [errors]);
  
  //
  useEffect(() => () => {
    refCancelled.current = true;
  }, [refCancelled]);
  //
  const upload = useCallback(async ({taskId, file}: UploadProps) => {
    setLoadings(state => ({...state, [taskId]: true}));
    try {
      await files.upload({taskId,  file});      
      onUploaded && onUploaded();
    } catch (e) {
      const message = (e instanceof Error) ? (e as Error).message : 'Unknown error';
      setErrors((state) => ({...state, [taskId]: message}));
    } finally {      
      if (refCancelled.current) return;
      setLoadings((state) => {
        const result = {...state};
        delete result[taskId];
        return result;
      });
    }
  }, [setLoadings, setErrors, refCancelled, onUploaded]);  
  return {
    loading,
    loadings,
    error,
    upload,
  };
};

export function useDelete({onDeleted}: UseDeleteProps) {
  const refCancelled = useRef<boolean | null>(null);
  const [loadings, setLoadings] = useState<Loadings>({});
  const [errors, setErrors] = useState<Errors>({});
  const loading = useMemo(() => Object.keys(loadings).length > 0, [loadings]);
  const error = useMemo(() => {
    const key = Object.keys(errors)[0];
    return key ? errors[key] : undefined;
  }, [errors]);  
  //
  useEffect(() => () => {
    refCancelled.current = true;
  }, [refCancelled]);
  //
  //
  const perform = useCallback(async (fileId: string) => {
    setLoadings(state => ({...state, [fileId]: true}));
    try {
      await files.delete(fileId);
      onDeleted && onDeleted();
    } catch (e) {
      const message = (e instanceof Error) ? (e as Error).message : 'Unknown error';
      setErrors((state) => ({...state, [fileId]: message}));
    } finally {      
      if (refCancelled.current) return;
      setLoadings((state) => {
        const result = {...state};
        delete result[fileId];
        return result;
      });
    }
  }, [setLoadings, setErrors, refCancelled]);  
  return {
    loading,
    loadings,
    error,
    perform,
  };
}
