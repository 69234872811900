import React, {useCallback, useEffect, useMemo, ReactElement, useState, useContext} from "react";
import clsx from 'clsx';

import {CustomerLoanAppraisal, Lender, Project, SetOfferNameRequest} from "@app/types";
import ContentTitle from "@features/Layout/components/ContentTitle";

import {EqualColumns, TitleContext} from "@features/Layout";
import DashboardBlock from "@components/organisms/DashboardBlock";
import SummaryBlock, {SummaryGroupProps, SummaryItem, Value} from "@components/molecules/SummaryBlock";
import DashboardBlockExpandable from "@components/organisms/DashboardBlockExpandable";

import {SUMMARY_COLUMNS, INDICATIVE_TERMS_COLUMNS, SUMMARY_APPRAISAL_ROWS, SOURCE_OF_FUNDS_ROWS, KEY_PERFOMANCE_INDICATORS_COLUMNS} from "./fields";

import TasksType from "../Tasks";
import Paper from "@components/atoms/Paper";
import Button from "@components/atoms/Button";
import RemixIcon from "@components/atoms/RemixIcon";
import Dialog from "@components/atoms/Dialog";
import LenderPopup from "@features/Search/components/LenderPopup";
import Question from "@components/organisms/Question";
import Skeleton from "@components/atoms/Skeleton";
import Notification from '@components/organisms/Notification';

import styles from './Offer.module.css';

type AnyFunc = (...args: any) => any;

type ControllerOf<T extends AnyFunc, Fields extends string | number | symbol> = (props: Pick<Parameters<T>[0], Fields>) => ReactElement | null;
interface Request {
  projectId: string;
  appraisalId: string;
}

interface OfferProps {
  projectId: string;
  offerId: string;
  loading: boolean;
  project?: Project;
  offer?: CustomerLoanAppraisal;
  renaming?: boolean;
  renamed?: boolean;
  rejecting?: boolean;
  rejected?: boolean;
  submitting?: boolean;
  submitted?: boolean;
  onOpenProject: (projectId: string) => unknown;
  onCloseProject: () => unknown;
  onOpenOffer: (appraisalId: string) => unknown;
  onReject: (appraisalId: string) => unknown;
  onRejectReset: () => unknown;
  onRename?: (request: SetOfferNameRequest) => unknown;
  onRenameReset?: () => unknown;
  onSubmit: (appraisalId: string) => unknown;
  onSubmitReset: () => unknown;
  Tasks: ControllerOf<typeof TasksType, "projectId" | "appraisalId" | "show" | "onLoad" | "ignoreStatus">;
}

type LinesValue = CustomerLoanAppraisal & {
  salesCosts?: number;
  loanTenureMonths?: number;
  roGDV?: number;
  calculatedBuildCost?: number;
};

export default function Offer({
  projectId,
  offerId,
  loading,
  renaming,
  renamed,
  rejecting,
  submitting,
  submitted,
  rejected,
  project,
  offer,
  onOpenProject,
  onOpenOffer,
  onCloseProject,
  onRename,
  onRenameReset,
  onReject,
  onRejectReset,
  onSubmit,
  onSubmitReset,
  Tasks,
}: OfferProps) {
  const [ready, setReady] = useState(false);
  const [submitVisible, setSubmitVisible] = useState(false);
  const [openLender, setOpenLender] = useState<Lender | undefined>();
  const [ongoingTasksCount, setOngoingTasksCount] = useState<number>();
  useEffect(() => {
    onOpenProject(projectId);
    onOpenOffer(offerId);
    return () => {
      onCloseProject();
    };
  }, [projectId, offerId, onOpenProject, onCloseProject, onOpenOffer]);
  useEffect(() => {
    return () => {
      onRenameReset && onRenameReset();
      onRejectReset && onRejectReset();
    };
  }, [onRenameReset, onRejectReset]);
  //
  useEffect(() => {
    if (submitted) {
      if (onSubmitReset) onSubmitReset();
      setSubmitVisible(false);
    }
  }, [submitted, setSubmitVisible, onSubmitReset]);
  //
  const handleSubmit = useCallback(() => {
    offer && onSubmit && onSubmit(offer.appraisalId);
  }, [onSubmit, offer]);
  //
  const handleReject = useCallback(() => {
    offer && onReject && onReject(offer.appraisalId);
  }, [offer, onReject]);
  //
  const handleRename = useCallback(
    (name: string) => {
      offer && onRename && onRename({appraisalId: offer?.appraisalId, name});
    },
    [onRename, offer]
  );
  const handleTasksLoad = useCallback((count: number) => {
    setOngoingTasksCount(count);
    setReady(true);
  }, [true]);
  //
  const handleClickSummary = useCallback(
    (item: SummaryItem) => {
      if (item.fieldName === "name") {
        setOpenLender(offer?.lender);
      }
    },
    [setOpenLender, offer]
  );
  const [renameIsActive, setRenameIsActive] = useState(false);
  const handleRenameChange = useCallback((value: boolean) => {
    setTimeout(() => {
      setRenameIsActive(value);
    }, 500);
    //
  }, [setRenameIsActive]);
  const linesValue = useMemo(() => {
    const result = {
      ...(offer || {}),
    } as LinesValue;
    if (project) result.salesCosts = project.loanQuery.salesCosts;
    result.loanTenureMonths = project?.loanQuery.loanTenureMonths;
    result.roGDV = project?.loanQuery.roGDV;
    result.calculatedBuildCost = (offer?.totalDevelopmentCost || 0) - (offer?.acquisitionCost || 0);
    if (!result.buildFacilitySum) result.buildFacilitySum = 0;
    if (!result.financeFacility) result.financeFacility = 0;
    return result;
  }, [project, offer]);
  const canSubmit = offer && offer.status === 'Active' && ongoingTasksCount === 0;
  const cantSubmit = offer && offer.status === 'Active' && ongoingTasksCount !== 0;
  //
  const left = (
    <>
      <DashboardBlock icon="file-list" caption="Summary" padding={1} last>
        <SummaryBlock columns={SUMMARY_COLUMNS} value={offer as unknown as Value} loading={loading} onClickItem={handleClickSummary} />
      </DashboardBlock>
      <DashboardBlockExpandable
        icon="file-list"
        caption="Indicative terms"
        full={<SummaryBlock columns={INDICATIVE_TERMS_COLUMNS} value={linesValue as unknown as Value} padding={1} />}
      />
      {/*<DashboardBlockExpandable icon="file-list" caption="Security package" full={<SummaryBlock columns={SECURITY_PACKAGE_COLUMNS} value={offer as unknown as Value} padding={1} />} />*/}
      <DashboardBlockExpandable
        icon="file-list"
        caption="Summary appraisal"
        full={<SummaryBlock rows={SUMMARY_APPRAISAL_ROWS} value={linesValue as unknown as Value} padding={1} noTitle />}
      />
      <DashboardBlockExpandable
        icon="file-list"
        caption="Sources and uses"
        full={<SummaryBlock rows={SOURCE_OF_FUNDS_ROWS} value={linesValue as unknown as Value} padding={1} />}
      />
      <DashboardBlockExpandable
        icon="file-list"
        caption="Key perfomance indicators"
        full={<SummaryBlock columns={KEY_PERFOMANCE_INDICATORS_COLUMNS} value={linesValue as unknown as Value} padding={1} noTitle />}
      />
    </>
  );
  const right = (
    <>
      <Tasks projectId={projectId} appraisalId={offerId} show="ongoing" onLoad={handleTasksLoad} ignoreStatus />
      <Tasks projectId={projectId} appraisalId={offerId} show="complete" onLoad={() => {}} ignoreStatus />
    </>
  );
  return (
    <>
      <ContentTitle title="Dashboard" breadcrumb={{url: "/", caption: "Dashboard"}} />
      <ContentTitle
        title={project?.name || "(Project not found)"}
        loading={loading || project === undefined}
        breadcrumb={{url: `/projects/${project?.id}/`, caption: project?.name || ""}}
      />
      <ContentTitle
        title={offer?.name || "(Offer not found)"}
        loading={loading || offer === undefined}
        breadcrumb={{url: `/projects/${project?.id}/offers/${offer?.appraisalId}`, caption: offer?.name || ""}}
        editable
        onChange={handleRename}
        onChangeReset={onRenameReset}
        renaming={renaming}
        renamed={renamed}        
      />      
      {cantSubmit ? <Notification className={clsx(styles['alert'])} type="alert" title="Action required!" text="Please complete all related tasks before expiration date." /> : null}
      {canSubmit ? <div className={styles['submit-panel']}>
        <div className={styles['submit-content']}>
          <h2 className={styles['submit-title']}>You can now submit your deal.</h2>
          <div>The lender will get in touch with you shortly via email once they have reviewed the deal.</div>
        </div>
        <Button className={styles['submit-button']} color="secondary" layout="auto" caption="Submit" onClick={handleSubmit} />
      </div> : null}
      {offer && offer.status === "Active" ? (
        <Paper size="small" margin="medium" flex border>
          {ready ? (
            <>              
              <Button className={styles['reject']} caption="Reject" icon={<RemixIcon value="thumb-down" size="small" margin={1} />} layout="auto" margin={0} loading={rejecting} onClick={handleReject} />
            </>
          ) : <Skeleton active height="42px" />}
        </Paper>
      ) : null}
      <EqualColumns left={left} right={right} />
      <LenderPopup value={openLender} setter={setOpenLender} />
      <Dialog open={submitVisible} setter={setSubmitVisible}>
        <Question title="" text="Are you sure to submit all related tasks to the lender?" action="Submit" onAction={handleSubmit} onCancel={() => setSubmitVisible(false)} loading={submitting} />
      </Dialog>
    </>
  );
}
