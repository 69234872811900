import {AuthToken} from '../app/types';

const TOKEN = 'FK_TOKEN';
const LAST_URL = 'FK_LAST_URL';

export class Storage {
  get token(): AuthToken | undefined {
    try {
      const sessionStorageValue = sessionStorage.getItem(TOKEN);
      if (sessionStorageValue) {        
        sessionStorage.removeItem(TOKEN);
        const result = JSON.parse(sessionStorageValue || '');
        this.token = result;
        return result;
      }      
      return JSON.parse(localStorage.getItem(TOKEN) || '');
    } catch (e) {
      this.token = undefined;
      return undefined;
    }
  }
  set token(value: AuthToken | undefined) {
    if (value) localStorage.setItem(TOKEN, JSON.stringify(value));
    else localStorage.removeItem(TOKEN);
  }
  get lastUrl(): string | undefined {
    try {
      return sessionStorage.getItem(LAST_URL) || undefined;
    } catch (e) {
      return undefined;
    }
  }
  set lastUrl(value: string | undefined) {
    try {
      if (value) sessionStorage.setItem(LAST_URL, value);
      else sessionStorage.removeItem(LAST_URL);
    } catch (e) {

    }
  }
}

export const storage = new Storage();

// export default storage;
