import {connect} from 'react-redux';

import {actions, selectors} from '@app/store/search';
import {actions as actionsProject, selectors as selectorsProject} from '@app/store/projects';

import {RootState} from '@app/store';

import Compare from '../components/Compare';

export default connect(
  (state: RootState) => ({
    loading: selectors.offers.loading(state),
    loaded: selectors.offers.loaded(state),
    source: selectors.offers.value(state),
    error: selectors.offers.error(state),
    project: selectorsProject.item.value(state),
  }),
  {
    onOpen: actions.offers.get,
    onOpenProject: actionsProject.item.open,
  }
)(Compare);
