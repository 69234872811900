import {combineReducers, createSelector} from "@reduxjs/toolkit";
import {getList, getItem, save, share, complete, cancel} from "./thunks";
import {list, item} from './slice';

export const reducer = combineReducers({
  list: list.reducer,
  item: item.reducer,
});

export type LocalState = ReturnType<typeof reducer>;

export type RootState = {
  tasks: LocalState;
}; 

export const actions = {
  list: {
    get: getList,
    reset: list.actions.reset,
  },
  item: {
    open: getItem,
    save,
    share, 
    complete,    
    cancel,
    ...item.actions,
  },
};

const selectActiveItems = createSelector((state: RootState) => state.tasks.list.value?.data, (items) => items ? items.filter((item) => item.status === 'Active') : items);

export const selectors = {
  list: {
    loading: (state: RootState) => state.tasks.list.loading,
    value: (state: RootState) => state.tasks.list.value?.data,
    activeItems: (state: RootState) => selectActiveItems(state),
    totalCount: (state: RootState) => state.tasks.list.value?.totalCount,
    error: (state: RootState) => state.tasks.list.error,
  },
  item: {
    loading: (state: RootState) => state.tasks.item.loading,
    submitting: (state: RootState) => state.tasks.item.submitting,
    saving: (state: RootState) => state.tasks.item.saving,
    sharing: (state: RootState) => state.tasks.item.sharing,
    completing: (state: RootState) => state.tasks.item.completing,
    completed: (state: RootState) => state.tasks.item.completed,
    cancelling: (state: RootState) => state.tasks.item.cancelling,
    value: (state: RootState) => state.tasks.item.value,
    error: (state: RootState) => state.tasks.item.error,
  },
};

