import {slice, InitialState} from './slice';
import {getList} from './thunks';

export interface RootState {
  history: InitialState;
}

export const actions = {
  ...slice.actions,
  get: getList,
}

export const reducer = slice.reducer;

export const selectors = {
  loading: (state: RootState) => state.history.loading,
  value: (state: RootState) => state.history.value,
}
