import Api from './api';
import {Project, SetProjectNameRequest} from '@app/types';

export default class Projects {
  constructor(private readonly api: Api) {}
  getList(): Promise<Project[]> {
    // return [];
    return this.api.get('/Projects');
  }
  archive(projectId: string): Promise<Project> {
    return this.api.put(`/Projects/${projectId}/archive`);
  }
  get(projectId: string): Promise<Project> {
    return this.api.get(`/Projects/${projectId}`);
  }
  setName(request: SetProjectNameRequest): Promise<Project> {
    const {projectId, name} = request;
    return this.api.put(`/Projects/${projectId}`, {name});
  }  
};
