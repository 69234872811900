import React from 'react';
import clsx from 'clsx';

import styles from './TextPair.module.css';

interface TextPairProps {
  className?: string;
  primary: string;
  secondary?: string;
  size?: 'small' | 'medium' | 'large';
  inverse?: boolean;
  required?: boolean;
  margin?: 'small' | 'medium' | 'large';
  align?: 'right';
}

export default function TextPair({
  className,
  primary,
  secondary,
  size,
  inverse,
  required,
  margin,
  align,
}: TextPairProps) {
  const classes = clsx(
    styles['container'],
    size ? styles['size_' + size] : null,
    inverse ? styles['type_inverse'] : null,
    margin ? styles['margin_' + margin] : null,
    align ? styles['align_' + align] : null,
    className
  );
  return (
    <div className={classes}>
      {primary ? (
        <label className={styles['primary']}>
          {primary}
          {required ? <span className="required"> *</span> : null}
        </label>
      ) : null}
      {secondary ? <label className={styles['secondary']}>{secondary}</label> : null}
    </div>
  );
}
