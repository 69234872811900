import React from 'react';
import RemixIcon from '@components/atoms/RemixIcon';

import styles from './DashboardBlockEmpty.module.css';

interface DashboardBlockEmptyProps {
  name: string;
}

export default function DashboardBlockEmpty({name}: DashboardBlockEmptyProps) {
  return (
    <div className={styles['container']}>
      <RemixIcon className={styles['icon']} value="function" size="large" />
      <span>You don't have any {name} yet</span>    
    </div>
  );
};
