import {createAsyncThunk, AsyncThunk, AsyncThunkPayloadCreator} from "@reduxjs/toolkit";

interface ThunkAPIConfig {};

type ApiThunkApiHandlerWithRequest<Request extends any, Response> = (request: Request) => Promise<Response>;
type ApiThunkApiHandlerWithoutRequest<Response> = () => Promise<Response>;
type ApiThunkApiHandler<Request, Response> = ApiThunkApiHandlerWithRequest<Request, Response> | ApiThunkApiHandlerWithoutRequest<Response>;

export type ApiThunkHandler<Request, Response> = ApiThunkApiHandler<Request, Response> | AsyncThunkPayloadCreator<Response, Request>;
type CreateApiThunkResult<Request, Response, ThunkAPIConfig> = AsyncThunk<Response, Request, ThunkAPIConfig>;

export function isApiThunkHandlerWithoutRequest(handler: unknown): handler is ApiThunkApiHandlerWithoutRequest<any> {
  return typeof handler === 'function' && handler.length === 0;
};

export function isApiThunkHandlerWithRequest(handler: unknown): handler is ApiThunkApiHandlerWithRequest<any, any> {
  return typeof handler === 'function' && handler.length === 1;
};

export function isReduxThunkHandler(handler: unknown): handler is AsyncThunkPayloadCreator<any, any, any> {
  return typeof handler === 'function' && handler.length === 2;
};

export function createApiThunk<Request extends any = void, Response = any, Scope extends string = string>(scope: Scope, handler: ApiThunkHandler<Request, Response>)
: CreateApiThunkResult<Request, Response, ThunkAPIConfig> {
  return createAsyncThunk<Response, Request, ThunkAPIConfig>(scope, async (request: Request, thunkAPI) => {
    try {
      if (isApiThunkHandlerWithoutRequest(handler)) return await handler();
      else if (isApiThunkHandlerWithRequest(handler)) return await handler(request);
      else if (isReduxThunkHandler(handler)) return await handler(request, thunkAPI);
      else throw new TypeError("Invalid api thunk handler");
    } catch (e) {
      throw e;
    }
  });
};
