import React, {FormEvent, useCallback, useEffect, useState} from 'react';
import {Project, ProjectTask} from '@app/types';

import DownloadFile from '../DownloadFile';
import UploadFile from '../UploadFile';
import FormAction from '../FormAction/FormAction';
import ErrorText from '@components/organisms/ErrorText';
import Errors from '@features/Search/components/Errors';

interface DevelopmentAppraisalProps {
  active: boolean;
  submitting?: boolean;
  saving?: boolean;
  task: ProjectTask;  
  onRefresh?: () => unknown;
  onSubmit: () => unknown;
  onSave: () => unknown;
}

export default function DevelopmentAppraisal({active, submitting, saving, task, onRefresh, onSubmit, onSave}: DevelopmentAppraisalProps) {
  const [error, setError] = useState(false);
  const fields = {
    'upload': 'The uploaded file is required'
  };
  const handleSubmit = useCallback(() => {
    if (!task.files[0]) {
      setError(true);
    } else {
      setError(false);
      onSubmit();
    }
  }, [task, onSubmit, setError]);

  useEffect(() => {
    if (error) window.scrollTo({left: 0, top: 0, behavior: 'smooth'});
  }, [error]);

  const handleFormSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();
    handleSubmit();
  }, [handleSubmit]);
  const canUpload = !(task?.files?.length > 0);
  return (
    <form onSubmit={handleFormSubmit} >
      {error ? <Errors<typeof fields> fields={['upload']} items={fields} /> : null}
      <DownloadFile sampleFile={task?.sampleFile} />
      {!canUpload ? <DownloadFile file={task.files[0]} active={active} onDeleted={onRefresh} /> : null}
      {canUpload && active ? <UploadFile error={error} required taskId={task?.id} onUploaded={onRefresh} /> : null}
      {/*{active ? <FormAction action="Complete" onAction={handleSubmit} loading={submitting} /> : null}*/}
      {active ? <FormAction type="submit" action="Complete" actionSize="medium" loading={submitting} prevAction="Save" onPrevAction={onSave} prevLoaing={saving} /> : null}
    </form>
  );
};
