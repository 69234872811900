import React from 'react';
import clsx from 'clsx';

import {CustomerLoanAppraisal} from "@app/types";

import styles from './CompareGrid.module.css';
import Money from '@components/atoms/Money';

export interface FieldDef {
  name: string;
  caption: string;
  type: 'money' | '%' | 'integer';
  compare: 'min' | 'max';
  total?: boolean;
  decimals?: number;
}

interface CompareGridProps {
  caption: string;
  fields: FieldDef[];
  objects: CustomerLoanAppraisal[];
}

const FORMAT_INTEGER = {
  integer: true,
};

const FORMAT_ONE_DIGIT = {
  oneDigit: true,
};

const FORMAT_TWO_DIGITS = {

}

function getFormatForInt(type: '%' | 'integer' | 'money', decimals?: number) {  
  if (decimals === undefined) {
    if (type === '%') return FORMAT_ONE_DIGIT;
    if (type === 'integer') return FORMAT_INTEGER;
    if (type === 'money') return FORMAT_INTEGER;
  }
  if (decimals === 0) return FORMAT_INTEGER;
  if (decimals === 1) return FORMAT_ONE_DIGIT;
  if (decimals === 2) return FORMAT_TWO_DIGITS;  
  return FORMAT_TWO_DIGITS;  
}

function getFormatFor(type: '%' | 'integer' | 'money', decimals?: number) {
  const result = getFormatForInt(type, decimals);  
  return result;
}

export default function CompareGrid({caption, fields, objects}: CompareGridProps) {
  return (
    <table className={styles['container']}>
      <thead>
        <tr>
          <th className={styles['caption']}>{caption}</th>
          {objects ? objects.map((item, i) => (
            <th className={styles['offer-caption']} key={i}>{item.name}</th>
          )) : null}
        </tr>
      </thead>
      <tbody>
        {fields.map((item, i) => (
          <tr key={i}>
            <td className={clsx(styles['field-caption'], item.total ? styles['total'] : null)}>{item.caption}</td>
            {objects ? objects.map((obj, i) => (
              <td className={clsx(styles['field-cell'])} key={i}>
                {item.type === '%' ? <><Money value={obj[item.name as keyof typeof obj] as number} {...getFormatFor('%', item.decimals)} />%</> : null}
                {item.type === 'integer' ? <Money value={obj[item.name as keyof typeof obj] as number} {...getFormatFor('integer', item.decimals)} /> : null}
                {item.type === 'money' ? <Money currency="£" value={obj[item.name as keyof typeof obj] as number} {...getFormatFor('money', item.decimals)} /> : null}
              </td>
            )) : null}
          </tr>
        ))}
      </tbody>
    </table>
  )
};
