import React, {useCallback, useMemo, useRef, forwardRef, InputHTMLAttributes, ReactNode, Ref, ChangeEvent, useState, useEffect, RefObject, RefCallback, ForwardedRef} from "react";
import clsx from "clsx";

import Input from "@components/atoms/Input";
import useNumberInput from "@hooks/useNumberInput";

import CommonInput from "../CommonInput";

import styles from "./PercentageInput.module.css";
import {Field} from "@hooks/useForm";

interface PercentageInputProps<Type, Name> extends InputHTMLAttributes<HTMLInputElement> {
  anchor?: string;
  field?: Field<Type, Name, {orPlaceholder?: string; percentsCaption?: string | ReactNode}>;
  base?: number;
  margin?: number;
  percentsShowOnly?: boolean;
  labelValue?: string | ReactNode;
  alternate?: boolean;
  smallError?: boolean;
  orPrefix?: string;
  orSuffix?: string;
}

type CallableRef<T> = (instance: T | null) => void;

function refIsCallable<T>(ref: ForwardedRef<T>): ref is CallableRef<T> {
  return typeof ref === "function";
}

const EMPTY_OBJECT = {};

export default function PercentageInput<Type, Name>({className, anchor, field, margin, base, required, percentsShowOnly, labelValue, alternate, smallError, orPrefix, orSuffix}: PercentageInputProps<Type, Name>) {    
  // placeholders
  const placeholder = typeof field?.placeholder === "string" ? field.placeholder : undefined;
  const orPlaceholder = typeof field?.orPlaceholder === "string" ? field.orPlaceholder : undefined;
  // local values
  const [percents, setPercents] = useState('');  
  const [alternateValue, setAlternateValue] = useState('');

  const refValue = useRef<string | undefined>('');
  const refPercents = useRef<string | undefined>('');
  const refBase = useRef<string | undefined>('');

  // calc routines
  const calcPercents = useCallback((value: number) => {
    let result = base ? (value * 100 / base).toFixed(2).toString() : '';
    if (result.endsWith('.00')) result = result.slice(0, result.length - 3);
    if (result.indexOf('.') > 0 && result.endsWith('0')) result = result.slice(0, result.length - 1);
    return result;
  }, [base]);
  //
  const calcMainByPercents = useCallback((value: number) => {
    let result = base ? (base * value / 100).toFixed(2) : '';
    if (result.endsWith('.00')) result = result.slice(0, result.length - 3);
    if (result.indexOf('.') > 0 && result.endsWith('0')) result = result.slice(0, result.length - 1);
    return result;
  }, [field, base]);
  //
  const calcMainByAlt = useCallback((value: number) => {
    let result = base ? (100 * value / base).toFixed(2) : '';
    if (result.endsWith('.00')) result = result.slice(0, result.length - 3);
    if (result.indexOf('.') > 0 && result.endsWith('0')) result = result.slice(0, result.length - 1);
    return result;
  }, [base]);
  //
  // const calcAltByMainPercents = useCallback((value: number) => {

  //}, [field, base])
  //
  const setMainByPercents = useCallback((value: string) => {
    if (value === '') field?.setTextValue('');
    else {
      const main = calcMainByPercents(+value);
      if (refValue.current !== main) {
        refValue.current = main;
        field?.setTextValue(main);
      }
    }
  }, [field, refValue, calcMainByPercents]);
  //
  const setPercentsByMain = useCallback((value: string) => {
    console.log('setPercents', value);
    if (value === '') setPercents('');
    else {
      const percents = calcPercents(+value);
      if (refPercents.current !== percents) {
        refPercents.current = percents;
        setPercents(percents);
      }
    }
  }, [setPercents, refPercents, calcPercents]);   
  //
  const setAltByMainPercents = useCallback((value: string) => {
    if (value === '') setAlternateValue('');
    else {      
      const altValue = calcMainByPercents(+value);
      if (refPercents.current !== altValue) {
        refPercents.current = altValue;
        setAlternateValue(altValue);
      }
    }
  }, [setAlternateValue, refPercents]);
  //
  const setPercentsByAlt = useCallback((value: string) => {
    if (value === '') field?.setTextValue('');
    else {
      const main = calcMainByAlt(+value);      
      if (refValue.current !== main) {
        console.log('setting new value by alt', refValue.current, '=>', main);
        refValue.current = main;
        field?.setTextValue(main);
      }
    }
  }, [field, refValue, calcMainByAlt]);

  useEffect(() => {
    if (alternate) {
      console.log('[ALT RECALC EFFECT]', field?.caption, 'value', field?.textValue, 'base', base, 'refBase', refBase.current);
      if (refBase.current !== (base || '').toString()) {
        console.log('REF CHANGED', base);
        refBase.current = (base || '').toString();
        refValue.current = field?.textValue;
        setAltByMainPercents(refValue.current || '');        
      } else if (refValue.current !== field?.textValue) {
        console.log('VALUE CHANGED', field?.textValue);
        refValue.current = field?.textValue;
        setAltByMainPercents(refValue.current || '');        
      } else if (refPercents.current !== alternateValue) {
        console.log('PERCENTS CHANGED', alternateValue);
        refPercents.current = alternateValue;
        setPercentsByAlt(alternateValue);
      }
    }
  }, [alternate, alternateValue, base, refValue, refPercents, calcPercents, field, setPercentsByAlt, setAltByMainPercents]);

  useEffect(() => {
    if (!alternate) {
      console.log('[RECALC EFFECT]', field?.caption, 'value', field?.textValue, 'base', base, 'refBase', refBase.current);
      if (refBase.current !== (base || '' ).toString()) {        
        refBase.current = (base || '').toString();
        refValue.current = field?.textValue;
        setPercentsByMain(refValue.current || '');
      }
      else if (refValue.current !== field?.textValue) {
        console.log('[RECALC EFFECT] field value changed', field?.textValue);
        refValue.current = field?.textValue;
        setPercentsByMain(refValue.current || '');
      }
      else if (refPercents.current !== percents) {
        console.log('[RECALC EFFECT] percents value changed', percents);
        refPercents.current = percents;
        setMainByPercents(percents);        
      }
    }
  }, [field, alternate, refValue, base, calcPercents, percents, refPercents, setPercentsByMain, setMainByPercents]);

  // change handlers
  const handleChangeValue = useCallback((e) => {    
    if (alternate) {
      setAlternateValue(e.currentTarget.value);
    } else {
      field?.setTextValue(e.currentTarget.value);    
    }
  }, [alternate, field, setAlternateValue]);
  //
  const handleChangePercents = useCallback((e: ChangeEvent<HTMLInputElement>) => {    
    if (alternate) {
      field?.setTextValue(e.currentTarget.value);
    } else {
      setPercents(e.currentTarget.value);
    }
  }, [alternate, field, setPercents]); 

  const mainValue = (alternate ? alternateValue : field?.textValue) || '';
  const percentsValue = (alternate ? field?.textValue : percents);

  const controlError = smallError && (field?.error || null);

  return (
    <CommonInput
      className={className}
      caption={field?.caption}
      description={field?.description}
      required={required || !!field?.required}
      error={smallError ? null : (field?.error || null)}
      margin={margin}
      tooltip={field?.hint}      
      flex
    >
      <Input
        className={styles['control']}
        anchor={anchor}
        name={alternate ? undefined : field?.name as unknown as string}
        placeholder={placeholder}
        inputType={percentsShowOnly ? 'float' : 'integer'}
        value={mainValue}
        prefix={percentsShowOnly ? '' : "£"}
        suffix={percentsShowOnly ? '%' : ''}
        onChange={handleChangeValue}
        error={!!controlError}
      />
      <span className={clsx(styles["delimiter"], percentsShowOnly ? styles["hidden"] : undefined)}>or</span>
      {percentsShowOnly ? (
        <div className={styles["label"]}>{labelValue}</div>
      ) : (
        <Input 
          error={!!controlError}
          className={styles['control']} 
          name={alternate ? field?.name as unknown as string : undefined}
          placeholder={orPlaceholder}  
          value={percentsValue} 
          onChange={handleChangePercents} 
          inputType={orPrefix === '£' ? 'integer' : 'float'}
          prefix={orPrefix || ''}
          suffix={orSuffix || '%'} />
      )}
    </CommonInput>
  );
}
