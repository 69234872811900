import React from 'react';
import {Outlet} from 'react-router-dom';

import {Layout} from '@features/Layout';

export default function LayoutView() {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}
