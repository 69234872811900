import React, {ElementType, MouseEventHandler, ReactNode} from 'react';
import clsx from 'clsx';

import {Link, To} from 'react-router-dom';

import RemixIcon from '@components/atoms/RemixIcon';
import TextPair from '@components/atoms/TextPair';

import styles from './ListItem.module.css';

interface ListItemProps {
  className?: string;
  icon?: string;
  color?: 'primary' | 'secondary';
  primary: string;
  secondary?: string;
  actions?: ReactNode;
  align?: 'right';
  size?: 'small' | 'medium' | 'large';
  url?: To;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  Component?: string | ElementType;
}

export default function ListItem({
  className,
  icon,
  color = 'secondary',
  primary,
  secondary,
  align,
  size,
  url,
  onClick,
  Component = 'div',
}: ListItemProps) {
  const classes = clsx(
    styles['container'],
    url || onClick ? styles['type_clickable'] : null,
    align ? styles['align_' + align] : null,
    className
  );
  const props = {} as {to: To};
  const Comp = url ? Link : Component;
  if (Comp === Link) props.to = url!;
  return (
    <Comp className={classes} onClick={onClick} {...props}>
      <RemixIcon className={styles['icon']} value={icon} color={color} size="large" />
      <TextPair primary={primary} secondary={secondary} align={align} size={size} />
    </Comp>
  );
}
