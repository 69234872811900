import {combineReducers} from '@reduxjs/toolkit';
import {reducer as api} from './api';
import {reducer as auth} from './auth';
import {reducer as contacts} from './contacts';
import {reducer as history} from './history';
import {reducer as offers} from './offers';
import {reducer as projects} from './projects';
import {reducer as search} from './search';
import {reducer as session} from './session';
import {reducer as tasks} from './tasks';

export default combineReducers({
  api,
  auth,
  contacts,
  history,
  offers,
  projects,
  search,
  session,
  tasks,
});
