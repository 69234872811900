import React, {useState, useCallback, useEffect, useMemo, useRef, PropsWithChildren} from 'react';
import {useNavigate, Outlet, useLocation} from 'react-router-dom';
import ContentTitle from '@features/Layout/components/ContentTitle';

import {SearchFormData} from '@app/types';
import {Provider, FormDataGetter, ContextData} from '../../contexts/form';

import useAnchor from '@hooks/useAnchor';

interface SearchProps {
  loaded: boolean;
  queryId?: string;
  step?: number;
  value?: SearchFormData;
  posted: boolean;
  onLoad: (queryId?: string) => void;
  onReset: () => unknown;
}

export default function Search({
  loaded,
  step,
  queryId,
  value,
  posted,
  onReset,
  onLoad,
  children,
}: PropsWithChildren<SearchProps>) {  
  const navigate = useNavigate();
  const location = useLocation();  
  const refValue = useRef<SearchFormData | null>();  
  useEffect(() => {    
    console.log('EFFECT SEARCH VALUE CHANGED', refValue.current, value);
    if (refValue.current !== value) {
      if (refValue.current && value && !value.id) {
        refValue.current = value;
        console.log('SEARCH WAS RESET');
        navigate('/search/new/step-1/');
        return;
      }
      if (value &&refValue.current?.id !== value?.id && typeof value?.id === 'string' && value.id !== '')  {        
        const url = window.location.pathname.replace('new', value.id);        
        if (window.location.pathname !== url) navigate(url, {replace: true});
      }
      if (value && refValue.current && refValue.current.id !== value.id && !value.id) {
        const url = '/search/new/step-1/';
        if (window.location.pathname !== url) navigate(url, {replace: true});
      }
    }
    refValue.current = value;
    if (!loaded || !value || (queryId !== 'new' && queryId !== '' && value.id !== queryId)) {
      onLoad(queryId);
    }
  }, [value, refValue, navigate, onLoad, queryId, loaded]);  
  // load
/*  useEffect(() => {
    if (!loaded || !value || (queryId !== 'new' && queryId !== '' && value.id !== queryId)) {
      onLoad(queryId);
    }    
  }, [onLoad, queryId, loaded, value]);*/
  useEffect(() => {
    return () => {
      onReset();
    }
  }, [onReset]);
  var refGet = useRef<FormDataGetter | null>(null);    
  // const 
  const providerData = useMemo<ContextData>(() => ({
    setGetter: (v: FormDataGetter) => {refGet.current = v},
    get: () => refGet.current? refGet.current() : undefined,
  }), [refGet]);
  // 
  useEffect(() => {
    if (!location.hash) return;
    console.log('HASH', location.hash);
  }, [location.hash]);
  //
  useAnchor({hash: location.hash});
  if (!loaded) return null;
  return (
    <>
      <Provider value={providerData}>
        <ContentTitle title="Complete these six steps to view your offers" />
        {children}
        <Outlet />
      </Provider>
    </>
  );
}
