import React, {PropsWithChildren, useMemo} from 'react';
import clsx from 'clsx';
import Paper from '@components/atoms/Paper';
import DashboardBlockHeader from '@components/molecules/DashboardBlockHeader';

import styles from './DashboardBlock.module.css';

interface DashboardBlockProps {
  className?: string;
  icon?: string;
  caption?: string;
  description?: string;
  hint?: string;
  bage?: string | number;
  size?: 'small' | 'big';
  height?: number;
  contentHeight?: number;
  collapsed?: boolean;
  animated?: boolean,
  border?: boolean;
  padding?: number;
  last?: boolean;
  ready?: boolean;
  required?: boolean;
  loading?: boolean;
  action?: {
    caption?: string;
    onClick?: () => void;
    url?: string;
    replace?: boolean;
    currentWindow?: boolean;
    disabled?: boolean;
  }
}

export default function DashboardBlock({className, ready = true, loading, icon, required, description, hint, padding, last, animated, caption, bage, size, height, contentHeight, collapsed, border, action, children}: PropsWithChildren<DashboardBlockProps>) {
  const classes = clsx(
    // styles['container'],
    ready ? styles['ready'] : null,
    className,
  );
  const classesContent = clsx(
    styles['content'],
    animated ? styles['animated'] : null,
    padding ? styles['padding_' + padding.toString()] : null,
    last ? styles['last'] : null,
  );
  const heightStyles = useMemo(() => {
    if (contentHeight !== undefined) return {
      maxHeight: contentHeight ? contentHeight + 'px' : 0,
      height: '1000vh',      
    };    
    return undefined;
  }, [contentHeight]);
  return (
    <Paper className={classes} margin="medium" Component="section" border={border}>
      <DashboardBlockHeader icon={icon} caption={caption} required={required} description={description} hint={hint} bage={bage} size={size} collapsed={collapsed} action={action} loading={loading} />    
      <div className={classesContent} style={heightStyles}>
        {children}
      </div>
    </Paper>
  );
};
