import React, {useEffect, useMemo} from 'react';

import DashboardBlock from '@components/organisms/DashboardBlock';
import ProjectTiles from '@components/organisms/ProjectTiles';

import styles from './ProjectsGroup.module.css';
import { Project } from '@app/types';
import DashboardBlockEmpty from '@components/molecules/DashboardBlockEmpty';

interface ProjectsGroupProps {
  loading?: boolean;
  items?: Project[];
  onOpen: () => unknown;
  onClose: () => unknown;
}

const ACTION = {
  caption: 'Show more',
  url: '/projects/'
}

export default function ProjectsGroup({loading, items, onOpen, onClose}: ProjectsGroupProps) {
  useEffect(() => {
    onOpen();
    return () => {
      onClose();
    }
  }, [onOpen, onClose]);  
  const projects = useMemo(() => {
    if (!items) return items;
    return items.slice(0, 3).map(item => ({
      id: item.id,
      name: item.name,
      createdOn: item.createdOn,
      amount: item.loanQuery.netLoan,
      type: item.loanQuery.proposedDevelopment,
      status: item.status,
    }));
  }, [items]);
  return (
    <DashboardBlock caption="Projects" bage={items?.length} action={items && items.length > 3 ? ACTION : undefined} >
      {((projects && projects.length) || loading) ?  <ProjectTiles loading={loading} items={projects} baseUrl={'/projects/'} /> : <DashboardBlockEmpty name="projects" />}
    </DashboardBlock>    
  )
}