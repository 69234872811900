import React, {useCallback, useState, ChangeEvent, DragEvent, MouseEvent} from "react";
import clsx from "clsx";

import {File as FileType} from "@app/types";

import Paper from "@components/atoms/Paper";
import Title from "@components/atoms/Title";
import RemixIcon from "@components/atoms/RemixIcon";
import Spinner from "@components/atoms/Spinner";

import {useUpload} from '@hooks/.';

import styles from "./UploadFile.module.css";

interface UploadFileProps {
  required?: boolean;
  error?: boolean;
  taskId: string;
  onUploaded?: () => unknown;
}

const handleTargetClick = (e: MouseEvent<HTMLInputElement>) => e.preventDefault();

export default function UploadFile({error: invalid, required, taskId, onUploaded}: UploadFileProps) {  
  const [targetActive, setTargetActive] = useState(false);  
  const {loading, error, upload} = useUpload({onUploaded});
  //
  const handleActivateTarget = useCallback(() => {
    setTargetActive(true);
  }, [setTargetActive]);
  const handleDeactivateTarget = useCallback(() => {
    setTargetActive(false);
  }, [setTargetActive]);
  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {    
    const file = e.currentTarget.files ? e.currentTarget.files[0] : undefined;
    if (file) {
      upload({taskId, file})
    }    
  }, [upload]);
  //
  return (
    <Paper size="large" margin="large">
      <Title className={styles["caption"]} level={2}>
        Upload
        {required ? <span className={styles['required']}> *</span> : null}
      </Title>
      <div className={styles["description"]}>Upload the completed document</div>
      <div className={clsx(styles["control"], invalid ? styles['invalid'] : null)}>
        <input disabled={loading} type="file" id="file" className={styles["field"]} onChange={handleChange} />
        <span>Select file</span>
        <span className={styles["icon"]}>
          <RemixIcon value="upload-2" />
          {loading ? <Spinner active /> : null}
        </span>
      </div>
      <label
        className={clsx(styles["target"], targetActive ? styles["target_active"] : null)}
        onDragEnter={handleActivateTarget}
        onDragOver={handleActivateTarget}
        onDragLeave={handleDeactivateTarget}
        onDrop={handleDeactivateTarget}
      >
        <input type="file" className={styles["drop-target-field"]} onChange={handleChange} onClick={handleTargetClick} />
        Drag and drop file here
      </label>
    </Paper>
  );
}
