import React, {useMemo} from 'react';
import clsx from 'clsx';

import styles from './Skeleton.module.css';

interface SkeletonProps {
  className?: string;
  active?: boolean;
  type?: 'box' | 'header' | 'line' | 'paragraph';
  width?: string;
  height?: string;
  margin?: number;
  size?: 'small' | 'big';
}

export default function Skeleton({className, type, active, width, height, margin, size}: SkeletonProps) {
  const classes = clsx(
    styles['container'],
    active ? styles['active'] : null,
    type ? styles[type] : null,
    size ? styles[size] : null,
    className
  );
  const style = useMemo(
    () => ({
      width: width ? width : undefined,
      height: height ? height : undefined,
      margin: margin ? margin * 17 + 'px' : undefined,
    }),
    [width, height, margin]
  );
  return (
    <span className={classes} style={style}>
      <span className={styles['former']}>ABC|</span>
    </span>
  );
}
