import {connect} from 'react-redux';

import {actions, selectors} from '@app/store/tasks';

import AssetAndLiability from '../components/AssetAndLiability';

export default connect(
  () => ({
  }), {    
  }  
)(AssetAndLiability);
