import React, {useCallback, useState} from "react";
import clsx from "clsx";

import {useNavigate} from "react-router-dom";

import {CustomerLoanAppraisal} from "@app/types";

import Money from "@components/atoms/Money";
import Button from "@components/atoms/Button";
import CheckBox from '@components/atoms/CheckBox';
import RemixIcon from "@components/atoms/RemixIcon";
import Title from "@components/atoms/Title";
import Loading from "@components/molecules/Loading";

import {LOGIN_URL} from "@features/Auth/components/LoginButton/LoginButton";

import styles from "./OfferTile.module.css";


type PreviewProps = Pick<CustomerLoanAppraisal, "name" | "netLoan" | "equity" | "ltgdv" | "ineterestRate" | "arrangementFee" | "exitFee" | "roC" | "totalRoE" | "leveredIRR" | "ltc">;
type FullProps = CustomerLoanAppraisal;

interface OfferTileProps {
  className?: string;
  loading?: boolean;
  preview?: PreviewProps;
  full?: FullProps;  
  canSelect?: boolean;
  selected?: boolean;
  canExpand?: boolean;
  onSelectedChange?: (props: FullProps) => void;
  onHeaderClick?: (props: FullProps) => void;
}

export default function OfferTile({className, loading, canExpand, preview, canSelect, full, selected, onSelectedChange, onHeaderClick}: OfferTileProps) {  
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const toggleExpand = useCallback(() => {
    if (!canExpand) window.location.href = LOGIN_URL;
    else setExpanded((value: boolean) => {
      return !value;
    });
  }, [setExpanded, canExpand, navigate]);
  const handleSelect = useCallback(() => {
    onSelectedChange && onSelectedChange(full!);
  }, [full]);
  const handleHeaderClick = useCallback(() => {
    full && onHeaderClick && onHeaderClick(full);
  }, [full, onHeaderClick]);
  const disableSelection = canSelect === false || !canExpand;  
  return (
    <li className={clsx(styles["container"], expanded ? styles["expanded"] : null, loading ? styles['loading'] : null, className)}>
      <div className={styles["header"]}>
        <RemixIcon className={styles.icon} color="secondary" size="small" value="bookmark-3" type="fill" />
        <Loading loading={loading}>
          <span className={clsx(onHeaderClick ? styles['header-title'] : null)} onClick={handleHeaderClick}>{preview?.name}</span>        
          <CheckBox className={styles.check} type="checkbox" checked={selected || false} onChange={handleSelect} disabled={disableSelection} />
        </Loading>
        {/*<RemixIcon className={styles.check} value="checkbox-blank" size="small" />*/}
      </div>
      <div className={styles.content}>
        <div className={styles.row}>
          <Loading loading={loading}>
            <span className={styles.caption}>Net loan</span>
            <Money value={preview?.netLoan} currency="£" integer />
          </Loading>
        </div>
        <div className={styles.row}>
          <Loading loading={loading}>
            <span className={styles.caption}>Equity required</span>
            <Money value={preview?.equity} currency="£" integer />
          </Loading>
        </div>
        <div className={styles.row}>
          <Loading loading={loading}>
            <span className={styles.caption}>LTGDV</span>
            <Money value={preview?.ltgdv} integer />%
          </Loading>
        </div>
        <div className={styles.row}>
          <Loading loading={loading}>
            <span className={styles.caption}>LTC</span>
            <Money value={preview?.ltc} integer />%
          </Loading>
        </div>
        <div className={styles.row}>
          <Loading loading={loading}>
            <span className={styles.caption}>Interest rate (pa)</span>
            <Money value={preview?.ineterestRate} />%
          </Loading>
        </div>
        <div className={styles.row}>
          <Loading loading={loading}>
            <span className={styles.caption}>Arr. fee</span>
            <Money value={preview?.arrangementFee} />%
          </Loading>
        </div>
        <div className={styles.row}>
          <Loading loading={loading}>
            <span className={styles.caption}>Exit fee</span>
            <Money value={preview?.exitFee} />%
          </Loading>
        </div>
        <div className={styles.row}>
          <Loading loading={loading}>
            <span className={styles.caption}>RoC</span>
            <Money value={preview?.roC} oneDigit />%
          </Loading>
        </div>
        <div className={styles.row}>
          <Loading loading={loading}>
            <span className={styles.caption}>Total RoE</span>
            <Money value={preview?.totalRoE} oneDigit />%
          </Loading>
        </div>
        <div className={styles.row}>
          <Loading loading={loading}>
            <span className={styles.caption}>Equity IRR</span>
            <Money value={preview?.leveredIRR} oneDigit />%
          </Loading>
        </div>
        <div className={styles.spacer} />
        <div className={styles["actions"]}>          
          <Button className={styles["state-action"]} caption="Show details" margin={0} onClick={toggleExpand} skeleton={loading} />          
        </div>
      </div>
      {full ? <div className={styles["full-content"]}>
        <Title className={styles["full-title"]} level={3}>
          Indicative terms
        </Title>
        <div className={styles["full-group"]}>
          <div className={styles["group-values"]}>
            <div className={styles.row}>
              <span className={styles.caption}>LTGDV</span>
              <Money value={full.ltgdv} integer />%
            </div>
            <div className={styles.row}>
              <span className={styles.caption}>LTC</span>
              <Money value={full.ltc} integer />%
            </div>
            <div className={styles.row}>
              <span className={styles.caption}>Interest rate (pa)</span>
              <Money value={full.ineterestRate} />%
            </div>
            <div className={styles.row}>
              <span className={styles.caption}>Arrangement fee</span>
              <Money value={full.arrangementFee} />%
            </div>
            <div className={styles.row}>
              <span className={styles.caption}>Exit fee</span>
              <Money value={full.exitFee} />%
            </div>
          </div>
          <div className={styles["group-values"]}>
            <div className={styles.row}>
              <span className={styles.caption}>Gross facility</span>
              <Money value={full.grossFacility} currency="£" integer />
            </div>
            <div className={styles.row}>
              <span className={styles.caption}>Finance cost</span>
              <Money value={full.financeCost} currency="£" integer />
            </div>
            <div className={styles.row}>
              <span className={styles.caption}>Interest cost</span>
              <Money value={full.interestCost} currency="£" integer />
            </div>
            <div className={styles.row}>
              <span className={styles.caption}>Arrangement fee</span>
              <Money value={full.arrangementFeeCost} currency="£" integer />
            </div>
            <div className={styles.row}>
              <span className={styles.caption}>Exit fee</span>
              <Money value={full.exitFeeCost} currency="£" integer />
            </div>
          </div>
        </div>
        <Title className={styles["full-title"]} level={3}>
          Sources and uses
        </Title>
        <div className={styles["full-group"]}>
          <div className={styles["group-values"]}>
            <Title className={styles["full-subtitle"]} level={4}>Sources of fund</Title>
            <div className={styles.row}>
              <span className={styles.caption}>Gross facility</span>
              <Money value={full.grossFacility} currency="£" integer />
            </div>
            <div className={styles.row}>
              <span className={styles.caption}>Equity</span>
              <Money value={full.equity} currency="£" integer />
            </div>
            <div className={styles.row}>
              <br/>
            </div>
            <div className={styles.row + ' ' + styles.total}>
              <span className={styles.caption}>Total Sources</span>
              <Money value={full.totalUses} currency="£" integer />
            </div>
          </div>
          <div className={styles["group-values"]}>
            <Title className={styles["full-subtitle"]} level={4}>Uses of fund</Title>
            <div className={styles.row}>
              <span className={styles.caption}>Development cost</span>
              <Money value={full.totalDevelopmentCost} currency="£" integer />
            </div>
            <div className={styles.row}>
              <span className={styles.caption}>Interest cost</span>
              <Money value={full.interestCost} currency="£" integer />
            </div>
            <div className={styles.row}>
              <span className={styles.caption}>Arrangement Fee</span>
              <Money value={full.arrangementFeeCost} currency="£" integer />
            </div>
            <div className={styles.row + ' ' + styles.total}>
              <span className={styles.caption}>Total Uses</span>
              <Money value={full.totalUses} currency="£" integer />
            </div>
          </div>
        </div>
        <Title className={styles["full-title"]} level={3}>
          Summary appraisal
        </Title>
        <div className={styles["full-group"]}>
          <div className={styles["group-values-big"]}>
            <div className={styles.row}>
              <span className={styles.caption}>Estimated Profit (unlevered)</span>
              <Money value={full.estimatedProfitUnlevered} currency="£" integer />
            </div>
            <div className={styles.row}>
              <span className={styles.caption}>Finance cost</span>
              <Money value={full.financeCost} currency="£" integer />
            </div>
            <div className={styles.row + ' ' + styles.total}>
              <span className={styles.caption}>Estimated Profit (levered)</span>
              <Money value={full.netProfit} currency="£" integer />
            </div>
          </div>
          <div className={styles['group-values-small']}>
            <div className={styles.row}>
              <span className={styles.caption}>RoC</span>
              <Money value={full.roC} oneDigit />%
            </div>
            <div className={styles.row}>
              <span className={styles.caption}>Total RoE</span>
              <Money value={full.totalRoE} oneDigit />%
            </div>
            <div className={styles.row}>
              <span className={styles.caption}>Equity IRR</span>
              <Money value={full.leveredIRR} oneDigit />%
            </div>
          </div>
        </div>
        <div className={styles["full-actions"]}>
          <Button className={styles["state-action"]} caption="Show less" margin={0} onClick={toggleExpand} />
        </div>
      </div> : null}
    </li>
  );
}
