import {connect} from 'react-redux';

import {actions, selectors, RootState} from '@app/store/api';
import Layout from '../components/Layout';

import Header from './Header';
import Footer from './Footer';

export default connect(
  (state: RootState) => ({
    hasErrors: selectors.hasErrors(state),
    errors: selectors.errors(state),
    Header,
    Footer,
  }), {
    onResetErrors: actions.reset,
  }
)(Layout);
