import {connect} from 'react-redux';

import {actions, selectors, RootState} from '@app/store/projects';

import Projects from "../components/Projects";

export default connect(
  (state: RootState) => ({
    loading: selectors.list.isLoading(state),
    items: selectors.list.value(state),
    baseUrl: '/projects/',
  }), {
    onOpen: actions.list.open,
    onClose: actions.list.close,
  }
)(Projects);
