import React, {useState} from 'react';
import clsx from 'clsx';

import styles from './Rights.module.css';

interface RightsProps {
  className?: string;
}

export default function Rights({className}: RightsProps) {
  const [year] = useState((new Date().getFullYear()));
  return (
    <div className={clsx(styles['container'], className)}>
      {year} fundkeet. All rights reserved
    </div>
  )
}