import React, {HTMLAttributes} from 'react';
import clsx from 'clsx';

import styles from './RemixIcon.module.css';

import 'remixicon/fonts/remixicon.css';

interface RemixIconProps extends HTMLAttributes<HTMLSpanElement> {
  className?: string;
  value?: string;
  type?: 'line' | 'fill' | 'blank';
  size?: 'small' | 'medium' | 'large' | 'giant';
  color?: 'primary' | 'secondary' | 'error' | 'filled-error';
  margin?: number;
}

export default function RemixIcon({className, value, type = 'line', size = 'medium', margin = 0, color, ...props}: RemixIconProps) {
  const classIcon = clsx('ri-' + value + '-' + type);
  const classes = clsx(
    classIcon,
    styles['container'],
    size ? styles['size_' + size] : null,
    color ? styles['color_' + color] : null,
    margin ? styles['margin_' + margin.toString()] : null,
    className
  );
  return <span className={classes} {...props} />;
}
