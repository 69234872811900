import {createSlice} from '@reduxjs/toolkit';

import {ProjectTask, ProjectTasksResponse} from '@app/types';
import {getList, getItem, save, complete, cancel, share} from './thunks';

interface InitialStateList {
  loading: boolean;
  value?: ProjectTasksResponse;
  error?: unknown;
}

interface InititalStateTask {
  loading: boolean;
  value?: ProjectTask;
  error?: unknown;
  submitting?: boolean;  
  saving: boolean;
  completing: boolean;
  completed: boolean;
  cancelling: boolean;
  sharing: boolean;
}

const initialStateList = {
  loading: false,  
} as InitialStateList;

const initialStateTask = {
  loading: false,
  submitting: false,
  saving: false,
  completing: false,
  completed: false,
  cancelling: false,
  sharing: false,
} as InititalStateTask;

export const list = createSlice({
  name: '@@tasks/list',
  initialState: initialStateList,
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.value = undefined;
      state.error = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getList.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(getList.fulfilled, (state, {payload}) => {
        state.loading = false;
        state.value = payload;
        state.error = undefined;
      })
      .addCase(getList.rejected, (state, {payload}) => {
        state.loading = false;
        state.value = undefined;
        state.error = payload;
      });
  },
});

export const item = createSlice({
  name: '@@tasks/item',
  initialState: initialStateTask,
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.submitting = false;
      state.completing = false;
      state.completed = false;
      state.value = undefined;
      state.error = undefined;
    },
    resetComplete: (state) => {
      state.completing = false;
      state.completed = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(getItem.fulfilled, (state, {payload}) => {
        state.loading = false;
        state.value = payload;
        state.error = undefined;
      })
      .addCase(getItem.rejected, (state, {payload}) => {
        state.loading = false;        
        state.value = undefined;
        state.error = payload;
      })
      .addCase(save.pending, (state) => {
        state.saving = true;
      })
      .addCase(save.fulfilled, (state) => {
        state.saving = false;
      })
      .addCase(save.rejected, (state) => {
        state.saving = false;
      })
      .addCase(complete.pending, (state) => {
        state.completing = true;
        state.completed = false;
      })
      .addCase(complete.fulfilled, (state) => {
        state.completing = false;
        state.completed = true;
      })
      .addCase(complete.rejected, (state) => {
        state.completing = false;
        state.completed = true;
      })
      .addCase(cancel.pending, (state) => {
        state.cancelling = true;
      })
      .addCase(cancel.fulfilled, (state) => {
        state.cancelling = false;
      })
      .addCase(cancel.rejected, (state) => {
        state.cancelling = false;
      })
      .addCase(share.pending, (state) => {
        state.sharing = true;
      })
      .addCase(share.fulfilled, (state) => {
        state.sharing = false;
      })
      .addCase(share.rejected, (state) => {
        state.sharing = false;
      });
  }
})