import React, {ReactNode} from 'react';
import clsx from 'clsx';

import Paper from '@components/atoms/Paper';

import styles from './Total.module.css';

type Text = string | ReactNode;

interface TotalProps {
  className?: string;
  padding?: number;
  margin?: number;
  caption?: Text;
  values?: Text[];
  valuesAlign?: 'right';
}

export default function Total({className, padding, margin = 1, caption, values, valuesAlign}: TotalProps) {
  const classes = clsx(
    styles['container'],
    padding ? styles['padding-' + padding] : null,
    margin ? styles['margin-' + margin] : null,
    className
  );
  return (
    <Paper className={classes} size="large">
      <span className={styles['caption']}>{caption}</span>
      {values
        ? values.map((value, i) => (
            <span className={clsx(styles['value'], valuesAlign === 'right' ? styles['values-right'] : null)} key={i}>
              {value}
            </span>
          ))
        : null}
    </Paper>
  );
}
