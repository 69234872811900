import React, {ReactElement} from 'react';
import clsx from 'clsx';
import DashboardTagLine from '@components/molecules/DashboardTagLine';
import type {Tag, TagState} from '@components/molecules/DashboardTagLine';

import styles from './DashboardTagLines.module.css';

export {Tag, TagState};

export interface TagLine {
  tags: Tag[];
  actions?: Tag[];
  url?: string;
}

interface DashboardTagLinesProps {
  className?: string;
  wrap?: boolean;
  loading?: boolean;
  value?: TagLine[];
}

export default function DashboardTagLines({className, wrap, value}: DashboardTagLinesProps) {
  return (
    <ul className={clsx(styles['container'], wrap ? styles['wrap'] : null, className)}>
      {value ? value.map((line, i) => (
        <DashboardTagLine className={styles["line"]} {...line} key={i} />
      )) : null}
    </ul>
  );
};
