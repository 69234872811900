import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';

import {useLocation, useNavigate} from 'react-router-dom';

import actions from '@app/store/actions';
import selectors from '@app/store/selectors';
import {RootState} from '@app/store';
import {storage} from '@utils/storage';


import Private from './Private';

interface RootProps {
  isLoading: boolean;
  isLoaded: boolean;
  onLoad: () => void;
}

const WAIT_STYLE = {
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  left: '0',
  position: "absolute",
  top: '0',
  width: '100%',
}

export function Root({onLoad, isLoaded}: RootProps) {
  const refTimer = useRef<ReturnType<typeof setInterval> | null>();
  const refPathname = useRef<string>();
  const [waitingTime, setWaitingTime] = useState(0);  
  useEffect(() => {
    const start = new Date().getTime();
    if (refTimer.current && isLoaded) {
      console.log('root timer cleared');
      clearInterval(refTimer.current);
      refTimer.current = null;
    } else 
    if (!refTimer.current) {
      console.log('root timer set');
      refTimer.current = setInterval(() => {
        setWaitingTime(new Date().getTime() - start);
      }, 1000);
    }
  }, [refTimer, isLoaded, setWaitingTime]);
  const navigate = useNavigate();
  useEffect(() => {
    const url = storage.lastUrl;
    storage.lastUrl = undefined;    
    if (url) {
      navigate(url, {replace: true});
    }
  }, [navigate]);
  useEffect(() => {
    onLoad();
  }, [onLoad]);
  const location = useLocation();
  useEffect(() => {    
    if (!location.hash && refPathname.current !== location.pathname) {
      window.scroll({left: 0, top: 0, behavior: 'smooth'});
    }
    refPathname.current = location.pathname;
  }, [location, refPathname]);
  if (!isLoaded) {
    if (waitingTime < 1000) return null;
    return (
      //@ts-ignore
      <div style={WAIT_STYLE}>Waiting for the super-fast Microsoft Engine {(waitingTime / 1000).toFixed(2)}s</div>
    )
  }
  return <Private />;  
}

export default connect(
  (state: RootState) => ({
    isLoading: selectors.session.isLoading(state),
    isLoaded: selectors.session.isLoaded(state),
  }),
  {
    onLoad: actions.session.load,
  }
)(Root);
