import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';

import Skeleton from '../Skeleton';

import styles from './SummaryKey.module.css';

interface SummaryKeyProps {
  className?: string;
  color?: 'primary';
  loading?: boolean;
}

export default function SummaryKey({className, loading, color, children}: PropsWithChildren<SummaryKeyProps>) {
  const classes = clsx(
    styles['key'],
    color ? styles['color_' + color] : null,
    className,
  );
  return (
    <dt className={classes}>{loading ? <Skeleton active /> : children}</dt>
  )
}