import React, {ReactElement} from 'react';
import clsx from 'clsx';
import {Link} from 'react-router-dom';

import Tag from './Tag';
import type {TagState} from './Tag';

import styles from './DashboardTagLine.module.css';
import RemixIcon from '@components/atoms/RemixIcon';

export interface Tag {
  state?: TagState;
  node: ReactElement;
};

export {TagState};

interface DashboardTagLineProps {
  className?: string;
  tags: Tag[];
  actions?: Tag[];
  url?: string;
  loading?: boolean;
}

export default function DashboardTagLine({className, tags, actions, url, loading}: DashboardTagLineProps) {
  return (
    <li className={clsx(styles['container'], className)}>
      <div className={styles['tags']}>
        {tags.map((item, i) => (
          <Tag state={item.state} key={i}>
            {item.node}
          </Tag>
        ))}        
      </div>
      <div className={styles['actions']}>
        {actions ? actions.map((item, i) => (
          <Tag key={i}>
            {item.node}
          </Tag>
        )) : null}
        {url ? <Link className={styles["link"]} to={url}><RemixIcon value='arrow-right-s' /></Link> : null}
      </div>
    </li>
  );
};
