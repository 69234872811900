import Api from './api';
import {CustomerLoanAppraisal, SetOfferNameRequest} from '@app/types';

export default class Offers {
  constructor(private readonly api: Api) {}
  get(appraisalId: string): Promise<CustomerLoanAppraisal> {
    return this.api.get(`/Offers/${appraisalId}`);
  }
  submit(appraisalId: string): Promise<CustomerLoanAppraisal> {    
    return this.api.put(`/Offers/${appraisalId}/submit`);
  }
  reject(appraisalId: string): Promise<CustomerLoanAppraisal> {
    return this.api.put(`/Offers/${appraisalId}/reject`);
  }
  rename(request: SetOfferNameRequest): Promise<CustomerLoanAppraisal> {
    const {appraisalId, name} = request;
    return this.api.put(`/Offers/${appraisalId}`, {name});
  }
};
