import {ApiErrorData} from '../../types';
import RestApi, {RestApiError, Headers, GetAccessTokenProc} from '../utils/rest-api';

import type {RestApiResponse} from '../utils/rest-api';

export type Handler<Response> = () => Promise<Response>;
export type Params = Record<string, any>;
export type Event<T = void> = (params: T) => unknown;

const UNKNOWN_ERROR = 'Unknown error';

// @todo: find better place
export const isApiErrorData = (data: any): data is ApiErrorData => !!data.message || !!data.title;

export class ApiError extends Error {
  constructor(readonly data: ApiErrorData) {
    super(data.message || data.title || UNKNOWN_ERROR);
  }
  static isApiError(e: unknown): e is ApiError {
    return e instanceof ApiError;
  }
}

type ServerError = Record<string, unknown> | string;

function convertErrorData(data: ServerError, description?: string): ApiErrorData {
  if (typeof data === 'undefined') {
    return {
      message: 'Something went wrong' + (description ? ' (' + description + ')' : ''),
    };
  }
  if (typeof data === 'string') {
    return {
      message: data,
    } as ApiErrorData;
  } else return data as ApiErrorData;
}

export default class Api {
  
  public getUrlWithParams(url: string, params: Params) {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach((key) => {
      if (params[key] != null) searchParams.append(key, params[key].toString());
    });
    return url + '?' + searchParams.toString();
  }

  private rest: RestApi;
  private _onAuthError?: Event;
  constructor(baseUrl: string) {
    this.rest = new RestApi(baseUrl);
  }
  get onGetAccessToken() {
    return this.rest.onGetAccessToken;
  }
  set onGetAccessToken(value: GetAccessTokenProc | undefined) {
    this.rest.onGetAccessToken = value;
  }  
  get onAuthError() {
    return this._onAuthError;
  }
  set onAuthError(value: Event | undefined) {
    this._onAuthError = value;
  }
  get<Response extends RestApiResponse>(url: string, headers?: Headers): Promise<Response> {
    return this.wrapRequest(() => this.rest.get(url, headers));
  }
  post<Request, Response extends RestApiResponse>(url: string, data?: Request, headers?: Headers): Promise<Response> {
    return this.wrapRequest(() => this.rest.post(url, data, headers));
  }
  put<Request, Response extends RestApiResponse>(url: string, data?: Request, headers?: Headers): Promise<Response> {
    return this.wrapRequest(() => this.rest.put(url, data, headers));
  }
  patch<Request, Response extends RestApiResponse>(url: string, data?: Request, headers?: Headers): Promise<Response> {
    return this.wrapRequest(() => this.rest.patch(url, data, headers));
  }
  delete<Request, Response extends RestApiResponse>(url: string, data?: Request, headers?: Headers): Promise<Response> {
    return this.wrapRequest(() => this.rest.delete(url, data, headers));
  }
  private async wrapRequest<Response>(handler: Handler<Response>): Promise<Response> {
    try {
      return await handler();
    } catch (e) {
      console.log('ERR', e);            
      if (RestApiError.isRestApiError(e)) {        
        const error = e as RestApiError;        
        console.log('status', error.status);
        console.log('message', error.message);
        console.log('data', error.data);
        if (error.status === 401 && this.onAuthError) this.onAuthError();
        throw new ApiError(convertErrorData(error.data, error.message + ' ' + error.status));
      }
      throw e;
    }
  }
}
