import base64 from 'uuid-base64';

const defaultByteUtils = [' kb', ' mb', ' gb', ' tb', 'pb', 'eb', 'zb', 'yb'];

export function formatAmount(amount?: number, currency?: string, integer?: boolean, oneDigit?: boolean): string | null {
  if (amount === null || typeof amount === 'undefined') return null;  
  if (integer) amount = Math.round(amount);
  const c = currency ? currency.toUpperCase() : '';
  if (amount === 0) return `${c}0`;
  const absAmount = amount >= 0 ? amount : -amount;
  const d = Math.floor(absAmount);
  const n = (
    Array.from(d.toString())
      .reverse()
      .join('')
      .match(/\d{1,3}/g) || []
  )
    .join(',')
    .split('')
    .reverse()
    .join('');
  const sign = amount >= 0 ? '' : '-';
  const p = Math.round((absAmount - d) * 100);
  let result = `${c}${sign}${n}`;  
  if (!integer) result += `.${p < 10 ? '0' : ''}${p}`;
  if (oneDigit) result = result.slice(0, result.length-1);
  return result;
  //.${p < 10 ? '0' : ''}${p}`;
}

export function formatCardNumber(number: number, {short}: {short?: boolean} = {}) {
  const str = String(number);
  return (short ? '' : 'xxxx xxxx xxxx ') + str.substring(-4);
}

export function urlEncodeGuid(value?: string) {
  if (!value) return '';
  const result = base64.encode(value);
  return result.replace(/\./gmi, '*');
}

export function urlDecodeGuid(value?: string) {
  if (!value) return '';
  value = value.replace(/\*/gmi, '.');
  return base64.decode(value);
}

export function dateToString(value?: string) {
  if (!value) return '';
  return value.split('T')[0].split('-').reverse().join('/');
}

export function stringToDate(value?: string) {
  if (!value) return undefined;
  return value.split('/').reverse().join('-') + 'T00:00:00Z';
}

export function timeToString(value?: string) {
  if (!value) return undefined;
  return value.split('T')[1].split('.')[0].split(':').slice(0, 2).join(':');
}

export function dateTimeToString(value?: string) {
  if (!value) return '';
  return (dateToString(value) + ' ' + timeToString(value)).trim();
}


// const uriChars = `AaBbCcDdEeFfGgHhIiJjLlMmNnOoPpQqKkRrSsTtUuVvWwXxYyZz0123456789_!@$^*.,-+`;
// '5703c2c7-48f8-41ef-89b5-00e491e6e641'
// '5703c2c748f841ef89b500e491e6e641'
// '57 03 c2 c7 48 f8 41 ef 89 b5 00 e4 91 e6 e6 41'



/*export const formatValidThru = (valid: string) => valid ? moment(valid).format('MM/YY') : null
export const formatDate = (date: string) => moment(date).format('DD.MM.YYYY')
export const formatMessageDate = (date: string) => moment(date).format('DD/MM/YYYY')
export const formatDateTimeFromToday = (date: string) => {
  date = moment(date)
  if (date.isSame(new Date(), 'day')) return date.format('HH:mm')
  return date.format('DD/MM/YYYY HH:mm')
}
export const formatDateToISO = (date: string) => moment(date).toISOString().substring(0, 10)
export const formatFileSize = (size: number, byteUnits = defaultByteUtils) => {
  let i = -1
  do {
    size = size / 1024
    i++
  } while (size > 1024)

  return Math.max(size, 0.1).toFixed(1) + byteUnits[i]
}

export {
  formatPhoneNumberIntl
}
*/
