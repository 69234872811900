import React, {ReactElement, useCallback, useEffect, useMemo, useState} from "react";
import clsx from "clsx";
import {useParams} from "react-router-dom";

import {LoanOfferSearchResponse, CustomerLoanAppraisal, IdSet, GetOffersRequest, LoanOfferSearchSorting} from "@app/types";
import Button from "@components/atoms/Button";
import Dialog from "@components/atoms/Dialog";
import Offer from "@components/organisms/OfferTile";
import LenderPopup from "@features/Search/components/LenderPopup";
import PageAction from "@components/molecules/PageAction";
import NotFound from "../../controllers/NotFound";

import {LoginButton, RegisterButton} from "@features/Auth";

import styles from "./Offers.module.css";

interface OffersProps {
  loading: boolean;
  onOpen: (query: GetOffersRequest) => void;
  value?: LoanOfferSearchResponse;
  loggedIn: boolean;
  OffersActions: (props: any) => ReactElement | null;
}

const DESCRIPTION =
  "The Royal Bank of Scotland is one of the largest commercial banks in Scotland. It was founded in 1727. It is one of three Scottish banks that have the right to issue banknotes.";

const DESC_UNAUTH = "Please sign-in or sign-up";

export default function Offers({loading, value, onOpen, loggedIn, OffersActions}: OffersProps) {
  const params = useParams();
  const queryId = params.queryId;
  const [selection, setSelection] = useState<IdSet>({});
  const [openOffer, setOpenOffer] = useState<CustomerLoanAppraisal | null>(null);
  const [sorting, setSorting] = useState<LoanOfferSearchSorting>("Cheapest");
  //
  useEffect(() => {
    queryId && onOpen && onOpen({queryId, sorting});
  }, [queryId, sorting, onOpen]);
  //
  useEffect(() => {
    setSelection({} as IdSet);
  }, [value, setSelection]);
  //
  const offersQueryId = useMemo(() => {
    if (value && value.offers && value.offers.length) {
      return value.offers[0].queryId;
    }
    return undefined;
  }, [value]);
  //
  const handleSelectionChange = useCallback(
    (item: CustomerLoanAppraisal) => {
      setSelection(selection => {
        const result = {
          ...selection,
        };
        if (result[item.offerId]) delete result[item.offerId];
        else result[item.offerId] = true;
        return result;
      });
    },
    [setSelection]
  );
  const handleSelectTop5 = useCallback(() => {
    const items = value && value.offers;
    if (items && items.length) {
      setSelection(items.slice(0, 5).reduce((result, item) => ((result[item.offerId] = true), result), {} as IdSet));
    }
  }, [setSelection, value]);
  const handleHeaderClick = useCallback(
    (item: CustomerLoanAppraisal) => {
      setOpenOffer(item);
    },
    [setOpenOffer]
  );
  const handleDialogClose = useCallback(() => {
    setOpenOffer(null);
  }, [setOpenOffer]);
  const isEmpty = !loading && (!value || !value.count || !value.offers.length);
  const selectedLength = Object.keys(selection).length;
  const offers = useMemo(() => {
    if (loading) return [undefined, undefined, undefined, undefined];
    else return value?.offers;
  }, [value, loading]);  
  return (
    <>
      {isEmpty ? <NotFound /> : null}
      {!loggedIn ? <PageAction title="New to fundkeet?" subTitle="To view and save your offers, please sign up with us" action="Sign up" Action={LoginButton} /> : null}
      {!isEmpty && loggedIn ? <OffersActions
        loading={loading && !value}
        reloading={loading && !!value}
        selection={selection}
        queryId={offersQueryId}
        sorting={sorting}
        onSortingChange={setSorting}
        onSelectTop5={handleSelectTop5}
      /> : null}
      {!isEmpty ? <ul className={styles.container}>
        {offers
          ? offers.map((item, i) => (
              <Offer
                className={styles["tile"]}
                loading={loading}
                canSelect={item ? selection[item.offerId] || selectedLength < 5 : false}
                preview={item}
                full={item}
                selected={item ? selection[item.offerId] : false}
                canExpand={loggedIn}
                onSelectedChange={handleSelectionChange}
                onHeaderClick={handleHeaderClick}
                key={i}
              />
            ))
          : null}
      </ul> : null}
      {loggedIn ? <LenderPopup value={openOffer?.lender} onClose={handleDialogClose}>
        {!loggedIn ? <div className={styles["lender__actions"]}>
          <LoginButton className={styles["lender__action"]} caption="Sign-in" color="primary" />
          <RegisterButton className={styles["lender__action"]} caption="Sign-up" color="secondary" />
        </div> : null}
      </LenderPopup> : null}
    </>
  );
}
