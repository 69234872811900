import React from 'react';
import clsx from 'clsx';

import styles from './AreaUnits.module.css';

interface AreaUnitsProps {
  className?: string;
  value?: string;
  style?: 'uppercase' | 'capitalize' | 'lowercase';
}

export default function AreaUnits({className, value, style = 'lowercase'}: AreaUnitsProps) {
  const classes = clsx(style ? styles[style] : null, className);
  return (
    <span className={classes}>
      {value || '?'}
      <sup className={styles['sup']}>2</sup>
    </span>
  );
}
