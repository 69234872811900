import {createSlice, Action, ThunkAction, Draft, Selector} from '@reduxjs/toolkit';
import {createApiThunk} from './api-thunk';
import {scopeSelector, LocalSelector} from './scope';

interface InitialState<Request, Response> {
  open: boolean;
  query?: Request,
  loading: boolean,
  value?: Response,
  error?: unknown,
};

type AsyncHandler<Request extends any, Response extends any, ThunkAPI extends any = void> = (arg: Request, thunkApi: ThunkAPI) => Promise<Response>;
type GetResponse<T> = T extends AsyncHandler<any, infer R, any> ? R : void;
type GetRequest<T extends AsyncHandler<any, any, any>> = Parameters<T>[0];

export function createQuery<
  Handler extends AsyncHandler<any, any, any>,
  Scope extends string,
  RootState extends {},
  Request = GetRequest<Handler>,
  Response = GetResponse<Handler>,
>(scope: Scope, handler: Handler, getLocalState?: LocalSelector<RootState, InitialState<Request, Response>>) {
  type SliceState = InitialState<Request, Response>;
  const fetch = createApiThunk<Request, Response, Scope>(scope, handler);
  const initialState = {
    open: false,
    loading: true,
  } as SliceState;

  const refresh: ThunkAction<void, SliceState, void, Action<`${Scope}/refresh`>>
    = function (dispatch, getState) {
      const {query} = getState();
      if (query) dispatch(fetch(query));
    };

  const slice = createSlice({
    name: scope,
    initialState,
    reducers: {
      close: (state) => {
        state.open = false;
        state.loading = false;
        state.value = undefined;
        state.error = undefined;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetch.pending, (state, {payload}) => {
          state.open = true;
          state.loading = true;
          state.query = payload;
        })
        .addCase(fetch.fulfilled, (state, {payload}) => {
          if (state.open) {
            state.loading = false;
            state.value = payload as Draft<Response>;
            state.error = undefined;
          }
        })
        .addCase(fetch.rejected, (state, {payload}) => {
          if (state.open) {
            state.loading = false;
            state.value = undefined;
            state.error = payload;
          }
        });
    }
  });
  const selectors = {
    isLoading: (state: SliceState) => state.loading,
    isOpen: (state: SliceState) => state.open,
    value: (state: SliceState) => state.value,
    error: (state: SliceState) => state.error,
  };
  return {
    actions: {
      open: fetch,
      ...slice.actions,
      refresh,
    },
    reducer: slice.reducer,
    selectors: {
      isLoading: getLocalState ? scopeSelector(selectors.isLoading, getLocalState) : selectors.isLoading,
      isOpen: getLocalState ? scopeSelector(selectors.isOpen, getLocalState) : selectors.isOpen,
      value: getLocalState ? scopeSelector(selectors.value, getLocalState) : selectors.value,
      error: getLocalState ? scopeSelector(selectors.error, getLocalState) : selectors.error,
    },
  };
};