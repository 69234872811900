import React, {useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {connect} from 'react-redux';

import {selectors as projects, RootState as RootStateProjects} from '@app/store/projects';
import {selectors as tasks, RootState as RootStateTasks} from '@app/store/tasks';

import ContentTitle from '@features/Layout/components/ContentTitle';

import {Task} from '@features/Dashboard';
import { Project, ProjectTask } from '@app/types';

interface TaskViewProps {
  loading?: boolean;
  project?: Project;
  tasks?: ProjectTask[];
}

export function TaskView({loading, project, tasks}: TaskViewProps) {
  const {projectId, taskId} = useParams();  
  if (!taskId) return null;
  return (
    <>      
      <Task projectId={projectId} taskId={taskId} />
    </>
  );
};

export default connect(
  (state: unknown) => ({
    loading: projects.item.isLoading(state as RootStateProjects) || tasks.list.loading(state as RootStateTasks),
    project: projects.item.value(state as RootStateProjects),
    tasks: tasks.list.value(state as RootStateTasks),
  })
)(TaskView);
