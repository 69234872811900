import {createApiThunk, createQuery} from "@app/lib/thunks";
import {projects} from '@app/api/rest';
import { Project, SetProjectNameRequest } from "@app/types";

export const list = createQuery(
  '@@projects/list',
  projects.getList.bind(projects),  
);

export const item = createQuery(
  '@@projects/item',
  projects.get.bind(projects),
);

export const archive = createApiThunk('@@projects/item-archive', async (projectId: string, thunkApi) => {
  const result = await projects.archive(projectId);
  thunkApi.dispatch(item.actions.open.fulfilled(result, '@@projects/item', projectId));
});

export const setName = createApiThunk('@@projects/item-name', async (request: SetProjectNameRequest, thunkApi) => {
  const result = await projects.setName(request);
  thunkApi.dispatch(item.actions.open.fulfilled(result, '@@projects/item', '123'));
});
