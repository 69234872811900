import {selectors as auth} from './auth';
import {selectors as contacts} from './contacts';
import {selectors as history} from './history';
import {selectors as offers} from './offers';
import {selectors as search} from './search';
import {selectors as session} from './session';
import {selectors as tasks} from './tasks';

export default {
  auth,
  contacts,
  history,
  offers,
  search,
  session,
  tasks,
};
