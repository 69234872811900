import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {User} from '../../types';
import {load} from './thunks';

export interface InitialState {
  loading: boolean;
  loaded: boolean;
  value?: User;
  error?: unknown;
}

const initialState = {
  loading: false,
  loaded: false,
} as InitialState;

const slice = createSlice({
  name: '@@session',
  initialState,
  reducers: {
    set: (state, {payload}: PayloadAction<User>) => {
      state.value = payload;
      state.loading = false;
      state.loaded = true;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(load.pending, (state) => {
        state.loading = true;
      })
      .addCase(load.fulfilled, (state, {payload}) => {
        // make ts happy
        state.value = payload ? payload : undefined;
        state.loading = false;
        state.loaded = true;
        state.error = undefined;
      })
      .addCase(load.rejected, (state, {payload}) => {
        state.value = undefined;
        state.error = payload;
        state.loading = false;
        state.loaded = true;
      });
  },
});

export const actions = {
  ...slice.actions,
  load,
};
export const reducer = slice.reducer;
