import React from 'react';
import {connect} from 'react-redux';
import {selectors, RootState} from '@app/store/session';
import ContentTitle from '@features/Layout/components/ContentTitle';
import {Outlet, useLocation, useParams} from 'react-router-dom';
import {Offers} from '@features/Search';

interface OffersViewProps {
  loggedIn: boolean;
}

const TITLES = {
  true: 'Compare, assess and select the best offers ',
  false: 'Your offers are waiting for you',
};

const SEARCH = 'Search';
const BREADCRUMB = 'Offers list';

export function OffersView({loggedIn}: OffersViewProps) {
  const title = loggedIn ? TITLES.true : TITLES.false;  
  const {pathname} = useLocation();  
  const {queryId} = useParams();  
  return (
    <>
      <ContentTitle title={title} breadcrumb={{caption: SEARCH, url: `/search/${queryId}/step-7/`}}  />
      <ContentTitle title={title} breadcrumb={{caption: BREADCRUMB, url: pathname}}  />
      <Offers />
    </>
  );
}

export default connect(
  (state: RootState) => ({
    loggedIn: selectors.isLoggedIn(state),
  })
)(OffersView);
