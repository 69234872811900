import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Dialog from '@components/atoms/Dialog';
import Question from '@components/organisms/Question';

interface UseConfirmProps {
  caption?: string;
  question?: string;
  action?: string;  
  processing?: boolean;
  onAction: () => unknown;  
}

export default function useConfirm({caption, question, action, processing, onAction}: UseConfirmProps) {
  const [active, setActive] = useState(false);
  const open = useCallback(() => {    
    setActive(true);
  }, [setActive]);
  const handleClose = useCallback(() => {
    setActive(false);
  }, [setActive]);
  useEffect(() => {
    if (processing === false) handleClose();
  }, [processing, handleClose]);  
  const dialog = useMemo(() => (
    <Dialog open={active} setter={setActive}>
      <Question loading={processing} title={caption} text={question} action={action || 'Yes'} onAction={onAction} onCancel={handleClose} />
    </Dialog>
  ), [caption, question, action, onAction, active, processing, handleClose, setActive]);
  return {
    open, 
    dialog,
  };
};
