import {connect} from 'react-redux';

import {actions, selectors, RootState} from '@app/store/search';

import Form from '../components/Form';

export default connect(
  (state: RootState) => ({
    queryId: selectors.current.queryId(state),
    loading: selectors.current.loading(state),
    posting: selectors.current.posting(state),
    value: selectors.current.value(state),
  }),
  {
    onSubmit: actions.current.post,    
  }
)(Form);
