import React, {useEffect, useMemo} from 'react';
import ProjectTiles from '@components/organisms/ProjectTiles';
import ContentTitle from '@features/Layout/components/ContentTitle';

import { Project } from '@app/types';

import styles from './Projects.module.css';

interface ProjectsProps {
  loading?: boolean;
  items?: Project[];
  baseUrl: string;
  onOpen: () => unknown;
  onClose: () => unknown;
}

export default function Projects({loading, items, baseUrl, onOpen, onClose}: ProjectsProps) {
  useEffect(() => {
    onOpen();
    return () => {
      onClose();
    };
  }, [onOpen, onClose]);
  const projects = useMemo(() => {
    if (!items) return items;
    return items.map(item => ({
      id: item.id,
      name: item.name,
      createdOn: item.createdOn,
      amount: item.loanQuery.netLoan,
      type: item.loanQuery.proposedDevelopment,
      status: item.status,
    }));
  }, [items]);
  return (    
    <>            
      <ContentTitle title="Dashboard" breadcrumb={{caption: "Dashboard", url: `/`}} loading={loading || false} />
      <ContentTitle title="Projects" breadcrumb={{caption: "Projects", url: `/projects/`}} loading={loading || false} />
      <ProjectTiles className={styles['list']} loading={loading} items={projects} baseUrl={baseUrl} colCount={4} />    
    </>
  )
}