import React from 'react';
import base64 from 'uuid-base64';
import {useParams, Navigate} from 'react-router-dom';

function getParams(v: string) {
  const page = v.substring(0, 1);
  const id = base64.decode(v.substring(1));
  return {
    id,
    page,
  };
}

export default function Open() {
  const params = useParams();
  if (params.id === 'new') return <Navigate to={`/search/new/`} replace />;
  if (!!params.id) {
    const {id, page} = getParams(params.id);
    return <Navigate to={`/search/${id}/step-${page}/`} replace />;
  }
  return null;
}
