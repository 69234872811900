import {connect} from 'react-redux';

import {selectors, RootState} from '@app/store/session';

import NotFound from '../components/NotFound';

export default connect(
  (state: RootState) => ({
    loggedIn: selectors.isLoggedIn(state),
  })
)(NotFound);
