import React from 'react';
import clsx from 'clsx';
import {Link} from 'react-router-dom';

import styles from './Logo.module.css';
import image from './logo-insignia.png';

interface LogoProps {
  className?: string;
  url?: string;
}

export default function Logo({className, url = '/'}: LogoProps) {
  return (
    <Link className={clsx(styles['container'], className)} to={url}>
      <span className={styles['fund']}>fund</span><span className={styles['keet']}>keet</span>
    </Link>
  );
};
