import React, {useCallback, useEffect, useState} from 'react';

import DashboardBlock from '@components/organisms/DashboardBlock';
import {History, HistoryRequest} from '@app/types';

import {dateTimeToString} from '@utils/format';

import styles from './Feed.module.css';
import Button from '@components/atoms/Button';

interface FeedProps  {
  loading: boolean;
  projectId?: string;
  acquisitionId?: string;
  items?: History[];
  onOpen: (request: HistoryRequest) => void;
  onClose: () => void;
}

export default function Feed({loading, items, projectId, acquisitionId, onOpen, onClose}: FeedProps) {
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    onOpen({projectId, acquisitionId});
    return () => {
      onClose();
    }
  }, [onOpen, onClose, projectId, acquisitionId]);
  const toggleExpand = useCallback(() => {
    setExpanded(value => !value);
  }, [setExpanded]);
  const rows = items ? expanded ? items : items.slice(0, 4) : undefined;
  if (!loading && (!rows || rows.length === 0)) return null;
  return (
    <DashboardBlock icon="dashboard" caption="Feed" size="small" border >
      {rows ? rows.map((item, i) => (
        <div className={styles['row']} key={i}>
          <div className={styles['date']}>{dateTimeToString(item.createdOn)}</div>
          <div className={styles['content']}>{item.description}</div>
        </div>
      )) : null}
      {items && rows && items.length > rows.length  ? <div className={styles['actions']}>
        <Button caption={expanded ? 'Show less' : 'Show more'} onClick={toggleExpand} color="secondary" margin={0} />
      </div> : null}
    </DashboardBlock>
  );
};
