import React from 'react';

import DashboardBlock from '@components/organisms/DashboardBlock';
import SummaryBlock, {Value, SummaryGroupProps} from '@components/molecules/SummaryBlock';
import {ProjectTask} from '@app/types';

interface TaskDetailsProps {
  loading?: boolean;
  value?: ProjectTask;
}

const COLUMNS = [
  {        
    items: [
      {caption: 'Project', fieldName: 'name'},
      {caption: 'Stage', fieldName: 'status', format: 'text'},
      {caption: 'Expiry date', fieldName: 'deadline', format: 'datetime'},
    ],
  }
] as SummaryGroupProps[];

export default function TaskDetails({loading, value}: TaskDetailsProps) {  
  console.log('value', value);
  return (
    <DashboardBlock caption="Task details" size='small' icon="file-list" border>
      <br/>
      <SummaryBlock columns={COLUMNS} value={value as Value} loading={loading} padding={1} />
    </DashboardBlock>
  )
}