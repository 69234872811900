import React, {useCallback, useState, ReactElement, useMemo} from "react";
import clsx from "clsx";

import DashboardBlock from "../DashboardBlock";

import styles from "./DashboardBlockExpandable.module.css";

interface DashboardBlockExpandableProps {
  className?: string;
  icon: string;
  caption: string;
  short?: ReactElement;
  full: ReactElement;
  border?: boolean;
}

export default function DashboardBlockExpandable({className, icon, caption, short, full, border}: DashboardBlockExpandableProps) {
  const [expanded, setExpanded] = useState(false);
  const [shortHeight, setShortHeight] = useState<number>();
  const [fullHeight, setFullHeight] = useState<number>();
  const ready = useMemo(() => {
    if (!!short && shortHeight === undefined) return false;
    if (fullHeight === undefined) return false;
    return true;
  }, [shortHeight, fullHeight, !!short]);
  //
  const classes = clsx(styles["container"], expanded ? styles["expanded"] : null, ready ? styles["ready"] : null, className);
  const handleCollapse = useCallback(() => {
    setExpanded(false);
  }, [setExpanded]);
  const handleExpand = useCallback(() => {
    setExpanded(true);
  }, [setExpanded]);
  const action = expanded
    ? {
        caption: "Show less",
        onClick: handleCollapse,
      }
    : {
        caption: !!short ? "Show more" : 'Show',
        onClick: handleExpand,
      };
  const shortControl = useMemo(() => {
    return short
      ? React.cloneElement(short, {
          className: clsx(short.props.className, styles["content"]),
          onUpdateHeight: setShortHeight,
          opacity: expanded ? 0 : 1,
        })
      : undefined;
  }, [short, setShortHeight, expanded]);
  const fullControl = useMemo(() => {
    return React.cloneElement(full, {
      className: clsx(full.props.className, styles["content"]),
      onUpdateHeight: setFullHeight,
      opacity: expanded ? 1 : 0,
    });
  }, [full, setFullHeight, expanded]);
  return (
    <DashboardBlock
      className={classes}
      icon={icon}
      caption={caption}
      border={border}
      action={action}
      ready={ready}
      contentHeight={(expanded ? fullHeight : shortHeight) || 0}
      animated
      collapsed={!short && !expanded}
    >
      {fullControl}
      {shortControl}
    </DashboardBlock>
  );
}
