import React, {useCallback, useEffect, useRef} from 'react';
import {useShareholder} from '@features/Tasks/hooks/useBorrowerForm';
import {Shareholder} from '@app/types';
import RadioInput from '@components/molecules/RadioInput';
import { TextInput } from '@components/molecules/TextInput/TextInput';
import equal from 'fast-deep-equal';

import { FormErrors } from '@features/Tasks/hooks/validate';

interface BorrowerShareholderProps {
  error?: FormErrors<Shareholder>;
  value: Shareholder;
  active?: boolean;
  onChange: (value: Shareholder) => unknown;
}

export default function BorrowerMainShareholder({error, value, active, onChange}: BorrowerShareholderProps) {
  const refValue = useRef<Shareholder | undefined>(value);
  useEffect(() => {
    refValue.current = value;
  }, [value, refValue]);
  const handleChange = useCallback((value?: Shareholder) => {
    if (equal(refValue.current, value)) return;
    if (value) onChange(value);
  }, [onChange, refValue]);
  const {fields} = useShareholder({error, values: value, onChange: handleChange});
  const {type, title, name, typeOfCompany, dateOfBirth, countryOfResidence, registrationNumber, address, shareholding} = fields;  
  return (
    <>
      <RadioInput field={type} margin={0} smallError readOnly={!active} />
      {type.value === 'Corporate' ? null : <TextInput field={title} margin={0} smallError readOnly={!active} />}
      <TextInput field={name} margin={0} smallError readOnly={!active} />
      {type.value === 'Corporate' ? <TextInput field={typeOfCompany} margin={0} smallError readOnly={!active} /> : null}
      {type.value === 'Corporate' ? null : <TextInput field={dateOfBirth} margin={0} smallError readOnly={!active} />}
      <TextInput field={countryOfResidence} margin={0} smallError readOnly={!active} />
      {type.value === 'Corporate' ? <TextInput field={registrationNumber} margin={0} smallError readOnly={!active} /> : null}
      <TextInput field={address} margin={0} smallError readOnly={!active} />
      <TextInput field={shareholding} suffix="%" smallError readOnly={!active} />
    </>
  )
}