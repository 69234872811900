import {createApiThunk} from "@app/lib/thunks";

import {offers} from '@app/api/rest';
import {SetOfferNameRequest} from "@app/types";

export const getItem = createApiThunk('@offers/item', offers.get.bind(offers));

export const submit = createApiThunk('@@offers/item-submit', async (projectId: string, thunkApi) => {
  const result = await offers.submit(projectId);
  thunkApi.dispatch(getItem.fulfilled(result, '@@offers/item', projectId));
});

export const reject = createApiThunk('@@offers/item-reject', async (projectId: string, thunkApi) => {
  const result = await offers.reject(projectId);
  thunkApi.dispatch(getItem.fulfilled(result, '@@offers/item', projectId));
});

export const setName = createApiThunk('@@offers/item-name', async (request: SetOfferNameRequest, thunkApi) => {
  const result = await offers.rename(request);
  thunkApi.dispatch(getItem.fulfilled(result, '@@offers/item', '123'));
});
