import React from "react";
import clsx from "clsx";

import Button from "@components/atoms/Button";

import styles from "./FormAction.module.css";

interface FormActionProps {
  caption?: string;
  prevLoaing?: boolean;
  loading?: boolean;
  prevLoading?: boolean;
  action: string;
  prevAction?: string;
  type?: "submit" | "reset" | "button";
  url?: string;
  onAction?: () => void;
  onPrevAction?: () => void;
  actionSize?: "small" | "medium";
}

export default function FormAction({caption, action, type = 'button', url, loading, prevLoading, onAction, actionSize, prevAction, onPrevAction}: FormActionProps) {
  return (
    <div className={clsx(styles["container"], caption ? styles["with_caption"] : null)}>
      <span className={styles["caption"]}>{caption}</span>
      <span className={styles["actions"]}>
        {prevAction ? (
          <Button
            className={clsx(styles["action"], actionSize ? styles["action_size_medium"] : null)}
            type="button"
            onClick={onPrevAction}
            margin={0}
            layout="inline"
            caption={prevAction}
            loading={prevLoading}
          />
        ) : null}
        <Button
          className={clsx(styles["action"], actionSize ? styles["action_size_medium"] : null)}          
          type={type}
          url={url}
          onClick={onAction}
          margin={0}
          layout="auto"
          color="primary"
          caption={action}
          loading={loading}
        />
      </span>
    </div>
  );
}
