import React, {useMemo} from 'react';
import clsx from 'clsx';

import Paper from '@components/atoms/Paper';
import ListItem from '@components/molecules/ListItem';

import styles from './Stepper.module.css';

export interface Step {
  primary: string;
  secondary: string;
  icon?: string;
}

interface StepperProps {
  className?: string;
  items?: Step[];
  index?: number;
  nextIndex?: number;
  baseUrl?: string;
  searchId?: string;
}

export default function Stepper({className, items, index = 0, baseUrl, nextIndex = 0, searchId = 'new'}: StepperProps) {  
  const url = useMemo(() => baseUrl?.replace('{id}', searchId), [baseUrl, searchId]);
  return (
    <Paper className={clsx(styles['container'], className)} size="large" Component="aside">
      {items
        ? items.map((item, i) => (
            <ListItem
              className={clsx(styles['item'], nextIndex > i ? styles['complete'] : null)}
              color={nextIndex >= i ? 'primary' : undefined}
              size="large"
              icon={nextIndex > i && index !== i ? 'check' : item.icon}
              primary={item.primary}
              secondary={item.secondary}
              align="right"
              url={nextIndex >= i && index !== i ? url?.replace('{index}', (i + 1).toString()) : undefined}
              key={i}
            />
          ))
        : null}
    </Paper>
  );
}
