import React from "react";
// import ReactDom from 'react-dom'
import Application from './Application'
import './styles/index.css'

let ReactDom = null;
const application = <Application />

import('react-dom').then(ReactDOM => {
  const root = document.querySelector('#root')
  
  ReactDOM.render(application, root)
})

//const ReactDom = import('react-dom')



//Inna
//+7 (911) 8235341