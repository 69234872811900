import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';

import styles from './DashboardBlockHeader.module.css';
import RemixIcon from '@components/atoms/RemixIcon';
import Title from '@components/atoms/Title';
import Button from '@components/atoms/Button';
import Tooltip from '../Tooltip';
import Skeleton from '@components/atoms/Skeleton';

interface DashboardBlockHeaderProps {
  className?: string;
  bage?: string | number;
  size?: 'small' | 'big';
  icon?: string;
  caption?: string;
  required?: boolean;  
  description?: string;
  hint?: string;
  collapsed?: boolean;
  loading?: boolean;
  action?: {
    caption?: string;
    onClick?: () => void;
    url?: string;
    replace?: boolean;
    currentWindow?: boolean;
    disabled?: boolean;
  }
}

export default function DashboardBlockHeader({className, size, icon, loading, caption, description, hint, required, bage, collapsed, action}: PropsWithChildren<DashboardBlockHeaderProps>) {
  const classes = clsx(
    styles['container'],
    collapsed ? styles['collapsed'] : null,
    size ? styles['size_' + size] : null,
    description ? styles['has_description'] : null,
    className
  );
  return (
    <header className={classes}>
      {icon ? <RemixIcon className={styles['icon']} value={icon} size={size === 'small' ? 'medium' : "large"} color="secondary" /> : null}
      <Title className={styles['caption']} level={2}>
        {loading ? <Skeleton active /> : <>
          {caption}
          {required ? <span className={styles['required-mark']}>*</span> : null}
          {bage ? <span className={styles['bage']}> ({bage})</span> : null}
          {description ? <span className={styles['description']}>{description}</span> : null}
        </>}
      </Title>
      {action ? <Button className={styles['action']} type="button" margin={0} caption={action.caption} url={action.url} onClick={action.onClick} disabled={action.disabled} /> : null}
      {hint ? <Tooltip text={hint}>
        <RemixIcon className={styles['tooltip']} value="error-warning" />
      </Tooltip> : null}
    </header>
  );
}