import React from 'react';
import {Fields} from '@hooks/useForm';
import {SearchStep1Data} from '@app/types';
import {FormStepProps} from '../Form';

import AddressInput from '@components/molecules/AddressInput';
import TextInput from '@components/molecules/TextInput';
import RadioInput from '@components/molecules/RadioInput';

export default function Step1({fields}: FormStepProps<SearchStep1Data>) {
  const {projectSummary, proposedDevelopment, partBuiltScheme, siteFullAddress, acquisitionStatus, planningStatus, siteCity, siteCountry, sitePostcode, siteRegion} =
    fields;  
  return (
    <>
      <TextInput
        field={projectSummary}
        multiline
      />
      <RadioInput
        anchor="proposedDevelopment"
        field={proposedDevelopment}        
      />
      <RadioInput
        field={partBuiltScheme}
      />
      <AddressInput
        anchor="siteFullAddress"
        field={siteFullAddress}
        fieldCity={siteCity}
        fieldCountry={siteCountry}
        fieldPostCode={sitePostcode}
        fieldRegion={siteRegion}
      />
      <RadioInput
        anchor="acquisitionStatus"
        field={acquisitionStatus}        
      />
      <RadioInput
        anchor="planningStatus"
        field={planningStatus}        
      />
    </>
  );  
}