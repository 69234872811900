import React from 'react';
import ContentTitle from '@features/Layout/components/ContentTitle';
import {Outlet} from 'react-router-dom';

export default function OffersView() {
  return (
    <>
      <ContentTitle title="We compare, you choose the best offers" />
      <Outlet />
    </>
  );
}
