import {SummaryGroupProps, SummaryGroupRow} from '@components/molecules/SummaryBlock';

export const SUMMARY_COLUMNS = [{
  items: [
    {caption: 'Lender', fieldName: 'name', color: 'primary', clickable: true},
    {caption: 'Created on', fieldName: 'createdOn', format: 'date'},
  ],
}, {
  items: [
    {caption: 'Status', fieldName: 'status'},
    {caption: 'Expiry date', fieldName: 'deadline', format: 'date'},
  ],
}] as SummaryGroupProps[];

export const INDICATIVE_TERMS_COLUMNS = [
  {
    title: 'Terms',
    items: [
      {caption: 'Loan tenure', fieldName: 'loanTenureMonths', format: 'months'},
      {caption: 'LTGDV', fieldName: 'ltgdv', format: '%.0'},
      {caption: 'LTC', fieldName: 'ltc', format: '%.0'},
      {caption: 'Day 1 gross LTV', fieldName: 'grossLTVDay1', format: '%'},
      {caption: 'Arrangement fee', fieldName: 'arrangementFee', format: '%.2'},
      {caption: 'Interest rate (pa)', fieldName: 'ineterestRate', format: '%.2'},
      {caption: 'Exit fee', fieldName: 'exitFee', format: '%.2'},
    ]
  },
  {
    title: 'Loan summary',
    items: [
      {caption: 'Gross facility', fieldName: 'grossFacility', format: 'currency'},
      {caption: 'Interest facility', fieldName: 'interestCost', format: 'currency'},
      {caption: 'Build facility', fieldName: 'totalBuildCost', format: 'currency'},
      {caption: 'Gross acquisition facility', fieldName: 'acquisitionFacility', format: 'currency'},
      {caption: 'Arrangement fee', fieldName: 'arrangementFeeCost', format: 'currency'},
      {caption: 'Net acquisition facility', fieldName: 'netAcquisitionFacility', format: 'currency'},
      {caption: 'Exit fee', fieldName: 'exitFeeCost', format: 'currency'},
    ]
  },  
] as SummaryGroupProps[];

/*export const SECURITY_PACKAGE_COLUMNS = [
  {
    title: 'Security',
    items: [
      {caption: 'Charge',  fieldName: '???'},
      {caption: 'Debenture', fieldName: '???'},
      {caption : 'Personal guranteee', fieldName: '???'},
      {caption: 'Cost overrun gurantee', fieldName: '???'},
      {caption: 'Interset overrun gurantee', fieldName: '???'},
    ],
  },
  {
    title: 'Conditions',
    items: [
      {caption: 'Valuation', fieldName: '???'},
      {caption: 'Main contractor', fieldName: '???'},      
    ],   
  }
] as SummaryGroupProps[];*/

export const SUMMARY_APPRAISAL_ROWS = [
  [
    {
      items: [
        {caption: 'Gross development value', fieldName: 'grossRevenue', format: 'currency'},
        {caption: 'Cost of sales', fieldName: 'salesCosts', format: 'currency'}
      ]
    },
    {
      items: [
        {caption: 'Acquisition cost', fieldName: 'acquisitionCost', format: 'currency'},
        {caption: 'Build cost', fieldName: 'totalBuildCost', format: 'currency'},
        {caption: 'Professional and other cost', fieldName: 'professionalOtherCosts', format: 'currency'},
        // {caption: 'Other cost', fieldName: '???', format: 'currency'},
        {caption: 'Finance cost', fieldName: 'financeCost', format: 'currency'},
      ]
    }
  ],
  [
    {
      items: [
        {caption: 'Net development value', fieldName: 'netRevenue', format: 'currency', captionColor: "primary"},
      ]
    },
    {
      items: [
        {caption: 'Estimated profit (levered)', fieldName: 'estimatedProfitLevered', format: 'currency', captionColor: "primary"},
      ]
    }
  ]
] as SummaryGroupRow[];

export const SOURCE_OF_FUNDS_ROWS = [
  [
    {
      title: 'Source of funds',
      items: [
        {caption: 'Acquisition facility', fieldName: 'netAcquisitionFacility', format: 'currency'},
        {caption: 'Build facility', fieldName: 'buildFacilitySum', format: 'currency'},
        {caption: 'Finance facility', fieldName: 'financeFacility', format: 'currency'},
        {caption: 'Equity', fieldName: 'equity', format: 'currency'},
      ]
    },
    {
      title: 'Use of funds',
      items: [
        {caption: 'Acquisition cost', fieldName: 'acquisitionCost', format: 'currency'},
        {caption: 'Build cost', fieldName: 'calculatedBuildCost', format: 'currency'},
        {caption: 'Finance cost', fieldName: 'financeFacility', format: 'currency'},
      ]
    }
  ],
  [
    {
      items: [
        {caption: 'Total sources', fieldName: 'totalUses', format: 'currency', captionColor: 'primary'},
      ]
    },
    {
      items: [
        {caption: 'Total uses', fieldName: 'totalUses', format: 'currency', captionColor: 'primary'},
      ]
    }
  ]
  
] as SummaryGroupRow[];

export const KEY_PERFOMANCE_INDICATORS_COLUMNS = [
  {
    items: [
      {caption: 'RoC', fieldName: 'roC', format: '%'},
      {caption: 'Total RoE', fieldName: 'totalRoE', format: '%'},
      {caption: 'IRR (unlevered)', fieldName: 'unleveredIRR', format: '%'},      
    ],
  },
  {
    items: [
      {caption: 'RoGDV', fieldName: 'roGDV', format: '%'},
      {caption: 'RoE (per annum)', fieldName: 'roE', format: '%'},
      {caption: 'IRR (levered)', fieldName: 'leveredIRR', format: '%'},

    ],
  }
] as SummaryGroupProps[];