import React from 'react';
import {Link} from 'react-router-dom';
import DashboardBlock from '@components/organisms/DashboardBlock';
import {ProjectTask} from '@app/types';
import {dateToString} from '@utils/format';

import RemixIcon from '@components/atoms/RemixIcon';
import Skeleton from '@components/atoms/Skeleton';
import useTagsLines from '../TasksGroup/useTagsLines';

import styles from './Tasks.module.css';
import DashboardTagLines from '@components/organisms/DashboardTagLines';

interface GroupProps {
  loading?: boolean;
  name: string;
  items: ProjectTask[];
}

const loadingsItems = [1, 2, 3, 4];

export default function Group({loading, name, items}: GroupProps) {
  const {plainItems} = useTagsLines({items});
  return (
    <DashboardBlock caption={name} bage={items.length} size="big" loading={loading}>      
      <DashboardTagLines wrap value={plainItems} />
      {/*<ul className={styles['tasks']}>
        {loading
          ? <>
            {loadingsItems.map((i) => (
              <li className={styles['task']} key={i}>
                <Skeleton className={styles['task-skeleton']} active />
              </li>
            ))}
          </> 
          : <>
          {items.map((item, i) => (
            <li className={styles['task']} key={i}>
              <Link className={styles['link']} to={`/projects/${item.projectId}/tasks/${item.id}/`}>
                <span className={styles['task-name']}>{item.name} due {dateToString(item.deadline)}</span>
                <span className={styles['task-action']}>
                  <RemixIcon value="arrow-right-s" />
                </span>
              </Link>
            </li>
          ))}
        </>}
          </ul>*/}
    </DashboardBlock>
  );
};
