import React, {Fragment, useCallback, useEffect, useMemo, useState} from "react";

import {ProjectTask, TasksRequest} from "@app/types";

import DashboardBlock from "@components/organisms/DashboardBlock";
import DashboardLines from "@components/organisms/DashboardLines";
import DashboardTagLines from '@components/organisms/DashboardTagLines';
import DashboardBlockEmpty from "@components/molecules/DashboardBlockEmpty";
import {dateToString} from "@utils/format";
import Title from "@components/atoms/Title";
import useTagsLines from "./useTagsLines";

import styles from './TasksGroup.module.css';

interface TasksGroupProps {
  projectId?: string;
  appraisalId?: string;
  loading: boolean;
  items?: ProjectTask[];
  groupBy?: "lender";
  show?: "ongoing" | "complete";
  showBage?: boolean;
  showStatus?: boolean;
  countActive?: boolean;
  totalCount?: number;
  expandMode?: "expand" | "redirect";
  ignoreStatus?: boolean;
  onOpen: (request: TasksRequest) => unknown;
  onClose: () => unknown;
  onLoad?: (count: number, values?: ProjectTask[]) => unknown;
}

const SHOW_MORE = {
  caption: "Show more",
  url: "/tasks/",
};

function taskToText(task: ProjectTask): string {
  const result = [task.name];
  if (task.deadline) result.push("due " + dateToString(task.deadline));
  return result.join(" ");
}

const SHOW_LINES = 4;

interface TaskGroup {
  title: string;
  items: ProjectTask[];
}

function getItemClasses(item: ProjectTask) {
  return 'Test test etst';
}

export default function TasksGroup({projectId, appraisalId, loading, items, totalCount, groupBy, show, showBage, countActive, showStatus, ignoreStatus, expandMode = "expand", onOpen, onClose, onLoad}: TasksGroupProps) {
  const queryStatus = ignoreStatus ? undefined : show === 'ongoing' ? 'Active' : show === 'complete' ? 'Completed' : undefined;
  const top = projectId || ignoreStatus ? undefined : 10;
  useEffect(() => {
    onOpen({projectId, appraisalId, top, returnTotal: top ? true : false, status: queryStatus});
    return () => {
      onClose();
    };
  }, [projectId, appraisalId, onOpen, onClose]);    
  /*
  const caption = show === "ongoing" ? "Ongoing tasks" : show === "complete" ? "Completed tasks" : "To do List";
  const sourceItems = useMemo(() => {
    let result = items;
    if (!result) return result;
    if (projectId) result = result.filter(item => item.projectId === projectId);
    // if (appraisalId) result = result.filter(item => item.appraisalId === appraisalId);    
    if (items && show === "ongoing") result = result.filter(item => item.status === "Active");
    if (items && show === "complete") result = result.filter(item => item.status === "Completed");
    return result;
  }, [show, items, projectId, appraisalId]);  
  const activeItems = useMemo(() => {
    if (!sourceItems) return [];
    if (!countActive) return sourceItems;
    return sourceItems.filter(item => item.status === 'Active');
  }, [sourceItems, countActive]);  
  useEffect(() => {
    if (!loading && !!sourceItems && !!onLoad) {
      onLoad(sourceItems.length);
    }
  }, [loading, sourceItems, onLoad])
  const bage = activeItems?.length || 0;  //: undefined;
  const showAction = sourceItems && sourceItems.length > SHOW_LINES;
  const empty = sourceItems?.length === 0;
  const groupedItems = useMemo(() => {
    if (!groupBy) return undefined;
    if (!sourceItems) return undefined;
    const index = sourceItems.reduce((result, item) => {
      const key = groupBy === 'lender' ? item.projectId : undefined;
      if (key) {
        if (!result[key]) result[key] = {
          title: item.projectName || '<Unnamed project>',
          items: [],
        };
        result[key].items.push(item);
      }
      return result;
    }, {} as Record<string, TaskGroup>);
    const keys = (Object.keys(index) as string[]).slice(0, SHOW_LINES);
    return keys.map((key, i) => ({
      title: index[key].title,
      items: index[key].items.slice(0, SHOW_LINES).map(item => ({
        url: `/projects/${item.projectId}/tasks/${item.id}/`,
        closed: showStatus && item.status === "Completed",
        cancelled: showStatus && item.status === 'Canceled',
        expired: showStatus && item.status === 'Expired',
        children: taskToText(item),
      })),
      count: index[key].items.length,
    }));
  }, [sourceItems, groupBy]);
  const lines = useMemo(() => {
    return sourceItems
      ? sourceItems.slice(0, expanded ? sourceItems.length : SHOW_LINES).map(item => ({
          url: `/projects/${item.projectId}/tasks/${item.id}/`,
          closed: showStatus && item.status === "Completed",
          cancelled: showStatus && item.status === 'Canceled',
          expired: showStatus && item.status === 'Expired',
          children: taskToText(item),
        }))
      : undefined;
  }, [items, expanded]);
  const action = showAction
    ? {
        caption: expanded ? "Show less" : "Show more",
        url: expandMode === "redirect" ? (projectId ? `/projects/${projectId}/tasks/` : "/tasks/") : undefined,
        onClick: expandMode === "expand" ? toggleExpand : undefined,
      }
    : undefined;*/
  const {caption, groupItems, plainItems, bage, action, empty, sourceItems} = useTagsLines({projectId, items, groupBy, show, countActive, expandMode, totalCount: ignoreStatus ? undefined : totalCount});
  useEffect(() => {
    if (!loading && !!sourceItems && !!onLoad) {
      onLoad(sourceItems.length, sourceItems);
    }
  }, [loading, sourceItems, onLoad])
  return (
    <DashboardBlock icon="draft" caption={caption} bage={bage} action={action}>
      {loading ? <DashboardLines loading={loading} skeletonCount={SHOW_LINES} /> : !empty ? 
        <>
          {groupItems ? <>
            <div className={styles['spacer']} />
            {groupItems.map((group, i) => (
              <Fragment key={i}>
                <Title className={styles['group-title']} level={3}>{group.title} <span className={styles['bage']}>({group.count})</span></Title>
                <DashboardTagLines value={group.items} />
              </Fragment>
            ))}
          </> : <DashboardTagLines value={plainItems} />}
        </>
      : <DashboardBlockEmpty name="tasks" />}
    </DashboardBlock>
  );
}
