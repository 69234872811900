import {AssetAndLiability, BorrowerDetails, DeveloperCv, DeveloperDetails, DeveloperProjectExperience, Shareholder, Spv} from '@app/types';

type FieldErrorMessage = string;

type ArrayElement<ArrayType> = ArrayType extends readonly (infer ElementType)[]
  ? ElementType | undefined
  : never;

type FieldErrorValue<T> =   
  T extends string ? string :
  T extends number ? string :
  // array ?
  T extends readonly (infer ElementType)[] 
    ? (FieldErrorValue<ArrayElement<T> | undefined>)[] | undefined
    // record ?
    : T extends {} 
      ? {
        [name in keyof T]?: FieldErrorValue<T[name]>
      }
      : FieldErrorMessage;

export type FormErrors<FormData> = {
  [name in keyof FormData]?: FieldErrorValue<Partial<FormData[name]>>;
}

function fieldIsArray(v: unknown): v is FieldErrorValue<any>[] {
  return Array.isArray(v);
}

function isNull<T>(v: T | undefined): boolean {
  if (typeof v === 'string' && v === '') return true;
  if (typeof v === 'undefined') return true;
  if (typeof v === 'object' && v === null) return true;
  return false;
}

function isObject(v?: unknown): v is object {
  if (!v) return false;
  return (typeof v === 'object' && (v as Object).constructor === Object);
}

function checkRequired<T>(name: string, v?: T, message?: string): string | undefined {
  if (isNull(v)) {
    if (message) return message;
    else return `Fill in the ${name} field`;
  }
  return '';
}

function checkDate<T>(name: string, v?: T, message?: string): string | undefined {
  if (typeof v !== 'string') return `Invalid date format in ${name}`;
  const re = /\d\d\/\d\d\/\d\d\d\d/gmi;
  const r = re.exec(v);
  if (!r) return `Invalid date format in ${name}`;
  return undefined;
}

function checkNullableDate<T>(name: string, v?: T, message?: string): string | undefined {
  if (!v) return undefined;
  if (typeof v !== 'string') return `Invalid date format in ${name}`;
  const re = /\d\d\/\d\d\/\d\d\d\d/gmi;
  const r = re.exec(v);
  if (!r) return `Invalid date format in ${name}`;
  return undefined;
}

function cleanup<FormData>(v?: FormErrors<FormData>): FormErrors<FormData> | undefined {
  if (!v) return v;
  const result = {...v} as FormErrors<FormData>;
  const keys = Object.keys(result) as (keyof FormData)[];
  keys.forEach(key => {    
    const value = result[key];
    if (isObject(value)) result[key] = cleanup(value) as typeof result[typeof key];
    if (fieldIsArray(result[key]) && fieldIsArray(value)) {
      const temp = value.map(item => {
        if (isObject(item)) return cleanup(item);
        if (!item) return undefined;
        return item;
      });
      if (temp.filter(item => !!item).length === 0) result[key] = undefined;
      else result[key] = temp as typeof result[typeof key];
    }
    //if (Array.isArray(result[key])) result[key] = (result[key] as Array).map(cleanup);
    if (!result[key]) delete result[key];
  });
  if (Object.keys(result).length === 0) return undefined;
  return result;
}


export function validateAssetLiability(v: AssetAndLiability) {
  const result = {} as FormErrors<AssetAndLiability>;
  result['gurantor'] = checkRequired('Guarantor', v.gurantor);   
  return cleanup(result);
}

// Borrower

function calcShareholding(v?: Shareholder[]): number | undefined {
  if (!v) return;
  return v.reduce((result, v) => result + (v.shareholding ? v.shareholding : 0), 0);
}

function validateShareholding(v?: Shareholder[]): string[] | undefined {
  if (!v) return;
  const max = v.reduce((result, v) => result + (v.shareholding ? v.shareholding : 0), 0);
  if (max > 100) return ['Shareholding should be at most 100%'];
  return;
}

function validateSaveShareholder(v: Shareholder): FormErrors<Shareholder> | undefined {
  const result = {} as FormErrors<Shareholder>;
  if (v.shareholding && v.shareholding > 100) result['shareholding'] = 'Shareholding should be at most 100%';
  if (v.shareholding && v.shareholding < 25) result['shareholding'] = 'Shareholding should be at least 25%';
  if (v.type === 'Individual') {        
    result['dateOfBirth'] = checkNullableDate('Date of birth', v.dateOfBirth);
  }
  return result;
}

function validateSaveDirector(v: Spv): FormErrors<Spv> | undefined {
  const result = {} as FormErrors<Spv>;
  if (v.type === 'Individual') {    
    result['dateOfBirth'] = checkNullableDate('Date of birth', v.dateOfBirth);
  }
  return result;
}

export function validateSaveBorrowerDetails(v: BorrowerDetails): FormErrors<BorrowerDetails> | undefined {
  const result = {} as FormErrors<BorrowerDetails>;
  //
  const shareholding = calcShareholding(v.shareholders);
  //
  result['proposedDirectors'] = v.proposedDirectors ? v.proposedDirectors.map(validateSaveDirector) as FormErrors<Spv>[] : undefined;
  result['shareholders'] = v.shareholders ? v.shareholders.map(item => {
    const result = validateSaveShareholder(item) as FormErrors<Shareholder>;
    if (v.shareholders.length > 1 && shareholding && shareholding > 100) {
      result['shareholding'] = 'Total shareholding should be at most 100%';
    }
    return result;
  }) as FormErrors<Shareholder>[] : undefined;  
  //
  return cleanup(result);
}

function validateShareholder(v: Shareholder): FormErrors<Shareholder> | undefined {
  const result = {} as FormErrors<Shareholder>;
  result['type'] = checkRequired('Shareholder type', v.type);
  if (v.type === 'Individual') {
    result['title'] = checkRequired('Title', v.title);
    result['name'] = checkRequired('Full name', v.name);
    result['dateOfBirth'] = checkRequired('Date of birth', v.dateOfBirth);
    if (!result['dateOfBirth']) result['dateOfBirth'] = checkDate('Date of birth', v.dateOfBirth);
    result['countryOfResidence'] = checkRequired('Country of residence', v.countryOfResidence);
    result['address'] = checkRequired('Home address', v.address);    
  } else if (v.type === 'Corporate') {
    result['name'] = checkRequired('Company name', v.name);
    result['countryOfResidence'] = checkRequired('Country of incorporation', v.countryOfResidence);  
  }  
  result['shareholding'] = checkRequired('Shareholding', v.shareholding);
  if (!result['shareholding']) result['shareholding'] = v.shareholding > 100 ? 'Shareholding should be at most 100%' : undefined;
  return result;
}

function validateDirector(v: Spv): FormErrors<Spv> | undefined {
  const result = {} as FormErrors<Spv>;
  result['type'] = checkRequired('Shareholder type', v.type);
  if (v.type === 'Individual') {
    result['title'] = checkRequired('Title', v.title);
    result['name'] = checkRequired('Full name', v.name);
    result['dateOfBirth'] = checkRequired('Date of birth', v.dateOfBirth);
    if (!result['dateOfBirth']) result['dateOfBirth'] = checkDate('Date of birth', v.dateOfBirth);
    result['countryOfResidence'] = checkRequired('Country of residence', v.countryOfResidence);
    result['address'] = checkRequired('Home address', v.address);    
  } else if (v.type === 'Corporate') {
    result['name'] = checkRequired('Company name', v.name);    
    result['countryOfResidence'] = checkRequired('Country of incorporation', v.countryOfResidence);    
  }    
  return result;
}

export function validateBorrowerDetails(v: BorrowerDetails): FormErrors<BorrowerDetails> | undefined {
  const result = {} as FormErrors<BorrowerDetails>;
  result['companyName'] = checkRequired('Company name', v.companyName);  
  result['countryOfIncorporation'] = checkRequired('Country of incorporation', v.countryOfIncorporation);
  result['registrationNumber'] = checkRequired('Registration number', v.registrationNumber);
  result['address'] = checkRequired('Registered address', v.address);
  //
  const shareholding = calcShareholding(v.shareholders);
  //
  result['proposedDirectors'] = v.proposedDirectors ? v.proposedDirectors.map(validateDirector) as FormErrors<Spv>[] : undefined;
  result['shareholders'] = v.shareholders ? v.shareholders.map(item => {
    const result = validateSaveShareholder(item) as FormErrors<Shareholder>;
    if (v.shareholders.length > 1 && shareholding && shareholding > 100) {
      result['shareholding'] = 'Total shareholding should be at most 100%';
    }
    return result;
  }) as FormErrors<Shareholder>[] : undefined;  
  //result['shareholders'] = v.shareholders ? v.shareholders.map(validateShareholder) as FormErrors<Shareholder>[] : undefined;     
  //
  return cleanup(result);
}

// DeveloperCv

function validateDeveloper(v: DeveloperDetails) {
  const result = {} as FormErrors<DeveloperDetails>;
  result['name'] = checkRequired('Full name', v.name);
  result['yearsOfExperience'] = checkRequired('Years of experience', v.yearsOfExperience);
  result['bio'] = checkRequired('Brief bio', v.bio);
  return result;
}

function validateExperience(v: DeveloperProjectExperience) {
  const result = {} as FormErrors<DeveloperProjectExperience>;
  result['location'] = checkRequired('Location', v.location);
  result['type'] = checkRequired('Type', v.type);
  result['numberOfUnits'] = checkRequired('No of units', v.numberOfUnits);
  result['gdv'] = checkRequired('GDV', v.gdv)
  result['developmentCost'] = checkRequired('Development cost', v.developmentCost);
  result['deliveredOnYear'] = checkRequired('Year delivered', v.deliveredOnYear);
  return result;
}


export function validateDeveloperCv(v: DeveloperCv): FormErrors<DeveloperCv> | undefined {
  console.log('validating', v);
  const result = {} as FormErrors<DeveloperCv>;
  result['companyName'] = checkRequired('Company name', v.companyName);
  result['developerDetails'] = v.developerDetails ? v.developerDetails.map(validateDeveloper) as FormErrors<DeveloperDetails>[] : undefined;
  result['previousProjects'] = v.previousProjects ? v.previousProjects.map(validateExperience) as FormErrors<DeveloperProjectExperience>[] : undefined;  
  return cleanup(result);
};
