import React from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';

import {actions, selectors, RootState} from '@app/store/search';

import Search from '../components/Search';

export function SearchController(props: Parameters<typeof Search>[0]) {
  const params = useParams();
  return <Search queryId={params.queryId} {...props} />;
}

export default connect(
  (state: RootState) => ({
    step: selectors.current.step(state),
    loaded: selectors.current.loaded(state),
    value: selectors.current.value(state),
    posted: selectors.current.posted(state),
  }),
  {    
    onLoad: actions.current.open,
    onReset: actions.current.reset,
  }
)(SearchController);
