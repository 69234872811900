import React, {PropsWithChildren, ReactElement} from 'react';
import clsx from 'clsx';

import styles from './Aside.module.css';

interface AsideProps {
  aside?: ReactElement;
  alternate?: boolean;
}

export default function Aside({aside, alternate, children}: PropsWithChildren<AsideProps>) {
  return (
    <div className={clsx(styles['container'], alternate ? styles['container_alternate'] : null)}>
      <main className={styles['content']}>
        {children}
      </main>
      <aside className={styles['aside']}>
        {aside}
      </aside>
    </div>
  );
}
