import React, {useMemo, useState} from "react";
import {SearchStep6Data, SearchFormData, ProposedDevelopmentCaptions, ProcurementRoute} from "@app/types";
import {FormStepProps} from "../Form";

import {FormErrors} from "@hooks/useForm";


import FormSummary from '@components/molecules/FormSummary';
import KeyValues from '@components/atoms/KeyValues';
import KeyValue from '@components/atoms/KeyValue';
import PageAction from '@components/molecules/PageAction';
import TextInput from "@components/molecules/TextInput";
import RadioInput from "@components/molecules/RadioInput";
import BuildCosts from "@components/organisms/BuildCosts";
import UnitDetails from "../UnitDetails";
import PercentageText from "@components/molecules/PercentageText";
import PercentageInput from "@components/molecules/PercentageInput";
import Money from "@components/atoms/Money";
import Total from "@components/molecules/Total";
import AreaUnits from "@components/atoms/AreaUnits";
import Errors from "../Errors";

import {validate} from "@hooks/useForm";
import {formFieldsDefs} from '../../hooks/useNewForm';

import styles from './Step-7.module.css';

interface Step7Props extends FormStepProps<SearchFormData> {
  baseUrl?: string;
}

function getErrors(value: SearchFormData): FormErrors<SearchFormData> | undefined {
  const result = {} as FormErrors<SearchFormData>;
  if (typeof value.estimatedProfit !== 'undefined' && value.estimatedProfit <= 0) {
    result['estimatedProfit'] = 'The project is not fundable due to negative Estimated Profit (unlevered). Please re-check your gross development value, acqusition cost and build cost assumptions.';
  }
  if (typeof value.roc !== 'undefined' && value.roc < 15)  {
    result['roc'] = 'The project is unlikely to be fundable due to Return on Cost (RoC) below 15%. Most lenders expect RoC to be minimum 18%. Please re-check your gross development value, acqusition cost and build cost assumptions.';
  }
  if (Object.keys(result).length > 0) return result;
  else return;
}

function boolToYesNo(v?: boolean | string) {
  if (typeof v === 'boolean') return v ? 'Yes' : 'No';
  if (typeof v === 'string') return v === 'true' ? 'Yes' : 'No';
  return '';
}

function getProcurementRoute(v?: ProcurementRoute): string {
  if (v === 'MainContractor') return 'Fixed price contract';
  if (v === 'ConstructionManagement') return 'Construction management';
  return '';
}

export default function Step7({fields, loading, value, baseUrl = '/search/{id}/step-{number}/'}: Step7Props) {  
  const urlEditSummary = useMemo(() => {
    return baseUrl.replace('{id}', value?.id?.toString() || '').replace('{number}', '1');
  }, [value, baseUrl]);
  const urlUnitsSummary = useMemo(() => {
    return baseUrl.replace('{id}', value?.id?.toString() || '').replace('{number}', '3');
  }, [value, baseUrl]);
  const urlGiaSummary = useMemo(() => {
    return baseUrl.replace('{id}', value?.id?.toString() || '').replace('{number}', '4');
  }, [value, baseUrl]);
  const urlEditAppraisal = useMemo(() => {
    return baseUrl.replace('{id}', value?.id?.toString() || '').replace('{number}', '5');
  }, [value, baseUrl]);
  const urlEditBorrower = useMemo(() => {
    return baseUrl.replace('{id}', value?.id?.toString() || '').replace('{number}', '2');
  }, [value, baseUrl]);
  const urlEditFunding = useMemo(() => {
    return baseUrl.replace('{id}', value?.id?.toString() || '').replace('{number}', '6');
  }, [value, baseUrl]);
  const urlResults = useMemo(() => {
    return '/searches/{id}/'.replace('{id}', value?.id?.toString() || '');
  }, [value]);
  const errors = value ? getErrors(value) : undefined;
  const areaUnits = <AreaUnits value={value?.areaMetric} />;  
  const creditHistory = value ? (typeof value.adverseCreditHistory === 'string' ? (value.adverseCreditHistory === 'true' ? 'Adverse' : 'Clean') : (value.adverseCreditHistory ? 'Adverse' : 'Clean')) : '';  
  const formErrors = value ? validate(formFieldsDefs, value) : undefined;  
  const finalFormErrors = formErrors ? Object.keys(formErrors)
    .filter(key => formErrors[key as keyof SearchFormData])
    .reduce((result, key) => {
      result[key as keyof SearchFormData] = formErrors[key as keyof SearchFormData];
      return result;
    }, {} as FormErrors<SearchFormData>) : undefined;
  const finalErrors = (errors || finalFormErrors) ? {
    ...(errors || {}),
    ...(finalFormErrors || {}),
  } : undefined;
  const tagErrors = finalErrors ? <Errors fields={Object.keys(finalErrors) as (keyof SearchFormData)[]} items={finalErrors} /> : null;
  const tagAction = <PageAction
    title="You're almost there!"
    subTitle="Once you’re happy with the information provided, you can view your offers by clicking here"
    action="View offers"
    urlAction={urlResults}
  />
  return (
    <>
      {!loading ? (finalErrors ? tagErrors : tagAction) : null}
      <div className={styles['row']}>
        <FormSummary className={styles['cell-50']} icon="home-gear" caption="Project summary">
          <KeyValues>
            <KeyValue term="Site address" definition={value?.siteFullAddress} editUrl={urlEditSummary + '#siteFullAddress'} />
            <KeyValue term="Acquisition status" definition={value?.acquisitionStatus} editUrl={urlEditSummary + '#acquisitionStatus'} />
            <KeyValue term="Planning status" definition={value?.planningStatus} editUrl={urlEditSummary + '#planningStatus'} />
            <KeyValue term="Proposed development" definition={ProposedDevelopmentCaptions[value?.proposedDevelopment!]} editUrl={urlEditSummary + '#proposedDevelopment'} />
            <KeyValue term="Part-built scheme" definition={boolToYesNo(value?.partBuiltScheme)} editUrl={urlEditSummary + '#proposedDevelopment'} />
            <KeyValue term="Number of units" definition={value?.unitNumber} editUrl={urlUnitsSummary + '#numberOfUnits'} />
            <KeyValue term="NIA" definition={<><Money value={value?.nia} integer /> {areaUnits}</>} editUrl={urlUnitsSummary + '#nia'}  />
            <KeyValue term="GIA" definition={<><Money value={value?.gia} integer /> {areaUnits}</>} editUrl={urlGiaSummary + '#gia'} />
            <KeyValue term="Procurement route" definition={getProcurementRoute(value?.procurementRoute)} editUrl={urlGiaSummary + '#procurementRoute'} />
          </KeyValues>
        </FormSummary>
        <FormSummary className={styles['cell-50']} icon="wallet-3" caption="Key metrics" error={errors && !!errors['roc']}>
          <KeyValues>
            <KeyValue term="Average unit price" definition={<Money currency="£" value={value?.averageUnitPrice} integer />} />
            <KeyValue term={<>Average unit price per {areaUnits}</>} definition={
              <>
                <Money currency="£" value={value?.averageUnitPricePerMeter} integer /> {areaUnits}
              </>
            } />
            <KeyValue term="Commercial as % of GDV" definition={<><Money value={value?.commercialRationToTotalGDV} oneDigit />%</>} />
            <KeyValue term={"Acquisition cost"} definition={
              <>
                <Money currency="£" value={value?.acquisitionCostPerMetric} integer /> {areaUnits}
              </>
            } />            
            <KeyValue term="Build cost" definition={<>
              <Money currency="£" value={value?.buildCostPerMetric} integer /> {areaUnits}
            </>} />
            <KeyValue term="Development cost" definition={<><Money currency="£" value={value?.developmentCost} integer /> {areaUnits}</>} />
            <KeyValue term="RoC" definition={<><Money value={value?.roc} oneDigit />%</>} error={errors && !!errors['roc']} />
            <KeyValue term="RoGDV" definition={<><Money value={value?.roGDV} oneDigit />%</>} />
            <KeyValue term="Project IRR" definition={<><Money value={value?.projectIRR} oneDigit />%</>} />
          </KeyValues>
        </FormSummary>
      </div>
      <FormSummary className={styles['single']} icon="parent" caption="The Borrower">
        <div className={styles['list-row']}>
          <KeyValues>
            <KeyValue term="Developer experience" definition={value?.experienceLevel === 'FirstTimeDevelopment' ? '1st time' : 'Experienced'} editUrl={urlEditBorrower + '#experienceLevel'} />
            {value?.completedSimilarSchemes ? <KeyValue term="Number of scheme completed" definition={<Money value={value?.completedSimilarSchemes} integer />} editUrl={urlEditBorrower + '#completedSimilarSchemes'} /> : null}
            <KeyValue term="Borrowing SPV domicile" definition={value?.borrowingSPVDomicile} editUrl={urlEditBorrower + '#borrowingSPVDomicile'} />
          </KeyValues>
          <KeyValues>
            <KeyValue term="Shareholders residency status" definition={value?.countryOfResidence} editUrl={urlEditBorrower + '#countryOfResidence'} />
            <KeyValue term="Credit history" definition={creditHistory} editUrl={urlEditBorrower + '#adverseCreditHistory'} />
            <KeyValue term="Availability to provide a PG" definition={boolToYesNo(value?.canProvidePersonalGuarantee)} editUrl={urlEditBorrower + '#canProvidePersonalGuarantee'} />
          </KeyValues>
        </div>        
      </FormSummary>
      <FormSummary className={styles['single']} icon="briefcase" caption="Summary appraisal" error={errors && !!errors['estimatedProfit']}>
        <div className={styles['list-row']}>
          <KeyValues>
            <KeyValue term="Gross development value" definition={<Money currency="£" value={value?.grossDevelopmentValue} integer />} editUrl={urlUnitsSummary + '#gdv'}   />
            <KeyValue term="Costs of sales" definition={<Money currency="£" value={value?.salesCosts} integer />} editUrl={urlUnitsSummary} />
          </KeyValues>
          <KeyValues>
            <KeyValue term="Acquisition cost" definition={<Money currency="£" value={value?.acquisitionCost} integer />} editUrl={urlEditAppraisal} />
            <KeyValue term="Build cost" definition={<Money currency="£" value={value?.buildCostWithContingency} integer />} editUrl={urlGiaSummary + '#buildCost'} />
            <KeyValue term="Professional cost" definition={<Money currency="£" value={value?.professionalCosts} integer />} editUrl={urlGiaSummary + '#professionalCosts'} />
            <KeyValue term="Statutory cost" definition={<Money currency="£" value={value?.statutoryCosts} integer />} editUrl={urlGiaSummary + '#statutoryCosts'} />
            <KeyValue term="Other cost" definition={<Money currency="£" value={value?.otherCosts || 0} integer />} editUrl={urlGiaSummary + '#otherCosts'} />
          </KeyValues>
        </div>
        <hr className={styles['summary-delimiter']} />
        <div className={styles['list-row']}>
          <KeyValues>
            <KeyValue term="Net Development Value" color="primary" definition={<Money currency="£" value={value?.netDevelopmentValue} integer />} />
          </KeyValues>
          <KeyValues>
            <KeyValue term="Estimated Profit (unlevered)" color="primary" definition={<Money currency="£" value={value?.estimatedProfit} integer />} error={errors && !!errors['estimatedProfit']} />
          </KeyValues>
        </div>

      </FormSummary>
      <FormSummary
        className={styles['single']}
        icon="money-pound-circle"
        caption="Funding requirement"        
      >
        <div className={styles['list-row']}>
          <KeyValues>
            <KeyValue term="Facility" definition={value?.facility} />
            <KeyValue term="Funding" definition={value?.funding} />
            <KeyValue term="Tenure" definition={value?.loanTenureMonths + ' months'} editUrl={urlEditFunding + '#loanTenureMonths'} />
            <KeyValue term="Build period" definition={value?.buildPeriodMonths + ' months'} editUrl={urlEditFunding + '#buildPeriodMonths'} />
          </KeyValues>
          <KeyValues>
            <KeyValue term="Net loan" definition={<Money currency="£" value={value?.netLoan} integer />} editUrl={urlEditFunding + '#netLoan'} />
            <KeyValue term="LTGDV" definition={<><Money value={value?.ltgdv} integer />%</>} />
            <KeyValue term="LTC" definition={<><Money value={value?.calculatedLTC}  integer/>%</>} />
            <KeyValue term="Closing" definition={value?.loanStartPeriodWeeks + ' weeks'} editUrl={urlEditFunding + '#loanStartPeriodWeeks'} />
          </KeyValues>
        </div>
      </FormSummary>
    </>
  );
}
