import {createApiThunk} from "@app/lib/thunks";
import {tasks} from '@app/api/rest';
import {ProjectTaskUpdateRequest} from "@app/types";

export const getList = createApiThunk('@@tasks/get-list', tasks.getList.bind(tasks));
export const getItem = createApiThunk('@@tasks/get-item', tasks.get.bind(tasks));
export const save = createApiThunk('@@tasks/save', tasks.save.bind(tasks));
export const share = createApiThunk('@@tasks/share', tasks.save.bind(tasks));
export const complete = createApiThunk('@@tasks/complete', async (request: ProjectTaskUpdateRequest, thunkApi) => {
  const result = await tasks.complete(request);  
  thunkApi.dispatch(getItem.fulfilled(result, '@@tasks/get-item', '123'));
});
export const cancel = createApiThunk('@@tasks/cancel', tasks.cancel.bind(tasks));
