import React, {PropsWithChildren, useCallback} from 'react';

import LenderPopupControl from '@components/organisms/LenderPopup';
import Dialog from '@components/atoms/Dialog';

import {Lender} from '@app/types';

interface LenderPopupProps {
  value?: Lender;
  setter?: (v?: Lender) => unknown;
  onClose?: (v?: unknown) => unknown;  
}

export default function LenderPopup({value, setter, onClose, children}: PropsWithChildren<LenderPopupProps>) {
  const handleClose = useCallback(() => {
    if (setter) setter(undefined);
    if (onClose) onClose();
  }, [setter, onClose]);
  return (
    <Dialog open={!!value} onClose={handleClose}>
      <LenderPopupControl icon={value?.logoUrl} category={value?.type} url={value?.website} name={value?.name} description={value?.description} email={value?.email} phone={value?.phone} onClose={handleClose}>
        {children}
      </LenderPopupControl>
    </Dialog>
  )
}