import React, {useCallback} from 'react';
import clsx from 'clsx';

import {Link} from 'react-router-dom';

import RemixIcon from '@components/atoms/RemixIcon';

import styles from './CompareAction.module.css';

interface CompareActionProps {
  className?: string;
  active?: boolean;
  index?: number;
  url?: string;
  selected?: boolean;
  onToggle: (index?: number) => unknown;
}

export default function CompareAction({className, active, index, url, selected, onToggle}: CompareActionProps) {
  const handleClick = useCallback(() => {
    onToggle && onToggle(index);
  }, [onToggle, index]);
  return (
    <span className={clsx(styles['container'], className)}>
      <span className={clsx(styles['button'], selected ? styles['button_selected'] : null)} onClick={handleClick}>
        <RemixIcon value="checkbox-multiple" />
      </span>
      {url && selected && active ? <Link className={styles['link']} to={url}>
        Compare
      </Link> : <span className={styles['link_disabled']}>Compare</span>}
    </span>
  );
};
