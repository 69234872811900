import useForm, {FieldsDefs, useFieldForm} from "@hooks/useForm";

import {DeveloperCv, DeveloperCvForm, DeveloperDetails} from "@app/types";
import {FormErrors} from "./validate";
import {FormErrors as NativeErrors} from '@hooks/useForm';

interface UseDeveloperCvFormProps {
  error?: FormErrors<DeveloperDetails>;
  values?: DeveloperDetails;
  onChange: (value?: DeveloperDetails) => unknown;
}

interface UseDeveloperFormProps {
  error?: FormErrors<DeveloperCvForm>;
  values?: DeveloperCvForm;
  onChange: (value?: DeveloperCvForm) => unknown;
}

const fieldsDeveloperForm = {
  companyName: {
    name: 'companyName',
    caption: 'Company name',
    required: true,
    desciption: 'Enter the name',
    hint: 'Name of the development company. If under a personal name, just enter the full name of the individual.',
  },
  website: {
    name: 'website',
    caption: 'Company website',
    description: 'Enter the URL',
    hint: 'Enter the link of the website to the development company.  If not applicable, keep blank.',
  },
} as FieldsDefs<DeveloperCvForm>;

const fieldsDeveloperDetails = {  
  name: {
    name: 'name',
    caption: 'Full name',
    required: true,
    description: 'Enter the full name',
    hint: 'Enter the first and last name.',
  },
  yearsOfExperience: {
    name: 'yearsOfExperience',
    caption: 'Years of experience',
    required: true,
    description: 'Enter the number of years',
    type: 'int',
    hint: 'Experience as a builder, developer or within the construction industry.',
  },
  bio: {
    name: 'bio',
    caption: 'Brief bio',
    required: true,
    hint: 'Track-record, qualifications and achievements.',
  },
} as FieldsDefs<DeveloperDetails>;

export function useDeveloperForm({error, values, onChange}: UseDeveloperFormProps) {
  const {fields, handleSubmit, errors} = useForm({    
    fields: fieldsDeveloperForm,
    values,
    errors: error,
    onChange,
    trackValues: true,    
    skipValidation: true,
  });
  return {
    fields, 
    handleSubmit,
    errors,
  }
};

export function useDeveloperDetails({error, values, onChange}: UseDeveloperCvFormProps) {
  const {fields, handleSubmit} = useForm({    
    errors: error ? error as NativeErrors<DeveloperDetails> : undefined,
    fields: fieldsDeveloperDetails,
    values,
    onChange,
    trackValues: true,
  });
  return {
    fields, 
    handleSubmit,
  };
};
