import React from 'react';
import clsx from 'clsx';

import ImageSearch from '../ImageSearch';

import styles from './ErrorImage.module.css';

interface ErrorImageProps {
  className?: string;
  image?: 'common' | 'search';
}

export default function ErrorImage({className, image}: ErrorImageProps) {
  return (    
    <div className={clsx(styles.container, className)}>
      {image === 'search' ? <ImageSearch /> : null}
    </div>
  )

}