import React, {useCallback, useEffect, useRef, PropsWithChildren} from 'react';
import clsx from 'clsx';
import noScroll from 'no-scroll';

import styles from './Dialog.module.css';

interface DialogProps {
  className?: string;
  open: boolean;
  setter?: (v: boolean) => unknown;
  onClose?: (e: Event) => void;
}

export default function Dialog({className, open, setter, onClose, children}: PropsWithChildren<DialogProps>) {
  const ref = useRef<HTMLDialogElement>(null);
  //
  const handleOpen = useCallback(() => {
    noScroll.on();
  }, [noScroll]);
  //
  const handleClose = useCallback(
    (e) => {
      noScroll.off();
      if (setter) setter(false);
      if (onClose) onClose(e);
    },
    [setter, onClose, noScroll]
  );
  const handleCloseByClick = useCallback(() => {    
    // @ts-ignore
    if (ref.current) ref.current.close();
  }, [ref]);
  //
  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('open', handleOpen);
      ref.current.addEventListener('close', handleClose);
    }
  }, [ref.current]);
  //
  useEffect(() => {
    if (!ref.current) return;
    if (open) {
      handleOpen();
      // @ts-ignore
      ref.current.showModal();
    }
    // @ts-ignore
    else ref.current.close();
  }, [ref, open, handleOpen]);
  //
  return (
    <dialog className={clsx(styles['container'], className)} ref={ref}>
      <div className={styles['backdrop']} onClick={handleCloseByClick} />
      {children}
    </dialog>
  );
}
