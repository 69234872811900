import React, {useCallback, useEffect} from 'react';
import {ContactUsRequest} from '@app/types';
import Button from '@components/atoms/Button';
import Dialog from '@components/atoms/Dialog';
import Paper from '@components/atoms/Paper';
import Title from '@components/atoms/Title';
import Form from '@components/molecules/Form';
import TextInput from '@components/molecules/TextInput';
import useForm from '../../hooks/useForm';

import styles from './ContactUs.module.css';

interface ContactUsProps {
  loading: boolean;
  error?: string;
  success: boolean;
  done: boolean;
  onSubmit: (value: ContactUsRequest) => unknown;
  onReset: () => unknown;
}

export default function ContactUs({loading, error, success, done, onSubmit, onReset}: ContactUsProps) {
  const {fields, handleSubmit, title, reset} = useForm();
  const {firstname, lastname, company, phone, email, message} = fields;
  useEffect(() => {
    return () => {
      reset();
      onReset();
    };
  }, [onReset, reset]);
  const handleReset = useCallback(() => {
    reset();
    onReset();
  }, [onReset, reset]);
  return (
    <> 
      <Paper className={styles['container']} border>
        <form onSubmit={handleSubmit(onSubmit)} >
          <Title className={styles['title']}>{title}</Title>
          <TextInput smallError field={firstname} margin={0} autoFocus />
          <TextInput smallError field={lastname} margin={0} />
          <TextInput smallError field={company} margin={0} />
          <TextInput smallError field={phone} margin={0} />
          <TextInput smallError field={email} margin={0} />
          <TextInput multiline smallError field={message} />
          <Button className={styles['submit']} loading={loading} color="primary" caption="Submit" />
        </form>
      </Paper>
      <Dialog open={done && success} onClose={handleReset}>
        <Paper size="large">
          <p>We will do something shortly</p>
          <Button caption="OK" color="primary" onClick={handleReset} />
        </Paper>
      </Dialog>
    </>
  );
};
