import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';

import {CustomerLoanAppraisal, LoanQuery} from '@app/types';
import SummaryBlock, {SummaryGroupProps, SummaryGroupRow, Value} from '@components/molecules/SummaryBlock';

import styles from './ProjectFullSummary.module.css';

interface ProjectSummaryProps {
  className?: string;
  query?: LoanQuery;
  offers?: CustomerLoanAppraisal[];
  onUpdateHeight?: (value: number) => void;
  opacity?: number;
}

const ROWS = [
  [{
    title: 'Project summary',
    items: [
      {caption: 'Site address', fieldName: 'siteFullAddress'},
      {caption: 'Planning status', fieldName: 'planningStatus'},
      {caption: 'Aquisition status', fieldName: 'acquisitionStatus'},
      {caption: 'Proposed development', fieldName: 'proposedDevelopment'},
      {caption: 'Number of units', fieldName: 'unitNumber', type: 'integer'},
      {caption: 'NIA', fieldName: 'nia', format: 'integer', metric: 'areaMetric'},
      {caption: 'GIA', fieldName: 'gia', format: 'integer', metric: 'areaMetric'},    
    ],
  }, {
    title: 'Key metrics',
    items: [
      {caption: 'Average unit price', fieldName: 'averageUnitPrice', format: 'currency'},
      {caption: 'Average unit price', fieldName: 'averageUnitPricePerMeter', format: 'currency', metric: 'areaMetric', showMetricCaption: true},
      {caption: 'Build cost', fieldName: 'buildCostPerMetric', format: 'currency', metric: 'areaMetric'},
      {caption: 'Development cost', fieldName: 'developmentCost', format: 'currency', metric: 'areaMetric'},
      {caption: 'RoC', fieldName: 'roc', format: '%'},
      {caption: 'RoGDV', fieldName: 'roGDV', format: '%'},
      {caption: 'Project IRR', fieldName: 'projectIRR', format: '%'},      
    ],
  }],
  [{
    title: 'Summary appraisal',
    items: [
      {caption: 'Gross development value', fieldName: 'grossDevelopmentValue', format: 'currency'},
      {caption: 'Cost of sales', fieldName: 'salesCosts', format: 'currency'},
      {caption: 'Net development value', fieldName: 'netDevelopmentValue', format: 'currency'},
      {caption: 'AcquisitionCost', fieldName: 'acquisitionCost', format: 'currency'},
      {caption: 'Build cost', fieldName: 'buildCostWithContingency', format: 'currency'},
      {caption: 'Professional cost', fieldName: 'professionalCosts', format: 'currency'},
      {caption: 'Statutory cost', fieldName: 'statutoryCosts', format: 'currency'},
      {caption: 'Other cost', fieldName: '', format: 'currency'},
      {caption: 'Estimated Profit (unlevered)', fieldName: 'estimatedProfit', format: 'currency'},
    ],
  }, {
    title: 'Funding requirement',
    items: [
      {caption: 'Facility', fieldName: 'facility'},
      {caption: 'Funding', fieldName: 'funding'},
      {caption: 'Loan tenure', fieldName: 'loanTenureMonths', format: 'months'},
      {caption: 'Build period', fieldName: 'buildPeriodMonths', format: 'months'},
      {caption: 'Net Loan', fieldName: 'netLoan', format: 'currency'},
      {caption: 'LTGDV', fieldName: 'ltgdv', format: '%.0'},
      {caption: 'LTC', fieldName: 'ltc', format: '%.0'},
      {caption: 'Loan structure', fieldName: 'shariahCompliant', format: 'shariah'},
      {caption: 'Closing', fieldName: 'loanStartPeriodWeeks', format: 'weeks'},
    ],
  }]
] as SummaryGroupRow[];

export default function ProjectFullSummary({className, query, offers, opacity, onUpdateHeight}: ProjectSummaryProps) {  
  //
  if (!query) return null;  
  //  
  const value = useMemo(() => {
    const result = {
      ...query,
    };
    if (!result.ltc) {      
      for (let offer of (offers || [])) {
        if (!result.ltc || offer.ltc > result.ltc) result.ltc = offer.ltc;
      }
    }
    return result;
  }, [query, offers]);
  return (
    <div className={clsx(styles['container'], className)} >
      <SummaryBlock rows={ROWS} value={value as Value} opacity={opacity} onUpdateHeight={onUpdateHeight} />
    </div>
  );
};
