import SummaryBlock, {SummaryGroupProps, SummaryGroupRow, Value, SummaryItem} from './SummaryBlock';

export default SummaryBlock;

export type {
  SummaryGroupProps,
  SummaryGroupRow,
  SummaryItem,
  Value,
};
