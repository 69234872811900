import {connect} from 'react-redux';

import {actions, selectors, RootState} from '@app/store/tasks';

import Tasks from '../components/Tasks';

export default connect(
  (state: RootState) => ({
    loading: selectors.list.loading(state),
    items: selectors.list.activeItems(state),    
  }),
  {
    onOpen: actions.list.get,
    onClose: actions.list.reset,
  },
)(Tasks);
