import React, {ChangeEvent, useCallback, useEffect, useMemo, useState} from "react";
import {SearchStep6Data} from "@app/types";
import {FormStepProps} from "../Form";

import TextInput from "@components/molecules/TextInput";
import RadioInput from "@components/molecules/RadioInput";
import PercentageInput from "@components/molecules/PercentageInput";

import styles from './Step-6.module.css';
import CheckBox from "@components/atoms/CheckBox";


export default function Step6({fields}: FormStepProps<SearchStep6Data>) {
  const {shariahCompliant, ltc, brokerFee, buildPeriodMonths, loanTenureMonths, exitStategy, loanStartPeriodWeeks, totalAcquisitionCost, totalBuildCost} = fields;
  const [getBestOffers, setBestOffers] = useState(!ltc.value);
  const bestOffersItem = {
    value: true,
    caption: 'Show me the best offers',
  };
  const ltcBase = useMemo(() => {
    return (totalAcquisitionCost.value || 0) + (totalBuildCost.value || 0);
  }, [totalAcquisitionCost, totalBuildCost]);
  const handleChangeOffers = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.checked;    
    setBestOffers(value);
    if (value) {
      ltc.setTextValue('');
    }
  }, [setBestOffers, ltc]);
  useEffect(() => {
    if (ltc.textValue !== '' && getBestOffers) {
      setBestOffers(false);
    }
  }, [getBestOffers, setBestOffers, ltc]);
    
  return (
    <>
      <RadioInput field={shariahCompliant} />
      <PercentageInput anchor="netLoan" className={styles['pre-or-container']} margin={0} field={ltc} alternate base={ltcBase} required smallError />
      <div className={styles['or-container']}>
        <label className={styles['or-caption']}>or</label>
        <CheckBox type="checkbox" value={bestOffersItem} checked={getBestOffers} onChange={handleChangeOffers} />
      </div>

      <TextInput field={brokerFee} inputType="float" suffix="%" />
      <TextInput anchor="buildPeriodMonths" margin={0} field={buildPeriodMonths} inputPattern="\d" smallError />
      <TextInput anchor="loanTenureMonths" field={loanTenureMonths} inputPattern="\d" smallError />

      <RadioInput field={exitStategy} />
      <TextInput anchor="loanStartPeriodWeeks" field={loanStartPeriodWeeks} inputPattern="\d" />
    </>
  );
}
