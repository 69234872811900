import { Search, SearchFormData } from '@app/types';
import {combineReducers} from '@reduxjs/toolkit';
import slices, {InitialStateItem, InitialStateList, InitialStateOffers} from './slice';
import thunks from './thunks';

export const reducer = combineReducers({
  current: slices.current.reducer,
  list: slices.list.reducer,
  offers: slices.offers.reducer,
});

export const actions = {
  ...thunks,
  current: {
    ...thunks.current,
    ...slices.current.actions,
  },
  offers: {
    ...thunks.offers,
    ...slices.offers.actions,
  },
};

export interface RootState {
  search: {
    current: InitialStateItem;
    list: InitialStateList;
    offers: InitialStateOffers;
  };
}

export const selectors = {
  current: {
    loading: (state: RootState) => state.search.current.loading,
    posting: (state: RootState) => state.search.current.posting,
    loaded: (state: RootState) => state.search.current.loaded,
    posted: (state: RootState) => state.search.current.posted,
    saving: (state: RootState) => state.search.current.saving,
    value: (state: RootState) => state.search.current.value,
    error: (state: RootState) => state.search.current.error,
    step: (state: RootState) => state.search.current.step,
    savingForShare: (state: RootState) => state.search.current.savingForShare,
    queryId: (state: RootState) => (state.search.current.value ? state.search.current.value.id : 'new'),
    maxStepFilled: (state: RootState) => selectMaxStepFilled(state.search.current.value),
  },
  list: {
    loading: (state: RootState) => state.search.list.loading,
    value: (state: RootState) => state.search.list.value,
  },
  offers: {
    loading: (state: RootState) => state.search.offers.loading,
    loaded: (state: RootState) => state.search.offers.loaded,
    value: (state: RootState) => state.search.offers.value,
    error: (state: RootState) => state.search.offers.error,
    applying: (state: RootState) => state.search.offers.applying,
    applied: (state: RootState) => state.search.offers.applied,
    projectId: (state: RootState) => state.search.offers.appliedProjectId,
  }
};

const fieldsStep1 = ['projectSummary', 'proposedDevelopment', 'partBuiltScheme', 'siteFullAddress', 'acquisitionStatus', 'planningStatus'] as (keyof SearchFormData)[];
const fieldsStep2 = ['experienceLevel', 'canProvidePersonalGuarantee', 'adverseCreditHistory', 'countryOfResidence', 'borrowingSPVDomicile'] as (keyof SearchFormData)[];
const fieldsStep3 = ['areaMetric', 'unitDetails', 'salesAgentFee', 'salesLegalFee']  as (keyof SearchFormData)[];
const fieldsStep4 = ['procurementRoute', 'gia', 'buildCosts', 'contingency', 'professionalCosts', 'statutoryCosts', 'otherCosts']  as (keyof SearchFormData)[];
const fieldsStep5 = ['purchasePrice', 'stampDutyTaxLevy', 'acquisitionAgentFee', 'acquisitionLegalFee']  as (keyof SearchFormData)[];
const fieldsStep6 = ['shariahCompliant', 'brokerFee', 'buildPeriodMonths', 'loanTenureMonths', 'exitStategy', 'loanStartPeriodWeeks']  as (keyof SearchFormData)[];

export function selectMaxStepFilled(data?: SearchFormData) {
  if (!data) return 0;
  if (!data.id) return 0;  
  if (calcFilled(data, fieldsStep6) === fieldsStep6.length) return 6;
  if (calcFilled(data, fieldsStep5) === fieldsStep5.length) return 5;
  if (calcFilled(data, fieldsStep4) === fieldsStep4.length) return 4;
  if (calcFilled(data, fieldsStep3) === fieldsStep3.length) return 3;
  if (calcFilled(data, fieldsStep2) === fieldsStep2.length) return 2;
  if (calcFilled(data, fieldsStep1) === fieldsStep1.length) return 1;
  return 0;
}

function isNull(v?: any) {
  return typeof v === 'undefined' || v === '';
}

function calcFilled(data: SearchFormData, keys: (keyof SearchFormData)[]) {
  return keys.reduce((result, key) => result + (isNull(data[key]) ? 0 : 1), 0);
}