import React, {ReactElement, ReactNode, RefObject, useCallback, useEffect, useMemo, useRef, useState} from "react";
import base64 from "uuid-base64";
import {
  Project,
  ProjectTask,
  TasksRequest,
  ControllerOf,
  AssetAndLiability as AssetAndLiabilityType,
  ProjectTaskUpdateRequest,
  DeveloperCv as DeveloperCvType,
  LoanOfferSearchResponse,
  CustomerLoanAppraisal,
  BorrowerDetails as BorrowerDetailsType,
} from "@app/types";
import {Aside} from "@features/Layout";
import TaskDetails from "../TaskDetails";
import {validateAssetLiability, validateBorrowerDetails, validateDeveloperCv, validateSaveBorrowerDetails, FormErrors} from "../../../Tasks/hooks/validate";

import ContentTitle from "@features/Layout/components/ContentTitle";
import RemixIcon from "@components/atoms/RemixIcon";
import Button from "@components/atoms/Button";
import Dialog from "@components/atoms/Dialog";
import Share from "@components/organisms/Share";

import FaqLink from "@components/molecules/FaqLink";

import {AssetAndLiability, BorrowerDetails, DeveloperCv, DevelopmentAppraisal} from "@features/Tasks";

import styles from "./Task.module.css";
import RelatedLenders from "../RelatedLenders";
import Skeleton from "@components/atoms/Skeleton";
import Question from "@components/organisms/Question";

const questionText = `Are you sure that you want to mark this task as completed?
You will not be able to edit it anymore.`;

interface TaskProps {
  loading?: boolean;
  projectId?: string;
  taskId: string;
  appraisalId?: string;
  task?: ProjectTask;
  project?: Project;
  offer?: CustomerLoanAppraisal;
  saving?: boolean;
  sharing?: boolean;
  completing?: boolean;
  completed?: boolean;
  onOpen: (taskId: string) => unknown;
  onClose?: () => unknown;
  onOpenProject?: (id: string) => unknown;
  onOpenOffer?: (id: string) => unknown;
  onSave: (request: ProjectTaskUpdateRequest) => unknown;
  onShare: (request: ProjectTaskUpdateRequest) => unknown;
  onComplete: (request: ProjectTaskUpdateRequest) => unknown;
  onCompleteReset: () => unknown;
  onCancel: (request: ProjectTask) => unknown;
  TaskDetails: ControllerOf<typeof TaskDetails, "loading" | "value">;
}

export default function Task({
  loading,
  projectId,
  taskId,
  appraisalId,
  task,
  project: sourceProject,
  offer,
  saving,
  sharing,
  completing,
  completed,
  onOpen,
  onClose,
  onOpenProject,
  onOpenOffer,
  onSave,
  onShare,
  onComplete,
  onCancel,
  onCompleteReset,
  TaskDetails,
}: TaskProps) {
  const refActions = useRef() as RefObject<HTMLDivElement>;
  //
  const [errorsAssetLiability, setErrorsAssetLiability] = useState<FormErrors<AssetAndLiabilityType>>();
  const [errorsBorrowerDetails, setErrorsBorrowerDetails] = useState<FormErrors<BorrowerDetailsType>>();
  const [errorsDeveloperCv, setErrorsDeveloperCv] = useState<FormErrors<DeveloperCvType>>();
  //
  const refShowShare = useRef(false);
  const [showShare, setShowShare] = useState(false);
  //
  const [submitVisible, setSubmitVisible] = useState(false);
  // open something
  useEffect(() => {
    setErrorsBorrowerDetails(undefined);
    onOpen(taskId);
    return () => {
      onClose && onClose();
    };
  }, [taskId, onOpen, onClose, setErrorsBorrowerDetails]);
  //
  useEffect(() => {
    onOpenProject && projectId && onOpenProject(projectId);
  }, [projectId, onOpenProject]);
  useEffect(() => {
    task?.appraisalId && onOpenOffer && onOpenOffer(task.appraisalId);
  }, [task?.appraisalId, onOpenOffer]);
  //
  const handleRefresh = useCallback(() => {
    onOpen(taskId);
  }, [taskId, onOpen]);
  // saving something
  const shareLink = useMemo(() => {
    if (task) {
      return window.location.origin + "/t/" + base64.encode(task?.id) + "/";
    }
    return "";
  }, [task]);
  //
  const project = useMemo(() => {
    if (sourceProject && task && sourceProject.id === task.projectId) return sourceProject;
    return undefined;
  }, [sourceProject, task]);
  //
  const [taskAssetAndLiability, setTaskAssetAndLiability] = useState<AssetAndLiabilityType>();
  const [taskDeveloperCv, setTaskDeveloperCv] = useState<DeveloperCvType>();
  const [taskBorrowerDetails, setTaskBorrowerDetails] = useState<BorrowerDetailsType>();
  //
  useEffect(() => {
    if (task && task.assetsAndLiabilities) setTaskAssetAndLiability(task?.assetsAndLiabilities[0]);
    if (task && task.developerCv) setTaskDeveloperCv(task?.developerCv);
    if (task && task.borrowerDetails) setTaskBorrowerDetails(task?.borrowerDetails);
  }, [task, setTaskAssetAndLiability, setTaskDeveloperCv, setTaskBorrowerDetails]);
  useEffect(() => {
    if (refShowShare.current !== sharing) {
      if (refShowShare.current) setShowShare(true);
      if (sharing !== undefined) {
        refShowShare.current = sharing;
      }
    }
  }, [sharing, refShowShare]);
  //
  useEffect(() => {
    if (completed) {
      onCompleteReset && onCompleteReset();
      setSubmitVisible(false);
    }
  }, [completing, completed, setSubmitVisible, onCompleteReset]);
  //
  const handleComplete = useCallback(() => {
    const form = refActions.current?.parentNode?.querySelector("form");
    if (form) form.requestSubmit();
  }, [task, refActions]);
  const performSave = useCallback(
    (setter?: (v: ProjectTaskUpdateRequest) => unknown) => {
      if (task) {
        const {id, developerCv, borrowerDetails, assetsAndLiabilities} = task;
        const request = {
          taskId: id,
          developerCv,
          borrowerDetails,
          assetsAndLiabilities,
        } as ProjectTaskUpdateRequest;
        if (task.type === "AssetLiabilityStatement" && taskAssetAndLiability) {
          request.assetsAndLiabilities = [taskAssetAndLiability];
        } else {
          delete request.assetsAndLiabilities;
        }
        if (task.type === "DeveloperCV" && taskDeveloperCv) {
          request.developerCv = taskDeveloperCv;
        } else {
          delete request.developerCv;
        }
        if (task.type === "BorrowerDetails" && taskBorrowerDetails) {          
          const errors = validateSaveBorrowerDetails(taskBorrowerDetails);
          console.log('PERFORM SAVE BD', errors);
          setErrorsBorrowerDetails(errors);
          if (errors) return;
          request.borrowerDetails = taskBorrowerDetails;
        } else {
          delete request.borrowerDetails;
        }
        setter && setter(request);
      }
    },
    [task, taskAssetAndLiability, taskDeveloperCv, taskBorrowerDetails, setErrorsBorrowerDetails]
  );

  const performComplete = useCallback(() => {
    console.log('perform complete');
    if (task) {
      const {id, developerCv, borrowerDetails, assetsAndLiabilities} = task;
      const request = {
        taskId: id,
        developerCv,
        borrowerDetails,
        assetsAndLiabilities,
      } as ProjectTaskUpdateRequest;
      if (task.type === "AssetLiabilityStatement" && taskAssetAndLiability) {        
        //
        request.assetsAndLiabilities = [taskAssetAndLiability];
      } else {
        delete request.assetsAndLiabilities;
      }
      if (task.type === "DeveloperCV" && taskDeveloperCv) {
        request.developerCv = taskDeveloperCv;
      } else {
        delete request.developerCv;
      }
      if (task.type === "BorrowerDetails" && taskBorrowerDetails) {
        request.borrowerDetails = taskBorrowerDetails;
      } else {
        delete request.borrowerDetails;
      }
      console.log('completing request', request);      
      onComplete && onComplete(request);
    }
  }, [task, taskAssetAndLiability, taskDeveloperCv, taskBorrowerDetails, setErrorsAssetLiability, onComplete]);
  //
  const handleSave = useCallback(() => {
    performSave(onSave);
  }, [performSave, onSave]);
  const handleShare = useCallback(() => {
    performSave(onShare);
  }, [performSave, onShare]);
  //
  const handleSubmitAssetLiability = useCallback(
    (v: AssetAndLiabilityType) => {
      const errors = validateAssetLiability(v || {});
      setErrorsAssetLiability(errors);
      if (errors) {
      } else {
        setErrorsAssetLiability(undefined);
        setSubmitVisible(true);
        //performComplete();
      }
    },
    [setErrorsAssetLiability, validateAssetLiability, setSubmitVisible]
  );
  //
  const handleSubmitDeveloperCv = useCallback(
    (v: DeveloperCvType) => {
      const errors = validateDeveloperCv(v);
      setErrorsDeveloperCv(errors);
      if (errors) {
      } else {
        setErrorsDeveloperCv(undefined);
        setSubmitVisible(true);
        // performComplete();
      }
    },
    [setErrorsDeveloperCv, setSubmitVisible]
  );
  //
  const handleSubmitDeveloperAppraisal = useCallback(() => {
    setSubmitVisible(true);
    // performComplete();
  }, [setSubmitVisible]);
  //
  const handleSubmitBorrowerDetails = useCallback(
    (v: BorrowerDetailsType) => {      
      console.log('COMPLETE?');
      const errors = validateBorrowerDetails(v);
      setErrorsBorrowerDetails(errors);
      console.log('SUBMIT?', errors);
      if (errors) {
      } else {
        setErrorsBorrowerDetails(undefined);
        setSubmitVisible(true);
        //performComplete();
      }
    },
    [performComplete]
  );
  //
  const taskDValue = {
    ...(task || {}),
    name: project?.name,
  };
  const aside = (
    <>
      <TaskDetails loading={loading} value={taskDValue as unknown as ProjectTask} />
      <RelatedLenders loading={loading} value={task?.relatedLenders} />
      <FaqLink />
    </>
  );
  const hasOffer = !!offer?.name;
  const isActive = task?.status === "Active";
  // const isActive = true;
  //  
  return (
    <>
      <ContentTitle title={task?.name || ""} breadcrumb={{caption: "Dashboard", url: "/"}} loading={loading || false} />
      <ContentTitle title={task?.name || ""} breadcrumb={{caption: project?.name || "(Project not found)", url: `/projects/${projectId}/`}} loading={loading || false} />
      <ContentTitle title={task?.name || ""} breadcrumb={{caption: task?.name || "(Task not found)", url: `/projects/${projectId}/tasks/${taskId}/`}} loading={loading || false} />
      {!task || isActive ? (
        <div className={styles["actions"]} ref={refActions}>
          {isActive ? (
            <>
              <Button
                icon={<RemixIcon value="check-double" />}
                className={styles["action"]}
                caption="Complete"
                color="secondary"
                layout="auto"
                margin={0}
                onClick={handleComplete}
              />
              <Button
                icon={<RemixIcon value="save-3" />}
                className={styles["action"]}
                caption="Save"
                color="secondary"
                layout="auto"
                margin={0}
                onClick={handleSave}
                loading={saving}
              />
              <Button
                icon={<RemixIcon value="share" />}
                className={styles["action"]}
                caption="Share link"
                color="secondary"
                layout="auto"
                margin={0}
                onClick={handleShare}
                loading={sharing}
              />
            </>
          ) : (
            <Skeleton active height="40px" />
          )}
        </div>
      ) : null}
      <Aside aside={aside} alternate>
        {task?.type === "AssetLiabilityStatement" ? (
          <AssetAndLiability
            active={isActive}
            submitting={completing}
            saving={saving}
            error={errorsAssetLiability}
            value={taskAssetAndLiability}
            onChange={setTaskAssetAndLiability}
            onSubmit={handleSubmitAssetLiability}
            onSave={handleSave}
          />
        ) : null}
        {task?.type === "DeveloperCV" ? (
          <DeveloperCv
            active={isActive}
            error={errorsDeveloperCv}
            submitting={completing}
            saving={saving}
            task={task}
            value={taskDeveloperCv}
            onChange={setTaskDeveloperCv}
            onSubmit={handleSubmitDeveloperCv}
            onSave={handleSave}
          />
        ) : null}        
        {task?.type === "BorrowerDetails" ? (
          <BorrowerDetails
            submitting={completing}
            saving={saving}
            active={isActive}
            error={errorsBorrowerDetails}
            value={taskBorrowerDetails}
            onChange={setTaskBorrowerDetails}
            onSubmit={handleSubmitBorrowerDetails}
            onSave={handleSave}
          />
        ) : null}
        {task?.sampleFile?.fileName ? (
          <DevelopmentAppraisal
            active={isActive}
            submitting={completing}
            saving={saving}
            task={task}
            onRefresh={handleRefresh}
            onSubmit={handleSubmitDeveloperAppraisal}
            onSave={handleSave}
          />
        ) : null}
      </Aside>
      <Dialog open={showShare} onClose={() => setShowShare(false)}>
        <Share name="task" link={shareLink} onCancel={() => setShowShare(false)} />
      </Dialog>
      <Dialog open={submitVisible} setter={setSubmitVisible}>
        <Question title="" text={questionText} action="Submit" onAction={performComplete} onCancel={() => setSubmitVisible(false)} loading={completing} />
      </Dialog>
    </>
  );
}
